import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import $ from 'jquery';
import env from '../../../Config';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'


const BlogDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  $(document).ready(function () {
    // var stickyTop = $('.sticky1').offset().top;
    $(window).scroll(function () {
      var windowTop = $(window).scrollTop();
      // console.log(windowTop, 'windowTop');

      if (windowTop > 450 && windowTop < 2600) {
        $('.sticky1').css({ 'position': 'fixed', 'top': '120px' });
      } else {
        $('.sticky1').css({ 'position': 'absolute', 'top': '0' });
      }
    });
  });

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
      <Helmet>
        <title>Versatile Commerce | Blog </title>
      </Helmet>
      <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/Digital-Transformation-The-Future-Business.png)` }}>
        <div className='lg:container mx-auto px-4'>
          <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%]">
            <div className="text-center mx-auto lg:my-[5rem]">
              <div className="bg-[#FB9851] mb-[50px] text-[#FFF] py-2 px-9 text-[16px] text-center rounded-[40px] inline-block clear-both">Technology News</div>
              <h2 className="base-h2 text-[#101828] md:text-[#fff!important] lg:w-[48%] mx-auto">
                Digital Transformation: The Future of Business
              </h2>
            </div>
          </div>
        </div>
      </div>


      <div className='clear-both'></div>




      {/* blog details start */}
      <div className=" px-[12px] md:px-[36px] xl:px-0 mt-[70px] mx-auto text-md text-gray-500 relative blog-content max-w-[830px] text-[14px] leading-[25px] md:text-[18px] md:leading-[32px] lg:flex">
        <div className="flex-col items-center gap-6 absolute follow-scroll translate-x-[-56px] sticky1 hidden lg:block" >
          <p className="text-heading-6 font-bold text-gray-400 mb-5">Share</p>
          <a href="#"> <img src={env.baseUrl + '/images/blog-details-sm-icon1.png'} alt="img" /></a>
          <a href="#" className='my-5 inline-block'> <img src={env.baseUrl + '/images/blog-details-sm-icon2.png'} alt="" /></a>
          <a href="#"> <img src={env.baseUrl + '/images/blog-details-sm-icon3.png'} alt="" /></a>
        </div>

        <div className='lg:ml-[60px]'>
          <p className="mb-6 md:mb-9">In today's fast-paced and ever-changing business landscape, companies must adapt to stay relevant and competitive. One of the most significant changes in recent years is the rise of digital transformation. Digital transformation is the integration of digital technology into all areas of a business, fundamentally changing how businesses operate and deliver value to customers. In this blog post, we will explore the importance of digital transformation and how it can benefit your business. </p>

          <div className="mb-6 md:mb-9">
            <img className="h-full w-full object-cover" src={env.baseUrl + '/images/Importance-of-Digital-Transformation.png'} alt="Importance of Digital Transformation" />
          </div>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">The Importance of Digital Transformation
          </h3>
          <p className="mb-6 md:mb-9">Digital transformation is not just about adopting new technology; it's about rethinking how your business operates and interacts with customers. By embracing digital transformation, businesses can improve their operational efficiency, enhance customer experience, and drive innovation. </p>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Operational Efficiency
          </h3>
          <p className="mb-6 md:mb-9">Digital technology can help businesses streamline their operations, reduce costs, and increase productivity. For example, automation can eliminate manual processes, reducing the risk of errors and freeing up employees to focus on more strategic tasks. Cloud computing can provide businesses with access to scalable, cost-effective computing resources, allowing them to expand their operations without significant capital investments. </p>


          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Enhanced Customer Experience
          </h3>
          <p className="mb-4 md:mb-6">Digital transformation can also help businesses improve their customer experience. By leveraging data analytics, businesses can gain insights into customer behavior and preferences, enabling them to personalize their offerings and deliver more targeted marketing campaigns. Mobile apps and chatbots can provide customers with convenient self-service options, reducing wait times and improving satisfaction. </p>


          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Innovation </h3>
          <p className="mb-4 md:mb-6">Digital transformation can also drive innovation by enabling businesses to explore new business models and revenue streams. For example, businesses can leverage the Internet of Things (IoT) to create new products and services or use blockchain technology to improve supply chain transparency and security. </p>

          <figure className="mb-8 md:mb-12">
            <div className="grid md:grid-cols-2 gap-[30px]">

              <div className='col-span-1'>
                <img className="h-full w-full object-cover rounded-2xl" src={env.baseUrl + '/images/Benefits-of-Digital-Transformation.png'} alt="Benefits of Digital Transformation" />
              </div>
              <div className='col-span-1'>
                <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Benefits of Digital Transformation </h3>
                <p className="mb-4 md:mb-4">Digital transformation can bring numerous benefits to businesses, including:</p>

                <ul className='list-disc md:ml-6'>
                  <li>Improved efficiency and productivity</li>
                  <li>Enhanced customer experience</li>
                  <li>Increased revenue and profitability</li>
                  <li>Greater agility and flexibility</li>
                  <li>Improved data security and compliance</li>
                </ul>
                <br />
                <p className="mb-4 md:mb-6">By embracing digital transformation, businesses can stay ahead of the competition and position themselves for long-term success.</p>
              </div>

              {/* <img className="h-full w-full object-cover rounded-2xl row-span-2" src={env.baseUrl + '/images/blog-details-img3.png'} alt="img" />
                    <img className="h-full w-full object-cover rounded-2xl" src={env.baseUrl + '/images/blog-details-img4.png'} alt="img" />
                    <img className="h-full w-full object-cover rounded-2xl" src={env.baseUrl + '/images/blog-details-img5.png'} alt="img" /> */}
            </div>
          </figure>


          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Conclusion</h3>
          <p className="mb-4 md:mb-4">Digital transformation is not a one-time event; it's an ongoing journey that requires continuous adaptation and innovation. Businesses that fail to embrace digital transformation risk falling behind their competitors and losing relevance in the marketplace. By embracing digital transformation, businesses can improve their operational efficiency, enhance customer experience, drive innovation, and position themselves for long-term success in today's digital world. </p>
        </div>
      </div>
      {/* blog details end */}
      <div className='border-b-2 lg:container mx-auto mt-[50px]'></div>

      <div className='clear-both'></div>
      <section className='Our-blogs'>
        <div className='md:container p-4 mx-auto mt-[50px] mb-[50px] lg:mb-[100px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Recommended <span className='text-[#F26722]'> Blogs</span></h2>

          <div className='mt-[50px]'>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              autoPlaySpeed={3000}
              autoPlay={true}
              infinite={true}
            >
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/we-can-blend-colors-multiple-ways" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">We can blend colors multiple ways, the most common</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/blog-We-can-blend-colors-multiple-ways.png'} alt="We-can-blend-colors-multiple-ways" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/engineering-experiences-a-journey" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Engineering Experiences: A Journey of Innovation and Creativity</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/Blog-Engineering-Experiences.png'} alt="Engineering-Experiences" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#BEE1E6]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/saas-products" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Customer Services</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">SaaS Products</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/blog-SaaS-Products.png'} alt="SaaS-Products" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/the-evolution-significance" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">The Evolution and Significance of Cloud Engineering in Modern Computing</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/the-evolution-and-significance.png'} alt="the-evolution-and-significance" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/navigating-the-digital-migration" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Navigating the Digital Migration: A New Frontier in Business Transformation</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/navigating-the-digital-migration.png'} alt="navigating-the-digital-migration" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

            </Carousel>
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogDetails