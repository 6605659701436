import React from 'react'
import { FaArrowRight } from 'react-icons/fa';
import env from './../../Config';
const Discovery = ({ title, subTitle, desc, btnName, placeholder, type }) => {
  const [emailDiscovery, setEmailDiscovery] = React.useState({ email: '', emailTouched: false });
  const [errorMsg, setErrorMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');

  const newsLatter = () => {
    if (emailDiscovery.email && validateEmail(emailDiscovery.emailValid) != null) {
      const obj = { email: emailDiscovery.email };
      const url = 'https://nodweb.versatilecommerce.com/update-contact?type=discovery-call'; // Replace with your API endpoint
      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
      }).then(response => response.json())
        .then(result => {
          if (result && result?.body?.status != "error" && result?.body && result?.body?.category != 'OBJECT_ALREADY_EXISTS') {
            setEmailDiscovery({
              emailTouched: false,
              email: '',
            });
            setSuccessMsg('Successfully done');
            setTimeout(() => {
              setSuccessMsg('');
            }, 3000);
          } else {
            setErrorMsg(result?.body?.message);
          }
        })
        .catch(error => {
          // Handle error
        });
    } else {
      setEmailDiscovery({ ...emailDiscovery, emailTouched: true })
    }
  }


  const subscribeForJobAlert = () => {
    if (emailDiscovery.email && validateEmail(emailDiscovery.emailValid) != null) {
      const obj = { email: emailDiscovery.email };
      const url = 'https://nodweb.versatilecommerce.com/update-contact?type=subscribe-for-job-alert'; // Replace with your API endpoint
      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
      }).then(response => response.json())
        .then(result => {
          if (result && result?.body?.status != "error" && result?.body && result?.body?.category != 'OBJECT_ALREADY_EXISTS') {
            setEmailDiscovery({
              emailTouched: false,
              email: '',
            });
            setSuccessMsg('Successfully done');
            setTimeout(() => {
              setSuccessMsg('');
            }, 3000);
          } else {
            setErrorMsg(result?.body?.message);
          }
        })
        .catch(error => {
          // Handle error
        });
    } else {
      setEmailDiscovery({ ...emailDiscovery, emailTouched: true })
    }
  }

  const validateEmail = (email) => {
    let val = /\S+@\S+\.\S+/.test(email);
    return val;
  }

  return (
    <>
      <section className='Discovery-Call bg-[#F2F4F7] my-[50px] py-[50px] lg:my-[100px] lg:pt-[90px] lg:pb-[30px]'>
        <div className='md:container px-4 lg:px-4 mx-auto'>
          <div className='relative hidden lg:block'>
            <img className='max-w-full ml-5 ml-[auto] absolute right-0 top-[-70px]' src={env.baseUrl + '/images/wave-top.png'} />
          </div>
          <h2 className='text-[40px] md:text-[54px] text-center font-bold'>{title} <span className='text-[#F26722]'> {subTitle}</span></h2>
          <p className='text-[16px] text-[#475467] lg:w-[60%] text-center mx-auto mt-[5px] font-semibold'>{desc}</p>

          <div className='grid lg:grid-cols-12'>
            <div className='lg:col-start-4 lg:col-span-6'>
              <div className='relative mt-9 md:mx-[50px] lg:mx-[auto]'>
                <button onClick={() => type == 'Careers' ? subscribeForJobAlert() : newsLatter()} className='absolute px-4 lg:px-9 right-[10px] rounded-[7px] top-[11px] bg-[#101828] h-[50px] text-white font-bold hover:bg-[#f26722] hover:text-[#fff] hover:drop-shadow-lg duration-300 flex jusity-center items-center'>
                  <span className='hidden md:block'> {btnName}</span> <FaArrowRight className='text-[15px] md:ml-[13px]' /></button>
                <input onChange={(e) => setEmailDiscovery({ ...emailDiscovery, email: e.target.value })} type="email"
                  className="block w-full lg:pl-9 px-4 py-2 mt-5 h-[72px] text-gray-700 bg-white border rounded-md focus:border-orange-400 focus:ring-orange-300 focus:outline-none focus:ring focus:ring-opacity-40" placeholder={`${placeholder} ...`} value={emailDiscovery.email} />
              </div>
              {emailDiscovery.email && emailDiscovery.emailTouched && !validateEmail(emailDiscovery.email) ? (<span className="text-[red] mt-[10px] font-bold">{errorMsg || 'Please Enter Valid Email'}</span>) : null}
              {successMsg ? (<span className="text-[green] mt-[10px] font-bold">{successMsg}</span>) : null}
            </div>
          </div>
          <div className='hidden lg:block'>
            <img className='max-w-full' src={env.baseUrl + '/images/wave-down.png'} />
          </div>
        </div>
      </section>

    </>
  )
}

export default Discovery