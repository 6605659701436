import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import env from '../../../Config'
import { FaArrowRight } from 'react-icons/fa';
import Discovery from '../../../Components/DiscoveryCall/Discovery'
import Faqs from '../../../Components/Faqs/Faqs';
import './../../../Components/CustomJs/customjs';

const Insurance = () => {
    useEffect( ()=>{
        window.scrollTo(0, 0)
    })

    return (
        <>
            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrlLocal}/images/insurance_banner.png)` }}>
                <div className='lg:container mx-auto px-4 main-bnr-hgt'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
                        <div className="flex-1 lg:py-[100px]">
                            <h2 className="text-[2rem] lg:text-[82px] leading-tight font-bold text-center md:text-left lg:text-[#101828]">
                                Empowering <br /> <span className='text-[#f26722]'> Smart Insurance </span>
                            </h2>
                            <p className="text-[18px] lg:text-[24px] font-semibold mt-5 md:w-[50%] text-center md:text-left">
                                Lower operational costs, facilitate novel business models, and improve partner integration to expedite service delivery and align with customer expectations
                            </p>
                            <div className="flex items-center justify-start mt-[50px] lg:mt-[70px]">
                                <Link to='/contact-form' type="button" className='xs-mx-auto'> <a className="flex items-center relative transition-all group px-[22px] py-[12px] lg:px-[32px] lg:py-[19px] rounded-[8px] bg-[#F2F4F7] text-[#101828] text-[18px] hover:bg-[#f26722] hover:text-[#fff] hover:drop-shadow-lg duration-300  text-heading-6 tracking-wide " href="#">
                                    <span className="block text-inherit w-full h-full rounded-[8px] text-lg cutom-font mr-[15px]">Talk to our Experts</span><FaArrowRight className='text-[16px] ml-[2px]' /></a></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className='our-usp my-[70px] reveal'>
                <div className='lg:container mx-auto px-4'>
                    <div className='text-center'>
                        <h2 className="base-h2">
                            Capabilities / Services
                        </h2>
                    </div>

                    <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-x-12 gap-y-12 mt-[50px] lg:mt-[70px]">
                        <div className="Capabty-card">
                            <div className='capbty-ong-box'></div>
                            <h5>Omnichannel Client Experiences</h5>
                            <p>
                                Innovate insurance platform for a seamless, immersive client experience across all channels, redefining engagement with our services
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>

                        <div className="Capabty-card">
                            <div className='capbty-ong-box'></div>
                            <h5>Transforming Insurance Operations</h5>
                            <p>
                                Unlock human-machine collaboration for resilient, adaptable operations, preparing your business for a future-ready foundation.
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>

                        <div className="Capabty-card">
                            <div className='capbty-ong-box'></div>
                            <h5>Insurance Workforce Evolution</h5>
                            <p>
                                Seize the opportunities presented by upcoming insurance workforce trends to shape a dynamic and competitive workforce that meets the demands of the future.
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>

                        <div className="Capabty-card">
                            <div className='capbty-ong-box'></div>
                            <h5>Lorem ipsum</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque porta lacus vitae vulputate imperdiet.
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>

                        <div className="Capabty-card">
                            <div className='capbty-ong-box'></div>
                            <h5>Lorem ipsum dolor sit</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque porta lacus vitae vulputate imperdiet.
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>

                        <div className="Capabty-card">
                            <div className='capbty-ong-box'></div>
                            <h5>Lorem ipsum dolor sit</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque porta lacus vitae vulputate imperdiet.
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>

                        <div className="Capabty-card">
                            <div className='capbty-ong-box'></div>
                            <h5>Lorem ipsum dolor</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque porta lacus vitae vulputate imperdiet.
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>

                        <div className="Capabty-card">
                            <div className='capbty-ong-box'></div>
                            <h5>Lorem ipsum dolor</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque porta lacus vitae vulputate imperdiet.
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>

                        <div className="Capabty-card">
                            <div className='capbty-ong-box'></div>
                            <h5>Lorem ipsum dolor</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque porta lacus vitae vulputate imperdiet.
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>
                    </div>

                    <div className="experinace-befits bg-[#FB9851] p-[1rem] md:py-[40px] md:px-[80px] mt-[50px] mt-[70px] rounded-[7px] text-[white] lg:flex justify-between">
                        <h4 className='text-[28px] lg:text-[50px] lg:w-[65%] leading-none'>Experience the Benefits of Versatile Commerce Today</h4>

                        <Link to='/contact-form' class="mt-7 px-4 lg:px-9 right-[10px] rounded-[7px] top-[11px] bg-[#101828] h-[50px] text-white font-bold hover:bg-[#f26722] hover:text-[#fff] hover:drop-shadow-lg duration-300 flex justify-center items-center"><span class="me-2"> Book Now </span> <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="text-[15px] md:ml-[13px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></Link>
                    </div>
                </div>
            </section>

            <section className="solve-mlti-promls ">
                <div className="lg:container mx-auto px-4">
                    <div className="text-center">
                        <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">Features</div>
                    </div>

                    <h2 className='base-h2 mb-[70px] lg:w-[70%] mx-auto text-center'>Solve Multiple Problems for Insurance on One Platform
                    </h2>

                    <div className="relative reveal">
                        <div className="solve-box mt-[30px] lg:mr-[30px] ">
                            <div className="solve-box-content lg:w-[60%] py-[20px] px-[10px] lg:px-[30px]">
                                <h4 className='cutom-font-bold-200'>Versatile Planning Analytics</h4>
                                <p>
                                    Harness the power of AI and predictive modelling to offer insurers, agents, brokers, and service providers a deeper understanding of risk dynamics.
                                    <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'> <FaArrowRight className='inline text-[13px]' /></Link>
                                </p>
                            </div>
                        </div>
                        <div className="solve-box-img solve-box-img1 h-[300px] xxl-h-380 lg:block xl:block">
                            <img className="lg:w-[40%] lg:absolute lg:right-[0px] lg:top-[30px] " src={env.baseUrlLocal + '/images/Planning-Analytics.png'} />
                        </div>
                    </div>

                    <div className="reveal solve-box mt-[30px] lg:ml-[30px] relative lg:top-[-120px]">
                        <div className="solve-box-img solve-box-img2 lg:block">
                            <img className="lg:w-[40%] lg:absolute lg:left-[-30px]  lg:top-[-165px]" src={env.baseUrlLocal + '/images/Application-Management.png'} />
                        </div>
                        <div className="solve-box-content lg:pl-[40%] w-[100%] py-[20px] px-[10px] lg:px-[30px]">
                            <h4 className='cutom-font-bold-200'>Application Management</h4>
                            <p>
                              Red Hat OpenShift on Versatile Cloud Transition from technical debt and cloud expenses to a competitive advantage by deploying workloads in the most suitable and purpose-driven environments
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'> <FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>
                    </div>


                    <div className="reveal relative">
                        <div className="solve-box solve-box3 mt-[30px] lg:mt-[-80px] lg:mr-[30px] ">
                            <div className="solve-box-content lg:w-[60%] py-[20px] px-[10px] lg:px-[30px]">
                                <h4 className='cutom-font-bold-200'>Versatile Assistant</h4>
                                <p>
                                   Transforming Customer Experiences with AI-Powered ChatbotsHarness the capabilities of our AI tools to curate data and offer tailored insurance services, guidance, and governance.
                                    <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'> <FaArrowRight className='inline text-[13px]' /></Link>
                                </p>
                            </div>
                        </div>
                        <div className="solve-box-img  solve-box-img3 h-[300px] lg:block">
                            <img className="lg:w-[40%] lg:absolute lg:right-[0px] lg:top-[30px] " src={env.baseUrlLocal + '/images/Versatile-Assistant.png'} />
                        </div>
                    </div>

                    <div className="reveal solve-box solve-box4 mt-[30px] lg:ml-[30px] relative lg:top-[-120px]">
                        <div className="solve-box-img solve-box-img4 lg:block">
                            <img className="lg:w-[40%] lg:absolute lg:left-[-30px]  lg:top-[-165px]" src={env.baseUrlLocal + '/images/Claims.png'} />
                        </div>
                        <div className="solve-box-content lg:pl-[40%] w-[100%] py-[20px] px-[10px] lg:px-[30px]">
                            <h4 className='cutom-font-bold-200'>Claims</h4>
                            <p>
                               An efficient, insight-driven suite built on best-of-breed micro-offerings, designed to minimise claims leakage and enhance decision-making.
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'> <FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>
                    </div>

                    <div className="reveal relative">
                        <div className="solve-box solve-box5 mt-[30px] lg:mt-[-80px] lg:mr-[30px] ">
                            <div className="solve-box-content lg:w-[60%] py-[20px] px-[10px] lg:px-[30px]">
                                <h4 className='cutom-font-bold-200'>Enabling Functions</h4>
                                <p>
                                    Providing a suite of solutions to bolster distribution requirements, encompassing hierarchy management, performance evaluation, compensation, financial operations, and sales support
                                    <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'> <FaArrowRight className='inline text-[13px]' /></Link>
                                </p>
                            </div>
                        </div>
                        <div className="solve-box-img h-[300px] lg:block">
                            <img className="lg:w-[40%] lg:absolute lg:right-[0px] lg:top-[30px] " src={env.baseUrlLocal + '/images/Enabling-Functions.png'} />
                        </div>
                    </div>

                    <div className="reveal solve-box solve-box6 mt-[30px] lg:ml-[30px] relative lg:top-[-120px]">
                        <div className="solve-box-img solve-box-img6 lg:block">
                            <img className="lg:w-[40%] lg:absolute lg:left-[-30px]  lg:top-[-165px]" src={env.baseUrlLocal + '/images/default-img.png'} />
                        </div>
                        <div className="solve-box-content lg:pl-[40%] w-[100%] py-[20px] px-[10px] lg:px-[30px]">
                            <h4 className='cutom-font-bold-200'>Lorem ipsum dolor sit amet</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque porta lacus vitae vulputate imperdiet. Aenean euismod risus erat.
                                <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'> <FaArrowRight className='inline text-[13px]' /></Link>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className='lg:mt-[-120px]'></div>
            <Discovery
                title={'Discovery '}
                subTitle={'Call'}
                desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
                btnName={'Subscribe'}
                placeholder={'Drop a Email'} />


            <section className="solve-mlti-promls mb-[50px] lg:mb-[70px]">
                <div className="lg:container mx-auto px-4">
                    <h3 className='base-h2 text-center'>What is New in Retail</h3>

                    <div className="retail-cards grid lg:grid-cols-3 md:grid-cals-2 gap-5 mt-[70px]">
                        <div className="reveal retail-card ">
                            <div className="retail-card-img">
                                <img className="max-w-full " src={env.baseUrlLocal + '/images/new-retail-img1.png'} />
                            </div>
                            <div className="retail-card-text">
                                <p className='px-3 py-5 leading-normal'>
                                    Transform retail with Cutting-Edge Edge Computing Solutions Elevate Your Retail Business with Our Cutting-Edge Edge Computing Reports.
                                    <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                                </p>
                            </div>
                        </div>
                        <div className="reveal retail-card mt-[30px] lg:mt-[80px]">
                            <div className="retail-card-img">
                                <img className="max-w-full" src={env.baseUrlLocal + '/images/new-retail-img2.png'} />
                            </div>
                            <div className="retail-card-text">
                                <p className='px-3 py-5 leading-normal'>
                                    Retailers Worldwide Grapple with Ongoing Search Abandonment Issues. Explore the Influence of Search Abandonment on Brand Loyalty and Shopper Sentiment.
                                    <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                                </p>
                            </div>
                        </div>
                        <div className="reveal retail-card mt-[30px] lg:mt-[150px]">
                            <div className="retail-card-img">
                                <img className="max-w-full" src={env.baseUrlLocal + '/images/new-retail-img3.png'} />
                            </div>
                            <div className="retail-card-text">
                                <p className='px-3 py-5 leading-normal'>
                                    Secure Retail Resilience through Data-Driven Strategies. The Four Key Areas Where Investment in Data-Driven Strategies Drives Efficiency.
                                    <Link to='/blog' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Faqs
                heading1={'What are the key starting steps to Digital Transformation?'}
                content1={"Key starting steps to Digital Transformation include defining clear goals, assessing current processes and technologies, securing executive buy-in, forming a cross-functional team, and creating a comprehensive roadmap. Prioritise areas with the highest potential for impact. "}
                heading2={"What types of technologies are used in Digital Transformation?"}
                content2={"Technologies used in Digital Transformation include cloud computing, artificial intelligence, data analytics, Internet of Things (IoT), automation, blockchain, and machine learning. These technologies drive innovation, improve efficiency, and enable new business models. "}
                heading3={"Will Digital Transformation and technology replace my employees?"}
                content3={"Digital Transformation may change the nature of certain job roles, but it doesn't necessarily mean replacing employees. Instead, it can enhance employee capabilities, allowing them to focus on higher-value tasks that require creativity, critical thinking, and human interaction. "}
                heading4={"What types of process changes should I expect through Digital Transformation?"}
                content4={"Through Digital Transformation, you can expect streamlined workflows, automated processes, data-driven decision-making, enhanced collaboration, and improved customer interactions. There might also be a shift towards agile methodologies and continuous improvement. "}
                heading5={"What industries does Digital Transformation impact?"}
                content5={"Digital Transformation impacts a wide range of industries, including manufacturing, healthcare, finance, retail, education, agriculture, transportation, and more. Essentially, any industry that relies on processes, data, and customer interactions can benefit from Digital Transformation. "}
                status={true}
            />
        </>
    )
}

export default Insurance