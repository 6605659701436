import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import $ from 'jquery';
import env from '../../../Config';
import { Link } from 'react-router-dom';


const BlogDetailsTwo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  $(document).ready(function () {
    // var stickyTop = $('.sticky1').offset().top;
    $(window).scroll(function () {
      var windowTop = $(window).scrollTop();
      console.log(windowTop, 'windowTop');

      if (windowTop > 450 && windowTop < 1900) {
        $('.sticky1').css({ 'position': 'fixed', 'top': '120px' });
      } else {
        $('.sticky1').css({ 'position': 'absolute', 'top': '0' });
      }
    });
  });



  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
      <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/Blog-Engineering-Experiences-banner.png)` }}>
        <div className='lg:container mx-auto px-4'>
          <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%]">
            <div className="text-center mx-auto lg:my-[5rem]">
              <div className="bg-[#FB9851] mb-[50px] text-[#FFF] py-2 px-9 text-[16px] text-center rounded-[40px] inline-block clear-both">Technology News</div>
              <h2 className="base-h2 text-[#101828] md:text-[#fff!important] lg:w-[70%] mx-auto">
                Engineering Experiences: A Journey of Innovation and Creativity
              </h2>
            </div>
          </div>
        </div>
      </div>
      
      <div className='clear-both'></div>
      
      {/* blog details start */}
      <div className=" px-[12px] md:px-[36px] xl:px-0 mt-[70px] mx-auto text-md text-gray-500 relative blog-content max-w-[830px] text-[14px] leading-[25px] md:text-[18px] md:leading-[32px] lg:flex">
        <div className="flex-col items-center gap-6 absolute follow-scroll translate-x-[-56px] sticky1 hidden lg:block" >
          <p className="text-heading-6 font-bold text-gray-400 mb-5">Share</p>
          <a href="#"> <img src={env.baseUrl + '/images/blog-details-sm-icon1.png'} alt="img" /></a>
          <a href="#" className='my-5 inline-block'> <img src={env.baseUrl + '/images/blog-details-sm-icon2.png'} alt="" /></a>
          <a href="#"> <img src={env.baseUrl + '/images/blog-details-sm-icon3.png'} alt="" /></a>
        </div>

        <div className='lg:ml-[60px]'>
          <p className="mb-4 md:mb-6">Engineering is a discipline that has been around for centuries. It is a field that is constantly evolving, and it requires individuals who are innovative and creative. Engineers are responsible for designing, building, and maintaining the infrastructure that we rely on every day. From the bridges we drive over to the buildings we work in, engineers are the ones who make it all possible.</p>

          <div className="mb-6 md:mb-9">
            <img className="h-full w-full object-cover" src={env.baseUrl + '/images/blog-engineering-experiences-img.png'} alt="Engineering is a discipline" />
          </div>
          
          <p className="mb-3 md:mb-3">
            As an engineer, I have had the privilege of working on a variety of projects throughout my career. Each project has presented its own unique challenges and opportunities for innovation. In this blog post, I will share some of my experiences as an engineer and how they have shaped my career.

          </p>
          <p className="mb-3 md:mb-3">
            One of the most memorable experiences I had as an engineer was working on a project to design a new bridge. The project was challenging because the bridge needed to be built over a river that was prone to flooding. We had to design a bridge that could withstand the force of the water during a flood while also being aesthetically pleasing.
          </p>
          <p className="mb-3 md:mb-3">
            To solve this problem, we used a combination of computer simulations and physical models to test different designs. We eventually settled on a design that used a series of arches to support the bridge. This design not only looked beautiful but was also strong enough to withstand the forces of nature.

          </p>
          <p className="mb-3 md:mb-3">
            Another experience that stands out in my career was working on a project to design a new hospital. The project was challenging because we had to design a building that could accommodate the needs of patients, doctors, and staff while also being energy-efficient.
          </p>
          <p className="mb-4 md:mb-4">
            To solve this problem, we used a combination of passive design strategies and renewable energy sources. We designed the building to maximize natural light and ventilation while also using solar panels to generate electricity. The result was a building that was not only functional but also environmentally friendly.
          </p>
          <p className="mb-3 md:mb-3">
            One of the most rewarding experiences I have had as an engineer was working on a project to design a new water treatment plant. The project was challenging because we had to design a system that could treat water from a variety of sources while also being cost-effective.
          </p>
          <p className="mb-3 md:mb-3">
            To solve this problem, we used a combination of advanced treatment technologies and process optimization techniques. We designed the plant to use the minimum amount of chemicals while still achieving the required level of water quality. The result was a system that was not only effective but also cost-efficient.
          </p>
          <p className="mb-3 md:mb-3">
            In conclusion, engineering is a field that requires creativity, innovation, and problem-solving skills. As an engineer, I have had the opportunity to work on a variety of projects that have presented unique challenges and opportunities for innovation. Each project has taught me something new and has helped shape my career. I am proud to be an engineer and look forward to continuing to work on projects that make a difference in people's lives.
          </p>

        </div>
      </div>
      {/* blog details end */}
      <div className='border-b-2 lg:container mx-auto px-4 mt-[50px]'></div>

      <div className='clear-both'></div>
      <section className='Our-blogs'>
        <div className='md:container p-4 mx-auto mt-[50px] mb-[50px] lg:mb-[100px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Recommended <span className='text-[#F26722]'> Blogs</span></h2>

          <div className='mt-[50px]'>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              autoPlaySpeed={3000}
              autoPlay={true}
              infinite={true}
            >
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/we-can-blend-colors-multiple-ways" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">We can blend colors multiple ways, the most common</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/blog-We-can-blend-colors-multiple-ways.png'} alt="We-can-blend-colors-multiple-ways" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/engineering-experiences-a-journey" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Engineering Experiences: A Journey of Innovation and Creativity</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/Blog-Engineering-Experiences.png'} alt="Engineering-Experiences" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#BEE1E6]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/saas-products" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Customer Services</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">SaaS Products</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/blog-SaaS-Products.png'} alt="SaaS-Products" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/the-evolution-significance" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">The Evolution and Significance of Cloud Engineering in Modern Computing</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/the-evolution-and-significance.png'} alt="the-evolution-and-significance" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/navigating-the-digital-migration" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Navigating the Digital Migration: A New Frontier in Business Transformation</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/navigating-the-digital-migration.png'} alt="navigating-the-digital-migration" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

            </Carousel>
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogDetailsTwo