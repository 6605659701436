import React, { useState } from 'react'
import './Styles.scss'
import { Link } from 'react-router-dom'
import { Tab } from '@headlessui/react'
import env from './../../Config'

// import $ from 'jquery';

const Foorter = () => {
    const [signUpTowardsNews, setSignUpTowardsNews] = useState({ email: '', name: '', isFormTouched: false });
    const [successMsg, setSuccessMsg] = useState('');
    const newsLatter = () => {
        if (signUpTowardsNews.email && signUpTowardsNews.name && validateEmail(signUpTowardsNews.email)) {
            const obj = { email: signUpTowardsNews.email, name: signUpTowardsNews.name };
            const url = 'https://nodweb.versatilecommerce.com/update-contact?type=news-letter';
            fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(obj),
            }).then(response => response.json())
                .then(result => {
                    if (result && result?.statusCode == 200) {
                        setSignUpTowardsNews({
                            emailTouched: false,
                            email: '',
                            name: ''
                        });
                        setSuccessMsg('Successfully done');
                        setTimeout(() => {
                            setSuccessMsg('');
                        }, 3000);
                    } else {
                        // setErrorMsg(result?.body?.message);
                    }
                })
                .catch(error => {
                    // Handle error
                });
        } else {
            setSignUpTowardsNews({ ...signUpTowardsNews, isFormTouched: true })
        }
    }

    const validateEmail = (email) => {
        let val = /\S+@\S+\.\S+/.test(email);
        return val;
    }

    return (
        <>

            <section className='footer-sec py-2 lg:py-8 text-[16px]'>
                <div className='lg:container p-4 lg:p-0 mx-auto my-8'>
                    <div className='grid lg:grid-cols-12 gap-5'>
                        <div className='lg:col-span-6 md:col-span-12 p-4 lg:px-5 lg:pt-5 lg:pb-2 bg-white rounded-[10px] mt-[5px]'>
                            <img src={env.baseUrl + '/images/Versatile-Commerce.png'} className='mb-5 lg:w-[35%]' alt='Versatile Commerce' />
                            <p className='text-gray-600'>
                                Operating from the UK, USA, and India, we've played a vital role in the eCommerce industry's growth over the past decade. The Versatile Commerce Platform embodies our insights from this evolution, fortified by robust product development. Our customers' sustained business testifies to its resounding success.
                            </p>
                            <div className='grid lg:grid-cols-12 md:grid-cols-12 gap-5  xs-t-center'>
                                <div className='lg:col-span-4 md:col-span-4 mt-5 ftr_links'>
                                    <h6 className='font-bold text-lg pb-[5px] '>Insights
                                        <div className='foter-bodr1'></div>
                                    </h6>
                                    <ul className='list-none'>
                                        <li> <Link to={'/about-us'}>About Us</Link></li>
                                        <li> <Link to={'/blog'}>Blog</Link></li>
                                        {/* <li> <Link to={'/CaseStudies'}>Case Studies</Link></li> */}
                                        {/* <li> <Link to={'/our-team'}>Our Team</Link></li> */}
                                        <li> <Link to={'/careers'}>Careers</Link></li>
                                        <li> <Link to={'/faqs-we-here'}>FAQ's</Link></li>
                                        <li> <Link to={'/contact-form'}>Contact Us</Link></li>
                                    </ul>
                                </div>
                                <div className='lg:col-span-5 md:col-span-4 mt-5'>
                                    <h6 className='font-bold text-lg pb-[5px]'>Specialties
                                        <div className='foter-bodr2'></div>
                                    </h6>
                                    <ul className='list-none text-[#667085] leading-[2.3]'>
                                        <li>Digital Expertise</li>
                                        <li>Digital Intelligence</li>
                                        <li>Digital Assurance / Assistance</li>
                                        <li>Consulting / Discovery</li>
                                        <li>Cloud Consulting Services</li>
                                    </ul>
                                </div>
                                <div className='lg:col-span-3 md:col-span-4 mt-5 ftr_links'>
                                    <h6 className='font-bold text-lg pb-[5px]'>Our Partners
                                        <div className='foter-bodr3'></div>
                                    </h6>
                                    <ul className='list-none'>
                                        <li><a href="#">AWS</a></li>
                                        <li><a href="#">Microsoft</a></li>
                                        <li><a href="#">Amazon</a></li>
                                        <li><a href="#">Ebay</a></li>
                                        <li><a href="#">OnBuy</a></li>
                                        <li><a href="#">Shopify</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='lg:col-span-3 md:col-span-5 lg:p-5 lg:mt-[3.4vh]'>
                            <div className='newsltr'>
                                <h6 className='text-sm font-semibold'>NEWSLETTER</h6>
                                <h5 className='text-3xl mt-1 font-bold'>Sign up towards <span className='orange-c'>newsletter</span></h5>

                                <input onChange={(e) => setSignUpTowardsNews({ ...signUpTowardsNews, name: e.target.value })} type='text' className={`ftr-input-form p-2 w-full mt-8 ${(signUpTowardsNews.isFormTouched && !signUpTowardsNews.name) ? 'inValidInput' : ''}`} value={signUpTowardsNews.name} placeholder='Enter Your Name' />
                                <input onChange={(e) => setSignUpTowardsNews({ ...signUpTowardsNews, email: e.target.value })} type='text mt-5' className={`ftr-input-form p-2 w-full mt-8 ${(signUpTowardsNews.isFormTouched && !validateEmail(signUpTowardsNews.email)) ? 'inValidInput' : ''}`} value={signUpTowardsNews.email} placeholder='Enter your Email Id' />



                                <button onClick={() => newsLatter()} className='ftr-subsrb-btn hover:bg-[#f26722] hover:text-[#fff] hover:drop-shadow-lg duration-300'>Subscribe <i className="las la-arrow-right"></i></button>

                                {successMsg ? (<div className="text-[green] mt-[20px] font-bold text-center text-[20px]">{successMsg}</div>) : null}
                            </div>

                        </div>
                        <div className='lg:col-span-3 md:col-span-7'>
                            <h4 className='text-2xl font-semibold border-b border-b-2 pl-3'>Reach Us</h4>
                            {/* location start */}
                            <section className="tabs-sec lg:container mx-auto">
                                <Tab.Group>
                                    <Tab.List className='ml-[-5px] mx-auto footerr mt-3'>
                                        <Tab className='w-[60px] px-3 active:bg-gray-200 bg-gray-100 text-[16px] text-gray-600 font-bold h-[25px] gap-2 hover:text-[#FF9135] inline border-r-2 border-[#333] text-[#222]'>UK</Tab>
                                        <Tab className='w-[60px] mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[16px] text-gray-600 font-semibold h-[25px] gap-2 hover:text-[#FF9135] inline border-r-2 border-[#333] text-[#222]'>USA</Tab>
                                        {/* <Tab className='w-[60px] mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[16px] text-gray-600 font-semibold h-[25px] gap-2 hover:text-[#FF9135] inline border-r-2 border-[#333] text-[#222]'>U A E</Tab> */}
                                        <Tab className='w-[60px] mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[16px] text-gray-600 font-semibold h-[25px] gap-2 hover:text-[#FF9135] inline   text-[#222]'>IND</Tab>

                                    </Tab.List>
                                    <Tab.Panels >
                                        <Tab.Panel>
                                            <div className="mt-[10px] min-h-[220px] bg-[#fff] p-3 rounded-lg relative">
                                                <div className='addressBox relative w-[100%] z-[1]'>
                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2454.5795793473053!2d-0.7706012999999998!3d52.032756299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877aaa77c5325ad%3A0x36f43d1d76ebed83!2s100%20Avebury%20Blvd%2C%20Milton%20Keynes%20MK9%201FH%2C%20UK!5e0!3m2!1sen!2sin!4v1694007993484!5m2!1sen!2sin" width="100%" style={{ border: 0, height: 300 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                </div>
                                                <Link to='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2454.5795793473053!2d-0.7706012999999998!3d52.032756299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877aaa77c5325ad%3A0x36f43d1d76ebed83!2s100%20Avebury%20Blvd%2C%20Milton%20Keynes%20MK9%201FH%2C%20UK!5e0!3m2!1sen!2sin!4v1694007993484!5m2!1sen!2sin' className='addressDetais absolute z-[9] text-[white] p-4 ' target='_blant'>

                                                    <p className='mt-5 text-center'>
                                                        100 Avebury Blvd, Milton Keynes <br />MK9 1FH, United Kingdom
                                                    </p>

                                                    <p className='mt-5 font-semibold  absolute right-[15px] bottom-[20px]'>
                                                        <i className="las la-phone text-[23px] text-[#F26722]"></i> +44 03330062294
                                                    </p>
                                                </Link>

                                            </div>
                                        </Tab.Panel>

                                        <Tab.Panel>
                                            <div className="mt-[10px] min-h-[220px] bg-[#fff] p-3 rounded-lg relative">

                                                <div className='addressBox relative w-[100%] z-[1]'>
                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.146689913432!2d-122.4021831!3d37.7866019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858087e4a4fdd9%3A0x5d54a80ea8d97eed!2sThe%20Paramount%2C%2095%203rd%20St%202nd%20Floor%2C%20San%20Francisco%2C%20CA%2094103%2C%20USA!5e0!3m2!1sen!2sin!4v1694007272657!5m2!1sen!2sin" width="100%" style={{ border: 0, height: 300 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                </div>
                                                <Link to='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.146689913432!2d-122.4021831!3d37.7866019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858087e4a4fdd9%3A0x5d54a80ea8d97eed!2sThe%20Paramount%2C%2095%203rd%20St%202nd%20Floor%2C%20San%20Francisco%2C%20CA%2094103%2C%20USA!5e0!3m2!1sen!2sin!4v1694007272657!5m2!1sen!2sin' className='addressDetais absolute z-[9] text-[white] p-4 ' target='_blant'>

                                                    <p className='mt-5 text-center'>95 Third Street, 2nd Floor, San Francisco, California 94103</p>
                                                    <p className='mt-5 font-semibold  absolute right-[15px] bottom-[20px]'>
                                                        <i className="las la-phone text-[23px] text-[#F26722]"></i> +1 628 2286091
                                                    </p>
                                                </Link>
                                            </div>
                                        </Tab.Panel>

                                        <Tab.Panel>
                                            <div className="mt-[10px] min-h-[220px] bg-[#fff] p-3 rounded-lg relative">
                                                <div className='addressBox relative'>

                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25605.60448121739!2d78.38655560010915!3d17.44554695493697!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9115c5614f13%3A0xdb1f7211d7bdfdd1!2sVersatile%20Commerce!5e0!3m2!1sen!2sin!4v1693999811732!5m2!1sen!2sin" width="100%" style={{ border: 0, height: 300 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                </div>
                                                <Link to='https://www.google.com/maps/place/Versatile+Commerce/@17.445547,78.3865556,14.25z/data=!4m6!3m5!1s0x3bcb9115c5614f13:0xdb1f7211d7bdfdd1!8m2!3d17.4424904!4d78.3874919!16s%2Fg%2F11qg4lp5w8?entry=ttu' className='addressDetais absolute z-[9] text-[white] p-4' target='_blant'>
                                                    <p className='mt-5  text-center'>
                                                        Krishe'Sapphire, 1st floor, Hitechcity, Hyderabad, Telangana 500081, Landmark - Beside Durgam cheruvu metro station
                                                    </p>
                                                    <p className='mt-5 font-semibold  absolute right-[15px] bottom-[20px]'>
                                                        <i className="las la-phone text-[23px] text-[#F26722]"></i> +91 91142 81142
                                                    </p>
                                                </Link>
                                            </div>
                                        </Tab.Panel>

                                    </Tab.Panels>
                                </Tab.Group>

                                <h6 className='font-bold text-lg pt-4 my-3 pl-3'>Email Id</h6>
                                <p>
                                    <a href='/' className='my-1 orange-c pl-3'>
                                        <i className="las la-envelope"></i> hello@versatilecommerce.com
                                    </a>
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section className="footer-donw bg-[#F26722] py-3 text-[white]">
                <div className="lg:container mx-auto px-4 lg:justify-between lg:flex md:text-center xs-t-center">
                    <div className='pt-[5px] lg:flex'>
                        <span className='font-bold hidden md:hidden lg:block'> © Versatile Commerce 2023</span>
                        <ul className='list-none md:inline-flex lg:inline-flex lg:ml-5 mb-5 md:my-5 lg:my-0'>
                            <li><Link to="/privacy-policy" className='mx-3 hover:text-gray-900 duration-300'>Privacy policy</Link></li>
                            <li><Link to="/cookie-policy" className='mx-3 hover:text-gray-900 duration-300'>Cookies</Link></li>
                            <li><Link to="/Terms-And-Conditions" className='mx-3 hover:text-gray-900 duration-300'>Terms of service</Link></li>
                        </ul>
                    </div>
                    <div className='md:flex md:justify-center xs-flex-center md:text-center mb-5 md:mb-0'>
                        <ul className='list-none flex lg:ml-5 md:'>
                            <li>
                                <Link to="https://www.linkedin.com/company/versatilecommerce-vc/" target='_blank'>
                                    <img className='hover:drop-shadow-xl mx-3 inline-block' src={env.baseUrl + '/images/social-icon-in.png'} alt='versatil ecommerce linkedin' /></Link>
                            </li>
                            <li>
                                <Link to="https://twitter.com/vccommerce" target='_blank'>
                                    <img className='hover:drop-shadow-xl mx-3 inline-block' src={env.baseUrl + '/images/social-icon-twr.png'} alt='versatil ecommerce Twitter' /></Link>
                            </li>
                            <li>
                                <Link to="https://www.facebook.com/versatilecommerce " target='_blank'>
                                    <img className='hover:drop-shadow-xl mx-3' src={env.baseUrl + '/images/social-icon-fb.png'} alt='versatil ecommerce Facebook' /></Link>
                            </li>
                            <li>
                                <Link to="https://www.instagram.com/versatilecommerce/ " target='_blank'>
                                    <img className='hover:drop-shadow-xl mx-3 inline-block' src={env.baseUrl + '/images/social-icon-insta.png'} alt='versatil ecommerce Instagram' /></Link>
                            </li>
                        </ul>
                    </div>
                    <span className='font-bold md:block lg:hidden mt-9 md:mt-4'>© Versatile Commerce 2023</span>
                </div>
            </section>
        </>
    )
}

export default Foorter;