import React, { useState } from 'react'
import { FaArrowRight } from 'react-icons/fa';
import { Disclosure } from "@headlessui/react";
import useSmoothHorizontalScroll from "use-smooth-horizontal-scroll";
import './styles.scss';
import { useEffect } from 'react';
import FaqsContent from './FaqsContent';
import env from '../../../Config';

const FaqsWeHere = ({ heading1, content1 }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { scrollContainerRef, handleScroll, scrollTo, isAtStart, isAtEnd } = useSmoothHorizontalScroll();
    // const [seledtedTabIndex, setSeledtedTabIndex]=useState(1);
    const [seledtedTabIndex, setSeledtedTabIndex] = useState(1)
    return (
        <>
            <div className="services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/faqs-banner.png)` }}>
                <div className='lg:container mx-auto'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] xl_py-260 max-w-[100%]">
                        <div className="lg:my-[8rem]">
                            <div className='text-center mx-auto'>
                                <h2 className="base-h2 text-[#222!important] md:text-[#fff!important]">We are here to help you</h2>
                                <div className='relative mt-9 md:mx-[50px] lg:mx-[auto] lg:w-[550px]'>
                                    <button className='absolute px-[17px] lg:px-[17px] right-[13px] rounded-[40px] top-[11px] bg-[#F26924] h-[50px] text-white font-bold hover:bg-[#555] hover:text-[#fff] hover:drop-shadow-lg duration-300 flex jusity-center items-center'>
                                        <FaArrowRight className='text-[15px]' /></button>
                                    <input type="email"
                                        className="block w-full lg:pl-9 px-4 py-2 mt-5 h-[72px] text-gray-700 bg-white border rounded-[40px] focus:border-orange-400 focus:ring-orange-300 focus:outline-none focus:ring focus:ring-opacity-40" placeholder='Ark a questions...' />
                                </div>
                                <p className="base-p1 font-[100!important] text-[#222!important] md:text-[#fff!important] text-center lg:w-[60%] mx-auto">
                                    Where Questions Unravel, Paving the Path to Informed Decisions and Knowledge Enrichment with Confidence.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className='tabSectn lg:container mx-auto mt-[50px] lg:mt-[120px] lg:mb-[70px]'>

                <div className='px-2 lg:px-5' style={{ display: "flex", flex: 1, flexDirection: 'row' }}>
                    <button className='scrollLeft-btn' onClick={() => scrollTo(-300)} disabled={isAtStart}><i class="las la-angle-right text-[20px] font-[1100]"></i></button>
                    <div className='mt-1' ref={scrollContainerRef} onScroll={handleScroll} style={{ overflowX: "hidden", display: "flex", flex: 1, flexDirection: 'row' }}>
                        <button onClick={() => setSeledtedTabIndex(1)} className='focus:outline-none  focus:border-[#FF9135] cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[15px] lg:text-[18px] text-gray-600 border-gray-100 border-2 md:min-w-[180px] md:h-[70px] h-[40px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Key </button>
                        <button onClick={() => setSeledtedTabIndex(2)} className='focus:outline-none  focus:border-[#FF9135] cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[15px] lg:text-[18px] text-gray-600 border-gray-100 border-2 md:min-w-[180px] md:h-[70px] h-[40px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Industry</button>
                        <button onClick={() => setSeledtedTabIndex(3)} className='focus:outline-none  focus:border-[#FF9135] cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[15px] lg:text-[18px] text-gray-600 border-gray-100 border-2 md:min-w-[180px] md:h-[70px] h-[40px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Technical</button>
                        {/* <button onClick={() => setSeledtedTabIndex(4)} className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[15px] lg:text-[18px] text-gray-600 border-gray-100 border-2 md:min-w-[180px] md:h-[70px] h-[40px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Category&nbsp;4</button>
                        <button onClick={() => setSeledtedTabIndex(5)} className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[15px] lg:text-[18px] text-gray-600 border-gray-100 border-2 md:min-w-[180px] md:h-[70px] h-[40px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Category&nbsp;5</button>
                        <button onClick={() => setSeledtedTabIndex(6)} className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[15px] lg:text-[18px] text-gray-600 border-gray-100 border-2 md:min-w-[180px] md:h-[70px] h-[40px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Category&nbsp;6</button>
                        <button onClick={() => setSeledtedTabIndex(7)} className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[15px] lg:text-[18px] text-gray-600 border-gray-100 border-2 md:min-w-[180px] md:h-[70px] h-[40px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Category&nbsp;7</button>
                        <button onClick={() => setSeledtedTabIndex(8)} className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[15px] lg:text-[18px] text-gray-600 border-gray-100 border-2 md:min-w-[180px] md:h-[70px] h-[40px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Category&nbsp;8</button>
                        <button onClick={() => setSeledtedTabIndex(9)} className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[15px] lg:text-[18px] text-gray-600 border-gray-100 border-2 md:min-w-[180px] md:h-[70px] h-[40px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Category&nbsp;9</button>
                        <button onClick={() => setSeledtedTabIndex(10)} className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[15px] lg:text-[18px] text-gray-600 border-gray-100 border-2 md:min-w-[180px] md:h-[70px] h-[40px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Category&nbsp;10</button> */}

                    </div>
                    <button className='scrollRight-btn' onClick={() => scrollTo(300)} disabled={isAtEnd}><i class="las la-angle-left text-[20px] font-[1100]"></i></button>
                </div>

                <div className="tab-content mt-[30px] lg:mt-[50px] mb-[50px] lg:mb-[100px]">

                    {seledtedTabIndex == 1 && (
                        <div>
                            <div className='lg:container mx-auto'>
                                <div className='grid lg:grid-cols-12 lg:mx-6 md:mx-9 mx-3'>
                                    <div className='lg:col-start-2 lg:col-span-10'>
                                        {/* accourdian starts */}
                                        <FaqsContent
                                            heading1={'What is Digital Transformation?'}
                                            content1={"Digital Transformation refers to the profound and holistic integration of digital technologies, processes, and strategies into all aspects of an organisation's operations and business models. It involves leveraging technological advancements to enhance customer experiences, streamline operations, improve decision-making, and create new value propositions. "}
                                            heading2={"Why is Digital Transformation important?"}
                                            content2={"Digital Transformation is crucial because it allows organisations to stay competitive and relevant in a rapidly evolving digital landscape. It enables increased efficiency, agility, and innovation, fostering better customer relationships and opening new avenues for growth and revenue"}
                                            heading3={"What are the benefits and obstacles of Digital Transformation?"}
                                            content3={"Benefits of Digital Transformation include improved operational efficiency, enhanced customer experiences, data-driven insights, innovation opportunities, and competitive advantages. Obstacles can include resistance to change, resource constraints, cultural barriers, cybersecurity concerns, and the need for significant upfront investments. "}
                                            heading4={"How Can Digital Transformation Help My Business?"}
                                            content4={"Digital Transformation can help your business by optimising processes, enabling data-driven decision-making, reaching new markets, enhancing customer engagement, and fostering innovation. It can also lead to improved employee productivity and better adaptation to market trends. "}
                                            heading5={"How can I justify the cost of Digital Transformation?"}
                                            content5={"To justify the cost of Digital Transformation, focus on potential ROI, increased revenue streams, reduced operational costs, improved customer satisfaction, and long-term competitive advantages. Develop a clear business case that outlines the expected benefits and aligns with your organisations strategic goals. "}
                                        />
                                        {/* accourdian end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {seledtedTabIndex == 2 && (
                        <div>
                            <div className='lg:container mx-auto'>
                                <div className='grid lg:grid-cols-12 lg:mx-6 md:mx-9 mx-3'>
                                    <div className='lg:col-start-2 lg:col-span-10'>
                                        {/* accourdian starts */}
                                        <FaqsContent
                                            heading1={'What are some indicators that it is the right time to start Digital Transformation?'}
                                            content1={"Indicators that it's the right time for Digital Transformation include increasing customer demands for digital experiences, growing competition leveraging technology, stagnant growth, inefficient processes, and a lack of agility in responding to market changes. "}
                                            heading2={"How long does Digital Transformation take?"}
                                            content2={"The duration of Digital Transformation varies depending on the organisation's size, complexity, existing infrastructure, and goals. It can take anywhere from a couple of years to a decade to fully implement, but incremental changes and quick wins can be realised along the way. "}
                                            heading3={"How can I help my organisation succeed with Digital Transformation?"}
                                            content3={"To ensure success, focus on strong leadership support, employee engagement, clear communication, a well-defined strategy, collaboration across departments, continuous learning, and a willingness to adapt to changes. "}
                                            heading4={"Who are the stakeholders for Digital Transformation?"}
                                            content4={"Stakeholders for Digital Transformation include executives, employees, customers, investors, partners, and vendors. Each group has a unique perspective and role to play in the successful implementation of Digital Transformation. "}
                                            heading5={"Is Your Organisation Ready for Digital Transformation?"}
                                            content5={"Assess your organisation's readiness for Digital Transformation by evaluating its current technological capabilities, culture, leadership alignment, and willingness to embrace change. A readiness assessment can help you identify areas that need attention before embarking on the transformation journey. "}
                                        />
                                        {/* accourdian end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {seledtedTabIndex == 3 && (
                        <div>
                            <div className='lg:container mx-auto'>
                                <div className='grid lg:grid-cols-12 lg:mx-6 md:mx-9 mx-3'>
                                    <div className='lg:col-start-2 lg:col-span-10'>
                                        <FaqsContent
                                            heading1={'What are the key starting steps to Digital Transformation?'}
                                            content1={"Key starting steps to Digital Transformation include defining clear goals, assessing current processes and technologies, securing executive buy-in, forming a cross-functional team, and creating a comprehensive roadmap. Prioritise areas with the highest potential for impact. "}
                                            heading2={"What types of technologies are used in Digital Transformation?"}
                                            content2={"Technologies used in Digital Transformation include cloud computing, artificial intelligence, data analytics, Internet of Things (IoT), automation, blockchain, and machine learning. These technologies drive innovation, improve efficiency, and enable new business models. "}
                                            heading3={"Will Digital Transformation and technology replace my employees?"}
                                            content3={"Digital Transformation may change the nature of certain job roles, but it doesn't necessarily mean replacing employees. Instead, it can enhance employee capabilities, allowing them to focus on higher-value tasks that require creativity, critical thinking, and human interaction. "}
                                            heading4={"What types of process changes should I expect through Digital Transformation?"}
                                            content4={"Through Digital Transformation, you can expect streamlined workflows, automated processes, data-driven decision-making, enhanced collaboration, and improved customer interactions. There might also be a shift towards agile methodologies and continuous improvement. "}
                                            heading5={"What industries does Digital Transformation impact?"}
                                            content5={"Digital Transformation impacts a wide range of industries, including manufacturing, healthcare, finance, retail, education, agriculture, transportation, and more. Essentially, any industry that relies on processes, data, and customer interactions can benefit from Digital Transformation. "}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {seledtedTabIndex == 4 && (
                        <div>
                            <div className='lg:container mx-auto'>
                                <div className='grid lg:grid-cols-12 lg:mx-6 md:mx-9 mx-3'>
                                    <div className='lg:col-start-2 lg:col-span-10'>
                                        <h1 className='text-[30px]'>Category tab content 4</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {seledtedTabIndex == 5 && (
                        <div>
                            <div className='lg:container mx-auto'>
                                <div className='grid lg:grid-cols-12 lg:mx-6 md:mx-9 mx-3'>
                                    <div className='lg:col-start-2 lg:col-span-10'>
                                        <h1 className='text-[30px]'>Category tab content 5</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {seledtedTabIndex == 6 && (
                        <div>
                            <div className='lg:container mx-auto'>
                                <div className='grid lg:grid-cols-12 lg:mx-6 md:mx-9 mx-3'>
                                    <div className='lg:col-start-2 lg:col-span-10'>
                                        <h1 className='text-[30px]'>Category tab content 6</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {seledtedTabIndex == 7 && (
                        <div>
                            <div className='lg:container mx-auto'>
                                <div className='grid lg:grid-cols-12 lg:mx-6 md:mx-9 mx-3'>
                                    <div className='lg:col-start-2 lg:col-span-10'>
                                        <h1 className='text-[30px]'>Category tab content 7</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {seledtedTabIndex == 8 && (
                        <div>
                            <div className='lg:container mx-auto'>
                                <div className='grid lg:grid-cols-12 lg:mx-6 md:mx-9 mx-3'>
                                    <div className='lg:col-start-2 lg:col-span-10'>
                                        <h1 className='text-[30px]'>Category tab content 8</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {seledtedTabIndex == 9 && (
                        <div>
                            <div className='lg:container mx-auto'>
                                <div className='grid lg:grid-cols-12 lg:mx-6 md:mx-9 mx-3'>
                                    <div className='lg:col-start-2 lg:col-span-10'>
                                        <h1 className='text-[30px]'>Category tab content 9</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {seledtedTabIndex == 10 && (
                        <div>
                            <div className='lg:container mx-auto'>
                                <div className='grid lg:grid-cols-12 lg:mx-6 md:mx-9 mx-3'>
                                    <div className='lg:col-start-2 lg:col-span-10'>
                                        <h1 className='text-[30px]'>Category tab content 10</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </section>

        </>
    )
}

export default FaqsWeHere