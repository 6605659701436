import React from 'react'
import { useEffect, Suspense } from 'react'
import Discovery from '../../../Components/DiscoveryCall/Discovery'
import ReactHelmet from '../../../Components/ReactHelmet'
import env from '../../../Config'

const IOT = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)
    })
    return (
        <>
        <Suspense>
        <ReactHelmet
          title={`Versatile Commerce | Services | Iot`}
          keywords={`Internet Of Things`}
          description={`Unlocking Boundless Opportunities, Transforming Realities: Embrace the Revolutionary Power of IoT for a Smarter and More Connected Future`}
          seoimgs={`service-iot-banner.png`}
        />
      </Suspense>
            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/service-iot-banner.png)` }}>
                <div className='lg:container mx-auto px-4'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
                        <div className="flex-1 md:py-[70px] lg:py-[100px]">
                            <h2 className="text-[2rem] lg:text-white lg:text-[82px] md:w-[50%] lg:w-[50%] md:text-[#fff] leading-tight font-bold text-center md:text-left">
                                IoT
                            </h2>
                            <p className="text-[18px] lg:text-[24px] mt-5 md:w-[50%] lg:w-[40%] md:text-[#fff] text-center font-semibold md:text-left">
                                Unlocking Boundless Opportunities, Transforming Realities: Embrace the Revolutionary Power of IoT for a Smarter and More Connected Future
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <section className='ourEngineerings'>
                <div className="lg:container mx-auto px-4 my-[40px] md:my-[70px] lg:mt-[100px]">
                    <div className="text-center">
                        <h2 className="text-[2rem] lg:text-[56px] leading-tight font-bold">Internet <span className="text-[#f26722]"> Of Things</span></h2>
                        <p className="text-[20px] md:text-[24px] text-[#475467] font-bold lg:w-[50%] mx-auto mt-4">
                            Where Connectivity Ignites Innovation: Embrace IoT's Visionary Impact on Our Connected World</p>
                    </div>

                    <div className='xl:px-0 mt-[70px] lg:mt-[80px]'>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Wearables.png'} alt='Wearables'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none"> Wearables</h2>
                                <p className='base-p1'>
                                    These are devices that can be worn on the body, such as smartwatches, fitness trackers, and health monitors.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Personalised Health Monitoring</h5>
                                            <div className='tooltip_box'>Wearable devices can track vital signs, activity levels, and sleep patterns, providing individuals with real-time insights into their health.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Remote Patient Monitoring</p>
                                            <div className='tooltip_box'>Wearables enable healthcare professionals to monitor patients' conditions remotely, reducing hospital visits and improving patient outcomes.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Fitness and Wellness</h5>
                                            <div className='tooltip_box'>Wearables encourage users to stay active and maintain healthy lifestyles by tracking physical activity, setting goals, and providing motivation.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data-Driven Insights</h5>
                                            <div className='tooltip_box'>Wearables collect data that can be analysed to provide valuable insights into behaviour patterns, helping users make informed decisions.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Smartwatches</h5>
                                            <div className='tooltip_box'>Wearable smartwatches offer functionalities beyond health tracking, such as notifications, GPS navigation, and interaction with other IoT devices.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> IoT Connectivity</h5>
                                            <div className='tooltip_box'>Wearables can act as a bridge between individuals and their IoT devices, enabling seamless control of smart home systems or connected gadgets.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Elderly Care</h5>
                                            <div className='tooltip_box'>Wearables can enhance the safety of the elderly by monitoring their movements and sending alerts in case of falls or emergencies.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Workplace Productivity</h5>
                                            <div className='tooltip_box'>Wearables can assist employees by providing real-time information, enhancing communication, and monitoring working conditions.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Location Tracking</h5>
                                            <div className='tooltip_box'>Wearables with GPS capabilities offer accurate location tracking, making them useful for personal safety or asset management.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Fashion and Style</h5>
                                            <div className='tooltip_box'>Wearables are designed to blend fashion with technology, making them appealing and enhancing users' personal style.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Smart Home Devices</h2>
                                <p className='base-p1'>
                                    These are devices that can be used to control and automate home appliances, such as smart thermostats, smart lights, and smart locks.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Home Automation</h5>
                                            <div className='tooltip_box'>Smart home devices allow users to automate various tasks, such as lighting, heating, cooling, and security systems, enhancing convenience.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Remote Control</h5>
                                            <div className='tooltip_box'>Users can control and monitor their smart home devices remotely through mobile apps, even when they are away from home.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Energy Efficiency</h5>
                                            <div className='tooltip_box'>Smart thermostats, lighting, and appliances help users optimise energy consumption, reducing utility bills and environmental impact.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Security and Surveillance</h5>
                                            <div className='tooltip_box'>Smart cameras, doorbell cameras, and sensors enhance home security by providing real-time monitoring and alerts for suspicious activities.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Voice Control</h5>
                                            <div className='tooltip_box'>Many smart home devices integrate with virtual assistants like Amazon Alexa or Google Assistant, allowing users to control devices using voice commands.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Interconnectivity</h5>
                                            <div className='tooltip_box'>Smart devices can communicate with each other, enabling advanced automation scenarios. For example, lights can turn on when a door is unlocked.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Health and Wellness</h5>
                                            <div className='tooltip_box'>Smart home devices can monitor indoor air quality, humidity, and other environmental factors that affect occupants' health and well-being.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Appliance Management</h5>
                                            <div className='tooltip_box'>Smart plugs and switches can turn regular appliances into smart devices, allowing users to remotely control their operation.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Convenience</h5>
                                            <div className='tooltip_box'>Smart home devices streamline daily tasks by offering features like automatic door locking, grocery list creation, and hands-free operation.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data-Driven Insights</h5>
                                            <div className='tooltip_box'>Smart home devices collect data that can provide insights into household patterns, helping users make informed decisions.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Smart-home-devices.png'} alt='Smart Home Devices'/>
                            </div>
                        </div>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Industrial-IoT-devices.png'} alt='Industrial IoT Devices'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Industrial IoT Devices</h2>
                                <p className='base-p1'>
                                     These are devices that are used in industrial settings, such as sensors that monitor machinery, supply chain management systems, and inventory tracking systems.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Predictive Maintenance</h5>
                                            <div className='tooltip_box'>IIoT devices enable real-time monitoring of industrial equipment, allowing predictive maintenance to prevent unplanned downtime.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Remote Monitoring</p>
                                            <div className='tooltip_box'>IIoT devices enable remote monitoring of critical processes, ensuring operational efficiency and reducing the need for on-site presence.</div>
                                        </div>


                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Asset Tracking</h5>
                                            <div className='tooltip_box'>IIoT devices track the location and condition of assets, improving inventory management and reducing losses.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Analytics</h5>
                                            <div className='tooltip_box'>IIoT devices collect vast amounts of data that can be analysed to gain insights into process optimisation, efficiency, and quality control.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Process Automation</h5>
                                            <div className='tooltip_box'>IIoT devices facilitate automation of complex industrial processes, leading to improved accuracy, reduced human intervention, and increased productivity.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Energy Efficiency</h5>
                                            <div className='tooltip_box'>IIoT devices monitor energy consumption and help identify opportunities for optimising energy usage in industrial operations.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Supply Chain Management</h5>
                                            <div className='tooltip_box'>IIoT devices provide visibility into supply chain processes, enabling real-time tracking of goods and enhancing logistics efficiency.</div>
                                        </div>



                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Safety Enhancements</h5>
                                            <div className='tooltip_box'>IIoT devices offer real-time monitoring of workplace conditions, helping to ensure the safety of workers and compliance with regulations.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Quality Assurance</h5>
                                            <div className='tooltip_box'>IIoT devices monitor and track product quality throughout the production process, leading to improved quality control and reduced defects.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Condition Monitoring</h5>
                                            <div className='tooltip_box'>IIoT devices continuously monitor the condition of machinery and equipment, detecting anomalies or potential failures before they occur.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Medical IoT Devices</h2>
                                <p className='base-p1'>
                                    These are devices that are used in healthcare settings, such as remote patient monitoring devices, medical imaging equipment, and drug delivery systems.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Remote Patient Monitoring</h5>
                                            <div className='tooltip_box'>MIoT devices enable healthcare professionals to monitor patients' health conditions remotely, enhancing patient care and reducing hospital visits.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Real-Time Health Tracking</h5>
                                            <div className='tooltip_box'>MIoT devices track vital signs, medication adherence, and other health metrics, providing real-time insights into patients' well-being.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Chronic Disease Management</h5>
                                            <div className='tooltip_box'>MIoT devices support the management of chronic conditions like diabetes, heart disease, and asthma by providing continuous health data.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Emergency Alerts</h5>
                                            <div className='tooltip_box'>MIoT devices can send alerts to caregivers or medical professionals in case of critical health events, ensuring timely intervention.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Telemedicine</h5>
                                            <div className='tooltip_box'>MIoT devices enable virtual consultations, allowing patients to communicate with healthcare providers and receive medical advice remotely.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Healthcare Data Exchange</h5>
                                            <div className='tooltip_box'>MIoT devices facilitate the secure exchange of patient data between different medical facilities, improving continuity of care.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Wearable Health Tech</h5>
                                            <div className='tooltip_box'>Wearable MIoT devices like smartwatches and fitness trackers encourage individuals to adopt healthier lifestyles by monitoring activity levels and health metrics.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Preventive Healthcare</h5>
                                            <div className='tooltip_box'>MIoT devices empower users to proactively manage their health by providing insights that encourage preventive measures.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Medication Management</h5>
                                            <div className='tooltip_box'> MIoT devices assist patients in adhering to medication schedules through reminders and monitoring, improving treatment outcomes.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Medical Research</h5>
                                            <div className='tooltip_box'>MIoT devices contribute to medical research by collecting large datasets that can be used for studying health trends and improving treatment protocols.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Medical-IoT-devices.png'} alt='Medical IoT Devices'/>
                            </div>
                        </div>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Smart-city-devices.png'} alt='Smart City Devices'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Smart City Devices</h2>
                                <p className='base-p1'>
                                These are devices that are used to manage and optimize city infrastructure, such as traffic management systems, public safety systems, and waste management systems.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Urban Efficiency</h5>
                                            <div className='tooltip_box'>Smart city devices optimise resource utilisation, including energy, water, and transportation, leading to more efficient urban operations.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Traffic Management</p>
                                            <div className='tooltip_box'>IoT devices enable real-time monitoring of traffic flow, helping to alleviate congestion and improve overall traffic management.</div>
                                        </div>


                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Public Safety</h5>
                                            <div className='tooltip_box'> Smart city devices enhance public safety through video surveillance, gunshot detection, and emergency response systems.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Waste Management</h5>
                                            <div className='tooltip_box'>IoT devices can optimise waste collection routes and monitor bin fill levels, reducing costs and promoting sustainable practices.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Air Quality Monitoring</h5>
                                            <div className='tooltip_box'>Smart city devices measure air quality and pollution levels, allowing for targeted interventions to improve urban environmental health.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Energy Conservation</h5>
                                            <div className='tooltip_box'>IoT devices monitor and control street lighting and energy usage in public buildings, contributing to energy conservation.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data-Driven Governance</h5>
                                            <div className='tooltip_box'>Smart city devices provide data that informs policy decisions and urban planning for more sustainable and livable cities.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Public Wi-Fi</h5>
                                            <div className='tooltip_box'>IoT-enabled Wi-Fi infrastructure enhances internet connectivity and digital accessibility for residents and visitors.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Smart Parking</h5>
                                            <div className='tooltip_box'>IoT devices guide drivers to available parking spots, reducing traffic congestion and enhancing parking management.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Environmental Sustainability</h5>
                                            <div className='tooltip_box'>Smart city devices promote sustainability through efficient resource management, reduced emissions, and improved quality of life.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <Discovery
                title={'Discovery '}
                subTitle={'Call'}
                desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
                btnName={'Subscribe'}
                placeholder={'Drop a Email'} />
        </>
    )
}

export default IOT