import React from 'react'
import { useEffect, Suspense } from 'react'
import ReactHelmet from '../../Components/ReactHelmet'

const CookiePolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Cookies`}
                />
            </Suspense>
            <div className='lg:container mx-auto px-4'>
                <h3 className='text-[22px] text-[#ff6b01] mt-9 mb-4 font-bold'>Cookie Policy</h3>

                <p className='mt-5'>
                    We and our Service Providers use cookies and other technologies (such as web beacons), as well as advertising identifiers, for various reasons. For example, we
                </p>
                <p className='mt-5'>
                    use these technologies to make it easy to access our services by remembering you when you return, to provide and analyse our services.
                </p>
                <p className='mt-5'>
                    We also use cookies, as well as advertising identifiers, to learn more about our users and their likely interests, and to deliver and tailor marketing or advertising. We want you to be informed about our use of these technologies, so this section explains the types of technologies we use, what they do and your choices regarding their use.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>What are cookies?</h4>
                <p className='mt-5'>
                    Cookies are small data files that are commonly stored on your device when you browse and use websites and online services. They are widely used to make websites work, or to work more efficiently, as well as to provide reporting information and assist with service or advertising personalization.
                </p>
                <p className='mt-5'>
                    Cookies are not the only types of technology that enable this functionality; we also use other, similar types of technologies. See below for more information and examples.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>What are advertising identifiers?</h4>
                <p className='mt-5'>
                    Advertising identifiers are similar to cookies and are found on many mobile devices and tablets (for example, the “Identifier for Advertisers” (or IDFA) on Apple iOS devices and the “Google Advertising ID” on Android devices), and certain streaming media devices. Like cookies, advertising identifiers are used to make online advertising more relevant.
                </p>


                <h4 className='text-[19px] mt-9 font-bold'>Why does Versatile Commerce use cookies and advertising identifiers?</h4>
                <p className='mt-5'>
                    <b>Essential cookies:</b> These cookies are strictly necessary to provide our website or application. For example, we and our Service Providers may use these cookies to authenticate and identify our members when they use our websites and applications so we can provide our service to them. They also help us to enforce our Terms of Use, prevent fraud and maintain the security of our service.
                </p>
                <p className='mt-5'>
                    <b>Performance and functionality cookies:</b> These cookies are not essential but help us to personalise and enhance your experience with Versatile Commerce. For example, they help us to remember your preferences and prevent you from needing to re-enter information you previously provided (for example, during member sign up). Deletion of these types of cookies may result in limited functionality of our service.
                </p>
                <p className='mt-5'>
                    <b>Advertising cookies and advertising identifiers:</b> These cookies and advertising identifiers use information about your use of this and other websites and apps, your response to ads and emails, and to deliver ads that are more relevant to you. These types of ads are called “interest-based advertising.” Many of the advertising cookies associated with our service belong to our Service Providers.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>How can I exercise choice regarding cookies and advertising identifiers?</h4>
                <p className='mt-5 mb-9'>
                    For more information about cookies set through our website, as well as other types of online tracking (including the collection of information by third parties about your online activities over time and across third-party Web sites or online services for online interest-based advertising), and to exercise choices regarding them, click here. At this time, we do not respond to Web browser “do not track” signals.
                </p>

            </div>
        </>
    )
}

export default CookiePolicy