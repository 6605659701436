import React from 'react'
import env from '../../Config'

const NotFoundPage  = () => {
  return (
    <>
        <div className="lg:container mx-auto">
              <img className="h-full lg:w-[40%] md:w-[50%]  w-[80%] md:my-[100px] my-[60px] mx-auto" src={env.baseUrl + '/images/404.png'} alt="Page not found" />
        </div>
      
     </>
  )
}

export default NotFoundPage 