import React, { Suspense, useEffect } from 'react'
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa';
import Discovery from '../../Components/DiscoveryCall/Discovery';
import Faqs from '../../Components/Faqs/Faqs';
import ReactHelmet from '../../Components/ReactHelmet';
import env from '../../Config';

const Careers = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
   
    const jobprofile = ()=>{
        const ulList=[
            'An updated CV', ' Educational certificates', ' Valid ID proof', " Relevant experience documents, including your offer letter, relieving letter, the last three months' pay slips, and your bank statement."
        ];
        return (
            <>
                <p>What should I bring for the Interview? Please ensure you have the following documents ready:</p>
                <ul className='list-disc ml-5 md:ml-6'>
                    {ulList.map(list => (
                        <li>{list}</li>
                    ))}
                </ul>
            </>
        )
    }
    
    return (
        <>
            <Suspense>
                <ReactHelmet 
                title={`Versatile Commerce | Careers`}
                keywords={`Working with us the awesome team`}
                description={`Our focus is always on finding the best people to work with. Our bar is high, but you look ready to take on the challenge.`}
                seoimgs={`careers-banner.png`}
                />
            </Suspense>
            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/careers-banner.png)` }}>
                <div className='lg:container mx-auto px-4'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%]">
                        <div className="text-center mx-auto lg:my-[8rem] xl:my-[10rem]">
                            <div class="bg-[#FB9851] mb-[50px] text-[#FFF] py-2 px-9 text-[16px] text-center rounded-[40px] inline-block clear-both">Success Together</div>
                            <h2 className="base-h2 text-[#101828] md:text-[#fff!important]">
                                Working with us, <br />the awesome team
                            </h2>
                            <p className="base-p1 font-[100!important] text-[#101828] md:text-[#fff!important] lg:w-[60%]  mx-auto">
                                Our focus is always on finding the best people to work with. Our bar is high, but you look ready to take on the challenge.
                            </p>

                        </div>
                    </div>
                </div>
            </div>

            <section className="video-career">
                <div className="lg:container mx-auto px-4">
                    <div class="relative mx-auto max-w-[90%] lg:max-w-[80%] mt-[2rem] lg:mt-[-7rem]">
                        <img class="h-full w-full object-cover rounded-2xl" src={env.baseUrl + '/images/career-image.png'} alt="Join a Workplace" />
                        {/* <button class="rounded-full bg-white grid place-items-center absolute play-video w-[60px] h-[60px] md:w-[138px] md:h-[138px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hover:drop-shadow-2xl">
                            <img src={env.baseUrl + '/images/icon-play.svg'} alt="play button" width="26" /></button> */}
                    </div>
                </div>
            </section>

            <section className="join-work-space">
                <div className="mx-auto">
                    <h2 className='base-h2 text-center my-[40px] lg:mt-[100px] lg:mb-[70px]'>
                        <span className='text-[#FF6B01]'>Join a Workplace</span> <br /> That Gives You More
                    </h2>
                </div>


                <div className='flex justify-between'>
                    <div className='pr-3'>
                        <img className='w-full' src={env.baseUrl + '/images/career-slider1.png'} alt='Join a Workplace' />
                    </div>

                    <div className='p-3'>
                        <img className='lg:mt-[90px] max-w-full' src={env.baseUrl + '/images/career-slider2.png'} alt='Join a Workplace' />
                    </div>

                    <div className='p-3'>
                        <img className=' max-w-full' src={env.baseUrl + '/images/career-slider3.png'} alt='Join a Workplace' />
                    </div>

                    <div className='p-3'>
                        <img className='lg:mt-[150px]  max-w-full' src={env.baseUrl + '/images/career-slider4.png'} alt='Join a Workplace' />
                    </div>

                    <div className='pl-3'>
                        <img className='max-w-full' src={env.baseUrl + '/images/career-slider5.png'} alt='Join a Workplace' />
                    </div>
                </div>

                <div className="lg:container mx-auto px-4 lg:px-[auto] border-b-1 w-full">
                    <div className='border-b-1 w-full'></div>
                </div>
            </section>

            <section className="choose-category">
                <div className="lg:container mx-auto px-4 mt-[50px] lg:mt-[100px] lg:px-[auto] border-t-2">
                    <h2 className="base-h2 text-center mt-[50px] lg:mt-[130px] lg:w-[50%] mx-auto">Choose the category <span className="text-[#F26722]">where you expert</span></h2>
                    <p className="base-p1 text-[#667085] lg:w-[60%] text-center mx-auto mt-[15px] font-semibold">In a professional context it often happens that private or corporate clients order a publication to publish news.</p>
                    
                     <div className='grid grid-cols-1 gap-x-[50px] gap-y-[35px] lg:gap-x-[30px] lg:gap-y-[50px] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-[70px]'>

                        <div class="flex items-start gap-[15px] md:gap-[30px] lg:p-[10px] lg:p-[10px] border-b-2">
                            <img src={env.baseUrl + '/images/Graphic-designer.png'} alt='Our Career' />
                            <div>
                                <p class="cutom-font-bold  text-[#101828] text-[22px]  lg:text-[28px] md:text-heading-6 mb-1 md:mb-[10px] ">Graphic designer
                                </p>
                                <div class="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                                    <p>Years of Experience: 4 - 6</p>
                                    <p>No of Positions: 2</p>
                                    <p>Positioning status: Completed</p>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit......</p>
                                    <Link to='/careers/job-details' className='inline-block mt-4 mb-8 text-[#F26722] text-[18px] ml-1 hover:text-gray-600 cutom-font-bold-200 duration-300'>Apply Now&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                                </div>
                            </div>
                        </div>

                        <div class="flex items-start gap-[15px] md:gap-[30px] lg:p-[10px] lg:p-[10px] border-b-2">
                            <img src={env.baseUrl + '/images/Web-developer.png'} alt='Our Career' />
                            <div>
                                <p class="cutom-font-bold  text-[#101828] text-[22px]  lg:text-[28px] md:text-heading-6 mb-1 md:mb-[10px] ">Web developer
                                </p>
                                <div class="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                                    <p>Years of Experience: 5 - 7</p>
                                    <p>No of Positions: 4</p>
                                    <p>Positioning status: Completed</p>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit......</p>
                                    <Link to='/careers/job-details' className='inline-block mt-4 mb-8 text-[#F26722] text-[18px] ml-1 hover:text-gray-600 cutom-font-bold-200 duration-300'>Apply Now&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                                </div>
                            </div>
                        </div>

                        <div class="flex items-start gap-[15px] md:gap-[30px] lg:p-[10px] lg:p-[10px] border-b-2">
                            <img src={env.baseUrl + '/images/Data-scientist.png'} alt='Our Career' />
                            <div>
                                <p class="cutom-font-bold  text-[#101828] text-[22px]  lg:text-[28px] md:text-heading-6 mb-1 md:mb-[10px] ">Data scientist
                                </p>
                                <div class="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                                    <p>Years of Experience: 3 - 5</p>
                                    <p>No of Positions: 2</p>
                                    <p>Positioning status: Completed</p>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit......</p>
                                    <Link to='/careers/job-details' className='inline-block mt-4 mb-8 text-[#F26722] text-[18px] ml-1 hover:text-gray-600 cutom-font-bold-200 duration-300'>Apply Now&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                                </div>
                            </div>
                        </div>

                        <div class="flex items-start gap-[15px] md:gap-[30px] lg:p-[10px] lg:p-[10px] border-b-2">
                            <img src={env.baseUrl + '/images/technical-support.png'} alt='Our Career' />
                            <div>
                                <p class="cutom-font-bold  text-[#101828] text-[22px]  lg:text-[28px] md:text-heading-6 mb-1 md:mb-[10px] ">Technical Support
                                </p>
                                <div class="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                                    <p>Years of Experience: 4 - 6</p>
                                    <p>No of Positions: 3</p>
                                    <p>Positioning status: Completed</p>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit......</p>
                                    <Link to='/careers/job-details' className='inline-block mt-4 mb-8 text-[#F26722] text-[18px] ml-1 hover:text-gray-600 cutom-font-bold-200 duration-300'>Apply Now&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                                </div>
                            </div>
                        </div>

                        <div class="flex items-start gap-[15px] md:gap-[30px] lg:p-[10px] lg:p-[10px] border-b-2">
                            <img src={env.baseUrl + '/images/Digita-Marketing.png'} alt='Our Career' />
                            <div>
                                <p class="cutom-font-bold  text-[#101828] text-[22px]  lg:text-[28px] md:text-heading-6 mb-1 md:mb-[10px] ">Digital Marketing
                                </p>
                                <div class="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                                    <p>Years of Experience: 4 - 7</p>
                                    <p>No of Positions: 2</p>
                                    <p>Positioning status: Completed</p>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit......</p>
                                    <Link to='/careers/job-details' className='inline-block mt-4 mb-8 text-[#F26722] text-[18px] ml-1 hover:text-gray-600 cutom-font-bold-200 duration-300'>Apply Now&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                                </div>
                            </div>
                        </div>

                        <div class="flex items-start gap-[15px] md:gap-[30px] lg:p-[10px] lg:p-[10px] border-b-2">
                            <img src={env.baseUrl + '/images/ui-designer.png'} alt='Our Career' />
                            <div>
                                <p class="cutom-font-bold  text-[#101828] text-[22px]  lg:text-[28px] md:text-heading-6 mb-1 md:mb-[10px] ">UI/UX designer
                                </p>
                                <div class="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                                    <p>Years of Experience: 3 - 6</p>
                                    <p>No of Positions: 1</p>
                                    <p>Positioning status: Completed</p>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit......</p>
                                    <Link to='/careers/job-details' className='inline-block mt-4 mb-8 text-[#F26722] text-[18px] ml-1 hover:text-gray-600 cutom-font-bold-200 duration-300'>Apply Now&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>

            <section className='WhyOurTeam bg-[#FB9851] mt-[40px] lg:mt-[100px] py-[40px] lg:py-[90px]'>
                <div className="lg:container mx-auto p-4">
                    <div className='grid md:grid-cols-12 lg:grid-cols-12 gap-7'>

                        <div className="col-span-12 md:col-span-12 lg:col-span-6 lg:pr-[115px]">
                            <div className='bg-[#FFF3EA] w-[220px] h-[44px] text-[16px] rounded-[40px] flex justify-center items-center'>
                                Built Exclusively For You
                            </div>
                            <h3 className='text-[35px] lg:text-[54px] font-bold text-[#fff] mt-[35px]'>
                                Why Our Team Is One Of The Best!
                            </h3>
                            <p className='text-[24px] text-[#fff] mt-4'>
                                There are countless great reasons to work at our company and we hate to brag, but it’s too hard not to!
                            </p>
                            <Link to="/contact-form" className="py-[15px] lg:py-[20px] px-[20px] lg:px-[30px] bg-[#101828] text-white  mt-[50px] rounded-[50px] text-[18px] font-bold flex justify-center items-center hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg float-left duration-300">Learn more <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="text-[15px] ml-[13px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></Link>
                        </div>

                        <div className='col-span-12 md:col-span-6 lg:col-span-3'>
                            <div className='bg-[#fff] p-4 lg:p-[25px] min-h-[270px] hover:shadow-lg duration-300 font-bold'>
                                <p>
                                We've enjoyed collaborating with Versatile Commerce for over a year. They've consistently excelled throughout our partnership, playing a pivotal role in preparing our SaaS platform for market readiness.
                                </p>
                                <div className='flex gap-5 mt-[30px]'>
                                    <div><img className='max-w-[100%]' src={env.baseUrl + '/images/testimonial-logo-certchain.png'} alt='Why Our Team Is best' /></div>
                                    <div>
                                        <div className='cutom-font-bold text-[16px] mt-5'>Stephen Holt  </div>
                                        <p className='text-[14px] text-[#667085]'>Cert Chain</p>
                                    </div>
                                </div>
                            </div>

                            <div className='bg-[#fff] p-4 lg:p-[25px] min-h-[270px] hover:shadow-lg duration-300 font-bold mt-7'>
                                <p>
                                They are experts in their field, readily available to communicate, address queries, and offer solutions. Their team consists of highly skilled professionals, eliminating the need for resource upskilling.
                                </p>
                                <div className='flex gap-5 mt-[30px]'>
                                    <div><img className='max-w-[100%]' src={env.baseUrl + '/images/testimonial-logo-cii.png'} alt='Why Our Team Is best' /></div>
                                    <div>
                                        <div className='cutom-font-bold text-[16px] mt-5'>Nana Marshall </div>
                                        <p className='text-[14px] text-[#667085]'>CII</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-span-12 md:col-span-6 lg:col-span-3'>
                            <div className='bg-[#fff] p-4 lg:p-[25px] min-h-[270px] hover:shadow-lg duration-300 font-bold'>
                                <p>
                                Your team exhibits several vital qualities that greatly benefit our collaboration. They are highly responsive, always providing a definitive timeline for project initiation, even when delays occur.  
                                </p>
                                <div className='flex gap-5 mt-[30px]'>
                                    <div><img className='max-w-[100%]' src={env.baseUrl + '/images/testimonial-logo-prop.png'} alt='Why Our Team Is best' /></div>
                                    <div>
                                        <div className='cutom-font-bold text-[16px] mt-5'>Sourabh Tyagi </div>
                                        <p className='text-[14px] text-[#667085]'>Prop Right </p>
                                    </div>
                                </div>
                            </div>

                            <div className='bg-[#fff] p-4 lg:p-[25px] min-h-[270px] hover:shadow-lg duration-300 font-bold mt-7'>
                                <p>
                                We have access to talented individuals with diverse skill sets, allowing us to scale resources up or down as needed. This team comprises hardworking and personable individuals who are knowledgeable and exceptionally supportive. 
                                </p>
                                <div className='flex gap-5 mt-[30px]'>
                                    <div><img className='max-w-[100%]' src={env.baseUrl + '/images/testimonial-logo-wment.png'} alt='Why Our Team Is best' /></div>
                                    <div>
                                        <div className='cutom-font-bold text-[16px] mt-5'>Fran Boorman  </div>
                                        <p className='text-[14px] text-[#667085]'>Wowment  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='FromOurBlog'>
                <div className='md:container p-4 mx-auto mt-[100px]'>
                    <h2 className='text-[40px] md:text-[56px] text-center font-bold'>From <span className='text-[#F26722]'>Our Blogs</span></h2>

                    <p className='text-[24px] text-[#667085] lg:w-[50%] text-center mx-auto mt-[5px] font-semibold'>
                        From Our blog and Event fanpage
                    </p>

                    <div className='grid lg:grid-cols-3 mt-[60px]'>
                        <div className='lg:px-4 md:mx-3'>
                            <Link to="/blog/we-can-blend-colors-multiple-ways" className="block group">
                                <div className="flex items-center mb-[11px]">
                                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                                </div>
                                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">We can blend colors multiple ways, the most common</p>
                                <div className="relative mb-16">
                                    <div className="relative">
                                        <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-We-can-blend-colors-multiple-ways.png'} alt="Digital Transformation" />
                                        </div>
                                        <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                                            <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='lg:px-4 md:mx-3'>
                            <Link to="/blog/engineering-experiences-a-journey" className="block group">
                                <div className="flex items-center mb-[11px]">
                                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                                </div>
                                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Engineering Experiences: A Journey of Innovation and Creativity</p>
                                <div className="relative mb-16">
                                    <div className="relative">
                                        <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/Blog-Engineering-Experiences.png'} alt="Engineering Experiences" />
                                        </div>
                                        <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                                            <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#BEE1E6]"></div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='lg:px-4 md:mx-3'>
                            <Link to="/blog/saas-products" className="block group">
                                <div className="flex items-center mb-[11px]">
                                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Customer Services</span>
                                </div>
                                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">SaaS Products</p>
                                <div className="relative mb-16">
                                    <div className="relative">
                                        <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-SaaS-Products.png'} alt="Saas Products" />
                                        </div>
                                        <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                                            <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className='lg:px-4 md:mx-3'>
                            <Link to="/blog/the-evolution-significance" className="block group">
                                <div className="flex items-center mb-[11px]">
                                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                                </div>
                                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">The Evolution and Significance of Cloud Engineering in Modern Computing</p>
                                <div className="relative mb-16">
                                    <div className="relative">
                                        <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/the-evolution-and-significance.png'} alt="The evolution and significance" />
                                        </div>
                                        <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                                            <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className='lg:px-4 md:mx-3'>
                            <Link to="/blog/navigating-the-digital-migration" className="block group">
                                <div className="flex items-center mb-[11px]">
                                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                                </div>
                                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Navigating the Digital Migration: A New Frontier in Business Transformation</p>
                                <div className="relative mb-16">
                                    <div className="relative">
                                        <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/navigating-the-digital-migration.png'} alt="Navigating the digital migration" />
                                        </div>
                                        <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                                            <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className='mx-auto flex justify-center items-center'>
                        <Link to="/blog" className="py-[15px] lg:py-[20px] px-[20px] lg:px-[30px] bg-[#101828] text-white  mt-[30px] rounded-[50px] text-[18px] font-bold flex justify-center items-center hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg float-left duration-300">View More <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="text-[15px] ml-[13px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></Link>
                    </div>
                </div>
            </section>

            <Discovery
                title={'Subscribe for more'}
                subTitle={'job Alert!'}
                desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
                btnName={'Subscribe'}
                placeholder={'Drop a Email'} />

            <Faqs
                heading1={'What documents should I carry for the Interview? '}
                content1={jobprofile}
                
                heading2={"How can I apply for this position? "}
                content2={["Interested in applying for a position? Visit our careers page at",  <Link to='https://www.versatilecommerce.com/#/careers'> versatilecommerce.com/careers </Link>, "to view our current job openings and access the application form. Alternatively, you can submit your updated CV directly to ", <Link to='mailto:hr@versatilecommerce.com'>hr@versatilecommerce.com</Link> ]}

                heading3={"What are your working hours?"}
                content3={"Our regular hours are from 12 p.m. to 9 p.m., Monday through Friday. Please note that we do not operate on Saturdays and Sundays."}

                heading4={"How do I apply for an internship in your company? "}
                content4={"The application process involves two steps for those interested in our internship programs. First, you'll need to complete a qualifying test. Upon passing the test, you'll be invited for an interview. Our internships typically range from 3 to 6 months, depending on the specific program."}
                status={false}
            />
            
        </>
    )
}

export default Careers