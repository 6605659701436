import React, { useEffect, Suspense } from 'react'
import Discovery from '../../../Components/DiscoveryCall/Discovery'
import ReactHelmet from '../../../Components/ReactHelmet'
import env from '../../../Config'

const CloudEngineering = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return (
        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Services | Cloud engineering`}
                    keywords={`Cloud engineering`}
                    description={`Innovate, Migrate, Elevate: Your Pathway to Cloud Engineering Excellence`}
                    seoimgs={`Cloud-engineering.png`}
                />
            </Suspense>
            
            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/Cloud-engineering.png)` }}>
                <div className='lg:container mx-auto px-4'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
                        <div className="flex-1 md:py-[70px] lg:py-[100px]">
                            <h2 className="text-[2rem] lg:text-[82px] text-[#222] md:text-[#fff] md:w-[50%] lg:w-[40%]  leading-tight font-bold text-center md:text-left">
                                Cloud Engineering
                            </h2>
                            <p className="text-[18px] lg:text-[24px] text-[#333] md:text-[#B5BDCC] font-semibold mt-5 md:w-[50%] lg:w-[40%] text-center md:text-left">
                                Innovate, Migrate, Elevate: Your Pathway to Cloud Engineering Excellence
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className='ourEngineerings'>
                <div className="lg:container mx-auto px-4 my-[40px] md:my-[70px] lg:mt-[100px]">
                    <div className="text-center">
                        <h2 className="text-[2rem] lg:text-[56px] leading-tight font-bold">Cloud <span className="text-[#f26722]"> Engineering </span></h2>
                        <p className="text-[20px] md:text-[24px] text-[#475467] font-bold lg:w-[45%] mx-auto mt-4">
                            Reshaping Infrastructure, Revolutionising Industries: Cloud Engineering at its Best</p>
                    </div>

                    <div className='xl:px-0 mt-[70px] lg:mt-[80px]'>
                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Cloud-assessment.png'} alt='Cloud Assessment'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Cloud Assessment</h2>
                                <p className='base-p1'>
                                    These points collectively help organisations  make informed decisions about adopting cloud solutions and ensure a successful and smooth transition to the cloud environment
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Infrastructure Evaluation</h5>
                                            <div className='tooltip_box'>A cloud assessment involves evaluating the existing IT infrastructure of an organisation to determine its readiness for migration to a cloud environment.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Workload Analysis</p>
                                            <div className='tooltip_box'>It's essential to analyse the current workloads and applications to identify which ones are suitable for migration to the cloud and which might require modifications.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Cost Analysis</h5>
                                            <div className='tooltip_box'>Cloud assessments focus on estimating the potential costs associated with migrating to the cloud, including factors such as infrastructure, data transfer, storage, and ongoing operational expenses.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Security and Compliance</h5>
                                            <div className='tooltip_box'>Assessing the organisations security requirements and compliance standards is crucial to ensure that the chosen cloud solution meets regulatory needs and offers robust data protection.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Performance Requirements</h5>
                                            <div className='tooltip_box'>Understanding performance needs helps in choosing the appropriate cloud resources to ensure optimal performance for applications and services.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scalability and Flexibility</h5>
                                            <div className='tooltip_box'>Assessing scalability requirements ensures that the cloud environment can accommodate future growth and handle fluctuations in demand effectively.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Management Strategy</h5>
                                            <div className='tooltip_box'>Organisations need to evaluate how their data will be managed in the cloud, including storage, backup, recovery, and data lifecycle management.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Integration and Interoperability</h5>
                                            <div className='tooltip_box'>A cloud assessment includes evaluating how existing systems and applications will integrate with the cloud environment to maintain seamless operations.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Vendor Selection</h5>
                                            <div className='tooltip_box'>Organisations need to assess various cloud service providers to determine the best fit based on their requirements, budget, and services offered.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Migration Strategy</h5>
                                            <div className='tooltip_box'>Based on the assessment, a migration strategy is formulated, outlining the sequence of migration, potential challenges, mitigation plans, and a timeline for the migration process.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Design and architecture</h2>
                                <p className='base-p1'>
                                    Revolutionising the SaaS Industry Through User-Centric Design and Scalable Architecture: Where Innovation Meets Reliability for a Seamless Digital Experience
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Interoperability and Integration</h5>
                                            <div className='tooltip_box'>Design should facilitate seamless integration with external systems, APIs, and services, enabling interoperability between different components.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Resilience and Fault Tolerance</h5>
                                            <div className='tooltip_box'>Architectures should be designed to handle failures gracefully, with redundancy and failover mechanisms to ensure continuous operation.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Management Strategy</h5>
                                            <div className='tooltip_box'>Design should include a well-defined strategy for data storage, retrieval, and management, considering data consistency, availability, and integrity.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Technology Stack Selection</h5>
                                            <div className='tooltip_box'>Choosing the right technologies, frameworks, and tools is crucial for the success of the project, aligning with the project's goals and requirements.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User-Centric Approach</h5>
                                            <div className='tooltip_box'>Design and architecture should prioritize the needs and preferences of the end-users, ensuring a seamless and intuitive experience.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Documentation and Communication</h5>
                                            <div className='tooltip_box'>Clear documentation of the architecture and design decisions is essential for effective communication among team members, stakeholders, and future developers.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scalability and Flexibility</h5>
                                            <div className='tooltip_box'>Designs should be scalable to accommodate growth and changes over time, while maintaining the flexibility to adapt to evolving requirements.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Modularity and Reusability</h5>
                                            <div className='tooltip_box'>Architecture should be modular, allowing components to be reused across different parts of the system, promoting efficiency and reducing development effort.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Performance Optimisation</h5>
                                            <div className='tooltip_box'>Architectural decisions should consider performance factors such as response time, latency, and throughput, ensuring efficient resource utilisation.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Security by Design</h5>
                                            <div className='tooltip_box'>Security considerations should be integrated into the design from the outset, addressing potential vulnerabilities and implementing robust data protection mechanisms.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Design-architecture.png'} alt='Design and architecture'/>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Cloud-native-agnostic-application-development.png'} alt='Cloud native agnostic application development'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Cloud native/agnostic application development</h2>
                                <p className='base-p1'>
                                    Developing agnostic applications requires careful consideration of standards, protocols, and design patterns that promote compatibility and flexibility across diverse environments.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[50px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Platform Agnosticism</h5>
                                            <div className='tooltip_box'>Agnostic application development involves creating software that is not tied to a specific platform or technology, allowing it to run seamlessly across different environments.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Device Agnosticism</p>
                                            <div className='tooltip_box'>Applications developed with agnosticism in mind are compatible with various devices, including smartphones, tablets, desktops, and IoT devices.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Operating System Agnosticism</h5>
                                            <div className='tooltip_box'>Agnostic applications are designed to work across different operating systems, such as Windows, macOS, Linux, iOS, and Android.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Browser Agnosticism</h5>
                                            <div className='tooltip_box'>Web applications that are agnostic can function consistently across various web browsers, reducing compatibility issues.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Format Agnosticism</h5>
                                            <div className='tooltip_box'>Agnostic applications can process and exchange data in multiple formats, adapting to different data sources and protocols.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Cloud Agnosticism</h5>
                                            <div className='tooltip_box'>Cloud agnostic applications are designed to function on various cloud platforms without being tightly coupled to a specific provider's services.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Language Agnosticism</h5>
                                            <div className='tooltip_box'>Agnostic development allows the use of multiple programming languages or frameworks within the same application, depending on the specific requirements.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Integration Agnosticism</h5>
                                            <div className='tooltip_box'>Applications designed with integration agnosticism in mind can seamlessly connect with different APIs, services, and third-party systems.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scalability Agnosticism</h5>
                                            <div className='tooltip_box'>Agnostic applications can scale easily across various environments, from on-premises to cloud-based, without major architectural changes.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Future-Proofing</h5>
                                            <div className='tooltip_box'>Developing agnostic applications future-proofs them to some extent, as they are less susceptible to becoming obsolete due to changes in platforms or technologies.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Migration</h2>
                                <p className='base-p1'>
                                    Successful migration involves careful planning, meticulous execution, and a focus on minimising disruptions while maximising the benefits of the new environment.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[50px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Assessment and Planning</h5>
                                            <div className='tooltip_box'>Thoroughly assess the current state of systems, applications, and data before migration. Create a detailed migration plan that outlines goals, scope, timeline, and resource allocation.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Migration Strategy</h5>
                                            <div className='tooltip_box'>Develop a strategy for transferring data between old and new systems. Consider data mapping, transformation, validation, and ensuring data integrity during the migration process.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Application Compatibility</h5>
                                            <div className='tooltip_box'>Evaluate the compatibility of applications with the target environment. Identify any necessary modifications, updates, or replacements to ensure smooth functioning after migration.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Testing and Validation</h5>
                                            <div className='tooltip_box'>Rigorous testing is crucial. Conduct tests to validate that migrated applications and systems operate as expected, including functional, performance, and security testing.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Risk Mitigation</h5>
                                            <div className='tooltip_box'>Identify potential risks and challenges that could arise during migration. Develop contingency plans to address issues promptly and minimise disruptions.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Communication and Training</h5>
                                            <div className='tooltip_box'>Keep stakeholders informed throughout the migration process. Provide training for end-users on any changes to workflows, interfaces, or processes.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Incremental Approach</h5>
                                            <div className='tooltip_box'>Consider an incremental migration approach where systems are migrated in stages. This reduces the overall impact and allows for continuous operation during the transition.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Resource Allocation</h5>
                                            <div className='tooltip_box'>Allocate sufficient resources, both technical and human, to ensure a successful migration. Adequate support during and after migration is essential for addressing unexpected issues.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Rollback Plan</h5>
                                            <div className='tooltip_box'>Have a rollback plan in case the migration encounters critical issues. This plan outlines steps to revert to the previous state and minimize downtime if necessary.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Post-Migration Optimisation</h5>
                                            <div className='tooltip_box'>After migration, monitor the new environment closely to identify performance bottlenecks, fine-tune configurations, and optimize resources for efficiency.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Migration.png'} alt='Migration'/>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Digital-advisory-transformation.png'} alt='Digital Advisory and Transformation'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Digital Advisory and Transformation</h2>
                                <p className='base-p1'>
                                    Digital advisory and transformation involve a strategic approach to integrating digital technologies into an organisations operations, culture, and strategy.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[50px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Definition</h5>
                                            <div className='tooltip_box'>Digital advisory and transformation refer to the process of utilising technology and digital strategies to guide organisations through substantial changes in their operations, processes, and business models, with the aim of enhancing efficiency, competitiveness, and customer experience.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Strategic Alignment</p>
                                            <div className='tooltip_box'>Successful digital transformation requires aligning technological initiatives with an organisations broader business strategy and objectives. It's essential to identify how technology can drive growth, innovation, and sustainable value.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Customer-Centric Approach</h5>
                                            <div className='tooltip_box'>A core principle of digital transformation is focusing on the customer journey. This involves leveraging digital tools to gain insights into customer behaviour, preferences, and needs, enabling the development of personalised experiences and solutions.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data-Driven Decision-Making</h5>
                                            <div className='tooltip_box'>Data is at the heart of digital transformation. Organisations must collect, analyse, and leverage data to make informed decisions. This involves implementing advanced analytics, AI, and machine learning to derive actionable insights.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Agility and Flexibility</h5>
                                            <div className='tooltip_box'>Digital transformation requires agility to adapt to rapidly evolving technologies and market dynamics. Organisations need to foster a culture of innovation, experimentation, and quick iteration to stay competitive.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Process Optimisation</h5>
                                            <div className='tooltip_box'>Transformation often involves reengineering existing processes to capitalise on digital capabilities. Automation, robotic process automation (RPA), and business process management (BPM) can streamline workflows and reduce manual effort.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Change Management</h5>
                                            <div className='tooltip_box'>People and culture play a significant role in digital transformation. Change management strategies are vital to ensuring that employees understand and embrace new technologies, processes, and ways of working.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Technology Stack</h5>
                                            <div className='tooltip_box'>Developing a comprehensive technology stack is crucial. This involves selecting the right mix of software, platforms, and tools that support the organisations specific goals. Cloud computing, cybersecurity, and integration capabilities are key considerations.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Ecosystem Partnerships</h5>
                                            <div className='tooltip_box'>Collaborating with external partners, startups, and technology providers can accelerate digital transformation. Partnerships can provide access to specialised expertise, cutting-edge solutions, and new market opportunities.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Evolution</h5>
                                            <div className='tooltip_box'>Digital transformation is an ongoing journey, not a one-time project. Organisations must be prepared to continually assess, refine, and evolve their strategies as technology and market conditions change.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Discovery
                title={'Discovery '}
                subTitle={'Call'}
                desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
                btnName={'Subscribe'}
                placeholder={'Drop a Email'} />
        </>
    )
}

export default CloudEngineering