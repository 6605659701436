import React, { useEffect } from 'react'
import env from '../../Config'
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Tab } from '@headlessui/react'
// import {
//   Tab,
//   initTE,
// } from "tw-elements";
// initTE({ Tab });

const Product = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>

      <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrlLocal}/images/world-most-agile-erp.png)` }}>
        <div className="lg:container mx-auto px-4 main-bnr-hgt lg:py-[100px]">
          <div className='grid lg:grid-cols-12 md:grid-cols-12 py-[50px] md:py-[20px] lg:mb-[80px]'>

            <div className="lg:col-cols-6 md:col-span-6 flex justify-start items-center lg:py-[40px]">
              <div className="banner-subcrib lg:mr-[30px] lg:mt-9">
                <h3 className='base-h3 cutom-font-bold-200'>Best Agile ERP Solution</h3>
                <p className='font-bold mt-4'>
                  Comprehensive ERP Solutions for Seamless Business Operations Consolidation, Sustained Growth, and Exceptional Customer Service.
                </p>

                <div className='relative mt-9 lg:mx-[auto]'>
                  <button className='absolute px-4 lg:px-9 right-[10px] rounded-[7px] top-[11px] bg-[#101828] h-[50px] text-white font-bold hover:bg-[#f26722] hover:text-[#fff] hover:drop-shadow-lg duration-300 flex jusity-center items-center'>
                    <span className='hidden md:block'>Subscribe</span>  <FaArrowRight className='text-[15px] md:ml-[13px]' /></button>
                  <input type="email"
                    className="block w-full lg:pl-9 px-4 py-2 mt-5 h-[72px] text-gray-700 bg-white border rounded-md focus:border-orange-400 focus:ring-orange-300 focus:outline-none focus:ring focus:ring-opacity-40" placeholder='Enter your email ...' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className='ERP_Features'>
        <div className="lg:container mx-auto lg:px-4">
         <h2 className='base-h2 cutom-font-bold-200 text-center lg:pt-[70px] my-5'>ERP Features</h2>
         <div className="base-h6 text-center px-4">VeloxERP offers a comprehensive suite of features to empower your business:</div>

          {/* new tabs start */}
          <div className='product-tabs-1 mt-5'>
            <Tab.Group>
              <div className='grid lg:grid-cols-12 relative'>
                <div className='grid lg:col-span-3 lg:order-2 z-10  right-0'>
                  <Tab.List className="product-tabs-section lg:h-[600px] z-10">
                    <Tab className="block w-full text-left px-7 py-4 text-[16px] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Financial Accounting</Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Order Management </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">HR and Payroll </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Supply Chain & Manufacturing </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">CRM </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Project Management </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Help desk </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Asset Management </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Website </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">ERP Analytics </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Procurement </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Risk Management & Compliance </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Enterprise Performance Management (EPM) </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Global Business Management </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Enterprise Performance Management </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Inventory Management </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Warehouse Management </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Customer and order management </Tab>

                    <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-\[16px\] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Planning and scheduling </Tab>
                  </Tab.List>
                </div>

                <div className='grid lg:col-span-9 shadow-lg rounded-lg z-0 mx-0 md:mx-4 lg:mx-[0]'>
                  <Tab.Panels className="z-0">

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Financial-Accounting.png"} alt="" className='max-w-100' />
                        <h4 className='base-h4 erp-features-h'>Financial Accounting</h4>
                        <div className='erp-features-p'>
                          <p>Gain Clarity in Your Finances for Informed Decision-Making.</p>
                          <p>Gain real-time insights into your cash flow with a robust accounting module covering all facets of bookkeeping.</p>
                        </div>

                        <h4 className='base-h6 erp-features-h3'>Features</h4>

                        <div className="grid lg:grid-cols-12 md:grid-cols-6 feature-content">
                          <div className="lg:col-span-6 md:col-span-6">
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Accounting Hub
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Reporting and analytics
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Payables and Assets
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i>Revenue management
                            </div>

                          </div>

                          <div className="lg:col-span-6 md:col-span-6">
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Receivables
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Collections management
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Expense management
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Joint venture management
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Order-Management.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Order Management</h4>
                        <p className='erp-features-p'>
                          Optimise productivity and reduce costs by effectively managing your sales and purchase cycles, from purchase to sales orders.
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/HR-and-Payroll.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>HR and Payroll</h4>
                        <p className='erp-features-p'>
                          Effortlessly handle the complete employee lifecycle, from onboarding to payroll, attendance, expense claims, asset management, and separation.
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Supply-Chain-Manufacturing.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Supply Chain & Manufacturing</h4>
                        <p className='erp-features-p'>
                          Optimise Production Processes through Effective Maintenance and Management of Multilevel Bills of Materials, Production Planning, Job Cards, and Inventory. With VC Cloud SCM, Establish Connectivity across Your Supply Network with an Integrated Suite of Cloud Business Applications Engineered to Excel in a Rapidly Changing Environment.
                        </p>

                        <h4 className='base-h6 erp-features-h3'>Features</h4>

                        <div className="grid lg:grid-cols-12 md:grid-cols-6 feature-content">
                          <div className="lg:col-span-6 md:col-span-6">
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Supply chain planning
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Inventory
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Manufacturing
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Maintenance
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Order management
                            </div>

                          </div>

                          <div className="lg:col-span-6 md:col-span-6">
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Logistics
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Product lifecycle management
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Procurement
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> IoT and blockchain
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/CRM.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>CRM</h4>
                        <p className='erp-features-p'>
                          Enhance customer relationships and boost sales by optimising the sales process. Track leads, opportunities, and generate quotes on the fly.
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Project-Management.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Project Management</h4>
                        <p className='erp-features-p'>
                          <p> Align Your Projects with Your Strategic Business Goals.</p>
                          <p>Successfully execute internal and external projects on time, within budget, and profitably. Track tasks, timesheets, and project issues.</p>
                        </p>

                        <h4 className='base-h6 erp-features-h3'>Features</h4>

                        <div className="grid lg:grid-cols-12 md:grid-cols-6 feature-content">
                          <div className="lg:col-span-6 md:col-span-6">
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Plan, schedule, and forecast
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Resource management
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Cost management and control
                            </div>
                          </div>

                          <div className="lg:col-span-6 md:col-span-6">
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Billing and revenue management
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Grant management
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Project asset management
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Help-desk.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Help desk</h4>
                        <p className='erp-features-p'>
                          Elevate your service quality with an intuitive issue tracker and an integrated knowledge base, ensuring a superior service experience.
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Asset-Management.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Asset Management</h4>
                        <p className='erp-features-p'>
                          Efficiently maintain and manage asset details, tracking their movement, value adjustments, and depreciation.
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel> <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                      <img src={env.baseUrlLocal + "/images/Website.png"} alt="" className='max-w-100' />

                      <h4 className='base-h4 erp-features-h'>Website</h4>
                      <p className='erp-features-p'>
                        Harness the power of a fully-featured content management system with blogs, web pages, and forms to create a compelling online presence.
                      </p>
                    </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/ERP-Analytics.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>ERP Analytics</h4>
                        <p className='erp-features-p'>
                          <p>Forecast and Analyse with AI.</p>
                          <p>
                            VC Analytics for Cloud ERP enhances embedded analytics in VC Cloud ERP by providing prepackaged use cases, predictive analysis, and KPIs based on variance analysis and historical trends.
                          </p>
                        </p>

                        <h4 className='base-h6 erp-features-h3'>Features</h4>

                        <div className="grid lg:grid-cols-12 md:grid-cols-6 feature-content">
                          <div className="lg:col-span-6 md:col-span-6">
                            <div>
                              <i className="las la-calendar-check orange-c"></i> KPI management
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Best practice metrics library
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Prebuilt analytic models
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Extensible architecture
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Business content areas
                            </div>
                          </div>

                          <div className="lg:col-span-6 md:col-span-6">
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Self-service data discovery
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Augmented analytics
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Collaboration and publishing
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Enterprise architecture and security
                            </div>
                            <div>
                              <i className="las la-calendar-check orange-c"></i> Mobile exploration
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Procurment.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Procurement</h4>
                        <p className='erp-features-p'>
                          <p>Empower Your Team and Streamline Procurement Processes.</p>
                          <p>
                            VC Procurement: Simplifying and Integrating Source-to-Pay with User-Friendly Design, Advanced Analytics, and Collaborative Supplier Management for Enhanced Profitability
                          </p>
                        </p>
                        <h4 className='base-h6 erp-features-h3'>Modules</h4>
                        <ul className='list-disc ml-8'>
                          <li>Procure-to-Pay</li>
                          <li>Supplier management</li>
                          <li>Sourcing</li>
                          <li>Contracts</li>
                          <li>Business Network</li>
                        </ul>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Risk-Management-Compliance.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Risk Management & Compliance</h4>
                        <p className='erp-features-p '>
                          <p>Foster Trust and Mitigate Risk through Automated Monitoring.</p>
                          <p>
                            VC Risk Management and Compliance: Leveraging AI and ML to Bolster Financial Controls, Prevent Cash Leaks, Ensure Audits, and Safeguard Against Emerging Risks, Delivering Efficiency and Accuracy.
                          </p>
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Enterprise-Performance-Management.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Enterprise Performance Management (EPM)</h4>
                        <p className='erp-features-p'>
                          <p>Achieve Excellence Through Agility</p>
                          <p>
                            <b>VC Enterprise Performance Management:</b> Empowering Cross-Functional Modelling and Planning Across Finance, HR, Supply Chain, and Sales. Streamline Financial Closures and Gain Valuable Insights for Informed Decision-Making.
                          </p>
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Global-Business-Management.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Global Business Management </h4>
                        <p className='erp-features-p'>
                          VC Streamlining Global Business Operations by Managing Multiple Currencies, Taxation Rules, and Reporting Across Diverse Geographies and Subsidiaries, All with Real-time Financial Consolidation and Visibility.
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Enterprise-Performance Mangt.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Enterprise Performance Management</h4>
                        <p className='erp-features-p'>
                          VC Enterprise Performance Management: Unifying Planning, Budgeting, Forecasting, Account Reconciliation, Financial Close, and Reporting Across the Organisation.
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Inventory-Management.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Inventory Management</h4>
                        <p className='erp-features-p'>
                          Streamline Inventory Management with Automation. Reduce Inventory Costs While Meeting Customer Delivery Expectations. VC Provides Tools for Multi-Location Inventory Tracking, Reorder Point Determination, Replenishment Management, and Safety Stock Optimisation.
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Warehouse-Management.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Warehouse Management</h4>
                        <p className='erp-features-p'>
                          Enhance Warehouse Operations Efficiency with RF-Device-Directed Putaway and Picking Tasks. Utilise Customised Strategies and Advanced Features including Wave Management, Cartonization, Cycle Count Planning, Real-time Inventory Updates, and Seamless Shipping System Integration.
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Customer-order-management.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Customer and order management</h4>
                        <p className='erp-features-p'>
                          Utilise Data-Driven Decision-Making for Flow Management, Operational Synchronisation, and Efficient Scheduling.
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pr-[5rem]'>
                        <img src={env.baseUrlLocal + "/images/Planning-scheduling.png"} alt="" className='max-w-100' />

                        <h4 className='base-h4 erp-features-h'>Planning scheduling</h4>
                        <div className='erp-features-p'>
                          <p>Attain a Comprehensive 360-Degree Customer View, Including Order History, Pricing, and Conditions.</p>
                          <p>
                            VC is your all-in-one solution for managing and growing your business effectively.
                          </p>
                        </div>
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </div>
              </div>
            </Tab.Group>
          </div>
          {/* new tabs end */}
        </div>
      </section>

      <section className="erp-benifits" style={{ backgroundImage: `url(${env.baseUrl}/images/erp-benifits-bg.png)` }}>
        <div className="lg:container mx-auto px-4">
          <h2 className='base-h2 cutom-font-bold-200 text-center lg:pt-[70px] my-5'>Velox ERP Benefits</h2>

          <div className=" grid lg:grid-cols-12 md:grid-cols-12 lg:py-[70px]">
            <span className="lg:cols-start-1 md:hidden lg:block"></span>

            <div className="benifits-boxs lg:col-span-2 md:col-span-4 px-0 md:px-4 lg:px-10 text-center lg:border-r-2 border-[#475467]">
              <i className="las la-calendar-check text-[40px] mt-5"></i>
              <h5 className='font-bold cutom-font-bold-200 mt-4'>Automated Financial Processes</h5>
              <p className='my-4'>
                Enhance financial operations, efficiency, and productivity through automation.
              </p>
            </div>

            <div className="benifits-boxs lg:col-span-2 md:col-span-4 px-0 md:px-4 lg:px-10 text-center lg:border-r-2 border-[#475467]">
              <i className="las la-calendar-check text-[40px] mt-5"></i>
              <h5 className='font-bold cutom-font-bold-200 mt-4'>Inventory Visibility</h5>
              <p className='my-4'>
                Maintain real-time visibility into your inventory levels, reduce carrying costs, and ensure on-time order delivery.
              </p>
            </div>
            <div className="benifits-boxs lg:col-span-2 md:col-span-4 px-0 md:px-4 lg:px-10 text-center lg:border-r-2 border-[#475467]">
              <i className="las la-calendar-check text-[40px] mt-5"></i>
              <h5 className='font-bold cutom-font-bold-200 mt-4'>Supply Chain Optimisation </h5>
              <p className='my-4'>
                Streamline the flow of goods across the entire value chain, from suppliers to customers.
              </p>
            </div>
            <div className="benifits-boxs lg:col-span-2 md:col-span-4 px-0 md:px-4 lg:px-10 text-center lg:border-r-2 border-[#475467]">
              <i className="las la-calendar-check text-[40px] mt-5"></i>
              <h5 className='font-bold cutom-font-bold-200 mt-4'>Error-Free Order Management</h5>
              <p className='my-4'>
                Implement a flawless order management and procurement system to eliminate errors.
              </p>
            </div>
            <div className="benifits-boxs lg:col-span-2 md:col-span-4 text-center px-0 md:px-4  lg:px-10">
              <i className="las la-calendar-check text-[40px] mt-5"></i>
              <h5 className='font-bold cutom-font-bold-200 mt-4'>Warehouse Efficiency Boost</h5>
              <p className='my-4'>
                Optimise the putaway process and minimise picking errors for increased warehouse efficiency.
              </p>
            </div>
          </div>
        </div>
      </section>


      <div className="pl-0 lg:pr-[70px] max-w-[100%] mx-auto Challenges-bg ">
        <div className="lg:py-7 text-center px-4">
          <h3 className="base-h3 cutom-font-bold-200 mb-3">Challenges VC ERP Solves</h3>
        </div>


        {/* new tab section 2 start */}
        <div className="product-tabs-2 mt-5  lg:container mx-auto">
          <Tab.Group>
            <div className='grid lg:grid-cols-12 relative'>
              <div className='grid lg:col-span-3 lg:order-2 z-10  right-0'>
                <Tab.List className="product-tabs-section  z-10">
                  <Tab className="block w-full text-left px-7 py-4 text-[16px] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Eliminate Manual Inefficiencies</Tab>

                  <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-[16px] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Seamless Data Access</Tab>

                  <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-[16px] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Ensuring Data Precision</Tab>

                  <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-[16px] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Exceeding Expectations</Tab>

                  <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-[16px] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Optimising Supply and Demand </Tab>

                  <Tab className="block w-full text-left my-2 rounded-xl px-7 py-4 text-[16px] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Strategic Inventory Management </Tab>
                </Tab.List>
              </div>

              <div className='grid lg:col-span-9 shadow-lg rounded-lg z-0 Challenges-box bg-[#101828;]'>
                <Tab.Panels className="z-0">

                  <Tab.Panel>
                    <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pl-[5rem]'>
                      <img src={env.baseUrlLocal + "/images/Eliminate-Manual-Inefficiencies.png"} alt="" className='max-w-100' />

                      <h4 className='base-h4 erp-features-h'>Eliminate Manual Inefficiencies</h4>
                      <p className='erp-features-p '>
                        Streamline and Automate Resource-Draining Processes.
                      </p>
                    </div>
                  </Tab.Panel>

                  <Tab.Panel>
                    <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pl-[5rem]'>
                      <img src={env.baseUrlLocal + "/images/Seamless-Data-Access.png"} alt="" className='max-w-100' />

                      <h4 className='base-h4 erp-features-h'>Seamless Data Access</h4>
                      <p className='erp-features-p '>
                        Rapidly Retrieve Real-Time Data Across the Entire Organisation.
                      </p>
                    </div>
                  </Tab.Panel>

                  <Tab.Panel>
                    <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pl-[5rem]'>
                      <img src={env.baseUrlLocal + "/images/Ensuring-Data-Precision.png"} alt="" className='max-w-100' />

                      <h4 className='base-h4 erp-features-h'>Ensuring Data Precision</h4>
                      <p className='erp-features-p '>
                        Deliver Timely and Accurate Financial Statements, Inventory Reports, and Beyond to Stakeholders.
                      </p>
                    </div>
                  </Tab.Panel>

                  <Tab.Panel>
                    <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pl-[5rem]'>
                      <img src={env.baseUrlLocal + "/images/Exceeding-Expectations.png"} alt="" className='max-w-100' />

                      <h4 className='base-h4 erp-features-h'>Exceeding Expectations</h4>
                      <p className='erp-features-p '>
                        Ensure Order Accuracy, On-Time Deliveries, and Cost Savings as Promised.
                      </p>
                    </div>
                  </Tab.Panel>

                  <Tab.Panel>
                    <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pl-[5rem]'>
                      <img src={env.baseUrlLocal + "/images/Optimising-Supply-Demand.png"} alt="" className='max-w-100' />

                      <h4 className='base-h4 erp-features-h'>Optimising Supply and Demand</h4>
                      <p className='erp-features-p '>
                        Navigating Supply Chains Amid Rapid Change, Escalating Costs, and Evolving Buyer Behavior.
                      </p>
                    </div>
                  </Tab.Panel>

                  <Tab.Panel>
                    <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pl-[5rem]'>
                      <img src={env.baseUrlLocal + "/images/Strategic-Inventory-Management.png"} alt="" className='max-w-100' />

                      <h4 className='base-h4 erp-features-h'>Strategic Inventory Management</h4>
                      <p className='erp-features-p '>
                        Planning and Balancing Inventory to Meet Varied Demand.
                      </p>
                    </div>
                  </Tab.Panel>

                </Tab.Panels>
              </div>
            </div>
          </Tab.Group>
        </div>
        {/* new tab section 2 end */}
      </div>


      <section className="capabilities lg:mt-[50px]">
        <div className="lg:container mx-auto px-4">

          <h3 className="base-h3 cutom-font-bold-200 mb-3 text-center mb-[40px] lg:mb-[60px]">Capabilities</h3>

          {/* capabilities tabs start */}
          <div className="product-tabs-3">
            <Tab.Group>
              <div className="flex justify-center mb-5">

                <Tab.List className="capabilty-btns mb-5 flex list-none flex-col flex-wrap pl-0 md:flex-row mx-auto text-center justify-center flex">
                  <Tab className=" my-2 block rounded px-7 py-3 text-[18px] leading-tight text-neutral-500 data-[te-nav-active]:!bg-[#FF9135] data-[te-nav-active]:text-[#ffffff!important] dark:bg-neutral-700 dark:text-white dark:data-[te-nav-active]:text-[#ffffff!important] md:mr-4 border-0">Full-featured</Tab>

                  <Tab className=" my-2 block rounded px-7 py-3 text-[18px] leading-tight text-neutral-500 data-[te-nav-active]:!bg-[#FF9135] data-[te-nav-active]:text-[#ffffff!important] dark:bg-neutral-700 dark:text-white dark:data-[te-nav-active]:text-[#ffffff!important] md:mr-4 border-0">Global</Tab>

                  <Tab className=" my-2 block rounded px-7 py-3 text-[18px] leading-tight text-neutral-500 data-[te-nav-active]:!bg-[#FF9135] data-[te-nav-active]:text-[#ffffff!important] dark:bg-neutral-700 dark:text-white dark:data-[te-nav-active]:text-[#ffffff!important] md:mr-4 border-0">Intelligent</Tab>

                  <Tab className=" my-2 block rounded px-7 py-3 text-[18px] leading-tight text-neutral-500 data-[te-nav-active]:!bg-[#FF9135] data-[te-nav-active]:text-[#ffffff!important] dark:bg-neutral-700 dark:text-white dark:data-[te-nav-active]:text-[#ffffff!important] md:mr-4 border-0">Cloud</Tab>

                </Tab.List>
              </div>

              <Tab.Panels className="product-tabs-3-tab-cont">
                <Tab.Panel>
                  <div className="grid lg:grid-cols-12 md:grid-cols-12">
                    <div className="lg:col-span-5 md:col-span-6">
                      <div className="capability-content p-3 lg:px-[30px] lg:py-[20px] lg:mt-5">
                        <i className="las la-briefcase text-[40px] orange-c"></i>
                        <h5 className='base-h6 erp-features-h3'>Full-featured</h5>
                        <p className='erp-features-p'>
                          An ERP Tailored for Manufacturers, Offering Robust Out-of-the-Box Features in Sales, Field Service, Manufacturing, Inventory, Maintenance, Purchasing, Finance, and Empowering Frontline Workers Uniquely.
                        </p>
                      </div>
                    </div>
                    <div className="lg:col-span-7 md:col-span-6">
                      <img src={env.baseUrlLocal + "/images/Full-featured.png"} alt="" className='max-w-100 shadow-2xl' />
                    </div>
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="grid lg:grid-cols-12 md:grid-cols-12">
                    <div className="lg:col-span-5 md:col-span-6">
                      <div className="capability-content p-3 lg:px-[30px] lg:py-[20px] lg:mt-5">
                        <i className="las la-globe-europe text-[40px] orange-c"></i>
                        <h5 className='base-h6 erp-features-h3'>Global</h5>
                        <p className='erp-features-p'>
                          Effortlessly Handle Global and Local Operations with Built-in Regulatory Compliance Support. Benefit from Regular Updates for Country Extensions, Ensuring Your Confidence in Operating in Regions with Distinct Accounting and Tax Regulations.
                        </p>
                      </div>
                    </div>
                    <div className="lg:col-span-7 md:col-span-6">
                      <img src={env.baseUrlLocal + "/images/Global.png"} alt="" className='max-w-100 shadow-2xl' />
                    </div>
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="grid lg:grid-cols-12 md:grid-cols-12">
                    <div className="lg:col-span-5 md:col-span-6">
                      <div className="capability-content p-3 lg:px-[30px] lg:py-[20px] lg:mt-5">
                        <i className="las la-brain text-[40px] orange-c"></i>
                        <h5 className='base-h6 erp-features-h3'>Intelligent</h5>
                        <p className='erp-features-p'>
                          Incorporate Embedded Analytics with a Range of Dashboards Tailored for Common Roles and Simplified KPI Creation, Backed by AI-Powered Process Mining for Advanced Intelligence.

                        </p>
                      </div>
                    </div>
                    <div className="lg:col-span-7 md:col-span-6">
                      <img src={env.baseUrlLocal + "/images/Intelligent.png"} alt="" className='max-w-100 shadow-2xl' />
                    </div>
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="grid lg:grid-cols-12 md:grid-cols-12">
                    <div className="lg:col-span-5 md:col-span-6">
                      <div className="capability-content p-3 lg:px-[30px] lg:py-[20px] lg:mt-5">
                        <i className="lab la-mixcloud text-[40px] orange-c"></i>
                        <h5 className='base-h6 erp-features-h3'>Cloud</h5>
                        <p className='erp-features-p'>
                          Scalable and Sustainable Cloud Software that Facilitates Regular Upgrades and Growth Without the Overhead of On-Premises Management.
                        </p>
                      </div>
                    </div>
                    <div className="lg:col-span-7 md:col-span-6">
                      <img src={env.baseUrlLocal + "/images/Cloud.png"} alt="" className='max-w-100 shadow-2xl' />
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
          {/* capabilities tabs end */}


        </div>
      </section>

      <section className="erp-development bg-[#101828;] py-[50px] lg:py-[80px] mt-[50px] lg:mt-[-50px]">
        <div className="lg:container mx-auto px-4  text-white">
          <div className="py-7 text-center">
            <h3 className="base-h4 cutom-font-bold-200 mb-3">The three main ERP deployment models</h3>
            <h5 className='text-[20px]'>include cloud-based, on-premises, and hybrid solutions.</h5>
          </div>

          <div className="grid lg:grid-cols-12 lg:gap-[100px] lg:mt-[70px]">
            <div className="lg:col-span-4 text-center">
              <i className="las la-cloud text-[70px]"></i>
              <h5 className='base-h6 font-bold mt-4'>Cloud-based ERP</h5>
              <p className='mt-4'>
                Is hosted by the software provider and accessed online. It's increasingly popular due to benefits like lower initial costs, scalability, user-friendliness, and the capacity to foster innovation.
              </p>
            </div>
            <div className="lg:col-span-4 text-center">
              <i className="las la-cog text-[70px]"></i>
              <h5 className='base-h6 font-bold mt-4'>On-premises ERP</h5>
              <p className='mt-4'>
                Is installed on the company's own servers and is accessible through the intranet. This choice is favored by businesses seeking greater control over their data and security.
              </p>
            </div>
            <div className="lg:col-span-4 text-center">
              <i className="las la-laptop-code text-[70px]"></i>
              <h5 className='base-h6 font-bold mt-4'>Hybrid ERP</h5>
              <p className='mt-4'>
                Is a fusion of cloud-based and on-premises ERP systems, enabling businesses to enjoy the advantages of both deployment models while retaining control over their data.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="erp-integration py-[50px] lg:py-[70px]">
        <div className="lg:container mx-auto px-4">
          <div className="lg:py-7 text-center">
            <h3 className="base-h3 cutom-font-bold-200 mb-3">ERP Integrations</h3>
            <p className='lg:w-[50%] mx-auto'>
              ERP system integration refers to the procedure of connecting multiple enterprise resource planning (ERP) systems to enable data sharing and automation of business processes. The specific method of integration is chosen based on the requirements of the participating businesses. Integration Methods
            </p>
            <h6 className='base-h6 mt-5'>Common methods for ERP system integration encompass</h6>
          </div>

          {/* new tabs start */}
          <div className='product-tabs-4 mt-5'>
            <Tab.Group>
              <div className='grid lg:grid-cols-12 relative'>
                <div className='grid lg:col-span-3 z-10  right-0 mr-[-5rem]'>
                  <Tab.List className="product-tabs-left text-right z-10">
                    <Tab className="block w-full text-right px-7 py-4 text-[16px] cutom-font-bold font-medium  leading-tight text-neutral-500 focus:outline-none">Data Synchronisation</Tab>

                    <Tab className="block w-full text-right my-2 rounded-xl px-7 py-4 text-[16px] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">API Integration</Tab>

                    <Tab className="block w-full text-right my-2 rounded-xl px-7 py-4 text-[16px] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Data Migration</Tab>

                    <Tab className="block w-full text-right my-2 rounded-xl px-7 py-4 text-[16px] cutom-font-bold font-medium leading-tight text-neutral-500 focus:outline-none">Workflow Automation</Tab>
                  </Tab.List>
                </div>

                <div className='grid lg:col-span-9 shadow-lg rounded-lg z-0'>
                  <Tab.Panels className="z-0">

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pl-[5rem]'>
                        <div className="grid lg:grid-cols-12">
                          <div className="lg:col-span-7">
                            <img src={env.baseUrlLocal + "/images/Data-synchronisation.png"} alt="" className='max-w-100' />
                          </div>

                          <div className="lg:col-span-5 lg:pl-5">
                            <h4 className='base-h5 cutom-font-bold-200 mt-5 lg:mt-0'>Data Synchronisation</h4>
                            <p className='erp-features-p '>
                              Involves automatically transferring data between the two systems regularly. This is often used when one system is used for finance and accounting purposes while the other system is used for manufacturing or sales.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pl-[5rem]'>
                        <div className="grid lg:grid-cols-12">
                          <div className="lg:col-span-7">
                            <img src={env.baseUrlLocal + "/images/API-Integration.png"} alt="" className='max-w-100' />
                          </div>

                          <div className="lg:col-span-5 lg:pl-5">
                            <h4 className='base-h5 cutom-font-bold-200 mt-5 lg:mt-0'>API Integration</h4>
                            <p className='erp-features-p '>
                              This method employs application programming interfaces (APIs) to establish connections between systems, enabling seamless data exchange.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pl-[5rem]'>
                        <div className="grid lg:grid-cols-12">
                          <div className="lg:col-span-7">
                            <img src={env.baseUrlLocal + "/images/Data-Migration.png"} alt="" className='max-w-100' />
                          </div>

                          <div className="lg:col-span-5 lg:pl-5">
                            <h4 className='base-h5 cutom-font-bold-200 mt-5 lg:mt-0'>Data Migration</h4>
                            <p className='erp-features-p '>
                              This process encompasses transferring data from one ERP system to another, which is often required during business mergers or acquisitions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className='prod-tab-contnt p-[1rem] lg:p-[2rem] lg:pl-[5rem]'>
                        <div className="grid lg:grid-cols-12">
                          <div className="lg:col-span-7">
                            <img src={env.baseUrlLocal + "/images/Workflow-Automation.png"} alt="" className='max-w-100' />
                          </div>

                          <div className="lg:col-span-5 lg:pl-5">
                            <h4 className='base-h5 cutom-font-bold-200 mt-5 lg:mt-0'>Workflow Automation</h4>
                            <p className='erp-features-p '>
                              This method automates business processes between two systems, facilitating tasks like order placement in one system and seamless processing in another.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>

                  </Tab.Panels>
                </div>
              </div>
            </Tab.Group>
          </div>
          {/* new tabs end */}
        </div>
      </section>
    </>
  )
}

export default Product