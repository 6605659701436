import React from 'react'
import { useEffect, Suspense } from 'react'
import Discovery from '../../../Components/DiscoveryCall/Discovery'
import ReactHelmet from '../../../Components/ReactHelmet'
import env from '../../../Config'

const Blockchain = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Services | Blockchain`}
                    keywords={`Blockchain`}
                    description={`Igniting the Future of Trust, Innovation, and Secure Digital Advancements Across Industries.`}
                    seoimgs={`Blockchain-banner.png`}
                />
            </Suspense>
            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/Blockchain-banner.png)` }}>
                <div className='lg:container mx-auto px-4'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
                        <div className="flex-1 md:py-[70px] lg:py-[100px]">
                            <h2 className="text-[2rem] lg:text-white lg:text-[82px] md:w-[50%] lg:w-[50%] md:text-[#fff] leading-tight font-bold text-center md:text-left">
                                Blockchain
                            </h2>
                            <p className="text-[18px] lg:text-[24px] mt-5 md:w-[50%] lg:w-[40%] md:text-[#fff] text-center font-semibold md:text-left">
                                Igniting the Future of Trust, Innovation, and Secure Digital Advancements Across Industries.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <section className='ourEngineerings'>
                <div className="lg:container mx-auto px-4 my-[40px] md:my-[70px] lg:mt-[100px]">
                    <div className="text-center">
                        <h2 className="text-[2rem] lg:text-[56px] leading-tight font-bold">Blockchain</h2>
                        <p className="text-[20px] md:text-[24px] text-[#475467] font-bold lg:w-[50%] mx-auto mt-4">
                            Elevate Your Ventures with Blockchain's Transformative Power: Forging Trust and Innovation in the Digital Frontier.
                        </p>
                    </div>

                    <div className='xl:px-0 mt-[70px] lg:mt-[80px]'>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Decentralisation.png'} alt='Decentralisation'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">Microsoft Azure</div>

                                <h2 className="base-h2 font-bold leading-none"> Decentralisation</h2>
                                <p className='base-p1'>
                                    Blockchain operates on a decentralised network of computers (nodes), eliminating the need for a central authority or intermediary.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Enhanced Security</h5>
                                            <div className='tooltip_box'>Decentralised IoT networks are less vulnerable to single points of failure or cyberattacks, as there's no central target for attackers to focus on.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Improved Reliability</p>
                                            <div className='tooltip_box'>Decentralisation distributes the workload across nodes, ensuring that even if some nodes fail, the network remains operational, enhancing overall reliability.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Efficient Data Handling</h5>
                                            <div className='tooltip_box'>Decentralised IoT networks can process and manage data more efficiently by distributing tasks among nodes, reducing congestion and latency.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Local Decision-Making</h5>
                                            <div className='tooltip_box'>Each node in a decentralised IoT network can make decisions based on local data, reducing the need for constant communication with a central authority.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Privacy and Anonymity</h5>
                                            <div className='tooltip_box'>Decentralisation can enhance privacy by allowing devices to interact directly without revealing sensitive information to a central entity.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scalability</h5>
                                            <div className='tooltip_box'>Decentralised IoT networks can scale easily by adding more nodes, accommodating growing data volumes and device connections.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Global Reach</h5>
                                            <div className='tooltip_box'>Decentralised IoT enables a global network that is accessible to anyone with an internet connection, promoting inclusivity and accessibility.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Distributed Trust</h5>
                                            <div className='tooltip_box'>Instead of relying on a single trusted entity, decentralised IoT networks leverage consensus mechanisms to achieve trust through network agreement.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Innovation and Flexibility</h5>
                                            <div className='tooltip_box'>Decentralisation encourages experimentation and innovation by allowing different nodes to implement unique features or services.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Resilience</h5>
                                            <div className='tooltip_box'>Decentralised IoT networks are more resilient in the face of network disruptions, as other nodes can continue functioning even if some are offline.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">Microsoft Azure</div>

                                <h2 className="base-h2 font-bold leading-none">Immutability</h2>
                                <p className='base-p1'>
                                    Once data is recorded on a blockchain, it cannot be altered or deleted, ensuring the integrity and security of information.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Integrity</h5>
                                            <div className='tooltip_box'>Immutability ensures that once data is recorded on the blockchain, it cannot be altered, preserving the accuracy and trustworthiness of information.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Trust and Transparency</h5>
                                            <div className='tooltip_box'>Immutability fosters trust by making it impossible to manipulate or tamper with historical transaction records, promoting transparency.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Secure Transactions</h5>
                                            <div className='tooltip_box'>Immutable records on the blockchain prevent unauthorised changes, enhancing the security of financial, contractual, or sensitive data.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Auditable History</h5>
                                            <div className='tooltip_box'>Immutability allows for a complete and traceable history of transactions, which is crucial for auditing and compliance purposes.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Smart Contracts Reliability</h5>
                                            <div className='tooltip_box'>Immutable code in smart contracts ensures that agreed-upon terms and conditions are adhered to without any alterations.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Permanent Records</h5>
                                            <div className='tooltip_box'>Immutability guarantees that records are maintained indefinitely, which is especially valuable for historical references or legal disputes.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Consensus Validation</h5>
                                            <div className='tooltip_box'>Immutability is achieved through consensus mechanisms, where participants agree on the state of the blockchain, ensuring data integrity.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Fraud Prevention</h5>
                                            <div className='tooltip_box'>Immutability reduces the risk of fraud by preventing unauthorised alterations or deletions of records, providing a secure digital trail.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Long-Term Data Preservation</h5>
                                            <div className='tooltip_box'>Immutable records ensure that data remains intact and accessible for future generations, research, or historical analysis.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Immutable Supply Chain</h5>
                                            <div className='tooltip_box'>In supply chain management, immutability ensures transparency, traceability, and authenticity of goods throughout the entire chain.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Immutability.png'} alt='Immutability'/>
                            </div>
                        </div>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Distributed-ledger.png'} alt='Distributed Ledger'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">AWS Cloud Platform</div>

                                <h2 className="base-h2 font-bold leading-none">Distributed Ledger</h2>
                                <p className='base-p1'>
                                    AWS Cloud Security encompasses various measures to ensure the confidentiality, integrity, and availability of customer data and applications. By leveraging these security features and best practices, organisations can build and maintain secure cloud environments on the AWS platform
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Decentralisation</h5>
                                            <div className='tooltip_box'>Distributed ledger technology operates on a network of computers (nodes) rather than a central authority, enhancing transparency and reducing control by a single entity.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Redundancy</p>
                                            <div className='tooltip_box'>Information is stored on multiple nodes in the network, ensuring redundancy and minimising the risk of data loss or single points of failure.</div>
                                        </div>


                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Consistency</h5>
                                            <div className='tooltip_box'>All nodes have access to the same data, ensuring consistency and preventing discrepancies that can arise from separate databases.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Immutability</h5>
                                            <div className='tooltip_box'>Once data is recorded on the distributed ledger, it cannot be altered or deleted, ensuring the integrity and security of the recorded information.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Transparency</h5>
                                            <div className='tooltip_box'>Transactions are visible to all participants in the network, promoting transparency and accountability in a trustless environment.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Secure Transactions</h5>
                                            <div className='tooltip_box'> Distributed ledger technology employs cryptographic techniques to secure data, making it highly resistant to unauthorised access or tampering.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Real-time Updates</h5>
                                            <div className='tooltip_box'> Changes to the distributed ledger are reflected in real-time across all nodes, providing up-to-date information to all participants.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Interoperability</h5>
                                            <div className='tooltip_box'> Distributed ledgers can interact with each other through standardised protocols, enabling seamless data exchange between different networks.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Smart Contracts</h5>
                                            <div className='tooltip_box'>Distributed ledger technology supports smart contracts, self-executing agreements that automatically trigger actions when predefined conditions are met.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Improved Efficiency</h5>
                                            <div className='tooltip_box'> Distributed ledgers eliminate the need for intermediaries and manual reconciliation, reducing administrative overhead and increasing efficiency.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">AWS Cloud Infrastructure</div>

                                <h2 className="base-h2 font-bold leading-none">Anonymity and Privacy</h2>
                                <p className='base-p1'>
                                    While transactions are transparent, participants' identities are often pseudonymous, providing a certain degree of privacy.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Pseudonymous Transactions</h5>
                                            <div className='tooltip_box'>Blockchain transactions can be recorded without revealing the real-world identities of the parties involved, enhancing privacy.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User Control</h5>
                                            <div className='tooltip_box'>Users have more control over their personal information and can choose when and what to disclose on the blockchain.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Reduced Data Exposure</h5>
                                            <div className='tooltip_box'>Anonymity and privacy mechanisms ensure that sensitive information is not unnecessarily exposed to the public.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Confidential Transactions</h5>
                                            <div className='tooltip_box'>Cryptographic techniques can be used to obscure transaction details, such as transaction amounts, ensuring financial privacy.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Permissioned Access</h5>
                                            <div className='tooltip_box'> Blockchain networks can restrict access to certain information, ensuring that only authorised parties can view specific data.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Private Blockchains</h5>
                                            <div className='tooltip_box'> Some blockchains are designed specifically for private networks, allowing organisations to control data access and visibility.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Zero-Knowledge Proofs</h5>
                                            <div className='tooltip_box'> Advanced cryptographic techniques like zero-knowledge proofs allow the verification of data without revealing the actual data itself.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Minimisation</h5>
                                            <div className='tooltip_box'>Blockchain encourages the principle of collecting and storing only necessary data, reducing the amount of sensitive information stored.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Immutable Audit Trail</h5>
                                            <div className='tooltip_box'>  While maintaining privacy, blockchain's transparency ensures a permanent, tamper-proof record of transactions for audit purposes.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Consent-driven Sharing</h5>
                                            <div className='tooltip_box'> Users can control who they share their data with and can grant temporary or conditional access to specific parties.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Anonymity-privacy.png'} alt='Anonymity and Privacy'/>
                            </div>
                        </div>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Data-integrity.png'} alt='Data Integrity'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">AWS Cloud Platform</div>

                                <h2 className="base-h2 font-bold leading-none">Data Integrity</h2>
                                <p className='base-p1'>
                                    Due to its immutability, blockchain is well-suited for applications requiring data integrity, such as legal contracts or academic credentials.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Immutable Records</h5>
                                            <div className='tooltip_box'>Blockchain ensures that once data is recorded, it cannot be altered, guaranteeing the integrity and accuracy of information.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Trustworthiness</p>
                                            <div className='tooltip_box'>Blockchain's immutability creates a trusted environment where participants can rely on the accuracy of the recorded data.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Tamper-Resistant</h5>
                                            <div className='tooltip_box'>Data on the blockchain is resistant to tampering or unauthorised changes, maintaining its original state.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Verifiable History</h5>
                                            <div className='tooltip_box'>Blockchain provides a transparent and traceable history of data changes, facilitating easy verification and auditing.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Secure Transactions</h5>
                                            <div className='tooltip_box'>Data integrity enhances the security of transactions by preventing unauthorised modifications and ensuring the validity of the data being exchanged.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Consensus Validation</h5>
                                            <div className='tooltip_box'>Blockchain's consensus mechanism ensures that data changes are agreed upon by the network, preventing malicious alterations.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Chain of Custody</h5>
                                            <div className='tooltip_box'>In supply chain management, blockchain ensures the authenticity and traceability of goods by maintaining their integrity throughout the journey.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Auditable Transactions</h5>
                                            <div className='tooltip_box'>The immutability of blockchain records makes auditing and compliance checks more efficient and reliable.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Smart Contracts Reliability</h5>
                                            <div className='tooltip_box'>Smart contracts execute based on predefined conditions and data, and their integrity ensures they behave as expected.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Provenance</h5>
                                            <div className='tooltip_box'>Blockchain ensures the origin and history of data can be traced back to its source, which is crucial for data quality and accuracy.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>

            <Discovery
                title={'Discovery '}
                subTitle={'Call'}
                desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
                btnName={'Subscribe'}
                placeholder={'Drop a Email'} />
        </>
    )
}

export default Blockchain