import Helmet from "react-helmet";
const ReactHelmet = ({seoimgs, title, description, keywords}) => {
    return (
        <Helmet>
            {/* <!-- HTML Meta Tags --> */}
            <title>{title}</title>
            <meta
                name="description"
                content={`${description || 'description'}`}
            />``
            <meta 
                name="keywords"
                content={keywords}
            />
            {/* <!-- Google / Search Engine Tags --> */}
            <meta itemprop="name" content={title} />
            <meta
                itemprop="description"
                content={description}
            />
            
            {seoimgs ? <meta
                itemprop="image"
                content={`https://www.versatilecommerce.com/images/${seoimgs}`}
            /> : null}
            
        </Helmet>
    )
}
export default ReactHelmet;