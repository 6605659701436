import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import $ from 'jquery';
import env from '../../../Config';
import { Link } from 'react-router-dom';



const NavigatingTheDigitalMigration = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  $(document).ready(function () {
    // var stickyTop = $('.sticky1').offset().top;
    $(window).scroll(function () {
      var windowTop = $(window).scrollTop();
      // console.log(windowTop, 'windowTop');

      if (windowTop > 450 && windowTop < 3100) {
        $('.sticky1').css({ 'position': 'fixed', 'top': '120px' });
      } else {
        $('.sticky1').css({ 'position': 'absolute', 'top': '0' });
      }
    });
  });



  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
      <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/navigating-the-digital-migration-banner.png)` }}>
        <div className='lg:container mx-auto px-4'>
          <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%]">
            <div className="text-center mx-auto lg:my-[5rem]">
              <div className="bg-[#FB9851] mb-[50px] text-[#FFF] py-2 px-9 text-[16px] text-center rounded-[40px] inline-block clear-both">Technology News</div>
              <h2 className="base-h2 text-[#101828] md:text-[#fff!important] lg:w-[80%] mx-auto">
                Navigating the Digital Migration: A New Frontier in Business Transformation
              </h2>
            </div>
          </div>
        </div>
      </div>


      <div className='clear-both'></div>




      {/* blog details start */}
      <div className=" px-[12px] md:px-[36px] xl:px-0 mt-[70px] mx-auto text-md text-gray-500 relative blog-content max-w-[830px] text-[14px] leading-[25px] md:text-[18px] md:leading-[32px] lg:flex">
        <div className="flex-col items-center gap-6 absolute follow-scroll translate-x-[-56px] sticky1 hidden lg:block" >
          <p className="text-heading-6 font-bold text-gray-400 mb-5">Share</p>
          <a href="#"> <img src={env.baseUrl + '/images/blog-details-sm-icon1.png'} alt="img" /></a>
          <a href="#" className='my-5 inline-block'> <img src={env.baseUrl + '/images/blog-details-sm-icon2.png'} alt="" /></a>
          <a href="#"> <img src={env.baseUrl + '/images/blog-details-sm-icon3.png'} alt="" /></a>
        </div>

        <div className='lg:ml-[60px]'>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900"> Introduction </h3>

          <p className="mb-6 md:mb-9">In a world where digital technology has been rapidly evolving, businesses and organizations are finding it crucial to adapt and transform their operations. One of the most significant shifts we've observed is the migration from traditional systems and practices to digital platforms—commonly known as Digital Migration. This blog explores the what, why, and how of Digital Migration, offering insights into the benefits and challenges that come with it.</p>

          <div className="mb-6 md:mb-9">
            <img className="h-full w-full object-cover" src={env.baseUrl + '/images/navigating-the-digital-migration-img.png'} alt="What is Digital Migration?" />
          </div>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">What is Digital Migration?
          </h3>
          <p className="mb-6 md:mb-9">Digital Migration refers to the transition of data, applications, and other business elements from traditional or manual systems to digital platforms. This can range from moving paper records to digital files, shifting from on-premise servers to the cloud, or even replacing legacy systems with modern, digital solutions.</p>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Why Digital Migration Matters </h3>

          <ul className='list-disc md:ml-6'>
            <li className='mb-5'><b>Enhanced Efficiency: </b> One of the most evident benefits is increased efficiency. Digital systems are often faster and more reliable than their traditional counterparts, allowing businesses to streamline operations and reduce overhead costs.</li>

            <li className='mb-5'><b>Scalability:</b> Digital platforms can easily adapt to the growing needs of a business, allowing for easy scaling up or down as required. This ensures that the technology grows with the company, rather than being a bottleneck.</li>

            <li className='mb-5'><b>Real-time Analytics:</b> With digital systems, businesses can collect and analyze data in real-time. This enables quicker decision-making and a more in-depth understanding of business performance and customer behavior.</li>

            <li className='mb-5'><b>Global Reach:</b> Digital migration allows businesses to break geographical barriers. Whether it's employees collaborating from various parts of the world or reaching out to a global customer base, digital platforms make it possible.</li>

          </ul>

          <h3 className="base-h4 mb-4 md:mb-6 lg:mt-9 text-gray-900">Key Components of Digital Migration</h3>
          <figure className="mb-8 md:mb-12">
            <div className="grid md:grid-cols-2 gap-[30px]">


              <div className='col-span-1'>

                <p className="mb-3"><b>Planning:</b> This is the first step and involves identifying which systems need migration and what new technologies to adopt.</p>

                <p className="mb-3"><b>Data Transfer:</b> This involves the actual movement of data from legacy systems to digital ones.</p>

                <p className="mb-3"><b>Training:</b> Employees need to be trained to adapt to new digital systems.</p>

                <p className="mb-3"><b>Testing and Feedback:</b>  Before fully transitioning, the new systems should be tested, and feedback should be collected for refinements.</p>
              </div>

              <div className='col-span-1'>
                <img className="h-full w-full object-cover rounded-2xl" src={env.baseUrl + '/images/navigating-the-digital-migration-img2.png'} alt="Key Components of Digital Migration" />
              </div>
            </div>
            <p className="mb-4 md:mb-6"><b>Implementation:</b> This is the final step where the digital system goes live.</p>
          </figure>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Challenges in Digital Migration</h3>
          <p className="mb-4 md:mb-4"><b>Complexity:</b>  Migrating from traditional to digital systems is often complex and can take a significant amount of time and resources.</p>

          <p className="mb-4 md:mb-4"><b> Security Risks: </b>The move to digital platforms often comes with the risk of data breaches, cyber-attacks, and other security concerns.</p>

          <p className="mb-4 md:mb-4"><b>Change Resistance:</b>
            Employees and stakeholders used to traditional methods may resist the change, creating hurdles in the migration process.</p>

          <p className="mb-4 md:mb-9"><b>Cost:</b> While digital migration is cost-effective in the long run, initial setup and migration costs can be substantial.</p>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Future Outlook</h3>
          <p className="mb-6 md:mb-9">As technology continues to advance, we can expect the process of digital migration to become more streamlined and less cumbersome. Emerging technologies like Artificial Intelligence, Machine Learning, and Blockchain are likely to play a significant role in facilitating even more secure and efficient digital transitions.</p>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Conclusion</h3>
          <p className="mb-6 md:mb-9">Digital Migration is more than a trend; it's a necessity for businesses looking to remain competitive in today's digital age. While it comes with its set of challenges, the benefits far outweigh the drawbacks. Organizations that successfully navigate the complexities of digital migration are well-positioned to capitalize on the numerous advantages that a digital landscape has to offer. In a world where digital readiness is increasingly equated with business resilience, making the leap to digital is not just an option but a strategic imperative.</p>
        </div>
      </div>
      {/* blog details end */}

      <div className='border-b-2 lg:container mx-auto mt-[50px]'></div>

      <div className='clear-both'></div>
      <section className='Our-blogs'>
        <div className='md:container p-4 mx-auto mt-[50px] mb-[50px] lg:mb-[100px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Recommended <span className='text-[#F26722]'> Blogs</span></h2>

          <div className='mt-[50px]'>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              autoPlaySpeed={3000}
              autoPlay={true}
              infinite={true}
            >
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/we-can-blend-colors-multiple-ways" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">We can blend colors multiple ways, the most common</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/blog-We-can-blend-colors-multiple-ways.png'} alt="We-can-blend-colors-multiple-ways" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/engineering-experiences-a-journey" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Engineering Experiences: A Journey of Innovation and Creativity</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/Blog-Engineering-Experiences.png'} alt="Engineering-Experiences" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#BEE1E6]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/saas-products" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Customer Services</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">SaaS Products</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/blog-SaaS-Products.png'} alt="SaaS-Products" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/the-evolution-significance" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">The Evolution and Significance of Cloud Engineering in Modern Computing</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/the-evolution-and-significance.png'} alt="the-evolution-and-significance" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/navigating-the-digital-migration" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Navigating the Digital Migration: A New Frontier in Business Transformation</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/navigating-the-digital-migration.png'} alt="navigating-the-digital-migration" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

            </Carousel>
          </div>
        </div>
      </section>
    </>
  )
}

export default NavigatingTheDigitalMigration