import React from 'react'
import { Disclosure } from "@headlessui/react";
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import env from '../../Config';

const Faqs = ({ heading1, content1, heading2, content2, heading3, content3, heading4, content4, heading5, content5, status }) => {
  //  console.log('===>>>>>>>>>>>', status)
  return (
    <>
      <section className='FAQs my-[50px] lg:mt-[20px] lg:mb-[80px]'>
        <div className='md:container px-4 lg:px-4 mx-auto'>
          <div className="grid lg:grid-cols-12">
            <div className="lg:col-span-5 xs-t-center xs-mx-auto">
              <h2 className='text-[40px] md:text-[54px] font-bold leading-none'>Frequently asked  <span className='text-[#F26722]'> questions</span></h2>
              <p className='text-[16px] text-[#475467] lg:w-[80%] mt-[20px] font-semibold'>Feeling inquisitive? Have a read through some of our FAQs or contact our supporters for help</p>

              <h5 className='text-[18px] font-semibold flex mt-[30px] xs-flex-center'>
                <img className='max-w-full mr-2' src={env.baseUrl + '/images/leaf-icon.svg'} /> Boost your sale</h5>
              <p className='text-[18px] lg:w-[60%] mt-3'>
                The latest design trends meet hand-crafted templates.
              </p>

              <h5 className='text-[18px] font-semibold flex mt-[30px] xs-flex-center'>
                <img className='max-w-full mr-2' src={env.baseUrl + '/images/leaf-icon.svg'} /> Introducing New Features</h5>
              <p className='text-[18px] lg:w-[60%] mt-3'>
                The latest design trends meet hand-crafted templates.
              </p>
              <div className='md:flex mx-auto ml-[20%] md:ml-[0]'>
                <Link to='/contact-form' className='py-[20px] px-[30px] w-[180px] md:w-[255px] bg-[#101828] text-white  mt-[50px] rounded-[50px] text-[18px] font-bold flex justify-center items-center hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg float-left duration-300'>
                  Contact Us <FaArrowRight className='text-[15px] ml-[13px]' />
                </Link>
                <Link to='/faqs-we-here' className='mt-[55px] text-[18px] font-bold pl-[30px] items-center hover:text-[#f26722] flex duration-300 w-[100%] lg:w-[auto] py-5 py-5 lg:py-1'>
                  Support Center <FaArrowRight className='text-[15px] ml-[13px]' />
                </Link>
              </div>
            </div>
            <div className="lg:col-span-7">
              <div className="w-full pt-5 lg:pt-0">
                <div className="w-full lg:p-2 mx-auto bg-white rounded-2xl">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex duration-300 justify-between w-full px-4 py-2 font-bold text-left text-gray-900 hover:text-gray-100 bg-[#E4E7EC] rounded-lg hover:bg-[#101828] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 text-[22px]">
                          <span>{heading1}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={4.5}
                            stroke="currentColor"
                            className={`${open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-gray-400 mt-2`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-[#475467]">
                          {content1}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="mt-6">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex duration-300 justify-between w-full px-4 py-2 font-bold text-left text-gray-900 hover:text-gray-100 bg-[#E4E7EC] rounded-lg hover:bg-[#101828] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 text-[22px]">
                          <span>{heading2}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={4.5}
                            stroke="currentColor"
                            className={`${open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-gray-400 mt-2`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-[#475467]">
                          {content2}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="mt-6">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex duration-300 justify-between w-full px-4 py-2 font-bold text-left text-gray-900 hover:text-gray-100 bg-[#E4E7EC] rounded-lg hover:bg-[#101828] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 text-[22px]">
                          <span>{heading3}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={4.5}
                            stroke="currentColor"
                            className={`${open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-gray-400 mt-2`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-[#475467]">
                          {content3}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="mt-6">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex duration-300 justify-between w-full px-4 py-2 font-bold text-left text-gray-900 hover:text-gray-100 bg-[#E4E7EC] rounded-lg hover:bg-[#101828] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 text-[22px]">
                          <span>{heading4}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={4.5}
                            stroke="currentColor"
                            className={`${open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-gray-400 mt-2`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-[#475467]">
                          {content4}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  {status ? (
                    <Disclosure as="div" className="mt-6">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex duration-300 justify-between w-full px-4 py-2 font-bold text-left text-gray-900 hover:text-gray-100 bg-[#E4E7EC] rounded-lg hover:bg-[#101828] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 text-[22px]">
                            <span>{heading5}</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={4.5}
                              stroke="currentColor"
                              className={`${open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-gray-400 mt-2`}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-[#475467]">
                            {content5}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : null}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Faqs