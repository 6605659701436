import React, { Suspense, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactHelmet from '../../Components/ReactHelmet';
import env from '../../Config';


const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Suspense>
        <ReactHelmet
          title={`Versatile Commerce | Blog`}
          keywords={`Our Blogs`}
          description={`From year to year we strive to invent the most innovative technology that is used by both small enterprises and space enterprises.`}
          seoimgs={`faqs-banner.png`}
        />
      </Suspense>
      <div className="services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/faqs-banner.png)` }}>
        <div className='lg:container mx-auto px-4'>
          <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] xl_py-260 max-w-[100%]">
            <div className="lg:my-[8rem]">
              <div className='text-center mx-auto'>
                <h2 className="base-h2 text-[#101828] md:text-[#fff!important]">Our Blogs</h2>

                <p className="base-p1 font-[100!important] text-[#101828] md:text-[#fff!important] text-center lg:w-[50%] mx-auto">
                  From year to year we strive to invent the most innovative technology that is used by both small enterprises and space enterprises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="ourTeam mt-[50px] lg:mt-[120px]">
        <div className="lg:container mx-auto px-4 gap-x-[30px]">
          <h2 className="text-[40px] md:text-[56px] text-center font-bold">Featured <span className="text-[#F26722]"> Blogs</span></h2>
          <p className="text-[24px] text-[#667085] lg:w-[60%] text-center mx-auto mt-[15px] font-semibold">From Our blog and Event fanpage</p>


          <div className="grid grid-cols-1 mt-[70px] gap-[30px] md:grid-cols-2 gap-y-[60px] lg:grid-cols-3">
            <Link to='/blog/we-can-blend-colors-multiple-ways' className="relative cursor-pointer">
              <div className="relative">
                <div className="pr-[26px] aspect-[458/557]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/we-can-blend-colors.png'} alt='We can blend colors' />
                </div>
                <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                  <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-200 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                </div>
              </div>
              <h3 className="cutom-font-bold text-[28px] leading-[32px] md:text-heading-4 mt-[45px] md:mt-[73px] mb-[18px]">We can blend colors multiple ways, the most common
              </h3>
              <p className="text-text text-gray-500 mb-[15px]">In today's fast-paced and ever-changing business landscape, companies must adapt to stay relevant and competitive. One of the most significant changes in recent years is the rise of digital transformation.
              </p>
              <div className="flex items-center gap-[15px]"><img className="h-full w-full object-cover rounded-full max-w-[60px]" src={env.baseUrl + '/images/blog-user1.png'} alt='blog user pic' />
                <div>
                  <p className="line-clamp-2 cutom-font-bold mb-[3px] lg:text-heading-6">Syam</p>
                  <p className="font-bold text-sm">August 15, 2023</p>
                </div>
              </div>
            </Link>
            <Link to='/blog/engineering-experiences-a-journey' className="relative cursor-pointer">
              <div className="relative">
                <div className="pr-[26px] aspect-[458/557]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/engineering-Experiences-blog.png'} alt='Engineering Experiences' />
                </div>
                <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                  <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-200 bg-bg-2 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                </div>
              </div>
              <h3 className="cutom-font-bold text-[28px] leading-[32px] md:text-heading-4 mt-[45px] md:mt-[73px] mb-[18px]">Engineering Experiences: A Journey of Innovation and Creativity
              </h3>
              <p className="text-text text-gray-500 mb-[15px]">Engineering is a discipline that has been around for centuries. It is a field that is constantly evolving, and it requires individuals who are innovative and creative.
              </p>
              <div className="flex items-center gap-[15px]"><img className="h-full w-full object-cover rounded-full max-w-[60px]" src={env.baseUrl + '/images/blog-user2.png'} alt='blog user pic' />
                <div>
                  <p className="line-clamp-2 cutom-font-bold mb-[3px] lg:text-heading-6">Jenny Wilson</p>
                  <p className="font-bold text-sm">August 07, 2023</p>
                </div>
              </div>
            </Link>
            <div className="flex flex-col gap-8 md:col-span-2 lg:col-span-1">
              <Link to='/blog/saas-products' className="flex items-center gap-6 pb-12 border-b border-gray-200 justify-between cursor-pointer">
                <div className="flex-1">
                  <p className="cutom-font-bold text-[18px] md:text-heading-6 line-clamp-2 mb-[18px]">SaaS Products
                  </p>
                  <div className="flex items-center gap-[11px]"><img className="h-full w-full object-cover rounded-full max-w-[42px]" src={env.baseUrl + '/images/blog-user3.png'} alt='Blog user pic' />
                    <div>
                      <p className="line-clamp-2 cutom-font-bold mb-[3px] text-[#667085]">Rahul Varda</p>
                      <p className="font-bold text-[12px] text-[#667085]">September 10, 2023</p>
                    </div>
                  </div>
                </div>
                <div className="relative flex-1 max-w-[133px]">
                  <div className="pr-[13px] aspect-square"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-SaaS-Products.png'} alt='SaaS Products' />
                  </div>
                  <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[13px] pl-[13px]">
                    <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-200 bg-bg-10 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                  </div>
                </div>
              </Link>
              <Link to='/blog/the-evolution-significance' className="flex items-center gap-6 pb-12 border-b border-gray-200 justify-between cursor-pointer">
                <div className="flex-1">
                  <p className="cutom-font-bold text-[18px] md:text-heading-6 line-clamp-2 mb-[18px]">The Evolution and Significance of Cloud Engineering in Modern Computing
                  </p>
                  <div className="flex items-center gap-[11px]"><img className="h-full w-full object-cover rounded-full max-w-[42px]" src={env.baseUrl + '/images/blog-user4.png'} alt='Blog user pic' />
                    <div>
                      <p className="line-clamp-2 cutom-font-bold mb-[3px] text-[#667085]">Ashwarya V</p>
                      <p className="font-bold text-[12px] text-[#667085]">August 28, 2023</p>
                    </div>
                  </div>
                </div>
                <div className="relative flex-1 max-w-[133px]">
                  <div className="pr-[13px] aspect-square"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/the-evolution-and-significance.png'} alt='The evolution and significance' />
                  </div>
                  <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[13px] pl-[13px]">
                    <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-200 bg-bg-3 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                  </div>
                </div>
              </Link>

              <Link to='/blog/navigating-the-digital-migration' className="flex items-center gap-6 pb-12 border-b border-gray-200 justify-between cursor-pointer">
                <div className="flex-1">
                  <p className="cutom-font-bold text-[18px] md:text-heading-6 line-clamp-2 mb-[18px]">Design Studios That Everyone Should Know
                  </p>
                  <div className="flex items-center gap-[11px]"><img className="h-full w-full object-cover rounded-full max-w-[42px]" src={env.baseUrl + '/images/blog-user6.png'} alt='Blog user pic' />
                    <div>
                      <p className="line-clamp-2 cutom-font-bold mb-[3px] text-[#667085]">Prashanth K</p>
                      <p className="font-bold text-[12px] text-[#667085]">August 21, 2023</p>
                    </div>
                  </div>
                </div>
                <div className="relative flex-1 max-w-[133px]">
                  <div className="pr-[13px] aspect-square"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/navigating-the-digital-migration.png'} alt='Navigating the digital migration' />
                  </div>
                  <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[13px] pl-[13px]">
                    <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-200 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                  </div>
                </div>
              </Link>
              <Link to='/blog' className='text-[28px] cutom-font-bold mt-[-20px]'>Recommended Blogs</Link>
            </div>
          </div>
        </div>
      </section>


      <section className='LatestBlog'>
        <div className='md:container p-4 mx-auto mt-[50px] lg:mt-[100px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Latest <span className='text-[#F26722]'>Blog Posts</span></h2>

          <p className='text-[24px] text-[#667085] lg:w-[50%] text-center mx-auto mt-[5px] font-semibold'>
            From Our blog and Event fanpage
          </p>

          <div className='grid lg:grid-cols-3 mt-[60px]'>
            <div className='lg:px-4 md:mx-3'>
              <Link to="/blog/we-can-blend-colors-multiple-ways" className="block group">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">We can blend colors multiple ways, the most common</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-We-can-blend-colors-multiple-ways.png'} alt="Digital Transformation" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className='lg:px-4 md:mx-3'>
              <Link to="/blog/engineering-experiences-a-journey" className="block group">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Engineering Experiences: A Journey of Innovation and Creativity</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/Blog-Engineering-Experiences.png'} alt="Engineering Experiences" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#BEE1E6]"></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className='lg:px-4 md:mx-3'>
              <Link to="/blog/saas-products" className="block group">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Customer Services</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">SaaS Products</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-SaaS-Products.png'} alt="Saas Products" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className='lg:px-4 md:mx-3'>
              <Link to="/blog/the-evolution-significance" className="block group">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">The Evolution and Significance of Cloud Engineering in Modern Computing</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/the-evolution-and-significance.png'} alt="The evolution and significance" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className='lg:px-4 md:mx-3'>
              <Link to="/blog/navigating-the-digital-migration" className="block group">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Navigating the Digital Migration: A New Frontier in Business Transformation</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/navigating-the-digital-migration.png'} alt="Navigating the digital migration" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          {/* <div className='grid lg:grid-cols-3 mt-[40px]'>
            <div className='px-4'>
              <Link to='/blog/blog-details' className="block group" href="#">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-9 mt-3 cutom-font-bold leading-tight min-h-[60px]">We can blend colors multiple ways, the most common</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-img-1.png'} alt="Agon" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className='px-4'>
              <Link to='/blog/blog-details' className="block group" href="#">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-9 mt-3 cutom-font-bold leading-tight min-h-[60px]">How To Blow Through Capital At An Incredible Rate</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-img-2.png'} alt="Agon" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#BEE1E6]"></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className='px-4'>
              <Link to='/blog/blog-details' className="block group" href="#">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Customer Services</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-9 mt-3 cutom-font-bold leading-tight min-h-[60px]">Design Studios That Everyone Should Know About?</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-img-3.png'} alt="Agon" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div> */}

          <div className='mx-auto flex justify-center items-center mb-9 lg:mb-[100px]'>
            <Link to="/blog" className="cursor-not-allowed py-[15px] lg:py-[20px] px-[20px] lg:px-[30px] bg-[#ddd] text-white mt-[30px] rounded-[50px] text-[18px] font-bold flex justify-center items-center hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg float-left duration-300 tracking-wide">Load more posts <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="text-[15px] ml-[13px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default Blog