import React from 'react'
import { useEffect, Suspense } from 'react'
import Discovery from '../../../Components/DiscoveryCall/Discovery'
import ReactHelmet from '../../../Components/ReactHelmet'
import env from '../../../Config'

const ResearchDemand = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Services | Research On Demand`}
                    keywords={`Research On Demand`}
                    description={`Unveiling Deeper Insights Whenever You Need Them: Your Trusted Partner in On-Demand Research`}
                    seoimgs={`Research-on-demand.png`}
                />
            </Suspense>

            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/Research-on-demand.png)` }}>
                <div className='lg:container mx-auto px-4'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
                        <div className="flex-1 md:py-[70px] lg:py-[100px]">
                            <h2 className="text-[2rem] lg:text-[82px] md:w-[50%] lg:w-[50%]  leading-tight font-bold text-center md:text-left">
                                <span className='text-[#FF6B01]'>Research</span><br /> On Demand
                            </h2>
                            <p className="text-[18px] lg:text-[24px] font-semibold mt-5 md:w-[50%] lg:w-[40%] text-center md:text-left">
                                Unveiling Deeper Insights Whenever You Need Them: Your Trusted Partner in On-Demand Research
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <section className='ourEngineerings'>
                <div className="lg:container mx-auto px-4 my-[40px] md:my-[70px] lg:mt-[100px]">
                    <div className="text-center">
                        <h2 className="text-[2rem] lg:text-[56px] leading-tight font-bold">Research <span className="text-[#f26722]"> On Demand </span></h2>
                        <p className="text-[20px] md:text-[24px] text-[#475467] font-bold lg:w-[60%] mx-auto mt-4">
                            Bridging the Gap with Timely Insights: Your On-Demand Research Solution.</p>
                    </div>


                    <div className='xl:px-0 mt-[70px] lg:mt-[80px]'>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Research-development.png'} alt='Research & Development'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Research & Development</h2>
                                <p className='base-p1'>
                                    Research & Development is a dynamic process that fuels innovation, technology advancement, and market differentiation. It involves iterative exploration, collaboration, risk-taking, and the creation of valuable intellectual property, all of which contribute to an organization's ability to stay ahead in competitive markets.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Innovation Catalyst</h5>
                                            <div className='tooltip_box'>R&D serves as the driving force behind innovation, leading to the creation of novel solutions that can reshape industries and markets.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Product Enhancement</p>
                                            <div className='tooltip_box'>R&D involves improving existing products or services through iterative testing, refinement, and adaptation based on market feedback and technological advancements.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Cross-Disciplinary Collaboration</h5>
                                            <div className='tooltip_box'>R&D frequently involves collaboration among experts from various fields, such as engineering, science, design, and business, to bring diverse perspectives and expertise to the table.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Intellectual Property Creation</h5>
                                            <div className='tooltip_box'>R&D can result in the creation of intellectual property (IP) assets, including patents, copyrights, and trademarks, which can provide competitive advantages and revenue streams.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Risk and Investment</h5>
                                            <div className='tooltip_box'>R&D carries inherent risks due to uncertainty in outcomes. Organizations invest resources in R&D with the anticipation of potential long-term benefits in terms of market differentiation and revenue growth.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Definition of Research & Development (R&D)</h5>
                                            <div className='tooltip_box'>Research & Development is the systematic process of exploring, designing, and creating new ideas, products, processes, or technologies to enhance innovation and improve the competitive edge of an organisation.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Technology Advancement</h5>
                                            <div className='tooltip_box'>R&D efforts are critical for advancing technological frontiers. It enables organisations to stay current with emerging technologies and harness their potential for business growth.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scientific Exploration</h5>
                                            <div className='tooltip_box'>R&D often involves fundamental scientific research to uncover new knowledge and insights that can lead to breakthrough discoveries and applications.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Market Disruption</h5>
                                            <div className='tooltip_box'>Successful R&D initiatives have the potential to disrupt established markets, drive industry shifts, and redefine consumer expectations.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Sustainable Growth</h5>
                                            <div className='tooltip_box'>Organisations that prioritise R&D foster a culture of continuous improvement and adaptability, positioning themselves for sustained growth and resilience in a rapidly changing business landscape.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Market Intelligence</h2>
                                <p className='base-p1'>
                                    Market Intelligence is a strategic tool that empowers businesses to make well-informed decisions by providing insights into market dynamics, consumer behavior, competition, trends, and risks. It plays a vital role in shaping business strategies, fostering innovation, and driving success in a rapidly evolving business landscape.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Strategic Decision-Making</h5>
                                            <div className='tooltip_box'>Market intelligence informs crucial strategic decisions by providing accurate insights into market trends, customer preferences, and competitive landscapes.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Competitor Analysis</h5>
                                            <div className='tooltip_box'>Market intelligence helps businesses understand their competitors' strengths, weaknesses, strategies, and market positioning.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Customer Insights</h5>
                                            <div className='tooltip_box'>It provides valuable information about customer behaviours, preferences, and needs, helping businesses tailor their products and services.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Identifying Opportunities</h5>
                                            <div className='tooltip_box'>Market intelligence identifies emerging trends and untapped market segments, enabling businesses to seize new opportunities.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Risk Mitigation</h5>
                                            <div className='tooltip_box'>By understanding market shifts and potential challenges, businesses can proactively address risks and adjust their strategies accordingly.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Product Development</h5>
                                            <div className='tooltip_box'>Market intelligence guides product development by identifying gaps in the market and helping refine features to meet customer demands.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Marketing Effectiveness</h5>
                                            <div className='tooltip_box'>It enhances marketing campaigns by providing insights into the effectiveness of messaging, channels, and target audiences.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Sales Forecasting</h5>
                                            <div className='tooltip_box'>Market intelligence aids in accurate sales forecasting by analysing historical data and current market trends.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Investment Decisions</h5>
                                            <div className='tooltip_box'>Investors use market intelligence to assess the potential of different markets and industries, guiding investment decisions.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Regulatory Compliance</h5>
                                            <div className='tooltip_box'>Market intelligence helps businesses stay updated on regulatory changes and industry standards, ensuring compliance.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Market-intelligence.png'} alt='Market Intelligence'/>
                            </div>
                        </div>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Business-intelligence.png'} alt='Business Intelligence'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Business Intelligence</h2>
                                <p className='base-p1'>
                                    Business Intelligence plays a crucial role in transforming raw data into actionable insights that drive decision-making across an organisation. It enhances efficiency, supports strategic planning, and empowers businesses to remain competitive in dynamic markets.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Predictive Analytics</h5>
                                            <div className='tooltip_box'>BI employs predictive modelling techniques to forecast future trends and outcomes, aiding businesses in anticipating market shifts and planning accordingly.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Gathering and Integration</p>
                                            <div className='tooltip_box'>BI involves collecting data from disparate sources, such as databases, spreadsheets, and applications, and integrating them into a unified platform for analysis.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Analysis and Reporting</h5>
                                            <div className='tooltip_box'>BI tools enable data analysis to identify trends, patterns, and insights. They also facilitate creating customisable reports and visualisations for easy comprehension.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Real-time Monitoring</h5>
                                            <div className='tooltip_box'>Business Intelligence allows real-time monitoring of key performance indicators (KPIs) to track the health of the business, helping to make proactive decisions.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Definition of Business Intelligence (BI)</h5>
                                            <div className='tooltip_box'>Business Intelligence refers to the technologies, processes, and strategies used to collect, analyse, and present data from various sources within an organisation to support informed decision-making.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Improvement</h5>
                                            <div className='tooltip_box'>BI promotes a culture of data-driven decision-making, fostering a cycle of continuous improvement through the analysis of results and the adjustment of strategies.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Strategic Planning</h5>
                                            <div className='tooltip_box'>By providing insights into market trends, customer behaviour, and competitive landscape, BI assists in formulating strategic plans that align with the organisations goals.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Visualisation</h5>
                                            <div className='tooltip_box'>BI tools offer graphical representations like charts, graphs, and dashboards, making complex data more accessible and facilitating better understanding.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Empowering Non-Technical Users</h5>
                                            <div className='tooltip_box'>BI platforms are designed to be user-friendly, enabling non-technical users to independently access and analyse data, reducing dependency on IT teams.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Operational Efficiency Enhancement</h5>
                                            <div className='tooltip_box'>BI helps identify bottlenecks, inefficiencies, and areas for improvement within business processes, leading to enhanced operational efficiency.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Market Dynamics</h2>
                                <p className='base-p1'>
                                    Market dynamics are the complex interactions of various internal and external factors that shape the behaviour, trends, and conditions within a market. Businesses need to understand and adapt to these dynamics to make informed decisions and remain competitive.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Definition of Market Dynamics</h5>
                                            <div className='tooltip_box'>Market Dynamics refer to the constantly changing and interacting forces that influence the behaviour, trends, and conditions within a specific market or industry.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Supply and Demand</h5>
                                            <div className='tooltip_box'>Market dynamics are shaped by the relationship between supply and demand for products or services. Changes in consumer preferences, population growth, and economic conditions can impact these dynamics.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Competitive Landscape</h5>
                                            <div className='tooltip_box'>The actions and strategies of competitors within the market impact its dynamics. New entrants, mergers, acquisitions, and product innovations can alter the competitive landscape.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Price Fluctuations</h5>
                                            <div className='tooltip_box'>Market dynamics are affected by changes in prices due to factors such as production costs, supply disruptions, inflation, and changes in currency exchange rates.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Consumer Behaviour</h5>
                                            <div className='tooltip_box'>Shifts in consumer preferences, purchasing habits, and buying patterns play a significant role in shaping market dynamics, influencing product development and marketing strategies.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Technological Advancements</h5>
                                            <div className='tooltip_box'>Rapid technological innovations can disrupt markets by introducing new products, services, and business models, leading to changes in market dynamics.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Regulatory Environment</h5>
                                            <div className='tooltip_box'>Changes in regulations and government policies, such as trade agreements, taxation, and industry standards, can have profound effects on market dynamics.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Economic Factors</h5>
                                            <div className='tooltip_box'>Economic indicators like GDP growth, inflation rates, and unemployment levels impact consumer spending power and thus influence market dynamics.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Cultural and Social Trends</h5>
                                            <div className='tooltip_box'>Social and cultural shifts, such as environmental awareness, lifestyle changes, and demographic shifts, can lead to changes in consumer preferences and market dynamics.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Globalisation</h5>
                                            <div className='tooltip_box'>Market dynamics are increasingly influenced by global factors, including international trade, cross-border competition, and cultural diffusion, which can lead to both challenges and opportunities.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Market-dynamics.png'} alt='Market Dynamics'/>
                            </div>
                        </div>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Technology-disruptions.png'} alt='Technology Disruptions'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Technology Disruptions</h2>
                                <p className='base-p1'>
                                    technology disruptions bring about radical changes across industries, reshaping the way business is conducted, creating new opportunities, and challenging established norms. Embracing these disruptions requires adaptability, innovation, and a forward-looking approach to thrive in the ever-evolving technological landscape.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Definition of Technology Disruptions</h5>
                                            <div className='tooltip_box'>Technology disruptions refer to the rapid and transformative changes brought about by the introduction of new technologies that significantly alter traditional industries, business models, and societal norms.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Innovation Catalyst</p>
                                            <div className='tooltip_box'>Technology disruptions act as catalysts for innovation, pushing industries to adopt novel approaches, products, and services to stay relevant.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Industry Transformation</h5>
                                            <div className='tooltip_box'>Disruptive technologies can reshape entire industries by rendering old processes obsolete and paving the way for new business models and market players.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Market Entry Barriers</h5>
                                            <div className='tooltip_box'>New technologies can lower entry barriers for startups and smaller businesses, enabling them to challenge established players and disrupt market dynamics.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Consumer Behaviour Evolution</h5>
                                            <div className='tooltip_box'>Disruptions often lead to changes in consumer behaviour and expectations, as individuals adapt to and adopt new technologies in their daily lives.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Job Landscape Evolution</h5>
                                            <div className='tooltip_box'> While technology disruptions create new job opportunities in emerging fields, they can also lead to job displacement in traditional industries, necessitating retraining and upscaling efforts.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Shift in Competitive Advantage</h5>
                                            <div className='tooltip_box'>Companies that embrace and adapt to technology disruptions early gain a competitive advantage, while those slow to respond risk losing market share.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Collaboration and Ecosystems</h5>
                                            <div className='tooltip_box'>Disruptions encourage collaboration among various players within an ecosystem, as partnerships and alliances become crucial to harnessing the potential of emerging technologies.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Ethical and Regulatory Challenges</h5>
                                            <div className='tooltip_box'>Disruptions often outpace regulatory frameworks, leading to ethical, privacy, and security challenges that need to be addressed to ensure responsible technology use.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Global Impact</h5>
                                            <div className='tooltip_box'>Technology disruptions have a global reach, transcending geographical boundaries and impacting economies, societies, and cultures worldwide.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Discovery
                title={'Discovery '}
                subTitle={'Call'}
                desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
                btnName={'Subscribe'}
                placeholder={'Drop a Email'} />
        </>
    )
}

export default ResearchDemand