import './App.scss';
import { Routes, Route } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';


import Home from './Screen/Home';
import About from './Screen/About';
// import Product from './Screen/Product';
import Navbar from './Components/Header';
import Services from './Screen/Services';
import Blog from './Screen/Blog';
import Foorter from './Components/Footer';



// import Faqs from './Components/Faqs/Faqs';
// import Discovery from './Components/DiscoveryCall/Discovery';
// <Discovery/>
import EngineeringExperience from './Screen/Services/EngineeringExperience';
import Careers from './Screen/Careers';
import JobDetails from './Screen/Careers/JobDetails';
import ContactUs from './Screen/ContactUs';
// import OurTeam from './Screen/OurTeam';
import FaqsWeHere from './Components/Faqs/FaqWeHere';
import BlogDetails from './Screen/Blog/BlogDetails';
// import CaseStudies from './Screen/CaseStudies';
// import CaseStudiesDetails from './Screen/CaseStudies/CaseStudiesDetails';
import TechnicalDevelopment from './Screen/Services/TechnicalDevelopment';
import TechnicalOperations from './Screen/Services/TechnicalOperations';
import CloudEngineering from './Screen/Services/CloudEngineering';
import DigitalMigration from './Screen/Services/DigitalMigration';
import ResearchDemand from './Screen/Services/ResearchOnDemand';
import CloudPartnership from './Screen/Services/CloudPartnership';
import IOT from './Screen/Services/IOT';
import Blockchain from './Screen/Services/Blockchain';
import TermsService from './Screen/TermsOfService';
import PrivacyPolicy from './Screen/PrivacyPolicy';
import CookiePolicy from './Screen/CookiePolicy';
import BlogDetailsTwo from './Screen/Blog/BlogDetails/BlogDetailsTwo';
import BlogDetailsThree from './Screen/Blog/BlogDetails/BlogDetailsThree';
import TheEvolutionSignificance from './Screen/Blog/BlogDetails/TheEvolutionSignificance';
import NavigatingTheDigitalMigration from './Screen/Blog/BlogDetails/NavigatingTheDigitalMigration';
import NotFoundPage from './Components/NotFoundPage';
import Cookies from './Components/CookiesPopup';
import { useCookies } from 'react-cookie';
import Retail from './Screen/Industry/Retail';
import Product from './Screen/Product';
import HealthCare from './Screen/Industry/HealthCare';
import Automotive from './Screen/Industry/Automotive';
import Insurance from './Screen/Industry/Insurance';
import Manufacturing from './Screen/Industry/Manufacturing';
import Travels from './Screen/Industry/Travel';
import Technology from './Screen/Industry/Technology';
import Education from './Screen/Industry/Education';
import Communication from './Screen/Industry/Communication';




// import Example from './Example';
const ReactHelmet = lazy(() => import('./Components/ReactHelmet'));

const publicRoutes = [
  { path: '/', component: Home },
  { path: '/about-us', component: About },
  { path: '/services', component: Services },
  { path: '/careers', component: Careers },
  { path: '/careers/job-details', component: JobDetails },
  { path: '/contact-form', component: ContactUs },
  // {path: '/our-team', component  : OurTeam},
  { path: '/faqs-we-here', component: FaqsWeHere },
  { path: '/blog', component: Blog },
  { path: '/blog/we-can-blend-colors-multiple-ways', component: BlogDetails },
  { path: '/blog/engineering-experiences-a-journey', component: BlogDetailsTwo },
  { path: '/blog/saas-products', component: BlogDetailsThree },
  { path: '/blog/the-evolution-significance', component: TheEvolutionSignificance },
  { path: '/blog/navigating-the-digital-migration', component: NavigatingTheDigitalMigration },

  // {path: '/CaseStudies', component: CaseStudies},
  // {path: '/CaseStudiesDetails', component: CaseStudiesDetails},
  // {path: '/services/:title', component: EngineeringExperience},
  { path: '/services/engineering-experience', component: EngineeringExperience },
  { path: '/services/technical-development', component: TechnicalDevelopment },
  { path: '/services/technical-operations', component: TechnicalOperations },
  { path: '/services/cloud-engineering', component: CloudEngineering },
  { path: '/services/digital-migration', component: DigitalMigration },
  { path: '/services/research-on-demand', component: ResearchDemand },
  { path: '/services/cloud-partnership-consulting', component: CloudPartnership },
  { path: '/services/iot', component: IOT },
  { path: '/services/blockchain', component: Blockchain },
  { path: '/Terms-And-Conditions', component: TermsService },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/cookie-policy', component: CookiePolicy },
  
  { path: '/retail', component: Retail },
  { path: '/health-care', component: HealthCare },
  { path: '/automotive', component: Automotive },
  { path: '/insurance', component: Insurance },
  { path: '/manufacturing', component: Manufacturing },
  { path: '/travels', component: Travels },
  { path: '/technology', component: Technology },
  { path: '/education', component: Education },
  { path: '/communication', component: Communication },

  

  {path: '/product',component: Product},
  { path: '*', component: NotFoundPage },
]

function App() {
  const [cookies] = useCookies(["name"]);
  useEffect(()=>{
    console.log(cookies, 'pppppppppp');
  },[])
  return (
    <div>
      <Suspense>
        <ReactHelmet
          title={`Versatile Commerce`}
          description={`Elevate your organization's digital capabilities and thrive in the rapidly evolving digital landscape`}
          seoimgs={`Revolutionise-your-digital-future.png`}
        />
      </Suspense>

      <div className="wrapper mx-auto text-gray-900 font-normal max-w-[100%]">
        <Navbar />

      </div>
      {/* <BrowserRouter> */}
      <Routes>
        <Route path="/" index element={<Home />} />
        {publicRoutes.map(({ path, component: Component }) => (
          <Route key={'path-' + path} path={path} element={<Component />} />
        ))}
      </Routes>
      {/* </BrowserRouter> */}

{/* <Example/> */}

      <Foorter />
      {/* <Cookies/> */}

      <div className="app">
      {!cookies.name && <Cookies/>}
    </div>
    </div>
  )
}

export default App;
