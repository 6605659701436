import React, { useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'
import { useCookies } from 'react-cookie';
import './Styles.scss'

const Cookies = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [enabled, setEnabled] = useState(false)
    const [enabled2, setEnabled2] = useState(false)
    const [enabled3, setEnabled3] = useState(false)
    const [enabled4, setEnabled4] = useState(false)
    const [enabled5, setEnabled5] = useState(false)
    const [enabled6, setEnabled6] = useState(false)
    const [cookies, setCookie] = useCookies(["cookieConsent"]);
    const giveCookieConsent = () => {
      setCookie("cookieConsent", true, { path: "/", maxAge: (3600 * 24 * 90), domain :"https://www.versatilecommerce.com/" },);
      setCookie('name', 'cookieConsent');
    };

    return (
        <>
            {isOpen && (<section className='cookie-setting' >

                <div className='cookie-setting-popup drop-shadow-lg' id="CookiePopup">
                    <div className='popHeader flex w-[100%] bg-[#2b2929] px-4 py-2 shadow-lg'>
                        <h4 className='text-[15px] font-bold text-[orange]'>Privacy Overview</h4>
                        <button className='ml-auto' onClick={() => setIsOpen(false)}> <i className="las la-times-circle text-[23px] text-[#ff7676]"></i> </button>
                    </div>
                    {/* cookie poppup start */}
                    
                    <div className='popBody p-4 overflow-auto max-h-[300px]'>
                        <p className='text-[14px]'>
                            This website uses cookies to improve your experience while you navigate through the website. Out of these, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may affect your browsing experience.
                        </p>
                        
                         {/* test accourdian start */}

                        <div className="w-full mt-3">
                            <div className="mx-auto w-full rounded-[10px] bg-white p-2">
                                <Disclosure>
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full rounded-lg bg-[#efefef] px-4 pt-2 pb-1 text-left text-lg font-bold font-medium text-[#000] hover:bg-[#ddd] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">

                                                <ChevronUpIcon
                                                    className={`${open ? 'rotate-180 transform' : ''
                                                        } h-7 w-7 mr-2 text-[#555]`}
                                                />
                                                <span>Necessary</span>

                                                {/* switch start */}
                                                <div className="py-0 ml-auto">
                                                    <Switch
                                                        checked={enabled}
                                                        onChange={setEnabled}
                                                        className={`${enabled ? 'bg-[green]' : 'bg-[#999]'}
                        relative inline-flex h-[30px] w-[66px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-100`}
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span
                                                            aria-hidden="true"
                                                            className={`${enabled ? 'translate-x-9' : 'translate-x-0'}
                            pointer-events-none inline-block h-[26px] w-[26px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                        />
                                                    </Switch>
                                                </div>
                                                {/* switch end */}
                                            </Disclosure.Button>



                                            <Disclosure.Panel>
                                                <div className="px-2 py-3 text-[#333]">
                                                    <p>
                                                        Necessary cookies are absolutely essential for the website to function properly. These cookies ensure basic functionalities and security features of the website, anonymously.
                                                    </p>
                                                    <div className='max-w-full overflow-auto'>
                                                        <table className="border-collapse border border-slate-400 text-[13px] mt-4">
                                                            <thead>
                                                                <tr>
                                                                    <th className='border border-slate-300 bg-[#eee] text-[14px] font-bold text-left px-1 py-1'>Cookie</th>
                                                                    <th className='border border-slate-300 bg-[#eee] text-[14px] font-bold text-left px-1 py-1'>Duration</th>
                                                                    <th className='border border-slate-300 bg-[#eee] text-[14px] font-bold text-left px-1 py-1'>Description</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='border border-slate-300 p-1'>cookielawinfo-checkbox-analytics</td>
                                                                    <td className='border border-slate-300 p-1'>11 months</td>
                                                                    <td className='border border-slate-300 p-1'>This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Analytics".</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='border border-slate-300 p-1'>cookielawinfo-checkbox-functional</td>
                                                                    <td className='border border-slate-300 p-1'>11 months</td>
                                                                    <td className='border border-slate-300 p-1'>The cookie is set by GDPR cookie consent to record the user consent for the cookies in the category "Functional".</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='border border-slate-300 p-1'>cookielawinfo-checkbox-necessary</td>
                                                                    <td className='border border-slate-300 p-1'>11 months</td>
                                                                    <td className='border border-slate-300 p-1'>This cookie is set by GDPR Cookie Consent plugin. The cookies is used to store the user consent for the cookies in the category "Necessary".</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='border border-slate-300 p-1'>cookielawinfo-checkbox-others </td>
                                                                    <td className='border border-slate-300 p-1'>11 months </td>
                                                                    <td className='border border-slate-300 p-1'>This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Other. </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='border border-slate-300 p-1'>cookielawinfo-checkbox-performance </td>
                                                                    <td className='border border-slate-300 p-1'>11 months </td>
                                                                    <td className='border border-slate-300 p-1'>This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Performance". </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='border border-slate-300 p-1'>viewed_cookie_policy </td>
                                                                    <td className='border border-slate-300 p-1'>11 months </td>
                                                                    <td className='border border-slate-300 p-1'>The cookie is set by the GDPR Cookie Consent plugin and is used to store whether or not user has consented to the use of cookies. It does not store any personal data. </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>

                                <Disclosure as="div" className="mt-2">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full rounded-lg bg-[#efefef] px-4 pt-2 pb-1 text-left text-lg font-bold font-medium text-[#000] hover:bg-[#ddd] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">

                                                <ChevronUpIcon
                                                    className={`${open ? 'rotate-180 transform' : ''
                                                        } h-7 w-7 mr-2 text-[#555]`}
                                                />
                                                <span>Functional</span>

                                                {/* switch start */}
                                                <div className="py-0 ml-auto">
                                                    <Switch
                                                        checked={enabled2}
                                                        onChange={setEnabled2}
                                                        className={`${enabled2 ? 'bg-[green]' : 'bg-[#999]'}
                        relative inline-flex h-[30px] w-[66px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-100`}
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span
                                                            aria-hidden="true"
                                                            className={`${enabled2 ? 'translate-x-9' : 'translate-x-0'}
                            pointer-events-none inline-block h-[26px] w-[26px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                        />
                                                    </Switch>
                                                </div>
                                                {/* switch end */}
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-lg text-gray-500">
                                                Functional cookies help to perform certain functionalities like sharing the content of the website on social media platforms, collect feedbacks, and other third-party features.
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>

                                <Disclosure as="div" className="mt-2">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full rounded-lg bg-[#efefef] px-4 pt-2 pb-1 text-left text-lg font-bold font-medium text-[#000] hover:bg-[#ddd] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">

                                                <ChevronUpIcon
                                                    className={`${open ? 'rotate-180 transform' : ''
                                                        } h-7 w-7 mr-2 text-[#555]`}
                                                />
                                                <span>Performance</span>
                                                {/* switch start */}
                                                <div className="py-0 ml-auto">
                                                    <Switch
                                                        checked={enabled3}
                                                        onChange={setEnabled3}
                                                        className={`${enabled3 ? 'bg-[green]' : 'bg-[#999]'}
                        relative inline-flex h-[30px] w-[66px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-100`}
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span
                                                            aria-hidden="true"
                                                            className={`${enabled3 ? 'translate-x-9' : 'translate-x-0'}
                            pointer-events-none inline-block h-[26px] w-[26px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                        />
                                                    </Switch>
                                                </div>
                                                {/* switch end */}
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-lg text-gray-500">
                                                Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience for the visitors.
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>

                                <Disclosure as="div" className="mt-2">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full rounded-lg bg-[#efefef] px-4 pt-2 pb-1 text-left text-lg font-bold font-medium text-[#000] hover:bg-[#ddd] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">

                                                <ChevronUpIcon
                                                    className={`${open ? 'rotate-180 transform' : ''
                                                        } h-7 w-7 mr-2 text-[#555]`}
                                                />
                                                <span>Analytics</span>
                                                {/* switch start */}
                                                <div className="py-0 ml-auto">
                                                    <Switch
                                                        checked={enabled4}
                                                        onChange={setEnabled4}
                                                        className={`${enabled4 ? 'bg-[green]' : 'bg-[#999]'}
                        relative inline-flex h-[30px] w-[66px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-100`}
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span
                                                            aria-hidden="true"
                                                            className={`${enabled4 ? 'translate-x-9' : 'translate-x-0'}
                            pointer-events-none inline-block h-[26px] w-[26px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                        />
                                                    </Switch>
                                                </div>
                                                {/* switch end */}
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-lg text-gray-500">
                                                Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics the number of visitors, bounce rate, traffic source, etc.
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>

                                <Disclosure as="div" className="mt-2">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full rounded-lg bg-[#efefef] px-4 pt-2 pb-1 text-left text-lg font-bold font-medium text-[#000] hover:bg-[#ddd] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">

                                                <ChevronUpIcon
                                                    className={`${open ? 'rotate-180 transform' : ''
                                                        } h-7 w-7 mr-2 text-[#555]`}
                                                />
                                                <span>Advertisement</span>
                                                {/* switch start */}
                                                <div className="py-0 ml-auto">
                                                    <Switch
                                                        checked={enabled5}
                                                        onChange={setEnabled5}
                                                        className={`${enabled5 ? 'bg-[green]' : 'bg-[#999]'}
                        relative inline-flex h-[30px] w-[66px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-100`}
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span
                                                            aria-hidden="true"
                                                            className={`${enabled5 ? 'translate-x-9' : 'translate-x-0'}
                            pointer-events-none inline-block h-[26px] w-[26px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                        />
                                                    </Switch>
                                                </div>
                                                {/* switch end */}
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-lg text-gray-500">
                                                Advertisement cookies are used to provide visitors with relevant ads and marketing campaigns. These cookies track visitors across websites and collect information to provide customized ads.
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>

                                <Disclosure as="div" className="mt-2">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full rounded-lg bg-[#efefef] px-4 pt-2 pb-1 text-left text-lg font-bold font-medium text-[#000] hover:bg-[#ddd] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">

                                                <ChevronUpIcon
                                                    className={`${open ? 'rotate-180 transform' : ''
                                                        } h-7 w-7 mr-2 text-[#555]`}
                                                />
                                                <span>Others</span>
                                                {/* switch start */}
                                                <div className="py-0 ml-auto">
                                                    <Switch
                                                        checked={enabled6}
                                                        onChange={setEnabled6}
                                                        className={`${enabled6 ? 'bg-[green]' : 'bg-[#999]'}
                        relative inline-flex h-[30px] w-[66px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-100`}
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span
                                                            aria-hidden="true"
                                                            className={`${enabled6 ? 'translate-x-9' : 'translate-x-0'}
                            pointer-events-none inline-block h-[26px] w-[26px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                        />
                                                    </Switch>
                                                </div>
                                                {/* switch end */}
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-lg text-gray-500">
                                                Other uncategorized cookies are those that are being analyzed and have not been classified into a category as yet.
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>

                            </div>
                        </div>

                        {/* test accourdian end */}

                    </div>
                    {/* cookie popup end */}
                    <div className='popFooter w-[100%] bg-[#2b2929] px-4 py-2 shadow-lg'>
                        <button onClick={giveCookieConsent} className='bg-[orange] py-2 px-4 rounded-[20px] text-[15px] flex  ml-auto hover:bg-[darkorange] hover:text-[white] duration-300 '>Save & Accept </button>
                    </div>
                </div>

            </section>)}
            {!cookies.cookieConsent && (
                <section className='cookies-box'>
                <div className='lg:container mx-auto px-4'>
                    <div className='flex justify-between grid md:grid-cols-12'>
                        <div className="col-span-8">
                            <p>
                                We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept All”, you consent to the use of ALL the cookies. However, you may visit "Cookie Settings" to provide a controlled consent.
                            </p>
                        </div>
                        <div className="col-span-4 mx-auto lg:flex justify-center items-center">
                            <button type='button' className='bg-white p-3 my-1 rounded-md text-[#444444] mx-2 hover:bg-[orange] hover:text-[white] duration-300' id="show-pop" onClick={() => setIsOpen(true)} >Cookies Settings</button>
                            <button type='button' onClick={giveCookieConsent} className='bg-white p-3 my-1 rounded-md text-[#444444] mx-2 hover:bg-[orange] hover:text-[white] duration-300'>Accept All Cookies</button>
                        </div>
                    </div>
                </div>
            </section>
            )}
        </>
    )
}

export default Cookies