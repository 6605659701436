import React, { useEffect, useState, Suspense } from 'react'
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import env from '../../../Config';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react';
import countryCode from './../../../Assets/jsonData/country.json';
import ReactHelmet from '../../../Components/ReactHelmet';

const JobDetails = () => {
    const [isOpen2, setIsOpen2] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [file, setFile] = useState(null);
    const [status, setStatus] = useState("initial"); /** "initial" | "uploading" | "success" | "fail" */

    const [data, setData] = useState({
        name: '',
        email: '',
        phoneNo: '',
        companyName: '',
        applyingPosition: '',
        emailValid: false,
        phoneNoValid: false,
        isFormTouched: false,
        countryCode: '+44',
    });



    function closeModal2() {
        setIsOpen2(false)
    }
    function openModal2() {
        setIsOpen2(true)
    }
    function onSubmit2() {
        setIsOpen2(false)
    }



    useEffect(() => {
        window.scrollTo(0, 0);
        // loadCaptchaEnginge(6);
    }, []);


    const handleFileChange = (e) => {
        console.log(e, 'sdd');
        if (e.target.files) {
            console.log(e.target.files[0]);
            console.log(e.target.files[0].size >= 5000000, 'e.target.files[0].size>=5000000');
            if (e.target.files[0].size >= 5000000) {
                setStatus("fileSize");
                return;
            }
            setStatus("initial");
            setFile(e.target.files[0]);
        }
    };

    const uploadResume = () => {
        console.log(data, 'data');
        if (data.emailValid && data.phoneNoValid && data.companyName && data.countryCode && data.name && data.phoneNo && data.applyingPosition) {

            // if (validateCaptcha(data.capcha) == false) {
            //     setData({ ...data, isInvalidCapcha: true });
            // }

            if (file) {
                setStatus("uploading");
                const formData = new FormData();
                formData.append("resume", file);
                formData.append("email", data.email);
                formData.append("name", data.name);
                formData.append("phoneNo", data.phoneNo);
                formData.append("companyName", data.companyName);
                formData.append("applyingPosition", data.applyingPosition);
                formData.append("countryCode", data.countryCode);

                const url = 'https://nodweb.versatilecommerce.com/careers'; // Replace with your API endpoint
                // const url = 'https://nodweb.versatilecommerce.com/careers'; // Replace with your API endpoint
                // const url = 'https://api.hubapi.com/contacts/v1/contact'; // Replace with your API endpoint
                fetch(url, {
                    method: 'POST',
                    // headers: {
                    //     "Content-Type": "multipart/form-data;boundary=MyBoundary"
                    // },
                    body: formData,
                }).then(response => response.json())
                    .then(result => {
                        // Do something with the updated data
                        console.log(result, 'result')

                        if (result && result?.statusCode==200) {
                            setStatus("success");
                            setData({
                                name: '',
                                email: '',
                                phoneNo: '',
                                companyName: '',
                                applyingPosition: ''
                            });
                            openModal2();
                        } else {
                            setStatus("fail");
                            setData({
                                ...data,
                                // capcha: '',
                                isFormTouched: false,
                                isInvalidCapcha: false,
                            });
                            setErrorMsg('User Already exist');
                        }
                    })
                    .catch(error => {
                        setStatus("fail");
                    });
            } else {
                setErrorMsg('Please, attatch your resume');
                setStatus("required");
                console.log('Please, attatch your resume');
            }

        } else {
            setData({ ...data, isFormTouched: true });
        }

    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    return (
        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Careers | Job Details`}
                />
            </Suspense>
            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/career-inner-bnr.png)` }}>
                <div className='lg:container mx-auto'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative py-[60px] max-w-[100%]">
                        <div className="lg:my-[8rem] xl:my-[10rem]">
                            <h2 className="base-h2 text-[#222!important] md:text-[#fff!important] md:w-[50%] mb-5">
                                Senior Full Stack Engineer, Creator Success Full Time
                            </h2>
                            <p className="base-p2 mt-[5px!important] text-[#B5BDCC!important]">
                                Years of Experience: 4 - 6
                            </p>
                            <p className="base-p2 mt-[5px!important] text-[#B5BDCC!important]">No of Positions: 2</p>
                            <p className="base-p2 mt-[5px!important] text-[#B5BDCC!important]">Positioning status: Completed</p>
                        </div>
                    </div>
                </div>
            </div>

            <section className="eqry-form">
                <div className="lg:container mx-auto">
                    <div className="bg-gray-100 relative p-[40px] md:pt-[91px] md:pr-[98px] md:pb-[86px] md:pl-[92px]  rounded-[16px] mx-4 mt-[2rem] lg:mt-[-7rem]">
                        <h4 className='text-[30px] lg:text-[35px] cutom-font-bold'>Job Application Form</h4>
                        <div className="base-p2 mb-16 font-bold">Please Fill Out the Form Below to Submit Your Job Application!</div>

                        {/* form start */}
                        <form className="flex-1" action="/">
                            <div className="flex flex-col gap-6 mb-6 lg:flex-row xl:gap-[30px]">
                                <input className={`outline-none flex-1 placeholder:text-gray-400 placeholder:text-md py-5 px-[30px] ${(data.isFormTouched && !data.name) ? 'inValidInput' : ''}`} type="text" placeholder="Enter your name" value={data.name}
                                    onChange={e => setData({ ...data, isFormTouched: true, name: e.target.value })} />

                                <input className={`outline-none flex-1 placeholder:text-gray-400 placeholder:text-md py-5 px-[30px] ${(data.isFormTouched && !data.companyName) ? 'inValidInput' : ''}`} type="text" placeholder="Company" value={data.companyName}
                                    onChange={e => setData({ ...data, isFormTouched: true, companyName: e.target.value })} />
                            </div>

                            <div className="flex flex-col gap-6 mb-6 lg:flex-row xl:gap-[30px]">
                                <input className={`outline-none lg:w-[49%] placeholder:text-gray-400 placeholder:text-md py-5 px-[30px] ${(data.isFormTouched && !data.emailValid) ? 'inValidInput' : ''}`} type="text" placeholder="Your email" value={data.email}
                                    onChange={e => {
                                        let emailValid = false;
                                        if (validateEmail(e.target.value)) {
                                            emailValid = true;
                                        }
                                        setData({ ...data, isFormTouched: true, email: e.target.value, emailValid: emailValid });
                                    }}
                                />

                                <select defaultValue={'+44'} onChange={(e) => {
                                    setData({ ...data, isFormTouched: true, countryCode: e.target.value });
                                }} className={`outline-none  placeholder:text-gray-400 placeholder:text-md py-5 px-[10px] text-left lg:w-[12%] text-[#667085] ${(data.isFormTouched && !data.countryCode) ? 'inValidInput' : ''}`}>
                                    <option value={""}>Select Country</option>
                                    {(countryCode && countryCode.length > 0) ? (
                                        countryCode.map(res => <option key={res.c} value={`+${res.d}`}>{res.c} ({`+${res.d}`})</option>)
                                    ) : null}
                                </select>

                                <input className={`outline-none placeholder:text-gray-400 placeholder:text-md py-5 px-[30px]  lg:w-[34%] ${(data.isFormTouched && !data.phoneNoValid) ? 'inValidInput' : ''}`} type="text" maxLength={10} minLength={10} placeholder="Phone number" value={data.phoneNo}
                                    onChange={e => {
                                        let phoneNoValid = false;
                                        if (e.target.value.length == 10) {
                                            phoneNoValid = true;
                                        }
                                        setData({ ...data, isFormTouched: true, phoneNo: e.target.value, phoneNoValid: phoneNoValid })
                                        console.log(e.target.value, e.target.value.length);
                                    }}
                                />
                            </div>
                            <div className="flex flex-col gap-6 mb-6 lg:flex-row xl:gap-[30px]">

                                <input className={`outline-none flex-1 placeholder:text-gray-400 placeholder:text-md py-5 px-[30px] ${(data.isFormTouched && !data.applyingPosition) ? 'inValidInput' : ''}`} type="text" placeholder="Applying Position For" value={data.applyingPosition}
                                    onChange={e => setData({ ...data, isFormTouched: true, applyingPosition: e.target.value })} />

                                <div>
                                    <label for="file" button type="file" name="image" className="text-[#667085] outline-none flex-1 placeholder:text-gray-400 placeholder:text-md py-5 px-[22px] mt-0 inline-block cursor-pointer"><i class="las la-paperclip text-[20px] rotate-45"></i>&nbsp;&nbsp; {file ? `${file.name}` : 'Attach Resume'}   </label>

                                    <input onChange={handleFileChange} accept=".doc, .docx, .pdf" type="file" id="file" name="image" style={{ visibility: 'hidden' }} />

                                </div>

                                {/* {data.errorMsg != "" ?
                                    (<div className='lg:flex justify-center items-center text-[#f44336] font-[600]'>
                                        {errorMsg}
                                    </div>) : null
                                } */}

                            </div>
                            <div className='text-center'>
                                <Result status={status} />
                            </div>
                            <div className='flex flex-col gap-5  items-end'>
                                <button type='button' onClick={uploadResume} class=" py-[20px] px-[30px] w-[180px] bg-[#101828] text-white  mt-[34px] rounded-[50px] text-[18px] font-bold flex justify-center items-center hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg float-left duration-300 inline-block">Apply Now <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="text-[15px] ml-[13px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></button>
                            </div>


                        </form>
                        {/* form end */}
                    </div>
                </div>
            </section>

            <section className="jobDescription">
                <div className='lg:container mx-auto px-4 lg:w-[75%] xl:w-[75%] mt-[50px] lg:mt-[100px]'>
                    <h4 className='base-h4 cutom-font-bold-200 mb-6'>Job Description</h4>
                    <p className='base-p4 font-bold mb-6'>
                        The AliStudio Design team has a vision to establish a trusted platform that enables productive and healthy enterprises in a world of digital and remote everything, constantly changing work patterns and norms, and the need for organizational resiliency.
                    </p>
                    <p className='base-p4 font-bold'>
                        The ideal candidate will have strong creative skills and a portfolio of work which demonstrates their passion for illustrative design and typography. This candidate will have experiences in working with numerous different design platforms such as digital and print forms.
                    </p>


                    <div class="relative mx-auto mt-[2rem] lg:mt-[5rem]">
                        <img class="h-full w-full object-cover rounded-2xl" src={env.baseUrl + '/images/career-job-description.png'} alt="Job Description" />
                        {/* <button class="rounded-full bg-white grid place-items-center absolute play-video w-[60px] h-[60px] md:w-[138px] md:h-[138px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hover:drop-shadow-2xl">
                            <img src={env.baseUrl + '/images/icon-play.svg'} alt="play button" width="26" /></button> */}
                    </div>

                    <h4 className='base-h4 cutom-font-bold-200 mt-12'>
                        Essential Knowledge, Skills, and Experience
                    </h4>

                    <ul className='pl-8 leading-loose mt-3 font-bold'>
                        <li> A portfolio demonstrating well thought through and polished end to end customer journeys</li>
                        <li>5+ years of industry experience in interactive design and / or visual design</li>
                        <li>Excellent interpersonal skills </li>
                        <li>Aware of trends in mobile, communications, and collaboration</li>
                        <li>Ability to create highly polished design prototypes, mockups, and other communication artifacts</li>
                        <li>The ability to scope and estimate efforts accurately and prioritize tasks and goals independently</li>
                        <li>History of impacting shipping products with your work</li>
                        <li>A Bachelor’s Degree in Design (or related field) or equivalent professional experience</li>
                        <li>Proficiency in a variety of design tools such as Figma, Photoshop, Illustrator, and Sketch</li>
                    </ul>

                    <h4 className='base-h4 cutom-font-bold-200 mt-6'>
                        Preferred Experience
                    </h4>
                    <ul className='pl-8 leading-loose mt-3 font-bold'>
                        <li>Designing user experiences for enterprise software / services </li>
                        <li>Creating and applying established design principles and interaction patterns </li>
                        <li>Aligning or influencing design thinking with teams working in other geographies </li>
                    </ul>

                    <h4 className='base-h4 cutom-font-bold-200 mt-7 '>
                        Product Designer
                    </h4>
                    <p className='font-bold mt-3'>Product knowledge: Deeply understand the technology and features of the product area to which you are assigned.</p>
                    <p className='font-bold mt-3'>Research: Provide human and business impact and insights for products.</p>
                    <p className='font-bold mt-3'>Deliverables: Create deliverables for your product area (for example competitive analyses, user flows, low fidelity wireframes, high fidelity mockups, prototypes, etc.) that solve real user problems through the user experience.</p>
                    <p className='font-bold mt-3'>Communication: Communicate the results of UX activities within your product area to the design team department, cross-functional partners within your product area, and other interested Superformula team members using clear language that simplifies complexity.</p>

                    <div className='border-t-2 justify-between my-[50px] lg:my-[90px] md:flex'>

                        <div className="flex items-center justify-start mt-[30px]">
                            <button type="button"> <Link to="#" className="flex items-center relative transition-all  group px-[22px] py-[8px] lg:px-[32px] lg:py-[19px] rounded-[40px] bg-gray-900 text-white hover:bg-[#f26722] hover:text-[#fff] hover:drop-shadow-lg duration-300 bg-black text-heading-6 tracking-wide "><span className="block text-inherit w-full h-full rounded-[8px] text-lg cutom-font mr-[15px]">Apply Now</span><FaArrowRight className='text-[16px] ml-[2px]' /></Link></button>

                            <Link className="cutom-font-bold-200 text-base flex items-center text-lg leading-[18px] gap-[5px] hover:text-[#f26722] ml-[34px] cutom-font-bold-200" to="/contact-form">Contact Us <FaArrowRight className='text-[16px] ml-[10px]' />
                            </Link>
                        </div>

                        <div className=' md:flex'>
                            <Link to='https://www.facebook.com/versatilecommerce' target='_blank' className='mx-2 mt-12 hover:drop-shadow-lg'>
                                <img src={env.baseUrl + '/images/career-btn1.png'} alt='Share job positions' />
                            </Link>
                            <Link to='https://twitter.com/AppsCommer73201' className='mx-2 mt-12 hover:drop-shadow-lg'>
                                <img src={env.baseUrl + '/images/career-btn2.png'} alt='Share job positions' />
                            </Link>
                            <Link to='mailto:name@email.com' className='mx-2 mt-12 hover:drop-shadow-lg'>
                                <img src={env.baseUrl + '/images/career-btn3.png'} alt='Share job positions' />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            {/* suceess dialog start 2*/}
            <Transition appear show={isOpen2} as={Fragment}>
                <Dialog as="div" className="relative z-[9999] tex-[red] bg-[blue]" onClose={closeModal2} >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full lg:max-w-[50%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                    <div className="">
                                        <section className="eqry-form">
                                            <div className="lg:container mx-auto">
                                                <div className="bg-gray-100 relative p-[40px] md:py-[20px] md:px-[40px] rounded-[16px]">
                                                    <button className='absolute  right-3 md:right-10' onClick={closeModal2}><i class="las la-times text-[20px] text-[#ffa2a2] bg-white rounded-[50px] px-3 py-3 font-bold hover:text-[red] flex justify-center items-center"></i></button>


                                                    {/* form start */}
                                                    <div className='text-center my-5'>
                                                        <i class="las la-check-circle text-[green] text-[80px] md:text-[150px]"></i>

                                                        <h4 className='text-[30px] lg:text-[35px] cutom-font-bold mb-5 mt-10'>Thank You</h4>

                                                        <p>Your Submission has been sent</p>
                                                    </div>
                                                    {/* form end */}
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            {/* suceess dialog end 2*/}
        </>
    )
}


const Result = ({ status }) => {
    if (status === "success") {
        return <p className={'text-[#00d26a] font-[600] '}>✅ Resume File uploaded successfully!</p>;
    } else if (status === "fail") {
        return <p className={'text-[#f44336] font-[600]'}>❌ Resume File upload failed!</p>;
    } else if (status === "uploading") {
        return <p className={'text-[#f44336] font-[600]'}>⏳ Resume Uploading selected file...</p>;
    } else if (status === "required") {
        return <p className={'text-[#f44336] font-[600] flex'}>❌Please, Upload your resume.</p>;
    } else if (status === "fileSize") {
        return <p className={'text-[#f44336] font-[600]'}>❌Max file size is 5MB</p>;
    } else {
        return null;
    }
};

export default JobDetails