import React from 'react'
import { useEffect, Suspense } from 'react'
import Discovery from '../../../Components/DiscoveryCall/Discovery'
import ReactHelmet from '../../../Components/ReactHelmet'
import env from '../../../Config'

const CloudPartnership = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Services | Cloud partnership consulting`}
                    keywords={`Cloud partnership consulting`}
                    description={`Where Ideas Soar and Innovations Flourish: Leading the Way in Cloud Partnership Consulting`}
                    seoimgs={`Cloud-partnership-consulting.png`}
                />
            </Suspense>

            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/Cloud-partnership-consulting.png)` }}>
                <div className='lg:container mx-auto px-4'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
                        <div className="flex-1 md:py-[70px] lg:py-[100px]">
                            <h2 className="text-[2rem] lg:text-[82px] md:w-[50%] lg:w-[50%]  leading-tight font-bold text-center md:text-left">
                                <span className='text-[#FF6B01]'>Cloud Partnership</span> Consulting
                            </h2>
                            <p className="text-[18px] lg:text-[24px] font-semibold mt-5 md:w-[50%] lg:w-[40%] text-center md:text-left">
                                Where Ideas Soar and Innovations Flourish: Leading the Way in Cloud Partnership Consulting
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <section className='ourEngineerings'>
                <div className="lg:container mx-auto px-4 my-[40px] md:my-[70px] lg:mt-[100px]">
                    <div className="text-center">
                        <h2 className="text-[2rem] lg:text-[56px] leading-tight font-bold">Cloud Partnership <span className="text-[#f26722]"> Consulting</span></h2>
                        <p className="text-[20px] md:text-[24px] text-[#475467] font-bold lg:w-[60%] mx-auto mt-4">
                            Fostering a Powerful Partnership: Guiding Your Business to New Horizons through Cloud Partnership Consulting</p>
                    </div>

                    <div className='xl:px-0 mt-[70px] lg:mt-[80px]'>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Azure-cloud.png'} alt='Azure Cloud'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">Microsoft Azure</div>

                                <h2 className="base-h2 font-bold leading-none">Azure Cloud</h2>
                                <p className='base-p1'>
                                    Microsoft Azure is a comprehensive cloud platform that offers a wide range of services for businesses to build, deploy, and manage applications efficiently and securely, with features such as scalability, hybrid capabilities, AI integration, and robust security measures.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Microsoft Azure Overview</h5>
                                            <div className='tooltip_box'>Microsoft Azure is a comprehensive cloud computing platform offered by Microsoft. It provides a wide range of services for building, deploying, and managing applications and services through Microsoft-managed data centres.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scalability and Flexibility</p>
                                            <div className='tooltip_box'>Azure allows businesses to easily scale up or down based on demand, ensuring that resources are allocated efficiently and cost-effectively.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Wide Service Offering</h5>
                                            <div className='tooltip_box'>Azure offers a vast array of services, including virtual machines, databases, AI, analytics, IoT, networking, and more, catering to diverse business needs.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Global Reach</h5>
                                            <div className='tooltip_box'>Azure operates data centres in multiple regions across the world, enabling businesses to host their applications and data closer to their target audience for improved performance and compliance.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Hybrid Capabilities</h5>
                                            <div className='tooltip_box'>Azure supports hybrid scenarios, allowing businesses to integrate their on-premises infrastructure with the cloud seamlessly. This facilitates migration, data synchronisation, and leveraging cloud benefits while maintaining local resources.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Security and Compliance</h5>
                                            <div className='tooltip_box'>Azure priorities security, offering a variety of tools for identity management, encryption, threat detection, and compliance certifications to help businesses meet their security requirements.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> AI and Machine Learning</h5>
                                            <div className='tooltip_box'>Azure provides AI and machine learning services that empower businesses to build, deploy, and manage machine learning models for various applications.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> DevOps Integration</h5>
                                            <div className='tooltip_box'>Azure offers tools and services that facilitate DevOps practices, enabling continuous integration, continuous delivery, and automated deployment pipelines.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Cost Management</h5>
                                            <div className='tooltip_box'>Azure provides cost management tools to monitor, optimise, and control spending on cloud resources, helping businesses make informed decisions about resource allocation.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Support and Documentation</h5>
                                            <div className='tooltip_box'>Azure offers extensive documentation, resources, and customer support, making it easier for businesses to get started, troubleshoot issues, and optimise their cloud deployments.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">Microsoft Azure</div>

                                <h2 className="base-h2 font-bold leading-none">Data Center Migration to Azure</h2>
                                <p className='base-p1'>
                                    migrating a data centre to Azure involves careful planning, assessment, application mapping, data transfer, network setup, testing, security considerations, and ongoing optimisation. A well-executed migration strategy can lead to improved efficiency, scalability, and cost savings in the cloud environment
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Planning and Assessment</h5>
                                            <div className='tooltip_box'>Data centre migration to Azure begins with a comprehensive planning phase, including assessing the existing infrastructure, applications, dependencies, and requirements.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Application Mapping</h5>
                                            <div className='tooltip_box'>Identifying and mapping applications and workloads to Azure services is crucial to determine the best migration approach, such as rehosting, refactoring, or rearchitecting.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Resource Sizing and Cost Analysis</h5>
                                            <div className='tooltip_box'>Properly sizing resources in Azure and estimating associated costs is essential for budgeting and optimising resource utilisation.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Transfer and Migration</h5>
                                            <div className='tooltip_box'>Planning the transfer of data and databases from the on-premises data centre to Azure involves choosing migration methods such as offline data transfer, online migration, or hybrid approaches.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Security and Compliance</h5>
                                            <div className='tooltip_box'>Adhering to data security and compliance standards during migration is crucial. Azure provides tools and services to maintain data security and meet regulatory requirements.</div>
                                        </div>


                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Testing and Validation</h5>
                                            <div className='tooltip_box'>Thorough testing of migrated applications, workloads, and data is essential to verify functionality, performance, and security in the Azure environment.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Network and Connectivity</h5>
                                            <div className='tooltip_box'>Ensuring seamless connectivity between the on-premises environment and Azure is critical. Setting up Virtual Networks, VPNs, and ExpressRoute connections may be required.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User Training and Transition</h5>
                                            <div className='tooltip_box'>Training users on the new Azure environment and providing guidance on using Azure services helps ensure a smooth transition and adoption.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Optimisation and Monitoring</h5>
                                            <div className='tooltip_box'>Continuously monitoring and optimizing the migrated environment in Azure is essential for cost control, performance enhancement, and overall efficiency.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Disaster Recovery and Business Continuity</h5>
                                            <div className='tooltip_box'>Implementing disaster recovery strategies in Azure, including backup and failover options, ensures business continuity in case of any unexpected issues.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Data-center-migration-to-Azure.png'} alt='Data Center Migration to Azure'/>
                            </div>
                        </div>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/AWS-cloud-security.png'} alt='AWS Cloud Security'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">AWS Cloud Platform</div>

                                <h2 className="base-h2 font-bold leading-none">AWS Cloud Security</h2>
                                <p className='base-p1'>
                                    AWS Cloud Security encompasses various measures to ensure the confidentiality, integrity, and availability of customer data and applications. By leveraging these security features and best practices, organisations can build and maintain secure cloud environments on the AWS platform
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Security Monitoring and Logging</h5>
                                            <div className='tooltip_box'>AWS CloudTrail, AWS Config, and Amazon CloudWatch allow customers to monitor and log activities, events, and changes within their AWS environment to detect and respond to security threats.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Identity and Access Management (IAM)</p>
                                            <div className='tooltip_box'>AWS IAM enables fine-grained control over user access to resources, allowing administrators to manage permissions, roles, and multi-factor authentication to enhance security.</div>
                                        </div>


                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Incident Response and Compliance</h5>
                                            <div className='tooltip_box'>AWS provides resources and guidelines for developing an incident response plan. It offers Compliance Center, which helps customers understand how AWS services can be used to meet specific compliance requirements.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Shared Responsibility Model</h5>
                                            <div className='tooltip_box'>AWS Cloud Security follows a shared responsibility model, where Amazon is responsible for the security of the cloud infrastructure, while customers are responsible for securing their data and applications within the cloud.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> DDoS Protection</h5>
                                            <div className='tooltip_box'>AWS Shield provides protection against Distributed Denial of Service (DDoS) attacks, safeguarding applications and data from disruptions caused by malicious traffic.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Web Application Firewall (WAF)</h5>
                                            <div className='tooltip_box'>AWS WAF helps protect web applications from common web exploits by filtering and monitoring incoming traffic, preventing security threats.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Penetration Testing and Auditing</h5>
                                            <div className='tooltip_box'>AWS customers can conduct security testing and assessments to identify vulnerabilities. AWS also offers tools like Amazon Inspector for automated security assessment.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Encryption</h5>
                                            <div className='tooltip_box'>AWS offers encryption at rest and in transit. Data can be encrypted using services like AWS Key Management Service (KMS) and SSL/TLS protocols, ensuring data security throughout its lifecycle.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Network Security</h5>
                                            <div className='tooltip_box'>AWS provides Virtual Private Cloud (VPC) for network isolation and security groups for controlling inbound and outbound traffic. Network Access Control Lists (ACLs) offer additional layer of control.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Compliance and Certifications</h5>
                                            <div className='tooltip_box'>AWS complies with various industry standards and regulations, providing customers with a secure and compliant environment. It undergoes regular audits and certifications.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">AWS Cloud Infrastructure</div>

                                <h2 className="base-h2 font-bold leading-none">High Performance Computing</h2>
                                <p className='base-p1'>
                                    High Performance Computing plays a crucial role in advancing scientific research, accelerating innovation, and solving complex problems that would be otherwise computationally infeasible. Its applications span across diverse fields, from scientific simulations to financial analysis and artificial intelligence
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Parallel Processing</h5>
                                            <div className='tooltip_box'>HPC involves breaking down tasks into smaller subtasks that can be processed simultaneously by multiple processors or cores, significantly speeding up computation.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Supercomputers</h5>
                                            <div className='tooltip_box'>Supercomputers, the epitome of HPC, deliver exceptionally high processing speeds and are used for scientific research, weather forecasting, nuclear simulations, and other data-intensive tasks.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scientific Research and Simulation</h5>
                                            <div className='tooltip_box'>HPC accelerates scientific discovery by enabling complex simulations, molecular modelling, climate studies, and particle physics simulations that require immense computational power.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data-Intensive Applications</h5>
                                            <div className='tooltip_box'>HPC is vital for handling large datasets in fields like genomics, astronomy, and seismic analysis, where rapid processing of massive data volumes is crucial.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Definition of High Performance Computing (HPC)</h5>
                                            <div className='tooltip_box'>High Performance Computing refers to the use of powerful computing systems and techniques to solve complex problems that require extensive computational resources, often involving large-scale simulations, data analysis, and scientific research.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Financial Modelling</h5>
                                            <div className='tooltip_box'>HPC is used in financial markets for real-time trading, risk assessment, and complex quantitative analysis due to its ability to process vast amounts of data quickly.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Engineering and Design</h5>
                                            <div className='tooltip_box'>Industries such as aerospace, automotive, and manufacturing rely on HPC for simulations that optimise designs, test prototypes, and enhance product performance.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Healthcare and Medicine</h5>
                                            <div className='tooltip_box'>HPC facilitates genomics research, drug discovery, medical imaging analysis, and personalised medicine by processing and analysing vast amounts of medical data.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Energy and Climate Modelling</h5>
                                            <div className='tooltip_box'>HPC contributes to energy exploration, climate modelling, and environmental impact assessments by simulating complex systems and scenarios.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Artificial Intelligence and Machine Learning</h5>
                                            <div className='tooltip_box'>HPC accelerates AI and machine learning training, enabling rapid experimentation and development of advanced models that require extensive computational resources.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/High-performance-computing.png'} alt='High Performance Computing'/>
                            </div>
                        </div>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/AWS-cloud-integrations.png'} alt='AWS Cloud Integrations'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">AWS Cloud Platform</div>

                                <h2 className="base-h2 font-bold leading-none">AWS Cloud Integrations</h2>
                                <p className='base-p1'>
                                    AWS provides a variety of services and tools to enable smooth integration between different cloud services, applications, and data sources. These integration capabilities empower businesses to build efficient, scalable, and interconnected cloud solutions.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> AWS Integration Services</h5>
                                            <div className='tooltip_box'>AWS offers a range of integration services, such as Amazon EventBridge, Amazon AppFlow, and AWS Step Functions, that allow seamless communication and interaction between different AWS services and external applications.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> AWS CloudFormation</p>
                                            <div className='tooltip_box'>CloudFormation allows you to define and provision AWS infrastructure as code, facilitating consistent and automated integration of resources in a repeatable manner.</div>
                                        </div>


                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> AWS Data Integration Services</h5>
                                            <div className='tooltip_box'>AWS offers data integration tools like AWS Glue, which automate the extraction, transformation, and loading (ETL) process, enabling seamless integration of data from various sources.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> AWS Lambda</h5>
                                            <div className='tooltip_box'>Lambda provides serverless computing, allowing you to trigger code execution in response to events from various sources, enabling integrations between different services and applications.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> AWS App Runner</h5>
                                            <div className='tooltip_box'>AWS App Runner simplifies deployment and scaling of web applications, providing easy integration between application code and containerised services.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> API Gateway</h5>
                                            <div className='tooltip_box'>AWS API Gateway enables the creation and management of APIs that connect applications to AWS resources, facilitating integration between cloud services and web or mobile applications.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Amazon Connect</h5>
                                            <div className='tooltip_box'>Amazon Connect offers cloud-based contact centre solutions that integrate with other AWS services, enhancing customer engagement through seamless integration of voice and chat interactions.</div>
                                        </div>



                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Amazon S3 Event Notifications</h5>
                                            <div className='tooltip_box'>S3 event notifications can trigger AWS Lambda functions, SQS queues, or SNS topics when objects are created or modified in S3 buckets, enabling real-time data processing.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Amazon Redshift Spectrum</h5>
                                            <div className='tooltip_box'>: This service allows you to run SQL queries on data stored in Amazon S3, enabling seamless integration between data warehousing and data lake capabilities.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Third-Party Integrations</h5>
                                            <div className='tooltip_box'>AWS Marketplace offers a wide range of third-party software solutions that can be integrated with AWS services, allowing you to extend functionalities and meet specific business needs.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <Discovery
                title={'Discovery '}
                subTitle={'Call'}
                desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
                btnName={'Subscribe'}
                placeholder={'Drop a Email'} />
        </>
    )
}

export default CloudPartnership