import React, { useEffect, Suspense, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import $ from 'jquery';
import Discovery from '../../Components/DiscoveryCall/Discovery';
import Faqs from '../../Components/Faqs/Faqs';
import { Link } from 'react-router-dom';
import ReactHelmet from '../../Components/ReactHelmet';
import env from '../../Config';


const About = () => {

  $(window).scroll(function () {
    if ($('#counter').offset()) {
      var a = 0;
      var oTop = $('#counter').offset().top - window.innerHeight;
      if (a == 0 && $(window).scrollTop() > oTop) {
        $('.counter-value').each(function () {
          var $this = $(this),
            countTo = $this.attr('data-count');
          $({ countNum: $this.text() }).animate({ countNum: countTo }, {
            duration: 1000, easing: 'swing',
            step: function () { $this.text(Math.floor(this.countNum)); },
            complete: function () { $this.text(this.countNum); }
          });
        });
        a = 1;
      }
    }
  });
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <>
      <Suspense>
        <ReactHelmet
          title={`Versatile Commerce | About`}
          keywords={`Our Story`}
          description={`Elevate your Leading the Way to Success: Your Vision, Powered by Our Dynamic Services and Dedicated Team`}
          seoimgs={`about-banner.png`}
        />
      </Suspense>
      <section className='about bg-[#F2F4F7] min-h-[14rem] lg:min-h-[32rem] lg:min-h-[40rem]'>
        <div className='lg:container mx-auto pt-[50px] pb-[50px] lg:pt-[80px] px-4'>
          <div className="text-center mx-auto">
            <h2 className="text-[40px] lg:text-[82px] leading-tight font-bold">
              Our <span className='text-[#f26722]'> Story </span>
            </h2>
            <p className="text-[18px] lg:text-[24px] text-[#475467] font-semibold mx-auto mt-5 md:mt-3 md:mt-5 lg:w-[60%]"> Elevate your Leading the Way to Success: Your Vision, Powered by Our Dynamic Services and Dedicated Team
            </p>
          </div>
        </div>
      </section>

      <div className='lg:container mx-auto px-4 relative'>
        <div class="relative mx-auto max-w-[100%] lg:mt-[-18rem]">
          <img class="h-full w-full object-cover rounded-2xl p-4 lg:px-0" src={env.baseUrl + '/images/about-banner.png'} alt="Elevate your Leading the Way to Success" />
          {/* <button class="rounded-full bg-white grid place-items-center absolute play-video w-[60px] h-[60px] md:w-[138px] md:h-[138px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hover:drop-shadow-2xl">
            <img src={env.baseUrl + '/images/icon-play.svg'} alt="play button" width="26" /></button> */}
        </div>

        <div className='grid grid-cols-2 gap-x-[10px] md:gap-x-[30px] gap-y-[45px] lg:gap-x-[46px] lg:gap-y-[77px] md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 mb-[60px] lg:mb-[70px] lg:mx-[7rem] mt-10 lg:mt-[70px] px-4 md:px-5 lg:px-0'>


          <div className="items-start text-center lg:text-left mx-auto">
            <div className='flex text-[34px] lg:text-[72px] text-[#FF6B01] font-bold'>
              <div>
                <p id="counter"><span className="counter-value" data-count="5">0 </span>x</p>
              </div>
              {/* <span className='text-[34px] lg:text-[64px] ml-2'>+</span> */}
            </div>
            <p className='text-[20px] font-bold text-[#667085] text-center'> Growth Rate</p>
          </div>

          <div className=" items-start text-center lg:text-left mx-auto">
            <div className='flex text-[34px] lg:text-[72px] text-[#FF6B01] font-bold'>
              <div>
                <p id="counter"><span className="counter-value" data-count="100">0</span></p>
              </div>
              <span className='text-[34px] lg:text-[64px] text-[#FF6B01] font-bold  relative lg:right-[0px]'>+</span>
            </div>
            <p className='text-[20px] font-bold text-[#667085] lg:pl-1 text-center'>Completed Projects
            </p>
          </div>

          <div className="items-start text-center lg:text-left mx-auto">
            <div className='flex text-[34px] lg:text-[72px] text-[#FF6B01] font-bold'>
              <div>
                <p id="counter"><span className="counter-value" data-count="60">0 </span></p>
              </div>
              <span className='text-[34px] lg:text-[64px] text-[#FF6B01] font-bold relative lg:right-[0px]'>+</span>
            </div>
            <p className='text-[20px] font-bold text-[#667085] text-center'>Happy Clients</p>
          </div>

          <div className="items-start text-center lg:text-left mx-auto">
            <div className='flex text-[34px] lg:text-[72px] text-[#FF6B01] font-bold'>
              <div>
                <p id="counter"><span className="counter-value" data-count="50">0 </span></p>
              </div>
              <span className='text-[34px] lg:text-[64px] text-[#FF6B01] font-bold relative lg:right-[0px]'>+</span>
            </div>
            <p className='text-[20px] font-bold text-[#667085] text-center'>Work Force</p>
          </div>

        </div>

        <div className='border-b-[1px] mb-[70px] mt-[0px] w-[100%]'></div>

      </div>

      <section className='elevate-your-emp'>
        <div className="lg:container mx-auto px-4">
          <div className='bg-[#FFF3EA] w-[180px] h-[44px] text-[16px] text-center mx-auto rounded-[40px] flex justify-center items-center'>
            How it's works?
          </div>

          <h2 className="base-h2 mt-[50px] leading-tight font-bold lg:w-[50%] mx-auto text-center">Elevate your Employee <span className="text-[#f26722]"> Development Journey </span></h2>

          {/* <div className='mt-[40px] lg:mt-[70px] p-4 lg:p-0'>
            <img className='max-w-[100%]  mx-auto' src={env.baseUrl + '/images/emp-devlp-jurny.svg'} />
          </div> */}

          <div className='px-[12px] md:px-[36px] xl:px-0 mt-[70px] lg:mb-[120px] text-center mx-auto max-w-[905px]'>
            <div className="flex flex-col gap-5 items-center justify-center relative md:flex-wrap md:flex-row lg:gap-[30px] xl:gap-[110px]">
              <div className="rounded-2xl p-[30px] md:py-[20px] md:px-[23px] self-stretch relative bg-bg-2 md:w-[calc(50%-20px)] lg:w-[calc(33.33%-30px)] xl:w-[calc(33.33%-75px)] transition-all duration-300 hover:translate-y-[-3px]">
                <div className="bg-white rounded-full grid place-items-center mx-auto mb-8 w-[80px] h-[80px]">
                  <img className="" src={env.baseUrl + '/images/about-elvat-icon1.png'} alt='Start Project' /></div>
                <h4 className="font-bold text-[22px] xl:text-heading-5 mb-[15px]">Start Project
                </h4>
                <p className="text-text text-[#667085]">Elevate Your Goals with Our Transformative Project Ventures
                </p>
                <img className="hidden absolute right-0 lg:block top-1/2 translate-x-[30px] z-[-1] xl:translate-x-full xl:right-[-15px]" src={env.baseUrl + '/images/about-elvat-arrow1.png'} alt="" />
              </div>

              <div className="rounded-2xl p-[30px] md:py-[20px] md:px-[23px] self-stretch relative bg-bg-3 md:w-[calc(50%-20px)] lg:w-[calc(33.33%-30px)] xl:w-[calc(33.33%-75px)] transition-all duration-300 hover:translate-y-[-3px]">
                <div className="bg-white rounded-full grid place-items-center mx-auto mb-8 w-[80px] h-[80px]">
                  <img src={env.baseUrl + '/images/about-elvat-icon2.png'} alt="Empowering Decision Making with Informed Evaluation" /></div>
                <h4 className="font-bold text-[22px] xl:text-heading-5 mb-[15px]">Evaluate
                </h4>
                <p className="text-text text-[#667085]">Empowering Decision-Making with Informed Evaluation and Strategic Insights
                </p>
                <img className="hidden absolute right-0 lg:block top-1/2 translate-x-[30px] z-[-1] xl:translate-x-full xl:right-[-15px]" src={env.baseUrl + '/images/about-elvat-arrow2.png'} alt="" />
              </div>

              <div className="rounded-2xl p-[30px] md:py-[20px] md:px-[23px] self-stretch relative bg-bg-5 md:w-[calc(50%-20px)] lg:w-[calc(33.33%-30px)] xl:w-[calc(33.33%-75px)] transition-all duration-300 hover:translate-y-[-3px]">
                <div className="bg-white rounded-full grid place-items-center mx-auto mb-8 w-[80px] h-[80px]">
                  <img src={env.baseUrl + '/images/about-elvat-icon3.png'} alt="Development" /></div>
                <h4 className="font-bold text-[22px] xl:text-heading-5 mb-[15px]">Development
                </h4>
                <p className="text-text text-[#667085]">Here's where our team jumps in, getting hands-on and fully engaged
                </p>
                <img className="hidden absolute right-0 top-full lg:block translate-x-[-60px] z-[-1] xl:translate-y-[30px]" src={env.baseUrl + '/images/about-elvat-arrow3.png'} alt="" />
              </div>

              <div className="hidden lg:block rounded-2xl p-[30px] md:py-[20px] md:px-[23px] self-stretch relative bg-bg-5 md:w-[calc(50%-20px)] lg:w-[calc(33.33%-30px)] xl:w-[calc(33.33%-75px)] transition-all duration-300 hover:translate-y-[-3px]">
                <div className="bg-white rounded-full grid place-items-center mx-auto mb-8 w-[80px] h-[80px]"><img src={env.baseUrl + '/images/about-elvat-icon4.png'} alt="our efforts converge for a
                  successful completion" /></div>
                <h4 className="font-bold text-[22px] xl:text-heading-5 mb-[15px]">Complete
                </h4>
                <p className="text-text text-[#667085]">Our efforts converge for a
                  successful completion, harmonising every aspect
                </p>
              </div>

              <div className="rounded-2xl p-[30px] md:py-[20px] md:px-[23px] self-stretch relative bg-bg-9 md:w-[calc(50%-20px)] lg:w-[calc(33.33%-30px)] xl:w-[calc(33.33%-75px)] transition-all duration-300 hover:translate-y-[-3px] ">
                <div className="bg-white rounded-full grid place-items-center mx-auto mb-8 w-[80px] h-[80px]">

                  <img src={env.baseUrl + '/images/about-elvat-icon5.png'} alt="Quality and Bugs" />
                </div>
                <h4 className="font-bold text-[22px] xl:text-heading-5 mb-[15px]"> Quality & Bugs
                </h4>
                <p className="text-text text-[#667085]"> Our attention shifts towards maintaining high standards and resolving any glitches
                </p><img className="hidden absolute left-0 lg:block top-1/2 translate-x-[-30px] z-[-1] xl:-translate-x-full xl:left-[-15px]" src={env.baseUrl + '/images/about-elvat-arrow4.png'} alt="" />
              </div>

              <div className="rounded-2xl p-[30px] md:py-[20px] md:px-[23px] self-stretch relative bg-bg-5 md:w-[calc(50%-20px)] lg:w-[calc(33.33%-30px)] xl:w-[calc(33.33%-75px)] transition-all duration-300 hover:translate-y-[-3px] lg:hidden">
                <div className="bg-white rounded-full grid place-items-center mx-auto mb-8 w-[80px] h-[80px]"><img src={env.baseUrl + '/images/about-elvat-icon4.png'} alt="our efforts converge for a
                  successful completion" /></div>
                <h4 className="font-bold text-[22px] xl:text-heading-5 mb-[15px]">Complete
                </h4>
                <p className="text-text text-[#667085]">Our efforts converge for a
                  successful completion, harmonising every aspect
                </p>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className='dont-take bg-[#FB9851] mt-[40px] lg:mt-[70px] py-[40px] lg:py-[90px]'>
        <div className="lg:container mx-auto p-4">
          <div className='grid md:grid-cols-12 lg:grid-cols-12 gap-7'>

            <div className="col-span-12 md:col-span-12 lg:col-span-6 lg:pr-[115px]">
              <div className='bg-[#FFF3EA] w-[220px] h-[44px] text-[16px] rounded-[40px] flex justify-center items-center'>
                Built Exclusively For You
              </div>
              <h3 className='text-[54px] font-bold text-[#fff] mt-[35px]'>
                Don’t take our word for it. See what our clients say.
              </h3>
              <p className='text-[24px] text-[#fff] mt-4'>
              Weaving Success Stories by Blending Innovation, Dedication, and Passion for Excellence
              </p>
              <Link to="/contact-form" className="py-[15px] lg:py-[20px] px-[20px] lg:px-[30px] bg-[#101828] text-white  mt-[50px] rounded-[50px] text-[18px] font-bold flex justify-center items-center hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg float-left duration-300">Learn more <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="text-[15px] ml-[13px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></Link>
            </div>

            <div className='col-span-12 md:col-span-6 lg:col-span-3'>
              <div className='bg-[#fff] p-4 lg:p-[25px] min-h-[270px] hover:shadow-lg duration-300'>
                <p>
                  We've enjoyed collaborating with Versatile Commerce for over a year. They've consistently excelled throughout our partnership, playing a pivotal role in preparing our SaaS platform for market readiness.
                </p>
                <div className='flex gap-5 mt-[30px]'>
                  <div><img className='max-w-[100%]' src={env.baseUrl + '/images/testimonial-logo-certchain.png'} alt='See what our clients say' /></div>
                  <div>
                    <div className='cutom-font-bold text-[16px] mt-5'>Stephen Holt </div>
                    <p className='text-[14px] text-[#667085]'>Cert Chain </p>
                  </div>
                </div>
              </div>

              <div className='bg-[#fff] p-4 lg:p-[25px] min-h-[270px] hover:shadow-lg duration-300 mt-7'>
                <p>
                  They are experts in their field, readily available to communicate, address queries, and offer solutions. Their team consists of highly skilled professionals, eliminating the need for resource upskilling.
                </p>
                <div className='flex gap-5 mt-[30px]'>
                  <div><img className='max-w-[100%]' src={env.baseUrl + '/images/testimonial-logo-cii.png'} alt='See what our clients say' /></div>
                  <div>
                    <div className='cutom-font-bold text-[16px] mt-5'>Nana Marshall </div>
                    <p className='text-[14px] text-[#667085]'>CII </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-span-12 md:col-span-6 lg:col-span-3'>
              <div className='bg-[#fff] p-4 lg:p-[25px] min-h-[270px] hover:shadow-lg duration-300'>
                <p>
                  Your team exhibits several vital qualities that greatly benefit our collaboration. They are highly responsive, always providing a definitive timeline for project initiation, even when delays occur.
                </p>
                <div className='flex gap-5 mt-[30px]'>
                  <div><img className='max-w-[100%]' src={env.baseUrl + '/images/testimonial-logo-prop.png'} alt='See what our clients say' /></div>
                  <div>
                    <div className='cutom-font-bold text-[16px] mt-5'>Sourabh Tyagi </div>
                    <p className='text-[14px] text-[#667085]'>Prop Right </p>
                  </div>
                </div>
              </div>

              <div className='bg-[#fff] p-4 lg:p-[25px] min-h-[270px] hover:shadow-lg duration-300 mt-7'>
                <p>
                  We have access to talented individuals with diverse skill sets, allowing us to scale resources up or down as needed. This team comprises hardworking and personable individuals who are knowledgeable and exceptionally supportive.
                </p>
                <div className='flex gap-5 mt-[30px]'>
                  <div><img className='max-w-[100%]' src={env.baseUrl + '/images/testimonial-logo-wment.png'} alt='See what our clients say' /></div>
                  <div>
                    <div className='cutom-font-bold text-[16px] mt-5'> Fran Boorman</div>
                    <p className='text-[14px] text-[#667085]'>Wowment</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="buildingThe mt-[40px] lg:mt-[70px]">
        <div className="lg:container mx-auto px-4">
          <h2 className="base-h2 text-center lg:mt-[100px] lg:w-[50%] mx-auto">Building the<br />  <span className="text-[#F26722]">Work Ecosystem</span></h2>
          <p className="base-p1 text-[#667085] lg:w-[60%] text-center mx-auto mt-[15px] font-semibold">As time unfolds, our commitment perseveres—to engineer pioneering technology that brings value to businesses, both big and small</p>

          <div className="grid lg:grid-cols-12 mx-auto gap-[30px] mt-[40px]  lg:mt-[70px]">
            <div className="lg:col-span-6 text-center rounded-2xl p-5 max-h-[300px] md:max-h-[450px] lg:max-h-[380px] xl:max-h-[455px] md:p-14 mb-[150px] md:mb-[300px] lg:mb-0 transition-all duration-300 hover:translate-y-[-3px] bg-bg-2">
              <h3 className="base-h4 cutom-font-bold-200 leading-[26px] md:text-heading-4 mb-[18px]">Research On Demand
              </h3>
              <p className="text-md text-gray-600 mb-[22px] lg:mx-10">Curating a World of Knowledge to Meet Your Needs Elevate Your Understanding with On-Demand Research Solutions</p><img className="p-5 rounded-2xl object-cover mx-auto w-full bg-[#EBECF4]" src={env.baseUrl + '/images/about-cross.png'} alt='Curating a World of Knowledge' />
            </div>
            <div className="lg:col-span-6 text-center rounded-2xl p-5 max-h-[300px] md:max-h-[450px] lg:max-h-[380px] xl:max-h-[455px] md:p-14 mb-[150px] md:mb-[300px] lg:mb-0 transition-all duration-300 hover:translate-y-[-3px] bg-bg-6">
              <h3 className="base-h4 cutom-font-bold-200 leading-[26px] md:text-heading-4 mb-[18px]">Discovery
              </h3>
              <p className="text-md text-gray-600 mb-[22px] lg:mx-16">Gaining Insight into Your Business Requirements through Appropriate Technology Implementation</p><img className="p-5 rounded-2xl object-cover mx-auto w-full bg-[#EBECF4]" src={env.baseUrl + '/images/about-extremely.png'} alt='Gaining Insight into Your Business Requirements' />
            </div>
          </div>
        </div>
      </section>
      <div className='clear-both'></div><br />

      <section className="ourTeam mt-[50px] lg:mt-[210px] xl_mt-350 hidden">
        <div className="lg:container mx-auto px-4 gap-x-[30px]">
          <h2 className="text-[40px] md:text-[56px] text-center font-bold">Our <span className="text-[#F26722]"> Team</span></h2>
          <p className="text-[24px] text-[#667085] lg:w-[60%] text-center mx-auto mt-[15px] font-semibold">Years of expertise in design and development spanning multiple decades.</p>

          <div className='team-list slick-initialized slick-slider mt-[50px] gap-x-[30px]'>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              autoPlaySpeed={3000}
              autoPlay={false}
              infinite={true}
            >
              <div className='mb-[70px] md:mx-3 lg:mx-3'>
                <div>
                  <div className="mb-[30px]"> <img className="h-full w-full object-cover" src={env.baseUrl + '/images/about-team1.png'} alt='Our team' />
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="mb-3">
                      <p className="text-md mb-2 opacity-50 text-[#0A3361]">Marketing CEO</p>
                      <p className="text-heading-6 cutom-font-bold">Theresa Webb</p>
                    </div>
                    <p className="text-excerpt text-gray-600 line-clamp-2 opacity-60 mb-6">Lorem ipsum dolor sit amet consectetur imp adipiscing elit justo</p>
                  </div>
                </div>
              </div>

              <div className='mb-[70px] md:mx-3 lg:mx-3'>
                <div>
                  <div className="mb-[30px]"> <img className="h-full w-full object-cover" src={env.baseUrl + '/images/about-team3.png'} alt='UI UX Designer' />
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="mb-3">
                      <p className="text-md mb-2 opacity-50 text-[#0A3361]">UI/UX Designer</p>
                      <p className="text-heading-6 cutom-font-bold">Darlene Robertson</p>
                    </div>
                    <p className="text-excerpt text-gray-600 line-clamp-2 opacity-60 mb-6">Lorem ipsum dolor sit amet consectetur imp adipiscing elit justo</p>
                  </div>
                </div>
              </div>

              <div className='mb-[70px] md:mx-3 lg:mx-3'>
                <div>
                  <div className="mb-[30px]"> <img className="h-full w-full object-cover" src={env.baseUrl + '/images/about-team5.png'} alt='Software Tester' />
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="mb-3">
                      <p className="text-md mb-2 opacity-50 text-[#0A3361]">Software Tester</p>
                      <p className="text-heading-6 cutom-font-bold">Devon Lane</p>
                    </div>
                    <p className="text-excerpt text-gray-600 line-clamp-2 opacity-60 mb-6">Lorem ipsum dolor sit amet consectetur imp adipiscing elit justo</p>
                  </div>
                </div>
              </div>

              <div className='mb-[70px] md:mx-3 lg:mx-3'>
                <div>
                  <div className="mb-[30px]"> <img className="h-full w-full object-cover" src={env.baseUrl + '/images/about-team7.png'} alt='Software Developer' />
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="mb-3">
                      <p className="text-md mb-2 opacity-50 text-[#0A3361]">Software Developer</p>
                      <p className="text-heading-6 cutom-font-bold">Darrell Steward</p>
                    </div>
                    <p className="text-excerpt text-gray-600 line-clamp-2 opacity-60 mb-6">Lorem ipsum dolor sit amet consectetur imp adipiscing elit justo</p>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </section>

      <section className='FromOurBlog mt-[50px] lg:mt-[190px]  xl_mt-350'>
        <div className='md:container p-4 mx-auto mt-[50px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>From <span className='text-[#F26722]'>Our Blogs</span></h2>

          <p className='text-[24px] text-[#667085] lg:w-[50%] text-center mx-auto mt-[5px] font-semibold'>
            From Our blog and Event fanpage
          </p>

          <div className='grid lg:grid-cols-3 mt-[60px]'>
            <div className='md:px-4 px-0'>
              <Link to="/blog/we-can-blend-colors-multiple-ways" className="block group">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">We can blend colors multiple ways, the most common</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-We-can-blend-colors-multiple-ways.png'} alt="We can blend colors multiple-ways" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className='md:px-4 px-0'>
              <Link to="/blog/engineering-experiences-a-journey" className="block group">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Engineering Experiences: A Journey of Innovation and Creativity</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/Blog-Engineering-Experiences.png'} alt="Engineering Experiences" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#BEE1E6]"></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className='md:px-4 px-0'>
              <Link to="/blog/saas-products" className="block group">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Customer Services</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">SaaS Products</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-SaaS-Products.png'} alt="Saas Products" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className='mx-auto flex justify-center items-center'>
            <Link to="/Blog" className="py-[15px] lg:py-[20px] px-[20px] lg:px-[30px] bg-[#101828] text-white  mt-[30px] rounded-[50px] text-[18px] font-bold flex justify-center items-center hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg float-left duration-300">View More <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="text-[15px] ml-[13px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></Link>
          </div>
        </div>
      </section>

      {/* <section className='latestNew'>
        <div className='md:container p-4 mx-auto mt-[50px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Latest <span className='text-[#F26722]'>News</span></h2>

          <p className='text-[24px] text-[#667085] lg:w-[50%] text-center mx-auto mt-[5px] font-semibold'>
            From Our blog and Event fanpage
          </p>

          <div className='grid lg:grid-cols-3 mt-[40px]'>
            <div className='md:px-4 px-0'>
              <a className="block group" href="#">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-9 mt-3 cutom-font-bold leading-tight min-h-[60px]">We can blend colors multiple ways, the most common</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-img-1.png'} alt="Latest news" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className='md:px-4 px-0'>
              <a className="block group" href="#">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-9 mt-3 cutom-font-bold leading-tight min-h-[60px]">How To Blow Through Capital At An Incredible Rate</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-img-2.png'} alt="Latest news" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#BEE1E6]"></div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className='md:px-4 px-0'>
              <a className="block group" href="#">
                <div className="flex items-center mb-[11px]">
                  <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Customer Services</span>
                </div>
                <p className="text-[24px] text-[#101828] mb-9 mt-3 cutom-font-bold leading-tight min-h-[60px]">Design Studios That Everyone Should Know About?</p>
                <div className="relative mb-16">
                  <div className="relative">
                    <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-img-3.png'} alt="Latest news" />
                    </div>
                    <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                      <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className='mx-auto flex justify-center items-center'>
            <Link to="#" className="py-[15px] lg:py-[20px] px-[20px] lg:px-[30px] bg-[#101828] text-white  mt-[30px] rounded-[50px] text-[18px] font-bold flex justify-center items-center hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg float-left duration-300">View More <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="text-[15px] ml-[13px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></Link>
          </div>
        </div>
      </section> */}

      <Discovery
        title={'Discovery'}
        subTitle={'Call'}
        desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
        btnName={'Subscribe'}
        placeholder={'Drop a Email'} />
      <Faqs
        heading1={'What are some indicators that it is the right time to start Digital Transformation?'}
        content1={"Indicators that it's the right time for Digital Transformation include increasing customer demands for digital experiences, growing competition leveraging technology, stagnant growth, inefficient processes, and a lack of agility in responding to market changes. "}
        heading2={"How long does Digital Transformation take?"}
        content2={"The duration of Digital Transformation varies depending on the organisation's size, complexity, existing infrastructure, and goals. It can take anywhere from a couple of years to a decade to fully implement, but incremental changes and quick wins can be realised along the way. "}
        heading3={"How can I help my organisation succeed with Digital Transformation?"}
        content3={"To ensure success, focus on strong leadership support, employee engagement, clear communication, a well-defined strategy, collaboration across departments, continuous learning, and a willingness to adapt to changes. "}
        heading4={"Who are the stakeholders for Digital Transformation?"}
        content4={"Stakeholders for Digital Transformation include executives, employees, customers, investors, partners, and vendors. Each group has a unique perspective and role to play in the successful implementation of Digital Transformation. "}
        heading5={"Is Your Organisation Ready for Digital Transformation?"}
        content5={"Assess your organisation's readiness for Digital Transformation by evaluating its current technological capabilities, culture, leadership alignment, and willingness to embrace change. A readiness assessment can help you identify areas that need attention before embarking on the transformation journey. "}
        status={true}
      />
    </>
  )
}

export default About;