import React, { Suspense, useEffect } from 'react'
import Discovery from '../../../Components/DiscoveryCall/Discovery'
import ReactHelmet from '../../../Components/ReactHelmet'
import env from '../../../Config'


function EngineeringExperience() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Services | Engineering experience`}
                    keywords={`Engineering Experince`}
                    description={`Engineered Brilliance: Weaving Together Years of Insight, Ingenuity, and Mastery to Create Transformative Solutions that Propel Industries Forward`}
                    seoimgs={`Engineering-experince.png`}
                />
            </Suspense>
            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/Engineering-experince.png)` }}>
                <div className='lg:container mx-auto px-4'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
                        <div className="flex-1 md:py-[70px] lg:py-[100px]">
                            <h2 className="text-[2rem] lg:text-[82px] text-[#222] md:text-[#fff] md:w-[50%] lg:w-[40%]  leading-tight font-bold text-center md:text-left">
                                Engineering Experience 
                            </h2>
                            <p className="text-[18px] lg:text-[24px] text-[#333] md:text-[#B5BDCC] font-semibold mt-5 md:w-[50%] lg:w-[40%] text-center md:text-left">
                                Engineered Brilliance: Weaving Together Years of Insight, Ingenuity, and Mastery to Create Transformative Solutions that Propel Industries Forward
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <section className='ourEngineerings'>
                <div className="lg:container mx-auto px-4 my-[40px] md:my-[70px] lg:mt-[100px]">
                    <div className="text-center">
                        <h2 className="text-[2rem] lg:text-[56px] leading-tight font-bold">Engineering <span className="text-[#f26722]"> Experience </span></h2>
                        <p className="text-[20px] md:text-[24px] text-[#475467] font-bold lg:w-[60%] mx-auto mt-4">
                            Forging a Trail to Innovation: Channeling a Legacy of Engineering Expertise to Sculpt Solutions that Define Future Possibilities.</p>
                    </div>
                    <div className='clear-both'></div>
                    <div className='xl:px-0 mt-[70px] lg:mt-[80px]'>
                        <div className='lg:grid lg:grid-cols-2 lg:gap-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Website-development.png'} alt='Website & CSM Development'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Website & CSM Development</h2>
                                <p className='base-p1'>
                                    Successful website and CMS development involves a combination of technical expertise, creative design, user-focused strategies, and continuous optimisation.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[50px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Strategic Planning</h5>
                                            <div className='tooltip_box'>Define the project scope, objectives, and target audience to lay the foundation for effective development</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User-Centric Design</p>
                                            <div className='tooltip_box'>Create intuitive user interfaces and seamless user experiences to enhance visitor engagement.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Responsive Design</h5>
                                            <div className='tooltip_box'>Ensure websites are accessible and functional across various devices, optimising for mobile and desktop experiences.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Content Management System (CMS)</h5>
                                            <div className='tooltip_box'> Implement a robust CMS to enable easy content creation, editing, and publishing.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Customisation</h5>
                                            <div className='tooltip_box'> Tailor websites to reflect brand identity and business goals, maintaining consistency in design and messaging.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Search Engine Optimisation (SEO)</h5>
                                            <div className='tooltip_box'> Incorporate SEO best practices during development to improve search engine visibility and organic traffic.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Performance Optimisation</h5>
                                            <div className='tooltip_box'> Optimise website speed and performance for faster loading times and a smoother user experience.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Security Measures</h5>
                                            <div className='tooltip_box'> Implement robust security protocols to safeguard against cyber threats and protect user data.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Integration Capabilities</h5>
                                            <div className='tooltip_box'> Integrate third-party tools, plugins, and APIs to enhance functionality and streamline workflows.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scalability</h5>
                                            <div className='tooltip_box'> Develop websites with scalability in mind, allowing for seamless growth and expansion as business needs evolve.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Software Products & Digital Engineering</h2>
                                <p className='base-p1'>
                                    Software products and digital engineering require a blend of innovation, technical expertise, user-centric design, and a deep understanding of business needs and industry trends.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[70px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Strategic Ideation </h5>
                                            <div className='tooltip_box'>Generate innovative ideas for software products, considering market demands and technological trends.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Customisation </h5>
                                            <div className='tooltip_box'>Tailor software solutions to address specific business needs, enhancing efficiency and effectiveness.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Agile Development</h5>
                                            <div className='tooltip_box'>Embrace agile methodologies to create flexible software that can adapt to evolving requirements.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User-Centred Design</h5>
                                            <div className='tooltip_box'>Prioritise user experience, employing intuitive interfaces and user-friendly interactions.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Integration and Deployment (CI/CD)</h5>
                                            <div className='tooltip_box'>Develop software with a solid foundation to ensure scalability, stability, and future enhancements.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Advanced Technologies</h5>
                                            <div className='tooltip_box'>Incorporate cutting-edge technologies such as AI, IoT, and blockchain to create impactful solutions.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Quality Assurance</h5>
                                            <div className='tooltip_box'>Rigorous testing and quality assurance processes to ensure software products are reliable and bug-free.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Integration and Deployment (CI/CD)</h5>
                                            <div className='tooltip_box'>Implement CI/CD pipelines for automated testing and seamless updates.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Security Protocols</h5>
                                            <div className='tooltip_box'>Integrate robust security measures to safeguard data and protect against cyber threats.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Digital Transformation</h5>
                                            <div className='tooltip_box'>Leverage digital engineering principles to transform processes and systems for enhanced outcomes.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Software-Products-Digital-Engineering.png'} alt='Software Products & Digital Engineering'/>
                            </div>
                        </div>


                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/User-experience-practise.png'} alt='User Experience Practise'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">User Experience Practise</h2>
                                <p className='base-p1'>
                                    User experience practice involves a deep understanding of user needs, iterative design, and constant refinement to create products that are both functional and delightful to use.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[70px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User Research</h5>
                                            <div className='tooltip_box'>Conduct in-depth research to understand user needs, behaviors, and preferences.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Persona Development</h5>
                                            <div className='tooltip_box'>Create detailed user personas to guide design decisions and tailor experiences.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User Journey Mapping</h5>
                                            <div className='tooltip_box'>Visualize user interactions across touchpoints to identify pain points and optimization opportunities.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Wireframing and Prototyping</h5>
                                            <div className='tooltip_box'>Develop wireframes and interactive prototypes to test and refine user interactions.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Usability Testing</h5>
                                            <div className='tooltip_box'>Conduct user testing to gather feedback and validate design decisions.</div>
                                        </div>
                                    </div>


                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Responsive Design</h5>
                                            <div className='tooltip_box'>Ensure consistent and user-friendly experiences across various devices and screen sizes.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Accessibility</h5>
                                            <div className='tooltip_box'>Implement inclusive design principles to make products usable by people of all abilities.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Visual Design</h5>
                                            <div className='tooltip_box'>Craft visually appealing interfaces that align with brand identity and user preferences.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Information Architecture</h5>
                                            <div className='tooltip_box'>Architecture: Organize content and navigation to facilitate intuitive user interactions.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Improvement</h5>
                                            <div className='tooltip_box'>Iterate on designs based on user feedback and data analysis to optimize user experiences over time.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='clear-both'></div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">DevOps & Continuous Engineering</h2>
                                <p className='base-p1'>
                                    DevOps and Continuous Engineering methodologies aim to enhance collaboration, automate processes, and ensure the delivery of high-quality software in a streamlined and efficient manner.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[70px]'>
                                    <div className='mb-[10px] lg:mb-0 '>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Collaborative Culture</h5>
                                            <div className='tooltip_box'>Foster a collaborative environment where development, operations, and quality assurance teams work together seamlessly.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Integration (CI) </h5>
                                            <div className='tooltip_box'>Automate code integration to detect and address issues early in the development cycle.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Delivery (CD)</h5>
                                            <div className='tooltip_box'>Automate deployment processes to ensure that code changes are ready for production at any given time.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Infrastructure as Code (IaC)</h5>
                                            <div className='tooltip_box'>Manage infrastructure through code, ensuring consistency and reproducibility.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Automated Testing</h5>
                                            <div className='tooltip_box'>Implement automated testing throughout the development process to maintain code quality.</div>
                                        </div>
                                    </div>


                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Version Control</h5>
                                            <div className='tooltip_box'>Utilise version control systems to track changes and collaborate effectively.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Monitoring and Feedback</h5>
                                            <div className='tooltip_box'>Implement monitoring tools to collect real-time data, enabling swift feedback and issue resolution.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Micro-services Architecture</h5>
                                            <div className='tooltip_box'>Design applications as independent micro-services for scalability and flexibility.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Release Orchestration</h5>
                                            <div className='tooltip_box'>Plan, coordinate, and manage complex releases to ensure smooth deployment.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Improvement</h5>
                                            <div className='tooltip_box'>Implement feedback loops, analyse data, and continuously refine processes for ongoing optimisation.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2'>
                                <img className='max-w-full' src={env.baseUrl + '/images/DevOp-continuous-engineering.png'} alt='DevOps & Continuous Engineering'/>
                            </div>

                        </div>

                        <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[70px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/UX-Design-UI-Engineering.png'} alt='UX Design / UI Engineering'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">UX Design / UI Engineering</h2>
                                <p className='base-p1'>
                                    UX Design and UI Engineering play critical roles in creating interfaces that are user-friendly, visually appealing, and functional, enhancing the overall user experience.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[70px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User-Centric Approach</h5>
                                            <div className='tooltip_box'>Prioritise the needs, preferences, and behaviours of users when designing interfaces.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Research and Analysis</h5>
                                            <div className='tooltip_box'>Conduct thorough research to understand user expectations and industry trends.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Persona Development</h5>
                                            <div className='tooltip_box'>Create detailed user personas to guide design decisions and tailor experiences.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Information Architecture</h5>
                                            <div className='tooltip_box'>Organise content and navigation to ensure intuitive and efficient user interactions.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Wire framing and Prototyping</h5>
                                            <div className='tooltip_box'>Develop wireframes and interactive prototypes to visualise and test design concepts.</div>
                                        </div>
                                    </div>


                                    <div className='mb-[30px] lg:mb-0'>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Visual Design</h5>
                                            <div className='tooltip_box'>Apply design principles to create visually appealing and consistent interfaces.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Responsive Design</h5>
                                            <div className='tooltip_box'>Ensure interfaces adapt seamlessly across various devices and screen sizes.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Interaction Design</h5>
                                            <div className='tooltip_box'>Design intuitive and engaging user interactions to enhance usability.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Usability Testing</h5>
                                            <div className='tooltip_box'>Conduct usability testing to gather feedback and refine design choices.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Code Implementation</h5>
                                            <div className='tooltip_box'>Translate design concepts into functional user interfaces, focusing on performance and compatibility.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Discovery
                title={'Discovery '}
                subTitle={'Call'}
                desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
                btnName={'Subscribe'}
                placeholder={'Drop a Email'} />
        </>
    )
}

export default EngineeringExperience