import React from 'react'
import { useEffect, Suspense } from 'react'
import Discovery from '../../../Components/DiscoveryCall/Discovery'
import ReactHelmet from '../../../Components/ReactHelmet'
import env from '../../../Config'

const DigitalMigration = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Services | Digital migration`}
                    keywords={`Digital migration`}
                    description={`Experience the Power of Seamlessness: Digitally Defined Transitions that Empower Your Evolution through Expert Digital Migration Services`}
                    seoimgs={`Digital-migration.png`}
                />
            </Suspense>

            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/Digital-migration.png)` }}>
                <div className='lg:container mx-auto px-4'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
                        <div className="flex-1 md:py-[70px] lg:py-[100px]">
                            <h2 className="text-[2rem] lg:text-[82px] text-[#222] md:text-[#fff] md:w-[50%] lg:w-[40%]  leading-tight font-bold text-center md:text-left">
                                Digital Migration
                            </h2>
                            <p className="text-[18px] lg:text-[24px] text-[#333] md:text-[#B5BDCC] font-semibold mt-5 md:w-[50%] lg:w-[40%] text-center md:text-left">
                                Experience the Power of Seamlessness: Digitally Defined Transitions that Empower Your Evolution through Expert Digital Migration Services
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <section className='ourEngineerings'>
                <div className="lg:container mx-auto px-4 my-[40px] md:my-[70px] lg:mt-[100px]">
                    <div className="text-center">
                        <h2 className="text-[2rem] lg:text-[56px] leading-tight font-bold">Digital <span className="text-[#f26722]"> Migration </span></h2>
                        <p className="text-[20px] md:text-[24px] text-[#475467] font-bold lg:w-[60%] mx-auto mt-4">
                            Digital Metamorphosis Unveiled: Navigating Migration Terrain with Confidence and Clarity for a Transformed Future.</p>
                    </div>

                    <div className='xl:px-0 mt-[70px] lg:mt-[80px]'>
                        <div className='lg:grid lg:grid-cols-2 lg:gap-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/App-modernisation.png'} alt='App Modernisation'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">App Modernisation</h2>
                                <p className='base-p1'>
                                    App modernisation is a crucial strategy for organisations aiming to leverage their existing software investments while ensuring they remain competitive and responsive to the ever-evolving digital landscape
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Definition and Context</h5>
                                            <div className='tooltip_box'>App modernisation refers to the process of updating, enhancing, or transforming existing software applications to meet current technological and business requirements while preserving their core functionality and value.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Legacy to Contemporary</p>
                                            <div className='tooltip_box'>It involves migrating applications from older, often monolithic architectures to more modern, scalable, and flexible ones, such as micro-services, containers, and serverless computing.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Improved User Experience</h5>
                                            <div className='tooltip_box'>App modernisation aims to enhance user experiences by incorporating modern design principles, responsive interfaces, and improved performance, making applications more user-friendly and efficient.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scalability and Performance</h5>
                                            <div className='tooltip_box'>By adopting cloud-native technologies and architectures, app modernisation allows applications to scale easily based on demand, ensuring optimal performance even during peak usage periods.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Cost Efficiency</h5>
                                            <div className='tooltip_box'>Modernising applications can lead to cost savings by optimizing resource utilisation, automating processes, and reducing maintenance overheads associated with outdated technology stacks.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Security Enhancement</h5>
                                            <div className='tooltip_box'>Legacy applications can have security vulnerabilities due to outdated libraries and frameworks. Modernisation provides an opportunity to address these issues and implement robust security practices.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Integration Capabilities</h5>
                                            <div className='tooltip_box'>Modernised apps are designed to seamlessly integrate with other software systems, APIs, and third-party services, enabling better collaboration and data exchange between different components of an IT ecosystem.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Agility and Innovation</h5>
                                            <div className='tooltip_box'>App modernisation enables organisations to iterate quickly and introduce new features, as well as adapt to changing market trends, customer preferences, and business needs.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data-Driven Insights</h5>
                                            <div className='tooltip_box'>Modern applications often incorporate analytics and data processing capabilities, allowing organisations to derive meaningful insights from the data they collect, leading to informed decision-making.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Future-Proofing</h5>
                                            <div className='tooltip_box'>Through app modernisation, businesses can future-proof their software assets by adopting technologies and architectures that are adaptable to ongoing technological advancements, avoiding the risk of becoming technologically obsolete.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Automation Development</h2>
                                <p className='base-p1'>
                                    Automation development spans various domains, from simple script automation to complex orchestration of workflows. It empowers organisations to operate more efficiently, reduce operational overheads, and adapt to the dynamic demands of modern business environments
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Definition and Scope</h5>
                                            <div className='tooltip_box'>Automation development involves designing and creating systems, scripts, or workflows that enable the automatic execution of tasks or processes that were previously done manually.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Efficiency and Productivity</h5>
                                            <div className='tooltip_box'>Automation development aims to improve efficiency by reducing human intervention in repetitive or time-consuming tasks, leading to increased productivity and resource optimisation.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Integration and Interoperability</h5>
                                            <div className='tooltip_box'>Automation can bridge gaps between different systems and software applications by enabling seamless data exchange and communication through APIs and integrations.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Enhanced Decision-Making</h5>
                                            <div className='tooltip_box'>Automation development can provide real-time data and insights, enabling organisations to make informed decisions quickly based on accurate information.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Innovation Enablement</h5>
                                            <div className='tooltip_box'>With routine tasks automated, employees can focus on more creative and strategic aspects of their roles, fostering a culture of innovation within the organisation. Automation development spans various domains, from simple script automation to complex orchestration of workflows. It empowers organisations to operate more efficiently, reduce operational overheads, and adapt to the dynamic demands of modern business environments. Was this response better or worse? BetterWorseSame</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Time and Cost Savings</h5>
                                            <div className='tooltip_box'>Automated processes often operate faster than manual ones, leading to time savings. Additionally, reduced manual labor can result in cost savings and resource allocation for more value-added activities.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scalability and Consistency</h5>
                                            <div className='tooltip_box'>Automation solutions can be scaled easily to accommodate increased workloads, ensuring consistent performance even as demands grow.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Customised Solutions</h5>
                                            <div className='tooltip_box'>Automation solutions can be tailored to the specific needs of an organisation, allowing for the creation of workflows that align with unique business processes and requirements.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Process Streamlining</h5>
                                            <div className='tooltip_box'>By automating workflows, organisations can streamline complex processes, minimise errors, and maintain consistency in the execution of tasks.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Reduced Errors</h5>
                                            <div className='tooltip_box'>Automation development can significantly reduce the risk of human errors, ensuring accuracy and reliability in tasks such as data entry, calculations, and information processing.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Automation-development.png'} alt='Automation Development'/>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Data-assurance.png'} alt='Data Assurance'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Data Assurance</h2>
                                <p className='base-p1'>
                                    Data assurance is essential for organisations aiming to leverage data-driven insights while ensuring the reliability and accuracy of the information they use. It involves a combination of technological tools, processes, and best practices to safeguard data assets and maintain their value in an ever-changing digital landscape.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[50px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Time and Cost Savings</h5>
                                            <div className='tooltip_box'>Automated processes often operate faster than manual ones, leading to time savings. Additionally, reduced manual labor can result in cost savings and resource allocation for more value-added activities.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Device Agnosticism</p>
                                            <div className='tooltip_box'>Scalability and Consistency: Automation solutions can be scaled easily to accommodate increased workloads, ensuring consistent performance even as demands grow.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Integration and Interoperability</h5>
                                            <div className='tooltip_box'>Automation can bridge gaps between different systems and software applications by enabling seamless data exchange and communication through APIs and integrations.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Enhanced Decision-Making</h5>
                                            <div className='tooltip_box'>Automation development can provide real-time data and insights, enabling organisations to make informed decisions quickly based on accurate information.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Innovation Enablement</h5>
                                            <div className='tooltip_box'>With routine tasks automated, employees can focus on more creative and strategic aspects of their roles, fostering a culture of innovation within the organisation.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Definition and Scope</h5>
                                            <div className='tooltip_box'>Automation development involves designing and creating systems, scripts, or workflows that enable the automatic execution of tasks or processes that were previously done manually.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Efficiency and Productivity</h5>
                                            <div className='tooltip_box'>Automation development aims to improve efficiency by reducing human intervention in repetitive or time-consuming tasks, leading to increased productivity and resource optimisation.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Customised Solutions</h5>
                                            <div className='tooltip_box'>Automation solutions can be tailored to the specific needs of an organisation, allowing for the creation of workflows that align with unique business processes and requirements.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Process Streamlining</h5>
                                            <div className='tooltip_box'>By automating workflows, organisations can streamline complex processes, minimise errors, and maintain consistency in the execution of tasks.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Reduced Errors</h5>
                                            <div className='tooltip_box'>Automation development can significantly reduce the risk of human errors, ensuring accuracy and reliability in tasks such as data entry, calculations, and information processing.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">ERP Development</h2>
                                <p className='base-p1'>
                                    ERP Development is a strategic initiative that involves creating tailored software solutions to integrate and optimise an organisations various functions. It offers benefits such as streamlined processes, improved collaboration, data centralisation, and real-time insights, but it also comes with challenges that require careful navigation for successful implementation.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Definition of ERP Development</h5>
                                            <div className='tooltip_box'>Enterprise Resource Planning (ERP) Development refers to the process of designing, creating, customising, and implementing software solutions that integrate various business functions and processes into a unified system to streamline operations and enhance efficiency.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Comprehensive Business Solution</h5>
                                            <div className='tooltip_box'>ERP systems are designed to cover a wide range of business operations, including finance, human resources, supply chain, manufacturing, sales, and customer relationship management, providing a holistic approach to managing an organisations resources.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Customisation and Integration</h5>
                                            <div className='tooltip_box'>ERP Development involves tailoring the software to meet the specific needs of the organisation. This often requires integrating the ERP solution with existing systems and databases to ensure seamless data flow across departments.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Core Modules</h5>
                                            <div className='tooltip_box'>ERP systems consist of core modules that address different aspects of business processes, such as financial management, inventory control, procurement, production planning, and reporting. These modules can be configured and extended to suit the organisations requirements.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Centralisation</h5>
                                            <div className='tooltip_box'>One of the key benefits of ERP systems is the centralisation of data. All relevant information is stored in a single database, reducing data duplication, ensuring data consistency, and facilitating accurate reporting.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Cross-Departmental Collaboration</h5>
                                            <div className='tooltip_box'>ERP Development encourages collaboration and data sharing among various departments, breaking down silos of information and enabling better communication and decision-making across the organisation.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Enhanced Efficiency</h5>
                                            <div className='tooltip_box'>ERP Development aims to automate repetitive tasks, eliminate manual data entry, and optimise workflows. This leads to increased operational efficiency, reduced errors, and faster processing times.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Real-time Insights</h5>
                                            <div className='tooltip_box'>ERP systems provide real-time insights into various aspects of the business, enabling managers and decision-makers to monitor key performance indicators, track trends, and make informed decisions based on up-to-date information.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scalability and Flexibility</h5>
                                            <div className='tooltip_box'>As businesses grow and evolve, their requirements change. ERP systems are designed to be scalable and flexible, allowing organisations to add new modules, functionalities, or users as needed.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Challenges in ERP Development</h5>
                                            <div className='tooltip_box'>Developing an ERP system can be complex and challenging. It requires a deep understanding of the organisations processes, careful planning, skilled development teams, and effective change management strategies to ensure a successful implementation and adoption by users.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                                <img className='max-w-full' src={env.baseUrl + '/images/ERP-development.png'} alt='ERP Development'/>
                            </div>
                        </div>

                        <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/CRM-development.png'} alt='CRM Development'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">CRM Development</h2>
                                <p className='base-p1'>
                                    CRM Development involves creating software systems that empower businesses to manage customer relationships effectively. These systems provide tools for contact management, sales automation, marketing campaign management, customer service enhancement, and analytics, all of which contribute to improved customer engagement, loyalty, and business growth.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[50px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Definition of CRM Development</h5>
                                            <div className='tooltip_box'>Customer Relationship Management (CRM) Development refers to the creation and customisation of software systems that enable businesses to manage and optimise interactions with customers, prospects, and partners across various touchpoint.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> 360-Degree Customer View</p>
                                            <div className='tooltip_box'>CRM systems provide a comprehensive view of each customer by centralising their interactions, purchase history, preferences, and communication history. This enables better understanding and personalised engagement.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Contact Management</h5>
                                            <div className='tooltip_box'>CRM development involves creating a database to store customer contact information, allowing easy access to details like names, addresses, phone numbers, and email addresses.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Sales Automation</h5>
                                            <div className='tooltip_box'>CRM systems streamline sales processes by automating tasks such as lead generation, opportunity tracking, sales forecasting, and quotation management. This enhances sales team efficiency.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Marketing Campaign Management</h5>
                                            <div className='tooltip_box'>CRM platforms enable businesses to plan, execute, and track marketing campaigns. They provide tools for segmenting customer lists, sending targeted communications, and measuring campaign effectiveness.</div>
                                        </div>
                                    </div>
                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Customer Service Enhancement</h5>
                                            <div className='tooltip_box'>CRM systems include features for tracking customer inquiries, complaints, and support requests. This ensures timely and effective customer service through issue resolution and follow-up.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Analytics and Reporting</h5>
                                            <div className='tooltip_box'>CRM development integrates data analytics capabilities to generate insights from customer interactions. This assists in making informed decisions, identifying trends, and evaluating performance.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Mobile Accessibility</h5>
                                            <div className='tooltip_box'>CRM systems are often developed with mobile compatibility, enabling sales teams and customer service representatives to access and update customer information on the go.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Customisation and Scalability</h5>
                                            <div className='tooltip_box'>CRM development offers customisation options to tailor the software to the unique needs of the business. Additionally, CRM solutions can be scaled to accommodate growing customer bases and evolving business requirements.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Integration with Communication Channels</h5>
                                            <div className='tooltip_box'>Modern CRM systems connect with various communication channels, such as email, social media, and chat, allowing businesses to engage customers across their preferred platforms.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Discovery
                title={'Discovery '}
                subTitle={'Call'}
                desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
                btnName={'Subscribe'}
                placeholder={'Drop a Email'} />
        </>
    )
}

export default DigitalMigration