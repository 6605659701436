import React, { useEffect, useState, lazy, Suspense } from 'react';
import './Styles.scss';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';


import countryCode from './../../Assets/jsonData/country.json';
import ReactHelmet from '../../Components/ReactHelmet';
import env from '../../Config';

const ContactUs = () => {
    let [isOpen2, setIsOpen2] = useState(false)
    let [errorMsg, setErrorMsg] = useState('')

    function closeModal2() {
        setIsOpen2(false)
    }
    function openModal2() {
        setIsOpen2(true)
    }
    function onSubmit2() {
        setIsOpen2(false)
    }

    const [data, setData] = useState({
        name: '',
        email: '',
        phoneNo: '',
        companyName: '',
        countryCode: '44',
        message: '',
        capcha: '',
        emailValid: false,
        phoneNoValid: false,
        isFormTouched: false,
        isInvalidCapcha: false,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        loadCaptchaEnginge(6);
    }, []);

    const contactUsFun = () => {

        if (data.emailValid && data.phoneNoValid && data.countryCode && data.companyName && data.name && data.phoneNo && data.message) {
            if (validateCaptcha(data.capcha) == true) {
                const obj = {
                    "properties": [
                        { "property": "email", "value": data.email },
                        { "property": "fullName", "value": data.name },
                        { "property": "firstname", "value": data.name },
                        { "property": "country_code", "value": data.countryCode },
                        { "property": "phone", "value": data.phoneNo },
                        { "property": "company", "value": data.companyName },
                        { "property": "message", "value": data.message },
                    ]
                };

                const url = 'https://nodweb.versatilecommerce.com/contactus'; // Replace with your API endpoint
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(obj),
                }).then(response => response.json())
                    .then(result => {
                        // Do something with the updated data
                        if (result && result?.body?.status != "error" && result?.body && result?.body?.category != 'OBJECT_ALREADY_EXISTS') {
                            setData({
                                name: '',
                                email: '',
                                phoneNo: '',
                                companyName: '',
                                message: '',
                                capcha: '',
                                emailValid: false,
                                phoneNoValid: false,
                                isFormTouched: false,
                                isInvalidCapcha: false,
                                countryCode: '44'
                            });
                            openModal2();
                        } else {
                            setData({
                                ...data,
                                capcha: '',
                                isFormTouched: false,
                                isInvalidCapcha: false,
                            });
                            setErrorMsg(result?.body?.message);
                        }
                    })
                    .catch(error => {
                        // Handle error
                    });
            } else {
                setData({ ...data, isInvalidCapcha: true });
            }

        } else {
            setData({ ...data, isFormTouched: true });
        }

    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }




    return (
        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Contact us`}
                    keywords={`Contact Us`}
                    description={`We supply enterprises, organizations and institutes of high-tech industries with modern components. We build long-term trusting relationships with our customers and partnes for further fruitful cooperations.`}
                    seoimgs={`contact-banner.png`}
                />
            </Suspense>
            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/contact-banner.png)` }}>
                <div className='lg:container mx-auto px-4'>
                    <div className="md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%]">
                        <div className="lg:my-[8rem] xl:my-[10rem]">
                            <h2 className="base-h1">
                                <span className="text-[#f26722]"> Contact</span> Us
                            </h2>
                            <p className="base-p1 md:w-[50%] lg:w-[50%]">
                                We supply enterprises, organizations and institutes of high-tech industries with modern components. We build long-term trusting relationships with our customers and partnes for further fruitful cooperations.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='lg:container mx-auto px-4'>
                <div className="text-center my-[50px] lg:my-[100px]">
                    <h2 className='md:text-[56px] text-[40px] mx-auto font-bold'>Our <span className='text-[#F26722]'>Locations</span></h2>
                    {/* <h5 className='text-[24px] text-gray-600 md:w-[58%] mx-auto font-semibold'>In a professional context it often happens that private or corporate clients order a publication to publish news.</h5> */}
                </div>

                <div className="justify-between lg:flex lg:items-center mx-auto lg:pb-[50px]">
                    <div className="gap-[15px] md:gap-[30px] py-[30px] lg:pb-[50px] lg:border-r-2 text-center md:w-[50%] md:px-5 md:float-left">
                        {/* <img  src={env.baseUrl + '/images/career-job-icon1.png'} /> */}
                        <div className="lg:w-[90%]">
                            <p className="cutom-font-bold-200  text-[#101828] text-[28px]  lg:text-[28px] md:text-heading-6 mb-1 md:mb-[10px] ">United Kingdom
                            </p>
                            <div className="text-[16px] text-[#7E7E7E] text-[16px] mt-[20px] font-bold lg:w-[70%] mx-auto">
                                <p>100 Avebury Blvd, Milton Keynes  <br />MK9 1FH, United Kingdom</p>
                            </div>
                            <br />
                            <p className='cutom-font-bold-200 text-[#f26722]'><i className="las la-phone"></i> +44 333 006 2294</p>
                        </div>
                    </div>
                    <div className="gap-[15px] md:gap-[30px] py-[30px] lg:pb-[50px] lg:border-r-2 text-center  md:w-[50%] md:px-5 md:float-left">
                        {/* <img  src={env.baseUrl + '/images/career-job-icon1.png'} /> */}
                        <div className="lg:w-[90%]">
                            <p className="cutom-font-bold-200  text-[#101828] text-[28px]  lg:text-[28px] md:text-heading-6 mb-1 md:mb-[10px] ">USA
                            </p>
                            <div className="text-[16px] text-[#7E7E7E] text-[16px] mt-[20px] font-bold  lg:w-[70%] mx-auto">
                                <p>95 Third Street, 2nd Floor, San Francisco, California 94103</p>
                            </div>
                            <br />
                            <p className='cutom-font-bold-200 text-[#f26722]'><i className="las la-phone"></i> +1 628 228 6091</p>
                        </div>
                    </div>
                    <div className="gap-[15px] md:gap-[30px] py-[30px] lg:pb-[50px] text-center md:w-[50%] md:px-5 md:float-left">
                        {/* <img  src={env.baseUrl + '/images/career-job-icon1.png'} /> */}
                        <div className="lg:w-[90%]">
                            <p className="cutom-font-bold-200  text-[#101828] text-[28px]  lg:text-[28px] md:text-heading-6 mb-1 md:mb-[10px] ">India
                            </p>
                            <div className="text-[16px] text-[#7E7E7E] text-[16px] mt-[20px] font-bold  ">
                                <p>Krishe'Sapphire, 1st floor, Hitechcity,  Hyderabad, Telangana - 500081.</p>
                            </div>
                            <br />
                            <p className='cutom-font-bold-200 text-[#f26722]'><i className="las la-phone"></i> +91 91142 81142</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='clear-both'></div><br />
            <section className="eqry-form my-[50px] lg:mb-[130px] lg:mt-[150px]">
                <div className="lg:container mx-auto px-4">
                    <div className="bg-gray-100 relative p-[20px] md:pt-[91px] md:pr-[98px] md:pb-[86px] md:pl-[92px]  rounded-[16px] mt-[2rem] lg:mt-[-7rem]">
                        <p className="base-p4 uppercase">Contact us</p>
                        <h4 className='text-[30px] lg:text-[35px] cutom-font-bold'>Have a project in mind?</h4>
                        <div className="base-p3 mb-16 font-bold">The right move at the right time saves your investment. Live the dream of expanding your business.</div>

                        {/* form start */}
                        <form className="flex-1" action="/">
                            <div className="flex flex-col gap-6 mb-6 lg:flex-row xl:gap-[30px]">

                                <img className='max-w-full ml-5 ml-[auto] absolute right-[50px] top-[50px] hidden lg:block' src={env.baseUrl + '/images/wave-top.png'} />

                                <input className={`outline-none flex-1 placeholder:text-gray-400 placeholder:text-md py-5 px-[30px] ${(data.isFormTouched && !data.name) ? 'inValidInput' : ''}`} type="text" placeholder="Enter your name" value={data.name}
                                    onChange={e => setData({ ...data, isFormTouched: true, name: e.target.value })} />

                                <input className={`outline-none flex-1 placeholder:text-gray-400 placeholder:text-md py-5 px-[30px] ${(data.isFormTouched && !data.companyName) ? 'inValidInput' : ''}`} type="text" placeholder="Company" value={data.companyName}
                                    onChange={e => setData({ ...data, isFormTouched: true, companyName: e.target.value })} />
                            </div>
                            <div className="flex flex-col gap-6 mb-6 lg:flex-row xl:gap-[30px]">
                                <input className={`outline-none lg:w-[49%] placeholder:text-gray-400 placeholder:text-md py-5 px-[30px] ${(data.isFormTouched && !data.emailValid) ? 'inValidInput' : ''}`} type="text" placeholder="Your email" value={data.email}
                                    onChange={e => {
                                        let emailValid = false;
                                        if (validateEmail(e.target.value)) {
                                            emailValid = true;
                                        }
                                        setData({ ...data, isFormTouched: true, email: e.target.value, emailValid: emailValid });
                                    }}
                                />

                                <select onChange={(e) => {
                                    setData({ ...data, isFormTouched: true, countryCode: e.target.value });
                                }} className={`outline-none  placeholder:text-gray-400 placeholder:text-md py-5 px-[10px] text-left lg:w-[12%] text-[#667085] ${(data.isFormTouched && !data.countryCode) ? 'inValidInput' : ''}`}>
                                    <Suspense>
                                        <option value={""}>Select Country</option>
                                        {(countryCode && countryCode.length > 0) ? (
                                            countryCode.map(res => <option key={res.c} selected={res.d == '44' ? true : false} value={`+${res.d}`}>{res.c} ({`+${res.d}`})</option>)
                                        ) : null}
                                    </Suspense>
                                </select>
                                <input className={`outline-none placeholder:text-gray-400 placeholder:text-md py-5 px-[30px]  lg:w-[34%] ${(data.isFormTouched && !data.phoneNoValid) ? 'inValidInput' : ''}`} type="text" maxLength={12} minLength={10} placeholder="Phone number" value={data.phoneNo}
                                    onChange={e => {
                                        let phoneNoValid = false;
                                        if (e.target.value.length > 9 && e.target.value.length < 13) {
                                            phoneNoValid = true;
                                        }
                                        setData({ ...data, isFormTouched: true, phoneNo: e.target.value, phoneNoValid: phoneNoValid })
                                        console.log(e.target.value, e.target.value.length);
                                    }}
                                />
                            </div>
                            <textarea className={`w-full py-5 resize-none outline-0 px-[30px] max-h-[150px] mb-[35px] md:mb-[56px] ${(data.isFormTouched && !data.message) ? 'inValidInput' : ''}`} name="" cols="100" rows="10" placeholder="Tell us about yourself" value={data.message}
                                onChange={e => setData({ ...data, isFormTouched: true, message: e.target.value })}></textarea>

                            {data.isFormTouched && (data.emailValid == false || data.phoneNoValid == false || data.name == '' || data.phoneNo == '' || data.message == '') ?
                                (<div className='lg:flex justify-left items-center text-[#f44336] font-[600]'>Please Fill All Required Fields.
                                </div>) : null
                            }

                            {data.errorMsg != "" ?
                                (<div className='lg:flex justify-left items-center text-[#f44336] font-[600]'>
                                    {errorMsg}
                                </div>) : null
                            }
                            <div className='text-right leading-3 text-[#667085] text-[14px]'>
                                <p className='md:mt-[-40px]'>By clicking contact us button, you agree our <a href='/' className='hover:text-gray-900'> terms and policy,</a>
                                </p>
                            </div>


                            <div className='md:flex justify-end items-end mt-4'>
                                <div className='Captcha mr-10'>
                                    <div className='captcha-inlines'>
                                        <div className='Captcha-charectr font-bold tracking-widest'>
                                        </div>
                                        <div className='Captcha-reload font-bold relative '><LoadCanvasTemplate reloadColor="#f36969" reloadText={'&#x21bb;'} /></div>
                                    </div>
                                    <div className='clear-both'></div>

                                    {data.isInvalidCapcha == true && (<div className='text-[red] text-[14px] mt-3'>Invalid Captcha</div>)}
                                    <input value={data.capcha} className={`outline-none flex-1 placeholder:text-gray-400 placeholder:text-md py-3 px-[20px] border-2 tracking-widest ${(data.isFormTouched && !data.capcha) ? 'inValidInput' : ''}`} type="text" placeholder="Enter Captcha Here"
                                        onChange={e => setData({ ...data, isFormTouched: true, capcha: e.target.value })}
                                    />
                                    <div className='clear-both'></div>
                                </div>
                                <div>
                                    <button onClick={contactUsFun} type='button' className=" py-[20px] px-[30px] min-w-[180px] bg-[#101828] text-white  mt-[34px] rounded-[50px] text-[18px] font-bold flex justify-center items-center hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg float-left duration-300 inline-block">Send Message <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="text-[15px] ml-[13px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></button>
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </form>
                        {/* form end */}
                    </div>
                </div>
            </section>


            {/* suceess dialog start 2*/}

            <Transition appear show={isOpen2} as={Fragment}>
                <Dialog as="div" className="relative z-[9999] tex-[red] bg-[blue]" onClose={closeModal2} >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full lg:max-w-[50%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                    <div className="">
                                        <section className="eqry-form">
                                            <div className="lg:container mx-auto">
                                                <div className="bg-gray-100 relative p-[40px] md:py-[20px] md:px-[40px] rounded-[16px]">
                                                    <button className='absolute  right-3 md:right-10' onClick={closeModal2}><i className="las la-times text-[20px] text-[#ffa2a2] bg-white rounded-[50px] px-3 py-3 font-bold hover:text-[red] flex justify-center items-center"></i></button>
                                                    {/* <p className="base-p4 uppercase">Contact us</p> */}



                                                    {/* form start */}
                                                    <div className='text-center my-5'>
                                                        <i className="las la-check-circle text-[green] text-[80px] md:text-[150px]"></i>

                                                        <h4 className='text-[30px] lg:text-[35px] cutom-font-bold mb-5 mt-10'>Thank You</h4>

                                                        <p>Your Submission has been sent</p>
                                                    </div>
                                                    {/* form end */}
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            {/* suceess dialog end 2*/}



        </>
    )
}

export default ContactUs