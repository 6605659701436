import React, {Suspense} from 'react'
import { Disclosure } from "@headlessui/react";
import ReactHelmet from '../../ReactHelmet';
import env from '../../../Config';

const FaqsContent = ({ heading1, content1, heading2, content2, heading3, content3, heading4, content4, heading5, content5 }) => {
  return (
    <>
      <Suspense>
        <ReactHelmet
          title={`Versatile Commerce | Faq's`}
          keywords={`We are here to help you`}
          description={`Where Questions Unravel, Paving the Path to Informed Decisions and Knowledge Enrichment with Confidence.`}
          seoimgs={`faqs-banner.png`}
        />
      </Suspense>
      <div className="lg:col-span-7">
        <div className="w-full pt-5 lg:pt-0">
          <div className="w-full lg:p-2 mx-auto bg-white rounded-2xl">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex duration-300 justify-between w-full px-4 py-2 font-bold text-left text-gray-900 hover:text-gray-100 bg-[#E4E7EC] rounded-lg hover:bg-[#101828] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 text-[22px]">
                    <span>{heading1}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={4.5}
                      stroke="currentColor"
                      className={`${open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-gray-400 mt-2`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-[#475467]">
                    {content1}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-6">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex duration-300 justify-between w-full px-4 py-2 font-bold text-left text-gray-900 hover:text-gray-100 bg-[#E4E7EC] rounded-lg hover:bg-[#101828] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 text-[22px]">
                    <span>{heading2}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={4.5}
                      stroke="currentColor"
                      className={`${open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-gray-400 mt-2`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-[#475467]">
                    {content2}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-6">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex duration-300 justify-between w-full px-4 py-2 font-bold text-left text-gray-900 hover:text-gray-100 bg-[#E4E7EC] rounded-lg hover:bg-[#101828] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 text-[22px]">
                    <span>{heading3}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={4.5}
                      stroke="currentColor"
                      className={`${open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-gray-400 mt-2`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-[#475467]">
                    {content3}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-6">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex duration-300 justify-between w-full px-4 py-2 font-bold text-left text-gray-900 hover:text-gray-100 bg-[#E4E7EC] rounded-lg hover:bg-[#101828] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 text-[22px]">
                    <span>{heading4}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={4.5}
                      stroke="currentColor"
                      className={`${open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-gray-400 mt-2`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-[#475467]">
                    {content4}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-6">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex duration-300 justify-between w-full px-4 py-2 font-bold text-left text-gray-900 hover:text-gray-100 bg-[#E4E7EC] rounded-lg hover:bg-[#101828] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 text-[22px]">
                    <span>{heading5}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={4.5}
                      stroke="currentColor"
                      className={`${open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-gray-400 mt-2`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-[#475467]">
                    {content5}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>


          </div>
        </div>
      </div>

    </>
  )
}

export default FaqsContent