import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import $ from 'jquery';
import env from '../../../Config';
import { Link } from 'react-router-dom';

const BlogDetailsThree = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  $(document).ready(function () {
    // var stickyTop = $('.sticky1').offset().top;
    $(window).scroll(function () {
      var windowTop = $(window).scrollTop();
      console.log(windowTop, 'windowTop');

      if (windowTop > 450 && windowTop < 2695) {
        $('.sticky1').css({ 'position': 'fixed', 'top': '120px' });
      } else {
        $('.sticky1').css({ 'position': 'absolute', 'top': '0' });
      }
    });
  });



  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
      <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/blog-saas-products-banner.png)` }}>
        <div className='lg:container mx-auto px-4'>
          <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%]">
            <div className="text-center mx-auto lg:my-[5rem]">
              <div className="bg-[#FB9851] mb-[50px] text-[#FFF] py-2 px-9 text-[16px] text-center rounded-[40px] inline-block clear-both">Technology News</div>
              <h2 className="base-h2 text-[#101828] md:text-[#fff!important] lg:w-[48%] mx-auto">
                SaaS Products
              </h2>
            </div>
          </div>
        </div>
      </div>


      <div className='clear-both'></div>




      {/* blog details start */}
      <div className=" px-[12px] md:px-[36px] xl:px-0 mt-[70px] mx-auto text-md text-gray-500 relative blog-content max-w-[830px] text-[14px] leading-[25px] md:text-[18px] md:leading-[32px] lg:flex">
        <div className="flex-col items-center gap-6 absolute follow-scroll translate-x-[-56px] sticky1 hidden lg:block" >
          <p className="text-heading-6 font-bold text-gray-400 mb-5">Share</p>
          <a href="#"> <img src={env.baseUrl + '/images/blog-details-sm-icon1.png'} alt="img" /></a>
          <a href="#" className='my-5 inline-block'> <img src={env.baseUrl + '/images/blog-details-sm-icon2.png'} alt="" /></a>
          <a href="#"> <img src={env.baseUrl + '/images/blog-details-sm-icon3.png'} alt="" /></a>
        </div>

        <div className='lg:ml-[60px]'>
          <p className="mb-6 md:mb-9">Software as a Service (SaaS) products have become increasingly popular in recent years. These cloud-based solutions offer a range of benefits to businesses, including cost savings, scalability, and ease of use. In this blog post, we will explore what SaaS products are, how they work, and the benefits they can offer businesses of all sizes.</p>

          <div className="mb-6 md:mb-9">
            <img className="h-full w-full object-cover" src={env.baseUrl + '/images/what-are-saas-products.png'} alt="What are SaaS Products" />
          </div>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">What are SaaS Products?  </h3>
          <p className="mb-6 md:mb-9">SaaS products are software applications that are hosted and maintained by a third-party provider. Instead of purchasing and installing software on their own servers, businesses can access these applications through the internet. This means that users can access the software from anywhere, as long as they have an internet connection. </p>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">How do SaaS Products Work? </h3>
          <p className="mb-5 md:mb-6">SaaS products work by providing users with access to a shared pool of computing resources. The provider is responsible for maintaining the hardware, software, and network infrastructure required to run the application. Users can access the application through a web browser or a dedicated app, depending on the provider's offering.</p>
          <p className="mb-6 md:mb-9">The provider typically charges a subscription fee for access to the software. This fee can be based on the number of users, the amount of data stored, or other factors. Because the provider is responsible for maintaining the infrastructure, businesses can save money on hardware and software costs.</p>


          <figure className="mb-8 md:mb-12">
            <div className="grid md:grid-cols-2 gap-[30px]">
              <div className='col-span-1'>
                <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Benefits of SaaS Products
                </h3>

                <p className="mb-4 md:mb-6">SaaS products offer a range of benefits to businesses, including:</p>

                <p className="mb-4 md:mb-4">1. Cost Savings - By using a SaaS product, businesses can avoid the upfront costs associated with purchasing and installing software. Instead, they can pay a monthly or annual subscription fee to access the software.</p>
                <p className="mb-4 md:mb-4">2. Scalability - SaaS products are designed to be scalable, which means that they can grow or shrink as needed. This makes them an ideal solution for businesses that experience seasonal fluctuations in demand or rapid growth.</p>
              </div>
              <div className='col-span-1'>
                <img className="h-full w-full object-cover rounded-2xl" src={env.baseUrl + '/images/benefits-of-saas-products.png'} alt="Benefits of SaaS Products" />
              </div>

            </div>
          </figure>
          <p className="mb-4 md:mb-4">3. Ease of Use - Because SaaS products are accessed through a web browser or app, they are easy to use and require minimal training. This makes them an ideal solution for businesses that need to quickly onboard new employees or contractors.</p>
          <p className="mb-4 md:mb-4 ">4. Automatic Updates - SaaS providers are responsible for maintaining the software and ensuring that it is up to date. This means that businesses don't have to worry about installing updates or patches themselves.</p>
          <p className="mb-4 md:mb-4 md:mb-9">5. Access from Anywhere - Because SaaS products are accessed through the internet, users can access them from anywhere in the world. This makes them an ideal solution for businesses with remote workers or multiple locations.</p>

          <h3 className="base-h4 mb-6 md:mb-9 text-gray-900">Conclusion</h3>
          <p className="mb-5 md:mb-6">SaaS products offer a range of benefits to businesses of all sizes. They provide cost savings, scalability, ease of use, automatic updates, and access from anywhere. If you're considering implementing a SaaS product in your business, be sure to do your research and choose a provider that meets your needs. With the right solution in place, you can streamline your operations, increase productivity, and grow your business. </p>
        </div>
      </div>
      {/* blog details end */}
      <div className='border-b-2 lg:container mx-auto mt-[50px]'></div>

      <div className='clear-both'></div>
      <section className='Our-blogs'>
        <div className='md:container p-4 mx-auto mt-[50px] mb-[50px] lg:mb-[100px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Recommended <span className='text-[#F26722]'> Blogs</span></h2>

          <div className='mt-[50px]'>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              autoPlaySpeed={3000}
              autoPlay={true}
              infinite={true}
            >
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/we-can-blend-colors-multiple-ways" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">We can blend colors multiple ways, the most common</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/blog-We-can-blend-colors-multiple-ways.png'} alt="We-can-blend-colors-multiple-ways" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/engineering-experiences-a-journey" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Engineering Experiences: A Journey of Innovation and Creativity</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/Blog-Engineering-Experiences.png'} alt="Engineering-Experiences" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#BEE1E6]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/saas-products" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Customer Services</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">SaaS Products</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/blog-SaaS-Products.png'} alt="SaaS-Products" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/the-evolution-significance" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">The Evolution and Significance of Cloud Engineering in Modern Computing</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/the-evolution-and-significance.png'} alt="the-evolution-and-significance" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/navigating-the-digital-migration" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Navigating the Digital Migration: A New Frontier in Business Transformation</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/navigating-the-digital-migration.png'} alt="navigating-the-digital-migration" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

            </Carousel>
          </div>
        </div>
      </section>

    </>
  )
}

export default BlogDetailsThree