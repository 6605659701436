import React, { useEffect, Suspense } from 'react'
import Discovery from '../../../Components/DiscoveryCall/Discovery'
import ReactHelmet from '../../../Components/ReactHelmet'
import env from '../../../Config'


const TechnicalDevelopment = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Services | Technical development`}
                    keywords={`Technical Development`}
                    description={`Navigating the Frontiers of Innovation: Cultivating Creative Solutions with Strategic Technical Development Expertise and Precision`}
                    seoimgs={`Technical-development.png`}
                />
            </Suspense>
            <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/Technical-development.png)` }}>
                <div className='lg:container mx-auto px-4'>
                    <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
                        <div className="flex-1 md:py-[70px] lg:py-[100px]">
                            <h2 className="text-[2rem] lg:text-[82px] text-[#222] md:text-[#fff] md:w-[50%] lg:w-[40%]  leading-tight font-bold text-center md:text-left">
                                Technical Development
                            </h2>
                            <p className="text-[18px] lg:text-[24px] text-[#222] md:text-[#B5BDCC] font-semibold mt-5 md:w-[50%] lg:w-[40%] text-center md:text-left">
                                Navigating the Frontiers of Innovation: Cultivating Creative Solutions with Strategic Technical Development Expertise and Precision
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <section className='TechnicalDevr'>
                <div className="lg:container mx-auto px-4 my-[40px] md:my-[70px] lg:mt-[100px]">
                    <div className="text-center">
                        <h2 className="text-[2rem] lg:text-[56px] leading-tight font-bold">Technical <span className="text-[#f26722]"> Development </span></h2>
                        <p className="text-[20px] md:text-[24px] text-[#475467] font-bold lg:w-[60%] mx-auto mt-4">
                            Unleashing Innovation's Potential: Orchestrating Creative Solutions through Skillful Expertise in Strategic Technical Development</p>
                    </div>

                    <div className='xl:px-0 mt-[70px] lg:mt-[80px]'>
                        <div className='lg:grid lg:grid-cols-2 lg:gap-[80px]'>
                            <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                                <img className='max-w-full' src={env.baseUrl + '/images/Application-development.png'} alt='Application Development'/>
                            </div>

                            <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                                <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                                <h2 className="base-h2 font-bold leading-none">Application Development</h2>
                                <p className='base-p1'>
                                    Application development demands a combination of technical expertise, user-centric design, rigorous testing, and ongoing maintenance to ensure optimal performance and user satisfaction.
                                </p>

                                <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[50px]'>
                                    <div className='mb-[10px] lg:mb-0 '>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Requirements Gathering</h5>
                                            <div className='tooltip_box'>Gather detailed specifications and requirements from stakeholders to define the scope of the application.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Design and Architecture</p>
                                            <div className='tooltip_box'>Plan the application's structure, user interface, and data flow through thorough design and architecture.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Front-End Development</h5>
                                            <div className='tooltip_box'>Develop the user interface, ensuring a user-friendly and visually appealing design.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Back-End Development</h5>
                                            <div className='tooltip_box'>Create the application's server-side logic, databases, and APIs to handle data and business logic.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Coding and Implementation</h5>
                                            <div className='tooltip_box'>Write clean, efficient, and well-documented code following best practices and coding standards.</div>
                                        </div>
                                    </div>

                                    <div className='mb-[30px] lg:mb-0'>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Platform Selection</h5>
                                            <div className='tooltip_box'>Choose the appropriate platform (web, mobile, desktop) based on the application's purpose and target audience.</div>
                                        </div>
                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Testing and Quality Assurance</h5>
                                            <div className='tooltip_box'>Perform rigorous testing to identify and rectify bugs, ensuring a smooth and error-free user experience.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Integration</h5>
                                            <div className='tooltip_box'>Integrate third-party services, APIs, and components as needed for enhanced functionality.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User Acceptance Testing (UAT)</h5>
                                            <div className='tooltip_box'>Involve end-users to test the application's functionality and usability before deployment.</div>
                                        </div>

                                        <div className='tooltip_btn'>
                                            <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Deployment and Maintenance</h5>
                                            <div className='tooltip_box'>Deploy the application to the chosen environment and provide ongoing maintenance, updates, and support.</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                        <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                            <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>
                            <h2 className="base-h2 font-bold leading-none">Application Modernisation</h2>
                            <p className='base-p1'>
                                Application modernisation aims to enhance performance, scalability, and user experience, while also leveraging the latest technologies and practices to ensure that applications remain relevant and effective in today's rapidly evolving technological landscape.
                            </p>

                            <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[70px]'>
                                <div className='mb-[10px] lg:mb-0 '>
                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Assessment and Evaluation</h5>
                                        <div className='tooltip_box'>Evaluate existing applications to determine their compatibility with modernisation strategies.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Legacy System Analysis</h5>
                                        <div className='tooltip_box'>Analyse legacy systems to identify pain points, bottlenecks, and areas for improvement.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Migration Strategy</h5>
                                        <div className='tooltip_box'>Develop a strategic plan for migrating legacy applications to modern platforms.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Cloud Adoption</h5>
                                        <div className='tooltip_box'>Consider migrating applications to cloud platforms for enhanced scalability, flexibility, and cost-efficiency.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Micro-services Architecture</h5>
                                        <div className='tooltip_box'>Decompose monolithic applications into micro-services to improve agility and scalability.</div>
                                    </div>
                                </div>

                                <div className='mb-[30px] lg:mb-0'>
                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Containerisation</h5>
                                        <div className='tooltip_box'>Containerise applications using technologies like Docker to enhance portability and deployment efficiency.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> API Integration</h5>
                                        <div className='tooltip_box'>Expose APIs to enable seamless integration with other systems and applications.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User Experience Enhancement</h5>
                                        <div className='tooltip_box'>Update user interfaces and experiences to align with modern design principles.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Modernisation</h5>
                                        <div className='tooltip_box'>Optimise data storage, retrieval, and management to leverage modern data technologies.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Improvement</h5>
                                        <div className='tooltip_box'>Implement agile methodologies and DevOps practices for continuous monitoring, optimisation, and refinement of modernized applications.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                            <img className='max-w-full' src={env.baseUrl + '/images/Application-modernisation.png'} alt='Application Modernisation'/>
                        </div>
                    </div>

                    <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                        <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                            <img className='max-w-full' src={env.baseUrl + '/images/QA-automate-testing.png'} alt='QA & Automate testing'/>
                        </div>

                        <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>
                            <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>
                            <h2 className="base-h2 font-bold leading-none">QA & Automate testing</h2>
                            <p className='base-p1'>
                                Automated testing enhances efficiency and accuracy in software testing, while QA ensures the overall quality of the software by identifying and addressing potential issues throughout the development lifecycle.
                            </p>

                            <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[40px]'>
                                <div className='mb-[10px] lg:mb-0 '>
                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Test Planning</h5>
                                        <div className='tooltip_box'>Develop a comprehensive test plan that outlines testing strategies, scope, and objectives.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Test Case Creation</h5>
                                        <div className='tooltip_box'>Design detailed test cases that cover various scenarios and use cases.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Automated Test Scripting</h5>
                                        <div className='tooltip_box'>Develop automated test scripts to execute repetitive and complex test cases.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Test Environment Setup</h5>
                                        <div className='tooltip_box'>Prepare testing environments that mirror production environments for accurate testing.</div>
                                    </div>
                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Load and Performance Testing</h5>
                                        <div className='tooltip_box'>Evaluate system behaviour under varying loads to ensure stability and responsiveness.</div>
                                    </div>
                                </div>

                                <div className='mb-[30px] lg:mb-0'>
                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Regression Testing</h5>
                                        <div className='tooltip_box'>Continuously test software updates to ensure new changes do not break existing functionality.</div>
                                    </div>
                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User Acceptance Testing (UAT)</h5>
                                        <div className='tooltip_box'>Involve end-users to validate that the software meets their requirements and expectations.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Integration and Continuous Deployment (CI/CD)</h5>
                                        <div className='tooltip_box'>Automate testing as part of the development pipeline to ensure rapid and reliable releases.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Bug Tracking and Reporting</h5>
                                        <div className='tooltip_box'>Document and track identified issues, facilitating effective communication and resolution.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Test Automation Framework</h5>
                                        <div className='tooltip_box'>Implement a structured framework that facilitates test creation, execution, and maintenance.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                        <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                            <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                            <h2 className="base-h2 font-bold leading-none">System Integration and implementation</h2>
                            <p className='base-p1'>
                                System integration and implementation involve aligning various software and hardware components to work seamlessly together while considering factors such as data integrity, security, user training, and ongoing maintenance.
                            </p>

                            <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[70px]'>
                                <div className='mb-[10px] lg:mb-0 '>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Integration Strategy</h5>
                                        <div className='tooltip_box'>Define a comprehensive integration strategy that outlines how different systems will communicate and work together.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> API Development</h5>
                                        <div className='tooltip_box'>Create well-defined APIs to enable seamless interaction between various systems.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Data Mapping and Transformation</h5>
                                        <div className='tooltip_box'>Ensure data compatibility and consistency across integrated systems by mapping and transforming data as needed.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Testing and Validation</h5>
                                        <div className='tooltip_box'>Rigorously test integrated components to ensure proper functionality, data accuracy, and security.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Middleware Utilisation</h5>
                                        <div className='tooltip_box'>Implement middleware solutions to facilitate communication and data exchange between disparate systems.</div>
                                    </div>
                                </div>


                                <div className='mb-[30px] lg:mb-0'>
                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Security Considerations</h5>
                                        <div className='tooltip_box'>Address security concerns by implementing proper authentication, authorisation, and encryption measures.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User Training</h5>
                                        <div className='tooltip_box'>Train end-users on how to effectively use the integrated systems to maximise benefits.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Migration Plan</h5>
                                        <div className='tooltip_box'>Develop a migration plan that outlines the process of moving data and functionalities from legacy systems to new ones.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Monitoring and Maintenance</h5>
                                        <div className='tooltip_box'>Implement monitoring tools to track system performance and proactively address any issues that arise.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Change Management</h5>
                                        <div className='tooltip_box'>Manage organisational change by communicating the benefits and impacts of system integration to stakeholders.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2'>
                            <img className='max-w-full' src={env.baseUrl + '/images/System-integration-implementation.png'} alt='System Integration and implementation'/>
                        </div>
                    </div>

                    <div className='lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                        <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                            <img className='max-w-full' src={env.baseUrl + '/images/Testing-processes.png'} alt='Testing processes'/>
                        </div>

                        <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>
                            <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>
                            <h2 className="base-h2 font-bold leading-none">Testing processes</h2>
                            <p className='base-p1'>
                                Effective testing processes ensure the quality and reliability of software by systematically evaluating its functionality, performance, security, and user experience across different stages of development.
                            </p>

                            <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[70px]'>
                                <div className='mb-[10px] lg:mb-0 '>
                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Test Planning</h5>
                                        <div className='tooltip_box'>Create a detailed plan outlining testing objectives, scope, resources, and timelines.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Requirement Analysis</h5>
                                        <div className='tooltip_box'>Understand and clarify testing requirements based on project specifications.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Test Case Design</h5>
                                        <div className='tooltip_box'>Develop comprehensive test cases covering various scenarios, functionalities, and use cases.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Test Environment Setup</h5>
                                        <div className='tooltip_box'>Prepare testing environments that mimic production conditions to ensure accurate results.</div>
                                    </div>
                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Test Execution</h5>
                                        <div className='tooltip_box'>Execute test cases according to the testing plan, recording results and observations.</div>
                                    </div>
                                </div>

                                <div className='mb-[30px] lg:mb-0'>
                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Regression Testing</h5>
                                        <div className='tooltip_box'>Continuously test to ensure that new changes don't negatively impact existing functionalities.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Automation</h5>
                                        <div className='tooltip_box'>Automate repetitive and critical test cases to enhance efficiency and consistency.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Defect Reporting</h5>
                                        <div className='tooltip_box'>Document and report identified defects or issues with clear details and steps to reproduce.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Performance Testing</h5>
                                        <div className='tooltip_box'>Evaluate system performance under different loads to identify bottlenecks and weaknesses.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> User Acceptance Testing (UAT)</h5>
                                        <div className='tooltip_box'>Engage end-users to validate that the software meets their requirements and expectations.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                        <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                            <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                            <h2 className="base-h2 font-bold leading-none">Performance Engineering</h2>
                            <p className='base-p1'>
                                Performance engineering focuses on enhancing system speed, responsiveness, and scalability by optimising code, infrastructure, and resources, ensuring that software applications meet performance expectations even under heavy loads.
                            </p>

                            <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[70px]'>
                                <div className='mb-[10px] lg:mb-0 '>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Requirements Analysis</h5>
                                        <div className='tooltip_box'>Understand performance expectations and define performance metrics based on business needs.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Performance Baseline</h5>
                                        <div className='tooltip_box'>Establish a baseline performance level to measure improvements and deviations.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Performance Testing</h5>
                                        <div className='tooltip_box'>Conduct various tests such as load, stress, and scalability tests to assess system behaviour under different conditions.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Bottleneck Identification</h5>
                                        <div className='tooltip_box'>Identify performance bottlenecks by analysing resource utilisation, response times, and system behaviour.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Code Optimisation</h5>
                                        <div className='tooltip_box'>Optimise code and algorithms to enhance system efficiency and reduce resource consumption.</div>
                                    </div>
                                </div>

                                <div className='mb-[30px] lg:mb-0'>
                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Infrastructure Scaling</h5>
                                        <div className='tooltip_box'>Scale hardware and resources as needed to accommodate growing demands.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Caching Strategies</h5>
                                        <div className='tooltip_box'>Implement caching mechanisms to reduce database queries and enhance response times.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Content Delivery Networks (CDNs)</h5>
                                        <div className='tooltip_box'>Utilise CDNs to optimise content delivery and reduce load times for distributed users.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Database Optimisation</h5>
                                        <div className='tooltip_box'>Optimise database queries, indexing, and schema design for faster data retrieval.</div>
                                    </div>

                                    <div className='tooltip_btn'>
                                        <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Monitoring</h5>
                                        <div className='tooltip_box'>Implement real-time monitoring to track performance metrics and identify deviations.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2'>
                            <img className='max-w-full' src={env.baseUrl + '/images/Performance-engineering.png'} alt='Performance Engineering'/>
                        </div>
                    </div>
                </div>
            </section>

            <Discovery
                title={'Discovery '}
                subTitle={'Call'}
                desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
                btnName={'Subscribe'}
                placeholder={'Drop a Email'} />
        </>
    )
}

export default TechnicalDevelopment