import React, { useEffect, Suspense } from 'react'
import ReactHelmet from '../../Components/ReactHelmet';

const TermsService = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return (

        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Terms of Service`}
                />
            </Suspense>
            <div className='lg:container mx-auto px-4'>
                <h3 className='text-[22px] text-[#ff6b01] mt-9 mb-4 font-bold'>Terms of service</h3>
                <p className='mt-5'>
                    Versatile Commerce Limited herein after referred as “Versatile Commerce” provides an eCommerce platform known as “Versatile Commerce application” or “vCApp”. Versatile Commerce application comprises order management software which automates online selling process providing with tools and support to scale eCommerce business across multiple marketplaces.
                </p>
                <p className='mt-5'>
                    Versatile Commerce application provide this software as a platform / a service, and the information that is used or process via our platform / service may contain personal data. We provide the Versatile Commerce application platform service as a processor on behalf of our clients (such as ecommerce marketplace sellers but not limited to) who act as the controller of this information used on the platform. It is the responsibility of our clients to determine how this personal data is processed and deleted and to ensure there is a lawful basis for its use. For any specific queries or concerns about how the data is being handled, you should contact the relevant company/organisation (our clients) in their capacity as a controller.
                </p>

                <p className='mt-5'>
                    These Terms of Use govern your use of our service. As used in these Terms of Use, “Versatile Commerce service”, “our service” or “the service” means the personalized service provided by Versatile Commerce for exploring and using Versatile Commerce application, including all features and functionalities, recommendations and reviews, the website, and user interfaces, as well as all content and software associated with our service.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>Subscription</h4>
                <p className='mt-5'>
                    1.1. Your Versatile Commerce membership will continue month-to-month until terminated. To use the vCApp service, you must have Internet access and a ready device and provide us with one or more Payment Methods. “Payment Method” means a current, valid, accepted method of payment, as may be updated from time to time, and which may include payment through your account with a third party. Unless you cancel your subscription before your monthly billing date, you authorize us to charge your next month’s subscription fee to your Payment Method (see “Cancellation” below).
                </p>
                <p className='mt-5'>
                    1.2. We may offer a number of subscription plans, including special promotional plans or subscription offered by third parties in conjunction with the provision of their own products and services. Some subscription plans may have differing conditions and limitations, which will be disclosed at your sign-up or in other communications made available to you. You can find specific details regarding your Versatile Commerce Subscription by visiting our website and clicking on the “Prices” link available at the top of the pages of the Versatile Commerce website.
                </p>
                <p className='mt-5'>
                    1.3. <b>Intellectual Property Ownership:</b>  Versatile Commerce alone (and its licensors, where applicable) shall own all right, title and interest, including all related Intellectual Property Rights, in and to the Versatile Commerce Technology, the Content and the Service and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you or any other party relating to the Service. This Agreement is not a sale and does not convey to you any rights of ownership in or related to the Service, the Versatile Commerce Technology or the Intellectual Property Rights owned by Versatile Commerce. The Versatile Commerce name, the Versatile Commerce logo, and the product names associated with the Service are trademarks of Versatile Commerce or third parties, and no right or license is granted to use them.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>Free Trials</h4>
                <p className='mt-5'>
                    2.1. Your Versatile Commerce membership may start with a free trial. The free trial period of your membership lasts for one month, or as otherwise specified during sign-up and is intended to allow new clients and certain former clients to try the service.
                </p>
                <p className='mt-5'>
                    2.2. Free trial eligibility is determined by Versatile Commerce at its sole discretion and we may limit eligibility or duration to prevent free trial abuse. We reserve the right to revoke the free trial and put your account on hold in the event that we determine that you are not eligible. Clients with an existing or recent Versatile Commerce subscription are not eligible. We may use information such as device ID, Merchant IDs, Marketplace user IDs, method of payment or an account email address used with an existing or recent Versatile Commerce subscription to determine eligibility. For combinations with other offers, restrictions may apply.
                </p>
                <p className='mt-5'>
                    2.3. We will charge your Payment Method for your monthly subscription fee at the end of the free trial period unless you cancel your subscription prior to the end of the free trial period. To view the monthly subscription price and end date of your free trial period, visit our application and click the “Billing details” link on the “Settings” page. We will notify about the free trial expiry while you are on free trial period.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>Billing and Cancellation</h4>

                <p className='mt-5'>
                    3.1. <b> Billing Cycle.</b> The subscription fee for the Versatile Commerce service and any other charges you may incur in connection with your use of the service, such as taxes and possible transaction fees, will be charged on a monthly basis to your Payment Method on the calendar day corresponding to the commencement of the paying portion of your subscription. In some cases, your payment date may change, for example if your Payment Method has not successfully settled or if your paid subscription began on a day not contained in a given month. Visit our application and click on the “Billing details” link on the “Settings” page to see your next payment date.
                </p>

                <p className='mt-5'>
                    3.2. <b> Payment Methods.</b> To use the Versatile Commerce service, you must provide one or more Payment Methods. You can update your Payment Methods by going to the ” Billing details ” page. We may also update your Payment Methods using information provided by the payment service providers. Following any update, you authorize us to continue to charge the applicable Payment Method(s). You authorize us to charge any Payment Method associated to your account in case your primary Payment Method is declined or no longer available to us for payment of your subscription fee. For some Payment Methods, the issuer may charge you certain fees, such as foreign transaction fees or other fees relating to the processing of your Payment Method. Local tax charges may vary depending on the Payment Method used. Check with your Payment Method service provider for details.
                </p>

                <p className='mt-5'>
                    3.2.1. <b>Non-Payments:</b> You remain responsible for any uncollected amounts. If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not cancel your account, we may suspend your access to the service until we have successfully charged a valid Payment Method. You agree that Versatile Commerce may charge such unpaid fees to your deposit, debit/credit card or otherwise bill you for such unpaid fees. Versatile Commerce reserves the right to impose a reconnection fee in the event you are suspended and thereafter request access to the Service. You agree and acknowledge that Versatile Commerce has no obligation to retain Customer Data and that such Customer Data may be irretrievably deleted if your account is 30 days or more delinquent.
                </p>

                <p className='mt-5'>
                    3.3. <b>Cancellation.</b> You can cancel your Versatile Commerce subscription at any time, and you will continue to have access to the Versatile Commerce service through the end of your monthly billing period. To the extent permitted by the applicable law, payments are non-refundable and we do not provide refunds or credits for any partial-month membership. To cancel, go to the “Settings” page and follow the instructions for cancellation. If you cancel your subscription, your account will automatically close at the end of your current billing period. To see when your account will close, click “Billing details” on the “Settings” page.
                </p>

                <p className='mt-5'>
                    3.4. <b>Changes to the Price and Subscription Plans.</b> We may change our subscription plans and the price of our service from time to time; however, any price changes or changes to our subscription plans will apply to you no earlier than 60 days following notice to you.
                </p>

                <p className='mt-5'>
                    3.5. <b>Payment information:</b> Versatile Commerce does not store any credit card information anywhere on our system. Credit Cards are processed directly via PayPal or Stripe and any recurring payment profiles are stored and managed by PayPal or Stripe. All direct debit payments are processed by GoCardless.
                </p>

                <p className='mt-5'>
                    3.6. Licences and Subscriptions are not for Resale, unless specified in a separate agreement.
                </p>
                <p className='mt-5'>
                    3.7. <b>Liability:</b> In no event shall either party and/or its licensors be liable to anyone for any indirect, punitive, special, exemplary, incidental, consequential or other damages of any type or kind (including loss of data, revenue, profits, use or other economic advantage) arising out of, or in any way connected with this service, including but not limited to the use or inability to use the service, or for any content obtained from or through the service, any interruption, inaccuracy, error or omission, regardless of cause in the content, even if the party from which damages are being sought or such party’s licensors have been previously advised of the possibility of such damages.
                </p>


                <h4 className='text-[19px] mt-9 font-bold'>Versatile Commerce Service </h4>

                <p className='mt-5'>
                    4.1. You must be 18 years of age, or the age of majority in your province, territory or country, to become a member of the Versatile Commerce service.
                </p>

                <p className='mt-5'>
                    4.2. The Versatile Commerce service and any services used through the Versatile Commerce application are for the purpose of the registered company use only and may not be shared with other individuals or other companies beyond your users within same company. During your Versatile Commerce subscription, we grant you a limited, non-exclusive, non-transferable right to access the Versatile Commerce service. You agree not to use the service for public performances or demo the services to other individuals or other companies beyond your users within same company.
                </p>

                <p className='mt-5'>
                    4.3. You may use the Versatile Commerce services primarily within the country in which you have established your account and only in geographic locations where we offer our services and have licensed.
                </p>

                <p className='mt-5'>
                    4.4. The Versatile Commerce service, including the features, is regularly updated. In addition, we continually test various aspects of our service, including our website, user interfaces, promotional features and availability of Versatile Commerce services. You can at any time opt-out of tests by visiting the “Account” page and changing the “Beta Test participation” settings.
                </p>

                <p className='mt-5'>
                    4.5. You agree to use the Versatile Commerce service, including all features and functionalities associated therewith, in accordance with all applicable laws, rules and regulations, or other restrictions on use of the service or content therein. You agree not to archive, reproduce, distribute, copy, impersonate, imitate, modify, display, perform, publish, license, create derivative works from, offer for sale, or use (except as explicitly authorized in these Terms of Use) content and information contained on or obtained from or through the Versatile Commerce service.
                </p>

                <p className='mt-5'>
                    4.6. You also agree not to: circumvent, remove, alter, deactivate, degrade or thwart any of the services or features or content protections in the Versatile Commerce service; use any robot, spider, scraper or other automated means to access the Versatile Commerce service; decompile, reverse engineer or disassemble any software or other products or processes accessible through the Versatile Commerce service; insert any code or product or manipulate the content of the Versatile Commerce service in any way; or use any data mining, data gathering or extraction method. In addition, you agree not to upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment associated with the Versatile Commerce service, including any software viruses or any other computer code, files or programs. We may terminate or restrict your use of our service if you violate these Terms of Use or are engaged in illegal or fraudulent use of the service.
                </p>

                <p className='mt-5'>
                    4.7. The resolution or quality of the display of the Versatile Commerce content may vary from device to device, and may be affected by a variety of factors, such as your location, the bandwidth available through and/or speed of your Internet connection. Not all services are available in all formats, such as reports, and not all subscription plans allow you to receive services in all formats. The minimum connection speed for better and uninterrupted service quality is 1 Mbps; however, we recommend a faster connection for improved quality of service. You are responsible for all Internet access charges. Please check with your Internet provider for information on possible Internet data usage charges. We recommend using Google Chrome, Mozilla Firefox. Safari, Internet Explorer & Edge on Windows and Mac computer devices. We recommend using Android and iOS operating system on Mobile and Tablet devices.
                </p>

                <p className='mt-5'>
                    4.8. Versatile Commerce software is developed by, or for, Versatile Commerce and is designed to offer services for eCommerce sellers subscribed to Versatile Commerce services. This software may vary by device and medium, and functionalities and features may also differ between devices. You acknowledge that the use of the service may require third party software that is subject to third party licenses. You agree that you may automatically receive updated versions of the Versatile Commerce and related third-party software.
                </p>

                <p className='mt-5'>
                    5. Passwords and Account Access. The member who created the Versatile Commerce account and whose Payment Method is charged (the “Account Owner”) has access and control over the Versatile Commerce application assigned to their account that are used to access our service and is responsible for any activity that occurs through the Versatile Commerce account. To maintain control over the account and to prevent anyone from accessing the account (which would include information on the account), the Account Owner should maintain control over the Versatile Commerce services that are used to access the service and not reveal the password or details of the Payment Method associated with the account to anyone. You are responsible for updating and maintaining the accuracy of the information you provide to us relating to your account. We can terminate your account or place your account on hold in order to protect you, Versatile Commerce or our partners from identity theft or other fraudulent activity.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>Miscellaneous</h4>
                <p className='mt-5'>
                    6.1. Governing Law. These Terms of Use shall be governed by and construed in accordance with the laws of the United Kingdom. These terms will not limit any consumer protection rights that you may be entitled to under the mandatory laws of your country of residence.
                </p>

                <p className='mt-5'>
                    6.2. Unsolicited Materials. Versatile Commerce does not accept unsolicited materials or ideas for Versatile Commerce content and is not responsible for the similarity of any of its content or programming in any media to materials or ideas transmitted to Versatile Commerce.
                </p>

                <p className='mt-5'>
                    6.3. Customer Support. To find more information about our service and its features or if you need assistance with your account, please visit the Versatile Commerce Help Center on our website. In certain instances, Customer Service may best be able to assist you by using a remote access support tool through which we have full access to your computer. If you do not want us to have this access, you should not consent to support through the remote access tool, and we will assist you through other means.
                </p>

                <p className='mt-5'>
                    6.4. Survival. If any provision or provisions of these Terms of Use shall be held to be invalid, illegal, or unenforceable, the validity, legality and enforceability of the remaining provisions shall remain in full force and effect.
                </p>

                <p className='mt-5'>
                    6.5. Changes to Terms of Use. Versatile Commerce may, from time to time, change these Terms of Use. We will notify you at least 30 days before such changes apply to you.
                </p>

                <p className='mt-5'>
                    6.6. Electronic Communications. We will send you information relating to your account (e.g. payment authorizations, invoices, changes in password or Payment Method, confirmation messages, notices) in electronic form only, for example via emails to your email address provided during registration.
                </p>

                <p className='mt-5'>
                    6.7. Publication: You may publish or disclose information regarding the Work and shall acknowledge the support of Versatile Commerce in all such publications. The Versatile Commerce may use the name of the registered company (referred as “client”), in any advertising or publicity with prior written approval from the client.
                </p>

                <p className='mt-5'>
                    6.8. Copyright Notice: You unconditionally guarantees that any elements of text, graphics, photos, designs, trademarks, or other artwork furnished to the Versatile Commerce for inclusion in the Web Design Project are owned by You , or You has permission from the rightful owner to use each of these elements, and will hold harmless, protect, indemnify and defend the Versatile Commerce and its subcontractors from any liability including attorney’s fees and court costs, including any claim or suit, threatened or actual, arising from the use of such elements furnished by You.
                </p>
                <p className='mt-5'>
                    6.7. If the performance of this agreement by either party is prevented hindered or delayed by reason of any cause beyond the control of that party including but not limited to war, strikes, riots, terrorism, civil commotion or governmental action then that party shall be excused from such performance to the extent that it is necessarily prevented, hindered or delayed thereby during the continuance of such cause and this agreement shall be suspended for so long as or to the extent that any such cause prevents, hinders or delays performance.
                </p>
                <p className='mt-5 mb-10 font-bold'>
                Last updated: 10 September 2023
                </p>


            </div>
        </>
    )
}

export default TermsService