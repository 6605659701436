import React, {Suspense} from 'react'
import { useEffect } from 'react'
import ReactHelmet from '../../Components/ReactHelmet'


const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <Suspense>
                <ReactHelmet
                    title={`Versatile Commerce | Privacy Policy`}
                />
            </Suspense>
            <div className='lg:container mx-auto px-4'>
                <h3 className='text-[22px] text-[#ff6b01] mt-9 mb-4 font-bold'>Privacy Policy</h3>

                <h4 className='text-[19px] mt-9 font-bold'>Privacy Statement</h4>
                <p className='mt-5'>
                    This Privacy Statement explains our practices, including your choices, regarding the collection, use, and disclosure of certain information, including your personal information, by the Versatile Commerce.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>Contacting Us</h4>
                <p className='mt-5'>
                    If you have general questions about your account or how to contact Customer Service for assistance, please visit our online help center at https://www.versatilecommerce.com/  For questions specifically about this Privacy Statement, or our use of your personal information, cookies or similar technologies, please contact our Data Protection Officer/Privacy Office by email at privacy@www.versatilecommerce.com.
                </p>

                <p className='mt-5'>
                    The data controller of your personal information is Versatile Commerce. Please note that if you contact us to assist you, for your safety and ours we may need to authenticate your identity before fulfilling your request.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>Collection of Information</h4>
                <p className='mt-5'>
                    We receive and store information about you such as:
                </p>

                <p className='mt-5'>
                    <b>Information you provide to us:</b> We collect information you provide to us which includes:
                </p>

                <p className='mt-5'>
                    your name, email address, address or postal code, payment method(s), and telephone number. In certain countries, we collect a government identification number for purposes such as billing and tax compliance. We collect this information in a number of ways, including when you enter it while using our service, interact with our Customer Service, or participate in surveys or marketing promotions;
                </p>

                <p className='mt-5'>
                    information when you choose to provide reviews or ratings, taste preferences, account settings, (including preferences set in the “user profile” section of our application), or otherwise provide information to us through our service or elsewhere.
                </p>

                <p className='mt-5'>
                    <b>Information we collect automatically:</b> We collect information about you and your use of our service, your interactions with us and our advertising, as well as information regarding your computer or other device used to access our service (such as browser, mobile devices, and tablet devices). This information includes:
                </p>

                <p className='mt-5'>
                    your activity on the Versatile Commerce application
                </p>

                <p className='mt-5'>
                    your interactions with our email, push and text messaging; details of your interactions with Customer Service, such as the date, time and reason for contacting us, transcripts of any chat conversations, and if you call us, your phone number and call recordings; device IDs or other unique identifiers; advertising identifiers, such as those on mobile devices, tablets, and streaming media devices that include such identifiers (see the “Cookies policy” section for more information); device and software characteristics (such as type and configuration), connection information, statistics on page views, referring source (for example, referral URLs), IP address (which may tell us your general location), browser and standard web server log information;
                </p>

                <p className='mt-5'>
                    Information collected via the use of cookies, web beacons and other technologies, including ad data (such as information on the availability and delivery of ads, the site URL, as well as the date and time). (See our “Cookies policy” section for more details.)
                </p>

                <p className='mt-5'>
                    <b>Information from other sources:</b> We also obtain information from other sources. We protect this information according to the practices described in this Privacy Statement, plus any additional restrictions imposed by the source of the data. These sources vary over time, but could include:
                </p>

                <p className='mt-5'>
                    service providers that help us determine a location based on your IP address in order to customize our service and for other uses consistent with this Privacy Statement;
                </p>

                <p className='mt-5'>
                    select partners that make our service available on their device or with which we offer co-branded services or engage in joint marketing activities;
                </p>
                <p className='mt-5'>
                    payment service providers who provide us with updated payment method information based on their relationship with you;
                </p>
                <p className='mt-5'>
                    online and offline data providers, from which we obtain demographic, interest based and online advertising related data;
                </p>
                <p className='mt-5'>
                    publicly-available sources such as open government databases.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>Use of Information</h4>
                <p className='mt-5'>
                    We use information to provide, analyse, administer, enhance and personalize our services and marketing efforts, to process your registration, your orders and your payments, and to communicate with you on these and other topics.
                </p>
                <p className='mt-5'>
                    For example, we use information to:
                </p>
                <p className='mt-5'>
                    determine your general geographic location, provide you with customized marketing content we think will be of interest to you, determine your Internet service provider, and help us quickly and efficiently respond to inquiries and requests;
                </p>
                <p className='mt-5'>
                    prevent, detect and investigate potentially prohibited or illegal activities, including fraud, and enforcing our terms (such as determining free trial eligibility);
                </p>
                <p className='mt-5'>
                    analyse and understand our audience, improve our service (including our user interface experiences) and optimize selection, recommendation algorithms and delivery;
                </p>
                <p className='mt-5'>
                    communicate with you concerning our service (for example by email, push notifications text messaging, and online messaging channels), so that we can send you news about Versatile Commerce, details about new features and content available on Versatile Commerce, and special offers, promotional announcements, and consumer surveys, and to assist you with operational requests such as password reset requests. Please see the “Your Choices” section of this Privacy Statement to learn how to set or change your communications preferences.
                </p>
                <p className='mt-5'>
                    Our legal basis for collecting and using the personal information described in this Privacy Statement will depend on the personal information concerned and the specific context in which we collect and use it. We will normally collect personal information from you where we need the personal information to perform a contract with you (for example, to provide our services to you), where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms (for example, our direct marketing activities in accordance with your preferences), or where we have your consent to do so (for example, for you to participate in certain consumer insights activities like specific surveys, beta releases and focus groups). In some cases, we may also have a legal obligation to collect personal information from you or may otherwise need the personal information to protect your vital interests or those of another person (for example, to prevent payment fraud or confirm your identity). For questions about our use of your personal information (including legal bases and transfer mechanisms we rely on), cookies or similar technologies, please contact our Data Protection Officer/Privacy Office by email at privacy@www.versatilecommerce.com.
                </p>


                <h4 className='text-[19px] mt-9 font-bold'>Disclosure of Information</h4>
                <p className='mt-5'>
                    We disclose your information for certain purposes and to third parties, as described below:
                </p>
                <p className='mt-5'>
                    The Versatile Commerce company: We share your information among the family of companies as needed for: data processing and storage; providing you with access to our services; providing customer support; making decisions about service improvements; content development; and for other purposes described in the Use of Information section of this Privacy Statement.
                </p>
                <p className='mt-5'>
                    <b>Service Providers:</b> We use other companies, agents or contractors (“Service Providers”) to perform services on our behalf or to assist us with the provision of services to you. For example, we engage Service Providers to provide marketing, advertising, communications, infrastructure and IT services, to personalize and optimize our service, to process credit card transactions or other payment methods, to provide customer service, to collect debts, to analyse and enhance data (including data about users’ interactions with our service), and to process and administer consumer surveys. In the course of providing such services, these Service Providers may have access to your personal or other information. We do not authorize them to use or disclose your personal information except in connection with providing their services.
                </p>
                <p className='mt-5'>
                    <b>Promotional offers:</b> We may offer joint promotions or programs that, in order for your participation, will require us to share your information with third parties. In fulfilling these types of promotions, we may share your name and other information in connection with fulfilling the incentive. Please note that these third parties are responsible for their own privacy practices.
                </p>
                <p className='mt-5'>
                    <b>Protection of Versatile Commerce and others:</b> Versatile Commerce and its Service Providers may disclose and otherwise use your personal and other information where we or they reasonably believe such disclosure is needed to (a) satisfy any applicable law, regulation, legal process, or governmental request, (b) enforce applicable terms of use, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address illegal or suspected illegal activities (including payment fraud), security or technical issues, or (d) protect against harm to the rights, property or safety of Versatile Commerce, its users or the public, as required or permitted by law.
                </p>
                <p className='mt-5'>
                    <b>Business transfers:</b> In connection with any reorganization, restructuring, merger or sale, or other transfer of assets, we will transfer information, including personal information, provided that the receiving party agrees to respect your personal information in a manner that is consistent with our Privacy Statement.
                </p>
                <p className='mt-5'>
                    Whenever in the course of sharing information we transfer personal information to countries outside of the European Economic Area and other regions with comprehensive data protection laws, we will ensure that the information is transferred in accordance with this Privacy Statement and as permitted by the applicable laws on data protection.
                </p>
                <p className='mt-5'>
                    You may also choose to disclose your information in the following ways: while using the Versatile Commerce service you will have opportunities to post reviews or other information publicly, and third parties could use the information you disclose; certain portions of our service may contain a tool which gives you the option to share information by email, text message and social or other sharing applications, using the clients and applications on your smart device;
                </p>
                <p className='mt-5'>
                    social plugins and similar technologies allow you to share information.
                </p>
                <p className='mt-5'>
                    Social plugins and social applications are operated by the social networks themselves and are subject to their terms of use and privacy policies.
                </p>


                <h4 className='text-[19px] mt-9 font-bold'>Access to Account and Profiles</h4>
                <p className='mt-5'>
                    For ease of access to your account, you can use the “Remember me on this device” function when you log in to the application. This function uses technology that enables us to provide direct account access and to help administer the Versatile Commerce service without requiring reentry of any password or with minimal information for user identification when your browser revisits the application or service.
                </p>
                <p className='mt-5'>
                    To remove access to your Versatile Commerce account from your devices: (a) visit the “Account” section of our application, choose “Sign out,” and follow the instructions to logout your devices (note, deactivation may not occur immediately) or (b) delete your Versatile Commerce settings from your device (steps vary by device and the option is not available on all devices). Where possible, users of public or shared devices should log out at the completion of each visit. If you sell or return a computer, you should log out and deactivate the device before doing so. If you do not maintain the security of your password or device, or fail to log out or deactivate your device, subsequent users may be able to access your account, including your personal information.
                </p>
                <p className='mt-5'>
                    If you share or otherwise allow others to have access to your account, they will be able to see your information (including in some cases personal information) such as your inventory, orders and any other account information (including your email address or other information in the “Account” area of our application).
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>Your Choices</h4>
                <p className='mt-5'>
                    Email and Text Messages. If you no longer want to receive certain communications from us via email or text message, simply access the “Communications Settings” option in the “Account” section of our website and uncheck those items to unsubscribe. Alternatively, click the “unsubscribe” link in the email or reply STOP (or as otherwise instructed) to the text message. Please note that you cannot unsubscribe from service-related correspondence from us, such as messages relating to your account transactions.
                </p>
                <p className='mt-5'>
                    <b>Interest-Based Ads.</b> Interest-based ads are online ads that are tailored to your likely interests based on your use of various apps and websites across the Internet. If you are using a browser, then cookies and web beacons can be used to collect information to help determine your likely interests. For your choices about interest-based ads from Versatile Commerce, please see the “Cookies policy” section.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>Your Information and Rights</h4>
                <p className='mt-5'>
                    You can request access to your personal information or correct or update out-of-date or inaccurate personal information we hold about you.
                </p>
                <p className='mt-5'>
                    You can most easily do this by visiting the “user profile” portion of our application, where you have the ability to access and update a broad range of information about your account, including your contact information, your payment information, and various related information about your account You must be signed in to access the “user profile” section. You may also request that we delete personal information that we hold about you.
                </p>
                <p className='mt-5'>
                    To make requests, or if you have any other question regarding our privacy practices, please contact our Data Protection Officer/Privacy Office at privacy@ versatilecommerce.com. We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. Please also see the “Your Choices” section of this Privacy Statement for additional choices regarding your information.
                </p>
                <p className='mt-5'>
                    You can object to processing of your personal information, ask us to restrict processing of your personal information or request portability of your personal information; if we have collected and process your personal information with your consent, then you can withdraw your consent at any time; withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent; you have the right to complain to a data protection authority about our collection and use of your personal information.
                </p>
                <p className='mt-5'>
                    We may retain information as required or permitted by applicable laws and regulations, including to honour your choices, for our billing or records purposes and to fulfil the purposes described in this Privacy Statement. We take reasonable measures to destroy or de-identify personal information in a secure manner when it is no longer required.
                </p>
                <p className='mt-5'>
                    We do not share or otherwise other users access any data you might import or export from Versatile Commerce application (such as inventory, orders, customers and any other information related to the marketplaces you might sell). These are protected accordingly to the subject of your terms of use and privacy policies with your clients/customers/marketplaces/any other third party other than Versatile Commerce.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>Security</h4>
                <p className='mt-5'>
                    We use reasonable administrative, logical, physical and managerial measures to safeguard your personal information against loss, theft and unauthorized access, use and modification. These measures are designed to provide a level of security appropriate to the risks of processing your personal information.
                </p>

                <h4 className='text-[19px] mt-9 font-bold'>Changes to This Privacy Statement</h4>
                <p className='mt-5'>
                    We will update this Privacy Statement from time to time in response to changing legal, regulatory or operational requirements. We will provide notice of any such changes (including when they will take effect) in accordance with law. Your continued use of the Versatile Commerce service after any such updates take effect will constitute acknowledgement and (as applicable) acceptance of those changes. If you do not wish to acknowledge or accept any updates to this Privacy Statement, you may cancel your use of the Versatile Commerce service. To see when this Privacy Statement was last updated, please see the “Last Updated” section below.
                </p>

                <h4 className='text-[19px] mt-9 mb-5 font-bold'>Last updated: 10 September 2023</h4>

            </div>
        </>
    )
}

export default PrivacyPolicy