import $ from 'jquery';


// window scrolling start the content
$(document).ready(function () {
  $('.content').hover(
      function () {
          $('.inter').fadeIn();
      }, function () {
          $('.inter').fadeIn();
      }
  );
});

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 20;

      if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
      } else {
          reveals[i].classList.remove("active");
      } 
  }
}
window.addEventListener("scroll", reveal);