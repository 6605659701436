import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize("G-RXSQ0HH7M8");
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,

  });
}
reportWebVitals(SendAnalytics);