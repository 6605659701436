import React, { useEffect } from 'react'
import { Fragment, useState } from 'react'
// import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  Collapse,
  Dropdown,
  Ripple,
  initTE,
} from "tw-elements";

import './Styles.scss';

import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Navbar() {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  initTE({ Collapse, Dropdown, Ripple });

  const [navbar, setNavbar] = useState(false);
  const baseUrl = 'http://' + window.location.host;

  useEffect(() => {

    setTimeout(() => {
      (() => {

        document.addEventListener("scroll", function (event) {
          const e = document.querySelector("header");
          // var t = document.querySelector("#backToTop");
          let y = window.scrollY;
          // console.log(e);
          if (y > 10) {
            e.classList.add("sticky-header");
          } else {
            if (e.classList.contains('sticky-header')) e.classList.remove("sticky-header");
          }
        });
      })();

    }, 2000);
  }, []);

  return (
    <>
      <div className="overlay"></div>
      <header className="h-auto full-width relative py-[15px] first-letter:lg:py-[26px]">
        <div className="lg:container mx-auto px-[12px] mt-0 flex items-center justify-between mx-auto relative max-w-[100%] tracking-wide">
          <Link onClick={() => setIsMenuOpened(false)} className="flex logo z-50 w-[150px] md:w-[200px] lg:max-w-[100%]" to="/">
            <img alt="img" className='max-w-[120%]' src={baseUrl + '/images/Versatile-Commerce.png'} />
          </Link>
          <nav className="z-50 absolute hidden top-1/2 left-3/4 -translate-x-1/2 -translate-y-1/2 lg:block">
            <ul className="cutom-font navbar flex flex-col justify-center  gap-[34px] lg:flex-row">

             <li className="flex items-center group">
                <Link className="hover:text-[#f26722] text-[20px] font-inter menu-link lg:text-heading-6 mr-[7px]" to={'/product'}>Products
                </Link>
                                
                 {/* <i className="arrow_down"></i>
                <ul className="menu-child opacity-0 bg-white top-full z-50 py-2 grid menu-shadow -translate-x-6 translate-y-8 transition-all duration-200 pointer-events-none group-hover:pointer-events-auto lg:absolute rounded-[4px] group-hover:grid group-hover:opacity-100 before:content-[''] before:block before:absolute before:w-full before:h-12 before:top-[-35px] before:left-0 grid-cols-2 w-[340px]">
                  <li className="menu-child-item  group transition-all duration-200 py-[10px] px-[22px] hover:text-[#f26722] hover:pl-[25px] hover:opacity-100" data-menu="hp1"><a className="flex items-center text-[14px]" href="index.html"><span className="whitespace-nowrap">Homepage 01</span></a></li>
                </ul>*/}
                
              </li> 

              <li className="flex items-center group lg:mr-[30px]">
                <Link className="hover:text-[#f26722] text-[20px] font-inter menu-link lg:text-heading-6" to={'/services'}>Services
                  {/* <a className="hover:text-[#f26722] text-[20px] font-inter menu-link lg:text-heading-6 mr-[7px]" href="#">Services</a> */}
                </Link>
                {/* <i className="arrow_down"></i>

                <ul className="menu-child opacity-0 bg-white top-full z-50 py-2 grid menu-shadow -translate-x-6 translate-y-8 transition-all duration-200 pointer-events-none group-hover:pointer-events-auto lg:absolute rounded-[4px] group-hover:grid group-hover:opacity-100 before:content-[''] before:block before:absolute before:w-full before:h-12 before:top-[-35px] before:left-0 grid-cols-2 w-[340px]">
                  <li className="menu-child-item  group transition-all duration-200 py-[10px] px-[22px] hover:text-[#f26722] hover:pl-[25px] hover:opacity-100" data-menu="hp1"><a className="flex items-center text-[14px]" href="index.html"><span className="whitespace-nowrap">Homepage 01</span></a></li>
                </ul> */}
              </li>



              {/* <li className="flex items-center group"><a className="hover:text-[#f26722] text-[20px] font-inter menu-link lg:text-heading-6 mr-[7px]" href="#">Insights</a>
                 <i className="arrow_down"></i>
                <ul className="menu-child opacity-0 bg-white top-full z-50 py-2 grid menu-shadow -translate-x-6 translate-y-8 transition-all duration-200 pointer-events-none group-hover:pointer-events-auto lg:absolute rounded-[4px] group-hover:grid group-hover:opacity-100 before:content-[''] before:block before:absolute before:w-full before:h-12 before:top-[-35px] before:left-0 grid-cols-1 w-[185px]">
                  <li className="menu-child-item  group transition-all duration-200 py-[10px] px-[22px] hover:text-[#f26722] hover:pl-[25px] hover:opacity-100" data-menu="sv1"><a className="flex items-center text-[14px]" href="services-1.html"><span className="whitespace-nowrap">Services 01</span></a></li>
                 
                </ul> 
              </li>*/}

            </ul>
          </nav>

          <div className="hidden xl:block">
            <button type="button"> <Link className="flex items-center z-10 relative transition-all duration-300 group px-[22px] py-[15px] lg:pl-[35px] lg:pr-[35px] lg:py-[20px] rounded-[50px] bg-gray-100 text-gray-900 hover:bg-[#f26722] hover:text-white hover:drop-shadow-lg cursor-pointer" to="/services"><span className="block text-inherit w-full h-full rounded-[50px] text-heading-6 cutom-font-bold ">Learn&nbsp;more</span> <FaArrowRight className='text-[15px] ml-[13px]' /> </Link></button>
          </div>
        </div>


        <div onClick={() => setIsMenuOpened(!isMenuOpened)} className="burger-icon burger-icon-white menu__icon lg:hidden block">
          <span className="burger-icon-top"></span>
          <span className="burger-icon-mid"></span>
          <span className="burger-icon-bottom"></span>
        </div>

        {/* <div class="burger-icon burger-icon-white menu__icon"><span class="burger-icon-top"></span><span class="burger-icon-mid"> </span><span class="burger-icon-bottom"></span></div> */}


        {/* mobile navbar */}
        <nav className={`fixed top-0 right-0 bg-white flex flex-col h-screen nav-shadow overflow-y-scroll nav-mobile transition-all duration-200 w-[380px] max-w-full z-[100] ${isMenuOpened ? 'opacity-1' : 'opacity-0'}`}>
          <div className="p-[30px] min-h-[5vh] overflow-y-auto z-30">
            <ul className=" font-medium text-[16px] leading-[16px] cutom-font-bold leading-normal">
              <li className="group menu-mobile-item py-[13px] border-b">
                <Link onClick={() => setIsMenuOpened(false)} className="hover:text-[#f26722] text-[20px] font-inter menu-link lg:text-heading-6 mr-[7px]" to={'/'}> Home</Link>
              </li>
              <li className="group menu-mobile-item py-[13px] border-b">
                <div className="flex items-center justify-between transition-all duration-200 hover:text-[#f26722] hover:translate-x-[2px]">

                  <Link onClick={() => setIsMenuOpened(false)} className="hover:text-[#f26722] text-[20px] font-inter menu-link lg:text-heading-6 mr-[7px]" to={'/services'}> Services</Link>
                </div>
               </li>
              <li className="group menu-mobile-item py-[13px] border-b">
                <div className="flex items-center justify-between transition-all duration-200 hover:text-[#f26722] hover:translate-x-[2px]">
                <Link onClick={()=>setIsMenuOpened(false)} className="hover:text-[#f26722] text-[20px] font-inter menu-link lg:text-heading-6 mr-[7px]" to={'/product'}> Products
                </Link>
                </div>
                {/* <ul className="pl-5 menu-child hidden pt-[10px]">
                  <li className="text-md py-[10px]" id="ab1"><a className="block transition-all duration-200 hover:text-[#f26722] hover:translate-x-1" href="about-1.html">About 01</a></li>
                  
                </ul>  */}
              </li>
             

            </ul>
            {/* <div className="mt-5 border-t border-b border-gray-100 pb-5 mb-[25px] pt-[30px]">
              <p className="font-bold text-heading-6 mb-[10px]">Your Account</p>
              <ul className="text-[15px]">
                <li className="py-[13px]"><a className="transition-all duration-200 hover:text-[#f26722] hover:translate-x-[2px]" href="#">Profile</a></li>
                <li className="py-[13px]"><a className="transition-all duration-200 hover:text-[#f26722] hover:translate-x-[2px]" href="#">Work Preferences</a></li>
              </ul>
            </div> */}

          </div>
        </nav>
      </header>



    </>
  )
}

export default Navbar