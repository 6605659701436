import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import $ from 'jquery';
import env from '../../../Config';
import { Link } from 'react-router-dom';



const TheEvolutionSignificance = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  $(document).ready(function () {
    // var stickyTop = $('.sticky1').offset().top;
    $(window).scroll(function () {
      var windowTop = $(window).scrollTop();
      // console.log(windowTop, 'windowTop');

      if (windowTop > 450 && windowTop < 3200) {
        $('.sticky1').css({ 'position': 'fixed', 'top': '120px' });
      } else {
        $('.sticky1').css({ 'position': 'absolute', 'top': '0' });
      }
    });
  });



  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
      <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/The-evolution-and-significance-cloud-engineering.png)` }}>
        <div className='lg:container mx-auto px-4'>
          <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%]">
            <div className="text-center mx-auto lg:my-[5rem]">
              <div className="bg-[#FB9851] mb-[50px] text-[#FFF] py-2 px-9 text-[16px] text-center rounded-[40px] inline-block clear-both">Technology News</div>
              <h2 className="base-h2 text-[#101828] md:text-[#fff!important] lg:w-[80%] mx-auto">
                The Evolution and Significance of Cloud Engineering in Modern Computing
              </h2>
            </div>
          </div>
        </div>
      </div>


      <div className='clear-both'></div>




      {/* blog details start */}
      <div className=" px-[12px] md:px-[36px] xl:px-0 mt-[70px] mx-auto text-md text-gray-500 relative blog-content max-w-[830px] text-[14px] leading-[25px] md:text-[18px] md:leading-[32px] lg:flex">
        <div className="flex-col items-center gap-6 absolute follow-scroll translate-x-[-56px] sticky1 hidden lg:block" >
          <p className="text-heading-6 font-bold text-gray-400 mb-5">Share</p>
          <a href="#"> <img src={env.baseUrl + '/images/blog-details-sm-icon1.png'} alt="img" /></a>
          <a href="#" className='my-5 inline-block'> <img src={env.baseUrl + '/images/blog-details-sm-icon2.png'} alt="img" /></a>
          <a href="#"> <img src={env.baseUrl + '/images/blog-details-sm-icon3.png'} alt="img" /></a>
        </div>

        <div className='lg:ml-[60px]'>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900"> Introduction </h3>
          <p className="mb-6 md:mb-9">In today's fast-paced world, businesses and individuals alike are increasingly leaning on technology to achieve various objectives. This high dependency has led to the evolution of several specialized fields in the world of computing, one of which is Cloud Engineering. Often considered a cornerstone in modern IT strategies, Cloud Engineering offers powerful solutions for storing, accessing, and managing data remotely. In this blog, we will delve deep into the intricacies of Cloud Engineering, its evolution, and why it has become a critical part of today's technological landscape.</p>

          <div className="mb-6 md:mb-9">
            <img className="h-full w-full object-cover" src={env.baseUrl + '/images/The-evolution-and-significance-img.png'} alt="The-evolution-and-significance-img" />
          </div>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">What is Cloud Engineering?
          </h3>
          <p className="mb-6 md:mb-9">Simply put, Cloud Engineering refers to the design, planning, management, and maintenance of cloud services and infrastructure. Cloud Engineers are responsible for cloud service architecture, deployment, automation, and ensuring the security of the cloud environment. They often work closely with other IT professionals such as DevOps, software developers, and cybersecurity experts to implement and maintain a holistic cloud strategy.</p>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">A Brief History </h3>
          <p className="mb-6 md:mb-9">Cloud Engineering is an offshoot of traditional software engineering practices but focused on cloud computing. While the term "cloud computing" was popularized in the early 2000s, the foundational elements date back to the 1960s, during the time of mainframe computing. However, it wasn't until the launch of Amazon Web Services (AWS) in 2006 that cloud computing truly became accessible to the masses.</p>
          <p className="mb-6 md:mb-9">Over the years, we've seen a proliferation of cloud services from numerous providers like Microsoft Azure, Google Cloud Platform, and IBM Cloud, among others. This has helped to create the dynamic and highly specialized field of Cloud Engineering.</p>


          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Key Components of Cloud Engineering
          </h3>
          <ul className='list-disc md:ml-6'>
            <li className='mb-5'><b>Infrastructure as a Service (IaaS):</b> Focused on providing virtualized hardware resources over the internet.</li>
            <li className='mb-5'><b>Platform as a Service (PaaS):</b> Provides a platform for developers to build, deploy, and run applications without worrying about the underlying hardware and software layers.</li>
            <li className='mb-5'><b>Software as a Service (SaaS):</b> Allows users to access software applications over the internet, usually through a web browser.</li>
            <li className='mb-5'><b>DevOps:</b> A practice that involves the continuous integration and delivery of software, often automated and closely tied to cloud engineering.</li>
            <li className='mb-5'><b>Security:</b> Involves ensuring the security of cloud services, including data protection, network security, and compliance.</li>
          </ul>

          <h3 className="base-h4 mb-4 md:mb-6 lg:mt-9 text-gray-900">The Significance of Cloud Engineering</h3>
          <figure className="mb-8 md:mb-12">
            <div className="grid md:grid-cols-2 gap-[30px]">

              <div className='col-span-1'>
                <img className="h-full w-full object-cover rounded-2xl" src={env.baseUrl + '/images/cloud-engineering-img.png'} alt="The Significance of Cloud Engineering" />
              </div>
              <div className='col-span-1'>

                <p className="mb-4 md:mb-4"><b>Flexibility and Scalability:</b> Cloud Engineering provides an unprecedented level of flexibility and scalability for businesses. Companies can easily upscale or downscale their resources based on their needs without the need for substantial investments in physical hardware.</p>

                <p className="mb-4 md:mb-6"><b>Cost-Efficiency:</b> With cloud engineering, businesses can operate on a "pay-as-you-go" model, eliminating the need for significant upfront costs and ongoing maintenance expenses.</p>
              </div>
            </div>
          </figure>

          <p className="mb-4 md:mb-4"><b>Accessibility and Collaboration:</b> Cloud services enable easy access to data and applications from anywhere in the world, fostering better collaboration among team members who may be dispersed geographically.</p>
          <p className="mb-4 md:mb-4"><b>Speed and Agility:</b> The cloud allows businesses to be more agile by enabling them to deploy new applications quickly, scale operations, and innovate at a faster pace than ever before.</p>
          <p className="mb-4 md:mb-4"><b>Challenges and Future Outlook:</b> Despite its many advantages, Cloud Engineering also faces challenges like ensuring data privacy, compliance with local and global regulations, and the complexities associated with managing multi-cloud environments.</p>
          <p className="mb-4 md:mb-9">Nonetheless, the field is ripe for innovation, especially with emerging technologies like edge computing, artificial intelligence, and blockchain becoming increasingly integrated with cloud services.</p>

          <h3 className="base-h4 mb-4 md:mb-6 text-gray-900">Conclusion</h3>
          <p className="mb-6 md:mb-9">SaaS products offer a range of benefits to businesses of all sizes. They provide cost savings, scalability, ease of use, automatic updates, and access from anywhere. If you're considering implementing a SaaS product in your business, be sure to do your research and choose a provider that meets your needs. With the right solution in place, you can streamline your operations, increase productivity, and grow your business. </p>
        </div>
      </div>
      {/* blog details end */}

      <div className='border-b-2 lg:container mx-auto mt-[50px]'></div>

      <div className='clear-both'></div>
      <section className='Our-blogs'>
        <div className='md:container p-4 mx-auto mt-[50px] mb-[50px] lg:mb-[100px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Recommended <span className='text-[#F26722]'> Blogs</span></h2>

          <div className='mt-[50px]'>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              autoPlaySpeed={3000}
              autoPlay={true}
              infinite={true}
            >
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/we-can-blend-colors-multiple-ways" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">We can blend colors multiple ways, the most common</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/blog-We-can-blend-colors-multiple-ways.png'} alt="We-can-blend-colors-multiple-ways" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/engineering-experiences-a-journey" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Engineering Experiences: A Journey of Innovation and Creativity</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/Blog-Engineering-Experiences.png'} alt="Engineering-Experiences" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#BEE1E6]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/saas-products" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Customer Services</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">SaaS Products</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/blog-SaaS-Products.png'} alt="SaaS-Products" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/the-evolution-significance" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">The Evolution and Significance of Cloud Engineering in Modern Computing</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/the-evolution-and-significance.png'} alt="the-evolution-and-significance" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/navigating-the-digital-migration" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Navigating the Digital Migration: A New Frontier in Business Transformation</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/../images/navigating-the-digital-migration.png'} alt="navigating-the-digital-migration" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

            </Carousel>
          </div>
        </div>
      </section>
    </>
  )
}

export default TheEvolutionSignificance