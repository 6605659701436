import React, { Suspense } from 'react';
import { Link } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa';
import $ from 'jquery';
import { useEffect } from 'react';
import Discovery from '../../Components/DiscoveryCall/Discovery';
import Faqs from '../../Components/Faqs/Faqs';
import ReactHelmet from '../../Components/ReactHelmet';
import env from './../../Config';
const Services = () => {

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.height >= 600) {
      $('.counter-value').text(100);
    }

  }, []);

  $(window).scroll(function () {
    if ($('#counter').offset()) {
      var a = 0;
      var oTop = $('#counter').offset().top - window.innerHeight;
      if (a == 0 && $(window).scrollTop() > oTop) {
        $('.counter-value').each(function () {
          var $this = $(this),
            countTo = $this.attr('data-count');
          $({ countNum: $this.text() }).animate({ countNum: countTo }, {
            duration: 1000, easing: 'swing',
            step: function () { $this.text(Math.floor(this.countNum)); },
            complete: function () { $this.text(this.countNum); }
          });
        });
        a = 1;
      }
    }
  });

  return (
    <>
      <Suspense>
        <ReactHelmet
          title={`Versatile Commerce | Services`}
          keywords={`Our Services`}
          description={`Elevate your Leading the Way to Success: Your Vision, Powered by Our Dynamic Services and Dedicated Team`}
          seoimgs={`banner-services.png`}
        />
      </Suspense>
      <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/banner-services.png)` }}>
        <div className='lg:container mx-auto px-4'>
          <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
            <div className="flex-1">
              <h2 className="text-[2rem] lg:text-[82px] leading-tight font-bold text-center md:text-left">
                Our <span className='text-[#f26722]'> Services </span>
              </h2>
              <p className="text-[18px] lg:text-[24px] text-[#475467] font-semibold mt-5 lg:w-[50%] text-center md:text-left">Elevate your Leading the Way to Success: Your Vision, Powered by Our Dynamic Services and Dedicated Team
              </p>
              <div className="flex items-center justify-start mt-[50px] lg:mt-[110px]">
                <Link to='/contact-form' type="button" className='xs-mx-auto'> <a className="flex items-center relative transition-all group px-[22px] py-[12px] lg:px-[32px] lg:py-[19px] rounded-[8px] bg-[#F2F4F7] text-[#101828] text-[18px] hover:bg-[#f26722] hover:text-[#fff] hover:drop-shadow-lg duration-300  text-heading-6 tracking-wide " href="#">
                  <span className="block text-inherit w-full h-full rounded-[8px] text-lg cutom-font mr-[15px]">Talk to our Experts</span><FaArrowRight className='text-[16px] ml-[2px]' /></a></Link>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-1 px-4 md:px-0 mt-[10px] lg:mt-[60px] pb-[70px] gap-x-[10px] gap-y-[15px] lg:gap-x-[46px] lg:gap-y-[77px] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
            <div className="flex items-start gap-[15px] md:gap-[25px] mx-auto md:ml-0">
              <img src={env.baseUrl + '/images/serv-project-done.png'} />
              <div>
                <p id="counter" className="font-bold text-[35px] text-[#FF6B01] md:text-heading-6 md:mb-3 md:mb-[22px]"><span className="counter-value" data-count="100">100 </span>+</p>
                <p className="text-text text-[20px] text-[#667085] font-bold">Completed Projects</p>
              </div>
            </div>

            <div className="flex items-start gap-[15px] md:gap-[25px] mx-auto md:ml-0">
              <img className='ml-[-50px] md:ml-[0]' src={env.baseUrl + '/images/serv-industries.png'} />
              <div>
                <p id="counter" className="font-bold text-[35px] text-[#FF6B01] md:text-heading-6 md:mb-3 md:mb-[22px]"><span className="counter-value" data-count="8">8 </span>+ </p>
                <p className="text-text text-[20px] text-[#667085] font-bold">Industries</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className='our-usp mt-[70px]'>
        <div className='lg:container mx-auto px-4'>
          <div className='text-center'>
            <h2 className="text-[2rem] lg:text-[56px] leading-tight font-bold">
              Our <span className='text-[#f26722]'> USP </span>
            </h2>
            <p className="text-[24px] text-[#475467] font-bold lg:w-[60%] mx-auto mt-4">
              Embrace the digital transformation journey with confidence, knowing you have a reliable partner by your side.
            </p>
          </div>

          <div className='mt-[60px]'>
            <div className='grid lg:grid-cols-3 gap-10'>
              <div className="col-span-1 xs-t-center">
                <div className='border-[#101828] border-2 rounded-[18px] p-4 px-[60px] pt-[40px] pb-[70px] hover:shadow-lg hover:border-[#f26722] duration-300 hover:scale-105 min-h-[370px]'>
                  <img className='mx-auto md:ml-0' src={env.baseUrl + '/images/serv-market-icon.png'} />
                  <h3 className='text-[25px] lg:text-[35px] cutom-font-bold leading-tight mt-[30px]'>Market <br /> research</h3>
                  <p className='text-[16px] text-[#475467] font-bold mt-[20px]'>
                    Expert IT Solutions Tailored to Your Needs: Harnessing Innovation for Optimal Performance.
                  </p>
                </div>
              </div>

              <div className="col-span-1 xs-t-center">
                <div className='border-[#101828] border-2 rounded-[18px] p-4 px-[60px] pt-[40px] pb-[70px] hover:shadow-lg hover:border-[#f26722] duration-300 hover:scale-105 min-h-[370px]'>
                  <img className='mx-auto md:ml-0' src={env.baseUrl + '/images/serv-stategic.png'} />
                  <h3 className='text-[25px] lg:text-[35px] cutom-font-bold leading-tight mt-[30px]'>Strategic <br /> Consulting</h3>
                  <p className='text-[16px] text-[#475467] font-bold mt-[20px]'>
                    Accelerate Business Growth with Custom Software Development and Agile Solutions.
                  </p>
                </div>
              </div>

              <div className="col-span-1 xs-t-center">
                <div className='border-[#101828] border-2 rounded-[18px] p-4 px-[60px] pt-[40px] pb-[70px] hover:shadow-lg hover:border-[#f26722] duration-300 hover:scale-105 min-h-[370px]'>
                  <img className='mx-auto md:ml-0' src={env.baseUrl + '/images/serv-cognitive.png'} />
                  <h3 className='text-[25px] lg:text-[35px] cutom-font-bold leading-tight mt-[30px]'>Cognitive <br />Solution </h3>
                  <p className='text-[16px] text-[#475467] font-bold mt-[20px]'>
                    Innovative AI Solutions for Data-driven Insights and Enhanced Decision-making Strategic IT Roadmap.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-services lg:mb-[60px]">
        <div className='lg:container mx-auto px-4'>
          <div className='text-center mt-[70px]'>
            <h2 className="text-[2rem] lg:text-[56px] leading-tight font-bold">
              Our <span className='text-[#f26722]'> Services </span>
            </h2>
            <p className="mx-auto text-[24px] text-[#475467] font-bold lg:w-[60%] mx-auto mt-4">Inspiring Possibilities, Transforming Realities: Trust in Our Dedicated Services and Unwavering Commitment
            </p>
          </div>

          <div className='grid grid-cols-1 gap-x-[50px] gap-y-[45px] lg:gap-x-[70px] lg:gap-y-[77px] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-[70px]'>

            <div className="flex items-start gap-[15px] md:gap-[30px] transition-all duration-300 partner-item lg:p-[10px] transition-all duration-300 partner-item lg:p-[10px]">
              <img src={env.baseUrl + '/images/serv-our-icon1.png'} />
              <div>
                <p className="cutom-font-bold  text-[#101828] text-[22px] md:text-heading-6 mb-1 md:mb-[10px] ">Engineering Experience
                </p>
                <p className="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                  Engineering Excellence Unleashed: Building a Future of Innovation and Success
                  <Link to='/services/engineering-experience' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                </p>
              </div>
            </div>

            <div className="flex items-start gap-[15px] md:gap-[30px] transition-all duration-300 partner-item lg:p-[10px]">
              <img src={env.baseUrl + '/images/serv-our-icon2.png'} />
              <div>
                <p className="cutom-font-bold  text-[#101828] text-[22px] md:text-heading-6 mb-1 md:mb-[10px]">Technical Development
                </p>
                <p className="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                  Empowering Technical Growth: Elevate Your Business with Our Expertise
                  <Link to='/services/technical-development' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                </p>
              </div>
            </div>

            <div className="flex items-start gap-[15px] md:gap-[30px] transition-all duration-300 partner-item lg:p-[10px]">
              <img src={env.baseUrl + '/images/serv-our-icon3.png'} />
              <div>
                <p className="cutom-font-bold  text-[#101828] text-[22px] md:text-heading-6 mb-1 md:mb-[10px]">Technical Operations
                </p>
                <p className="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                  Precision in Action: Technical Operations Redefined for Optimal Performance
                  <Link to='/services/technical-operations' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                </p>
              </div>
            </div>

            <div className="flex items-start gap-[15px] md:gap-[30px] transition-all duration-300 partner-item lg:p-[10px]">
              <img src={env.baseUrl + '/images/serv-our-icon4.png'} />
              <div>
                <p className="cutom-font-bold  text-[#101828] text-[22px] md:text-heading-6 mb-1 md:mb-[10px]">Cloud Engineering
                </p>
                <p className="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                  Architecting Success in the Cloud: Trust Our Engineering Expertise
                  <Link to='/services/cloud-engineering' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                </p>
              </div>
            </div>

            <div className="flex items-start gap-[15px] md:gap-[30px] transition-all duration-300 partner-item lg:p-[10px]">
              <img src={env.baseUrl + '/images/serv-our-icon5.png'} />
              <div>
                <p className="cutom-font-bold  text-[#101828] text-[22px] md:text-heading-6 mb-1 md:mb-[10px]">Digital Migration
                </p>
                <p className="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                  Navigate the Digital Frontier: Experience Smooth Migration with Us
                  <Link to='/services/digital-migration' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                </p>
              </div>
            </div>

            <div className="flex items-start gap-[15px] md:gap-[30px] transition-all duration-300 partner-item lg:p-[10px]">
              <img src={env.baseUrl + '/images/serv-our-icon6.png'} />
              <div>
                <p className="cutom-font-bold  text-[#101828] text-[22px] md:text-heading-6 mb-1 md:mb-[10px]">Research On Demand
                </p>
                <p className="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                  On-Demand Research, Endless Possibilities: Let's Explore Together
                  <Link to='/services/research-on-demand' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                </p>
              </div>
            </div>

            <div className="flex items-start gap-[15px] md:gap-[30px] transition-all duration-300 partner-item lg:p-[10px]">
              <img src={env.baseUrl + '/images/serv-our-icon7.png'} />
              <div>
                <p className="cutom-font-bold  text-[#101828] text-[22px] md:text-heading-6 mb-1 md:mb-[10px]">Cloud Partnership Consulting
                </p>
                <p className="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                  Elevate Your Cloud Journey: Thrive with Strategic Partnerships.
                  <Link to='/services/cloud-partnership-consulting' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                </p>
              </div>
            </div>

            <div className="flex items-start gap-[15px] md:gap-[30px] transition-all duration-300 partner-item lg:p-[10px]">
              <img src={env.baseUrl + '/images/serv-our-icon8.png'} />
              <div>
                <p className="cutom-font-bold  text-[#101828] text-[22px] md:text-heading-6 mb-1 md:mb-[10px]">IoT
                </p>
                <p className="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                  IoT Innovation in Motion: Where Devices Transform into Intelligent Solutions
                  <Link to='/services/iot' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                </p>
              </div>
            </div>

            <div className="flex items-start gap-[15px] md:gap-[30px] transition-all duration-300 partner-item lg:p-[10px]">
              <img src={env.baseUrl + '/images/serv-our-icon9.png'} />
              <div>
                <p className="cutom-font-bold  text-[#101828] text-[22px] md:text-heading-6 mb-1 md:mb-[10px]">Blockchain
                </p>
                <p className="text-text text-[#101828] text-[16px] mt-[11px] font-bold">
                  Transform Your Business with Blockchain: Where Vision Meets Immutability
                  <Link to='/services/blockchain' className='text-[#F26722] ml-1 hover:text-gray-400 hover:scale-125 duration-300 inline'>&nbsp;More&nbsp;<FaArrowRight className='inline text-[13px]' /></Link>
                </p>
              </div>
            </div>

          </div>
        </div>
      </section>
      <Discovery
        title={'Discovery'}
        subTitle={'Call'}
        desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
        btnName={'Book Now'}
        placeholder={'Enter your mail'} />
      <Faqs
        heading1={'What are the key starting steps to Digital Transformation?'}
        content1={"Key starting steps to Digital Transformation include defining clear goals, assessing current processes and technologies, securing executive buy-in, forming a cross-functional team, and creating a comprehensive roadmap. Prioritise areas with the highest potential for impact. "}
        heading2={"What types of technologies are used in Digital Transformation?"}
        content2={"Technologies used in Digital Transformation include cloud computing, artificial intelligence, data analytics, Internet of Things (IoT), automation, blockchain, and machine learning. These technologies drive innovation, improve efficiency, and enable new business models. "}
        heading3={"Will Digital Transformation and technology replace my employees?"}
        content3={"Digital Transformation may change the nature of certain job roles, but it doesn't necessarily mean replacing employees. Instead, it can enhance employee capabilities, allowing them to focus on higher-value tasks that require creativity, critical thinking, and human interaction. "}
        heading4={"What types of process changes should I expect through Digital Transformation?"}
        content4={"Through Digital Transformation, you can expect streamlined workflows, automated processes, data-driven decision-making, enhanced collaboration, and improved customer interactions. There might also be a shift towards agile methodologies and continuous improvement. "}
        heading5={"What industries does Digital Transformation impact?"}
        content5={"Digital Transformation impacts a wide range of industries, including manufacturing, healthcare, finance, retail, education, agriculture, transportation, and more. Essentially, any industry that relies on processes, data, and customer interactions can benefit from Digital Transformation. "}
        status={true}
      />
    </>
  )
}

export default Services