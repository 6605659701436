import React from 'react'
import { useEffect,Suspense } from 'react'
import Discovery from '../../../Components/DiscoveryCall/Discovery'
import ReactHelmet from '../../../Components/ReactHelmet'
import env from '../../../Config'

const TechnicalOperations = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    })

  return (
    <>
    <Suspense>
        <ReactHelmet
          title={`Versatile Commerce | Services | Technical operations`}
          keywords={`Technical Operations`}
          description={`Empowering businesses with seamless technical operations management, ensuring reliability, security, and optimal performance in a dynamic digital landscape`}
          seoimgs={`Technical-operations.png`}
        />
      </Suspense>
    <div className="bg-[#FAEDE3] services-banner-main max-[100%]" style={{ backgroundImage: `url(${env.baseUrl}/images/Technical-operations.png)` }}>
        <div className='lg:container mx-auto px-4'>
            <div className="px-[12px] md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
                <div className="flex-1 md:py-[70px] lg:py-[100px]">
                    <h2 className="text-[2rem] lg:text-[82px] text-[#222] md:text-[#fff] md:w-[50%] lg:w-[40%]  leading-tight font-bold text-center md:text-left">
                        Technical Operations
                    </h2>
                    <p className="text-[18px] lg:text-[24px] text-[#333] md:text-[#B5BDCC] font-semibold mt-5 md:w-[50%] lg:w-[40%] text-center md:text-left">
                        Empowering businesses with seamless technical operations management, ensuring reliability, security, and optimal performance in a dynamic digital landscape
                    </p>
                </div>
            </div>
        </div>
    </div>

    <section className='serviceTechnical'>
        <div className="lg:container mx-auto px-4 my-[40px] md:my-[70px] lg:mt-[100px]">
            <div className="text-center">
                <h2 className="text-[2rem] lg:text-[56px] leading-tight font-bold">Technical <span className="text-[#f26722]"> Operations </span></h2>
                <p className="text-[20px] md:text-[24px] text-[#475467] font-bold lg:w-[60%] mx-auto mt-4">
                    Enhancing business potential via meticulous technical operations, safeguarding systems and fostering a resilient digital ecosystem</p>
            </div>
            
            <div className='xl:px-0 mt-[70px] lg:mt-[80px]'>
                <div className='lg:grid lg:grid-cols-2 lg:gap-[80px]'>
                    <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                        <img className='max-w-full' src={env.baseUrl + '/images/Appln-performance-monitoring.png'} alt='Appln performance monitoring'/>
                    </div>

                    <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                        <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                        <h2 className="base-h2 font-bold leading-none">Appln performance monitoring </h2>
                        <p className='base-p1'>
                            Application Performance Monitoring is a critical practice that provides insights into an application's behaviour, user experience, and technical performance aspects. 
                        </p>

                        <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[50px]'>
                            <div className='mb-[10px] lg:mb-0 '>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Real-Time Visibility</h5>
                                    <div className='tooltip_box'>APM provides real-time insights into an application's performance, allowing developers and operations teams to monitor how the application is behaving and identify potential issues immediately.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> End-User Experience</p>
                                    <div className='tooltip_box'>APM tools enable tracking and analysis of user interactions, helping to gauge user satisfaction, detect slow response times, and pinpoint the root causes of poor user experiences.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Resource Utilisation</h5>
                                    <div className='tooltip_box'>APM monitors the utilisation of resources such as CPU, memory, disk, and network, helping to identify resource bottlenecks that might impact performance.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Transaction Tracing</h5>
                                    <div className='tooltip_box'>APM tools offer detailed tracing of individual transactions or requests through various application components, helping to diagnose performance problems down to the code level.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Error Detection</h5>
                                    <div className='tooltip_box'>APM solutions identify and report errors, exceptions, and crashes in real-time, assisting developers in swiftly addressing issues that could affect application stability.</div>
                                </div>
                            </div>
                            <div className='mb-[30px] lg:mb-0'>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scalability Insights</h5>
                                    <div className='tooltip_box'>APM helps in determining how well an application can scale under varying loads, assisting in making informed decisions about resource provisioning and scaling strategies.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Code-Level Insights</h5>
                                    <div className='tooltip_box'>Developers can analyse application code execution and identify performance bottlenecks or inefficiencies that could be optimized to enhance overall performance.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Third-Party Integration</h5>
                                    <div className='tooltip_box'>APM tools often integrate with third-party services and APIs, allowing monitoring of external services that might impact the application's performance.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Alerts and Notifications</h5>
                                    <div className='tooltip_box'>APM tools can be configured to send alerts and notifications when performance metrics cross predefined thresholds, enabling proactive issue resolution.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Historical Analysis</h5>
                                    <div className='tooltip_box'>APM solutions maintain historical data, enabling the comparison of performance trends over time and helping to identify long-term improvements or degradation.</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                    <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                        <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                        <h2 className="base-h2 font-bold leading-none">Devops & Application Maintenance</h2>
                        <p className='base-p1'>
                            DevOps emphasises collaboration, automation, continuous integration, and continuous delivery to streamline the software development lifecycle. 
                        </p>

                        <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[50px]'>
                            <div className='mb-[10px] lg:mb-0 '>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Automation</h5>
                                    <div className='tooltip_box'>Automation is a fundamental principle of DevOps. It involves automating manual and repetitive tasks throughout the software development lifecycle, leading to improved efficiency, reduced errors, and faster delivery.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Integration (CI) </h5>
                                    <div className='tooltip_box'>CI involves the frequent integration of code changes into a shared repository. Automated testing and validation processes are run after each integration, ensuring that new code does not disrupt the existing application functionality.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Continuous Delivery (CD)</h5>
                                    <div className='tooltip_box'>CD takes CI a step further by automating the deployment process. It ensures that code is always in a deployable state, allowing for rapid and reliable deployment to production or staging environments.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Infrastructure as Code (IaC)</h5>
                                    <div className='tooltip_box'>IaC treats infrastructure provisioning and management as code. This approach enables consistent and repeatable infrastructure setups, reducing the chance of configuration drift and making maintenance easier.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Integration of Development and Operations</h5>
                                    <div className='tooltip_box'> DevOps is a software development methodology that emphasizes collaboration and communication between software developers (Dev) and IT operations (Ops) teams, ensuring a streamlined and continuous delivery pipeline.</div>
                                </div>
                            </div>

                            <div className='mb-[30px] lg:mb-0'>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Monitoring and Feedback</h5>
                                    <div className='tooltip_box'>DevOps encourages continuous monitoring of applications in production. This feedback loop helps teams identify and address issues quickly, leading to improved application reliability and user satisfaction.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Scalability and Resilience</h5>
                                    <div className='tooltip_box'>DevOps practices help build applications that can scale up or down based on demand. By automating scaling processes and implementing redundancy, applications become more resilient to failures.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Security Integration</h5>
                                    <div className='tooltip_box'>Security is integrated throughout the DevOps lifecycle, with emphasis on practices such as automated security testing, vulnerability scanning, and code analysis to ensure the application's security posture.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Version Control</h5>
                                    <div className='tooltip_box'> Version control systems (e.g., Git) play a pivotal role in DevOps. They enable teams to track changes, collaborate effectively, and manage different versions of the application code.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Application Maintenance</h5>
                                    <div className='tooltip_box'>Post-deployment, DevOps practices extend to application maintenance. Regular updates, bug fixes, and feature enhancements are seamlessly integrated into the existing pipeline, ensuring a balance between innovation and stability.</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                        <img className='max-w-full' src={env.baseUrl + '/images/Devops-application-maintenance.png'} alt='Devops & Application Maintenance'/>
                    </div>
                </div>

                <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                    <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                        <img className='max-w-full' src={env.baseUrl + '/images/Data-centre-operations.png'} alt='Data centre operations'/>
                    </div>

                    <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                        <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                        <h2 className="base-h2 font-bold leading-none">Data centre operations</h2>
                        <p className='base-p1'>
                        In essence, data centre operations encompass a wide range of tasks aimed at ensuring the reliability, security, and efficiency of the physical infrastructure supporting critical IT services and applications.
                        </p>

                        <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[30px]'>
                            <div className='mb-[10px] lg:mb-0 '>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Infrastructure Management</h5>
                                    <div className='tooltip_box'>Data centre operations involve the management and maintenance of physical infrastructure, including servers, storage systems, networking equipment, and cooling systems.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Hardware Provisioning</p>
                                    <div className='tooltip_box'>Data centre staff handle hardware provisioning, ensuring that servers and other equipment are properly configured, deployed, and maintained.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Power and Cooling Management</h5>
                                    <div className='tooltip_box'>Efficient power and cooling systems are essential for preventing equipment overheating and ensuring optimal performance. Data centre operations include monitoring and managing these systems.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Security and Access Control</h5>
                                    <div className='tooltip_box'>Data centres house sensitive equipment and data. Proper security protocols, access controls, surveillance, and monitoring are crucial to prevent unauthorised access and protect data integrity.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Backup and Disaster Recovery</h5>
                                    <div className='tooltip_box'>Regular data backups and well-defined disaster recovery plans are essential components of data centre operations. These measures ensure business continuity in the event of hardware failure, data loss, or other emergencies.</div>
                                </div>
                            </div>
                            <div className='mb-[30px] lg:mb-0'>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Network Operations</h5>
                                    <div className='tooltip_box'>Managing networking components, such as switches, routers, and firewalls, is integral to maintaining connectivity and security within the data centre environment.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Monitoring and Performance Optimisation</h5>
                                    <div className='tooltip_box'>Continuous monitoring of data centre equipment and systems helps identify performance issues or anomalies. Optimisation efforts can then be undertaken to enhance efficiency and reliability.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Capacity Planning</h5>
                                    <div className='tooltip_box'>Data centre operators must anticipate future growth and capacity needs to ensure that resources are properly allocated and that the infrastructure can accommodate increasing demands.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Alerts Compliance and Regulations</h5>
                                    <div className='tooltip_box'>Data centres often need to adhere to industry-specific regulations and compliance standards. Operations teams ensure that these requirements are met to avoid legal and security risks.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Documentation and Reporting</h5>
                                    <div className='tooltip_box'>Detailed documentation of hardware configurations, network layouts, maintenance schedules, and incident reports is essential for smooth data centre operations and effective troubleshooting.</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                    <div className='xs-order2 flex-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                        <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                        <h2 className="base-h2 font-bold leading-none">Enterprise monitoring</h2>
                        <p className='base-p1'>
                            enterprise monitoring is a crucial practice that ensures the reliability, performance, and security of an organisations IT infrastructure and services. It empowers IT teams to respond proactively to issues, optimise resources, and align technical efforts with business objectives.
                        </p>

                        <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[50px]'>
                            <div className='mb-[10px] lg:mb-0 '>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Comprehensive Visibility</h5>
                                    <div className='tooltip_box'>Enterprise monitoring provides a holistic view of an organisations IT landscape, including applications, networks, servers, databases, and user experiences.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer tooltip_btn"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Real-time Performance Tracking</h5>
                                    <div className='tooltip_box'>Monitoring tools offer real-time insights into the performance of critical systems and services, enabling quick identification and resolution of issues.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> End-to-End User Experience</h5>
                                    <div className='tooltip_box'>Monitoring extends to end-user experiences, helping organisations understand how users interact with applications and services, and identifying areas for improvement.</div>
                                </div>
                                 <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Alerting and Escalation</h5>
                                    <div className='tooltip_box'>Monitoring tools can be configured to send alerts and notifications when predefined thresholds are exceeded, ensuring that relevant teams can respond promptly.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Capacity Planning</h5>
                                    <div className='tooltip_box'>By analysing trends and patterns, enterprise monitoring assists in capacity planning, enabling organisations to allocate resources effectively and avoid performance bottlenecks.</div>
                                </div>
                            </div>

                            <div className='mb-[30px] lg:mb-0'>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Incident Detection and Response</h5>
                                    <div className='tooltip_box'>Enterprise monitoring aids in promptly identifying incidents such as system outages, performance degradation, security breaches, and other anomalies, allowing for swift response and resolution.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Predictive Analytics</h5>
                                    <div className='tooltip_box'>Some advanced monitoring systems employ predictive analytics to anticipate potential issues based on historical data, facilitating proactive problem-solving.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Compliance and Auditing</h5>
                                    <div className='tooltip_box'>Monitoring supports compliance with regulatory standards by tracking and documenting system activities, security events, and changes to configurations.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Multi-Platform Monitoring</h5>
                                    <div className='tooltip_box'>Enterprise monitoring solutions often support diverse environments, including on-premises, cloud, and hybrid infrastructures, providing a unified view of the entire ecosystem.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Business Insights</h5>
                                    <div className='tooltip_box'>Beyond technical metrics, enterprise monitoring can provide insights into the correlation between IT performance and business outcomes, aiding strategic decision-making.</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='xs-order1 h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-2 mt-5 lg:mt-5'>
                        <img className='max-w-full' src={env.baseUrl + '/images/Enterprise-monitoring.png'} alt='Enterprise monitoring'/>
                    </div>
                </div>

                <div className='flex-reverse lg:grid lg:grid-cols-2 lg:gap-[70px] mt-[10px] lg:mt-[80px]'>
                    <div className='h-full w-full object-cover mb-[30px] lg:mb-0 lg:flex-1'>
                        <img className='max-w-full' src={env.baseUrl + '/images/Dataset-knowledge-intelligence.png'} alt='Dataset Knowledge Intelligence'/>
                    </div>

                    <div className='flex-1 order-1 mb-[20px] lg:gap-[30px] xl:gap-[64px]'>

                        <div className="bg-[#FFF3EA] mb-[30px] text-[#FF6B01] py-3 px-7 text-[16px] text-center rounded-[40px] inline-block clear-both">What we Do, What you Get</div>

                        <h2 className="base-h2 font-bold leading-none">Dataset | Knowledge | Intelligence</h2>
                        <p className='base-p1'>
                            In essence, datasets provide the raw material, knowledge refines it into insights, and intelligence applies those insights to solve problems and make decisions. This interplay is at the core of human cognition and technological advancement.
                        </p>

                        <div className='md:grid md:grid-cols-2 mt-[20px] lg:mt-[20px] md:gap-y-[34px] lg:gap-x-[30px]'>
                            <div className='mb-[10px] lg:mb-0 '>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer "> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Composition</h5>
                                    <div className='tooltip_box'>Datasets can comprise various types of data, including numerical, categorical, text, images, audio, and video. These elements collectively provide a comprehensive view of a particular subject.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <p className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Collection</p>
                                    <div className='tooltip_box'>Datasets are gathered from diverse sources such as surveys, sensors, databases, web scraping, and social media, reflecting real-world phenomena and information.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Quality</h5>
                                    <div className='tooltip_box'>High-quality datasets are crucial for accurate results. They should be clean, complete, and representative of the problem or phenomenon being studied.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Preprocessing</h5>
                                    <div className='tooltip_box'>Before analysis, datasets often require preprocessing, which involves tasks like cleaning, transformation, and normalisation to ensure consistency and usefulness.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Explicit vs. Tacit</h5>
                                    <div className='tooltip_box'>Knowledge can be explicit (formally documented and easily shared) or tacit (personal insights, intuitions, and expertise that are harder to articulate).</div>
                                </div>
                            </div>
                            <div className='mb-[30px] lg:mb-0'>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Application</h5>
                                    <div className='tooltip_box'>Knowledge empowers critical thinking, problem-solving, and decision-making. It helps individuals make informed choices and predictions based on their understanding of patterns and relationships.</div>
                                </div>
                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Human Intelligence</h5>
                                    <div className='tooltip_box'>Human intelligence is a complex blend of cognitive processes, emotions, and consciousness that enables advanced reasoning, emotional understanding, and self-awareness.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Artificial Intelligence (AI)</h5>
                                    <div className='tooltip_box'>AI refers to computer systems designed to mimic human intelligence. It involves machine learning algorithms, neural networks, and data processing to perform tasks traditionally requiring human intelligence.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Machine Learning</h5>
                                    <div className='tooltip_box'>A subset of AI, machine learning involves training algorithms on datasets to recognise patterns, make predictions, and automate decisions without explicit programming.</div>
                                </div>

                                <div className='tooltip_btn'>
                                    <h5 className="text-[18px] mb-3 cutom-font-bold-200 flex xs-flex-left hover:text-[#FB9851] duration-300 cursor-pointer"> <img className="max-w-full mr-2" src={env.baseUrl + '/images/leaf-icon.svg'} /> Levels of AI</h5>
                                    <div className='tooltip_box'>AI can range from weak or narrow AI (focused on specific tasks) to strong AI (possessing human-like cognitive capabilities) and even super intelligent AI (exceeding human intelligence, though this concept is speculative).</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <Discovery
        title={'Discovery '}
        subTitle={'Call'}
        desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
        btnName={'Subscribe'}
        placeholder={'Drop a Email'} />
    </>
  )
}

export default TechnicalOperations