import React, { useEffect, useState, useRef, lazy, Fragment, Suspense } from 'react';
import './Styles.scss';
import { Tab } from '@headlessui/react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaArrowRight } from 'react-icons/fa';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import ReactHelmet from '../../Components/ReactHelmet';
import env from '../../Config';

const Faqs = lazy(() => import("../../Components/Faqs/Faqs"));
const Discovery = lazy(() => import("./../../Components/DiscoveryCall/Discovery"));
const CountryList = lazy(() => import("../../Components/CountryList"));

const Home = () => {
  const [data, setData] = useState({
    name: '',
    email: '',
    phoneNo: '',
    companyName: '',
    designation: '',
    message: '',
    capcha: '',
    countryCode: '44',
    emailValid: false,
    phoneNoValid: false,
    isFormTouched: false,
    isInvalidCapcha: false,
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const selectedIndex = useRef(0);
  const [emailReachUs, setEmailReachUs] = useState({ email: '', emailValid: false });

  const [errorMsg, setErrorMsg] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contactUsFun = () => {
    if (data.emailValid && data.phoneNoValid && data.countryCode && data.companyName && data.name && data.phoneNo && data.message) {
      if (validateCaptcha(data.capcha) == true) {
        const obj = {
          "properties": [
            { "property": "email", "value": data.email },
            { "property": "fullName", "value": data.name },
            { "property": "firstname", "value": data.name },
            { "property": "country_code", "value": data.countryCode },
            { "property": "phone", "value": data.phoneNo },
            { "property": "company", "value": data.companyName },
            { "property": "message", "value": data.message },
            { "property": "category_type", "value": 'Contact US' },
          ]
        };

        const url = 'https://nodweb.versatilecommerce.com/contactus'; // Replace with your API endpoint
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(obj),
        }).then(response => response.json())
          .then(result => {
            if (result && result?.body?.status != "error" && result?.body && result?.body?.category != 'OBJECT_ALREADY_EXISTS') {
              setData({
                name: '',
                email: '',
                phoneNo: '',
                companyName: '',
                countryCode: '44',
                message: '',
                capcha: '',
                emailValid: false,
                phoneNoValid: false,
                isFormTouched: false,
                isInvalidCapcha: false,
              });
              toogleModal(true);
            } else {
              setData({
                ...data,
                capcha: '',
                isFormTouched: false,
                isInvalidCapcha: false,
              });
              setErrorMsg(result?.body?.message);
            }
          })
          .catch(error => {
            // Handle error
          });
      } else {
        setData({ ...data, isInvalidCapcha: true });
      }

    } else {
      setData({ ...data, isFormTouched: true });
    }
  };

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }
  // get started contact form end 


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    setTimeout(() => {
      $(window).scroll(function () {
        if ($('#counter').offset()) {
          var a = 0;
          var oTop = $('#counter').offset().top - window.innerHeight;
          if (a == 0 && $(window).scrollTop() > oTop) {
            $('.counter-value').each(function () {
              var $this = $(this),
                countTo = $this.attr('data-count');
              $({ countNum: $this.text() }).animate({ countNum: countTo }, {
                duration: 1000, easing: 'swing',
                step: function () { $this.text(Math.floor(this.countNum)); },
                complete: function () { $this.text(this.countNum); }
              });
            });
            a = 1;
          }
        }
      });
      // number count end
    }, 2000);
  }, []);


  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedIndex.current > 3) {
        setCurrentIndex(0);
        selectedIndex.current = 0;
        // console.log('aaa', selectedIndex.current);
      } else {
        // console.log('BBB', selectedIndex.current + 1);

        setCurrentIndex(selectedIndex.current + 1);
        selectedIndex.current = (selectedIndex.current + 1);
      }
    }, 6000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);


  function closeModal() {
    setIsOpen(false)
  }
  function openModal() {
    setIsOpen(true);
    setTimeout(() => {
      loadCaptchaEnginge(6);
    }, 100);
  }
  function onSubmit() {
    setIsOpen(false)
  }


  function toogleModal(val) {
    setIsOpen3(val)
    setIsOpen(false);
  }

  function isCloseSuccess() {
    setIsOpen3(false);
  }
  function isOpenSuccess() {
    setIsOpen3(true)
  }


  const newsLatter = () => {
    if (emailReachUs.email && validateEmail(emailReachUs.emailValid) != null) {
      const obj = { email: emailReachUs.email };
      const url = 'https://nodweb.versatilecommerce.com/update-contact?type=reach-us'; // Replace with your API endpoint
      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
      }).then(response => response.json())
        .then(result => {
          if (result && result?.body?.status != "error" && result?.body && result?.body?.category != 'OBJECT_ALREADY_EXISTS') {
            setEmailReachUs({
              emailValid: false,
              email: '',
            });

            isOpenSuccess();
          } else {
            setErrorMsg(result?.body?.message);
          }
        })
        .catch(error => {
          // Handle error
        });
    } else {
    }
  }


  return (
    <>
      <Suspense>
        <ReactHelmet
          title={`Versatile Commerce`}
          keywords={`Revolutionise your digital future with our transformative expertise`}
          description={`Elevate your organization's digital capabilities and thrive in the rapidly evolving digital landscape`}
          seoimgs={`Revolutionise-your-digital-future.png`}
        />
      </Suspense>
      {/* new banner start */}
      <div className="bg-[#FAEDE3] banner-main" style={{ backgroundImage: `url(${env.baseUrl}/images/Revolutionise-your-digital-future.png)` }}>
        <div className="lg:container mx-auto px-4 md:px-[36px] xl:px-0 mt-0 z-1 relative mx-auto py-[60px] max-w-[100%] lg:flex lg:items-center">
          <div className="flex-1">
            <h1 className="text-[2rem] lg:text-[80px]  text-[#101828!important] leading-tight font-bold md:w-[60%] lg:w-[100%] bg-[#ffffffe0] md:bg-[#ffffff00] p-5 md:p-0 xs-t-shadow">
              Revolutionise <span className='text-[#f26722]'> your digital future </span> with our transformative expertise
            </h1>
            <p className="text-[24px] text-[#000] md:text-[#667085] font-semibold mt-5 lg:w-[90%] bg-[#ffffffe0] md:bg-[#ffffff00] p-5 lg:pl-3">Elevate your organization's digital capabilities and thrive in the rapidly evolving digital landscape
            </p>
            <div className="md:flex items-center mt-[50px] lg:pl-3">
              <button type="button" onClick={openModal} className="flex items-center relative transition-all  group px-[22px] py-[8px] lg:px-[32px] lg:py-[19px] rounded-[8px] bg-gray-900 text-white hover:bg-[#f26722] hover:text-[#fff] hover:drop-shadow-lg duration-300 bg-black text-heading-6 tracking-wide " ><span className="block text-inherit w-full h-full rounded-[8px] text-lg cutom-font mr-[15px]">Get Started</span><FaArrowRight className='text-[16px] ml-[2px]' /> </button>

              <Link className="cutom-font-bold-200 text-base flex items-center text-lg leading-[18px] gap-[5px] hover:text-[#f26722] md:ml-[34px] mt-5 md:mt-[0]" to="/services">Learn more <FaArrowRight className='text-[16px] ml-[10px]' />
              </Link>
            </div>
          </div>
          <div className="hidden relative flex-1 h-auto pt-[50px] lg:pt-[250px] lg:block mt-[20px]">
            <div className="absolute w-full left-20 animate-hero-thumb-sm-animation bottom-[-60px] max-w-[526px]">
              {/* <img className="h-full w-full object-cover" src={env.baseUrl + '/images/banner-img.svg'} alt="Agon" /> */}
            </div>
            <div className="absolute opacity-80 animate-float max-w-[176px] bottom-[10%] -translate-x-1/3 top-[180px] ml-[20px]">
              <div className="relative bg-green-900 bg-opacity-80 rounded-2xl grid place-items-center">
                {/* <img className="h-full w-full object-cover" src={env.baseUrl + '/images/banner-video-bg.png'} alt="Elevate your organization's digital capabilities" /> */}
                {/* <p className="text-heading-4 absolute text-white  font-bold ml-[49px]">Watch intro video</p> */}
                {/* <button className="rounded-full bg-white grid place-items-center absolute play-video w-[64px] h-[64px] left-[-15%]"><img src={env.baseUrl + '/images/icon-play.svg'} alt="play button" width="15" /></button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* new banner end */}

      <section className='parter-sec md:container mx-auto px-4'>
        <div className="partner md:mt-[70px] mt-[70px]">
          <div className="text-center">
            <h2 className='md:text-[56px] text-[40px] mx-auto font-bold'>Our <span className='text-[#F26722]'>Partners</span></h2>
            <h5 className='text-[24px] text-gray-600 md:w-[58%] mx-auto font-semibold'>Our partners fuel our digital success, delivering exceptional solutions through innovation, collaboration, and expertise</h5>
          </div>

          <div className="px-[12px] md:px-[36px] xl:px-0 mt-[40px] md:mt-[50px] border-b-[3px] pb-[30px] md:pb-[40px]">

            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-[24px] lg:grid-cols-6 ">
              <a className="ourPartnerLogo transition-all duration-300 p-[15px] md:pr-[15px] xl:w-auto lg:w-[184px] mx-auto" href="#">
                <img src={env.baseUrl + '/images/our-partner_logo-aws.png'} alt='Our Partners' />
              </a>
              <a className="ourPartnerLogo transition-all duration-300 p-[15px] md:pr-[15px] xl:w-auto lg:w-[184px] mx-auto mt-[-16px]" href="#">
                <img src={env.baseUrl + '/images/our-partner_logo-microsoft.png'} alt='Our Partners' />
              </a>
              <a className="ourPartnerLogo transition-all duration-300 p-[15px] md:pr-[15px] xl:w-auto lg:w-[184px] mx-auto" href="#">
                <img src={env.baseUrl + '/images/our-partner_logo-amazon.png'} alt='Our Partners' />
              </a>
              <a className="ourPartnerLogo transition-all duration-300 p-[15px] md:pr-[15px] xl:w-auto lg:w-[184px] mx-auto mt-[-20px]" href="#">
                <img src={env.baseUrl + '/images/our-partner_logo-ebay.png'} alt='Our Partners' />
              </a>
              <a className="ourPartnerLogo transition-all duration-300 p-[15px] md:pr-[15px] xl:w-auto lg:w-[184px]  mx-auto md:col-start-2 lg:col-start-auto mt-[-15px]" href="#">
                <img src={env.baseUrl + '/images/our-partner_logo-onbuy.png'} alt='Our Partners' />
              </a>
              <a className="ourPartnerLogo transition-all duration-300 p-[15px] md:pr-[15px] xl:w-auto lg:w-[184px]  mx-auto md:col-start-3 lg:col-start-auto mt-[-20px]" href="#">
                <img src={env.baseUrl + '/images/our-partner_logo-shopify.png'} alt='Our Partners' />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="whyUs mt-[50px] md:mt-[60px]">
        <div className="md:container mx-auto px-4 xs:p-5">
          <h2 className="base-h2 lg:w-1/2 mx-auto text-center md:text-[54px] text-[40px]">See <span className='text-[#F26722]'> why we are trusted</span> all over the world </h2>
          <p className="text-[24px] md:w-[62%] text-[#475467] mt-4 mx-auto text-center lg:px-8 font-semibold">
            Companies worldwide confide in these services to drive innovation and achieve digital excellence
          </p>
        </div>
      </section>

      <section className="tabs-sec md:container mx-auto px-4 mt-[20px] md:mt-[40px] d-none">
        <Tab.Group selectedIndex={currentIndex} onChange={(index) => { setCurrentIndex(index); selectedIndex.current = index; }}>
          <Tab.List className='text-center mx-auto'>
            <Tab className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[18px] text-gray-600 font-semibold border-gray-100 border-2 md:min-w-[220px] md:h-[70px] h-[45px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline  www'>Discovery</Tab>
            <Tab className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[18px] text-gray-600 font-semibold border-gray-100 border-2 md:min-w-[220px] md:h-[70px] h-[45px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Design & Architecture</Tab>
            <Tab className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[18px] text-gray-600 font-semibold border-gray-100 border-2 md:min-w-[220px] md:h-[70px] h-[45px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Digital Transformation</Tab>
            <Tab className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[18px] text-gray-600 font-semibold border-gray-100 border-2 md:min-w-[220px] md:h-[70px] h-[45px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>Cloud Migration</Tab>
            <Tab className='cutom-font-bold-200 px-5 mx-2 my-2 active:bg-gray-200 bg-gray-100 text-[18px] text-gray-600 font-semibold border-gray-100 border-2 md:min-w-[220px] md:h-[70px] h-[45px] rounded-[50px] gap-2 hover:border-[#FF9135] hover:text-[#FF9135] inline'>ERP Development</Tab>
          </Tab.List>
          <Tab.Panels className='p-3'>
            <Tab.Panel>
              <div className="grid md:grid-cols-12 lg:grid-cols-12 flex mt-[40px] md:min-h-[300px]">
                <div className="md:col-span-12 lg:col-span-6 bg-[#FFF3EA] md:p-[40px] md:pb-[10px] p-[15px] relative xs-t-center">
                  <h3 className='md:text-[42px] text-[30px] font-bold'>Discovery</h3>
                  <p className='text-[18px] mt-[20px] lg:w-[90%] min-h-[170px] md:min-h-[170px] lg:min-h-[170px]'>
                    The paramount hurdle in integrating technology within industries lies in discerning appropriate, enduring, and trustworthy tech transformations. Our adept team has adeptly addressed numerous such challenges.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 h-[50px] md:h-[66px] bg-[#F2F4F7] inline-block rounded-[50px] w-[180px] text-[18px] text-center justify-center flex items-center lg:bottom-[20px] mb-[10px] lg:left-[40px] bottom-[25px] left-[15px] hover:bg-[#F26722] duration-300 hover:text-white xs-mx-auto xs-mb-20 sm-float-right'> Learn more <FaArrowRight className='text-[15px] ml-[13px]' /> </Link>
                </div>
                <div className="md:col-span-12 lg:col-span-6">
                  <img className='w-full' src={env.baseUrl + '/images/discovery.png'} alt='Discovery' />
                </div>
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="grid md:grid-cols-12 lg:grid-cols-12 flex mt-[40px] md:min-h-[300px]">
                <div className="md:col-span-12 lg:col-span-6 bg-[#FFF3EA] md:p-[40px] md:pb-[10px] p-[15px] relative xs-t-center">
                  <h3 className='md:text-[42px] text-[30px] font-bold'>Design & Architecture</h3>
                  <p className='text-[18px] mt-[20px] lg:w-[90%] min-h-[170px] md:min-h-[170px] lg:min-h-[170px]'>
                    In the IT industry, design and architecture play a crucial role in creating user-friendly interfaces and scalable systems that drive digital innovation and seamless user experiences.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 h-[50px] md:h-[66px] bg-[#F2F4F7] inline-block rounded-[50px] w-[180px] text-[18px] text-center justify-center flex items-center lg:bottom-[20px] mb-[10px] lg:left-[40px] bottom-[15px] left-[15px] hover:bg-[#F26722] duration-300 hover:text-white xs-mx-auto xs-mb-20 sm-float-right'> Learn more <FaArrowRight className='text-[15px] ml-[13px]' /></Link>
                </div>
                <div className="md:col-span-12 lg:col-span-6">
                  <img className='w-full' src={env.baseUrl + '/images/web-design-architecture.png'} alt='Web design architecture' />
                </div>
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="grid md:grid-cols-12 lg:grid-cols-12 flex mt-[40px] md:min-h-[300px]">
                <div className="md:col-span-12 lg:col-span-6 bg-[#FFF3EA] md:p-[40px] md:pb-[10px] p-[15px] relative xs-t-center">
                  <h3 className='md:text-[42px] text-[30px] font-bold'>Digital Transformation</h3>
                  <p className='text-[18px] mt-[20px] lg:w-[90%] min-h-[170px] md:min-h-[170px] lg:min-h-[170px]'>
                    Digital transformation is a strategic journey that empowers organizations to leverage advanced technologies and innovative processes, revolutionizing their operations and driving sustainable growth in the digital age.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 h-[50px] md:h-[66px] bg-[#F2F4F7] inline-block rounded-[50px] w-[180px] text-[18px] text-center justify-center flex items-center lg:bottom-[20px] mb-[10px] lg:left-[40px] bottom-[15px] left-[15px] hover:bg-[#F26722] duration-300 hover:text-white xs-mx-auto xs-mb-20 sm-float-right'> Learn more <FaArrowRight className='text-[15px] ml-[13px]' /></Link>
                </div>
                <div className="md:col-span-12 lg:col-span-6">
                  <img className='w-full' src={env.baseUrl + '/images/digital-transformation.png'} alt='Digital transformation' />
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="grid md:grid-cols-12 lg:grid-cols-12 flex mt-[40px] md:min-h-[300px]">
                <div className="md:col-span-12 lg:col-span-6 bg-[#FFF3EA] md:p-[40px] md:pb-[10px] p-[15px] relative xs-t-center">
                  <h3 className='md:text-[42px] text-[30px] font-bold'>Cloud Migration</h3>
                  <p className='text-[18px] mt-[20px] lg:w-[90%] min-h-[170px] md:min-h-[170px] lg:min-h-[170px]'>
                    Cloud migration is a transformative process that enables businesses to seamlessly move their data, applications, and services to the cloud, unlocking the benefits of scalability, flexibility, and cost-efficiency.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 h-[50px] md:h-[66px] bg-[#F2F4F7] inline-block rounded-[50px] w-[180px] text-[18px] text-center justify-center flex items-center lg:bottom-[20px] mb-[10px] lg:left-[40px] bottom-[15px] left-[15px] hover:bg-[#F26722] duration-300 hover:text-white xs-mx-auto xs-mb-20 sm-float-right'> Learn more <FaArrowRight className='text-[15px] ml-[13px]' /></Link>
                </div>
                <div className="md:col-span-12 lg:col-span-6">
                  <img className='w-full' src={env.baseUrl + '/images/cloud-migration.png'} alt='Cloud migration' />
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="grid md:grid-cols-12 lg:grid-cols-12 flex mt-[40px] md:min-h-[300px]">
                <div className="md:col-span-12 lg:col-span-6 bg-[#FFF3EA] md:p-[40px] md:pb-[10px] p-[15px] relative xs-t-center">
                  <h3 className='md:text-[42px] text-[30px] font-bold'>ERP Development</h3>
                  <p className='text-[18px] mt-[20px] lg:w-[90%] min-h-[170px] md:min-h-[170px] lg:min-h-[170px]'>
                    ERP development involves the creation of comprehensive software solutions that integrate and automate various business processes, enhancing efficiency and providing real-time insights for informed decision-making.
                  </p>

                  <Link to="/services" className='cutom-font-bold-200 h-[50px] md:h-[66px] bg-[#F2F4F7] inline-block rounded-[50px] w-[180px] text-[18px] text-center justify-center flex items-center lg:bottom-[20px] mb-[10px] lg:left-[40px] bottom-[15px] left-[15px] hover:bg-[#F26722] duration-300 hover:text-white xs-mx-auto xs-mb-20 sm-float-right'> Learn more <FaArrowRight className='text-[15px] ml-[13px]' /></Link>
                </div>
                <div className="md:col-span-12 lg:col-span-6">
                  <img className='w-full' src={env.baseUrl + '/images/ERP-app-Development.png'} alt='ERP app development' />
                </div>

              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </section>

      <section className='Our-Services lg:mt-[60px]'>
        <div className='md:container p-4 mx-auto mt-[50px] lg:mt-[50px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Our <span className='text-[#F26722]'> Services</span></h2>

          <p className='text-[24px] text-[#667085] lg:w-[60%] text-center mx-auto mt-[15px] font-semibold'>
            Spark a revolution in the digital age through inventive services that accommodate diverse business prerequisites.
          </p>

          <div className="grid lg:grid-cols-12 mt-[70px]">
            <div className="lg:col-span-6 overflow-hidden">
              <div className="relative mb-16 ">
                <div className="relative rounded-[20px] overflow-hidden">
                  <div className="lg:pl-[56px] lg:pt-[56px] aspect-[430/275] lg:mt-[-30px] rounded-lg">
                    <img className="h-full w-[100%] lg:w-[96%] object-cover rounded-2xl z-10 relative hover:-translate-y-1 hover:scale-125 duration-500 overflow-hidden" src={env.baseUrl + '/images/our-services-img.png'} alt="Our Services" />
                  </div>
                  <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                    <div className=" bg-[#FFF3EA] lg:w-[96%] lg:h-[86%] rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9  group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className='lg:col-span-6'>
              <div className="md:grid md:grid-cols-2 md:gap-y-[5px] md:gap-x-[30px] lg:gap-x-[40px]">
                <div className='transition-all duration-300 partner-item lg:p-[15px]'>
                  <div className='text-[21px] text-[#F26722] cutom-font-bold'>
                    <span className='h-[8px] w-[8px] bg-[#F26722] rounded inline-block mr-2 relative top-[-3px] '></span> Engineering&nbsp;Experience
                  </div>
                  <p className='text-[16px] text-[#667085] leading-7 mt-[11px] lg:min-h-[200px] lg:ml-6 relative'>
                    Introducing our groundbreaking and cutting-edge SaaS platform specially designed for engineering success, empowering your team to optimise workflows and achieve unrivalled efficiency like ever before
                    <Link to='/services/engineering-experience' className="font-bold text-[#F26722]"> Read&nbsp;more... </Link>
                  </p>
                </div>
                <div className='transition-all duration-300 partner-item lg:p-[15px]'>
                  <div className='text-[21px] text-[#F26722] cutom-font-bold mt-5 md:mt-0'>
                    <span className='h-[8px] w-[8px] bg-[#F26722] rounded inline-block mr-2 relative top-[-3px]'></span> Digital Migration
                  </div>
                  <p className='text-[16px] text-[#667085] leading-7 mt-[11px] lg:min-h-[200px] lg:ml-6 relative'>
                    As the digital landscape evolves, it's crucial to stay ahead in this dynamic age. Experience the transformative power of our cutting-edge future-proof solutions, designed to empower your business for sustained profitable growth
                    <Link to='/services/digital-migration' className="font-bold text-[#F26722]"> Read&nbsp;more... </Link>
                  </p>
                </div>
                <div className='transition-all duration-300 partner-item lg:p-[15px]'>
                  <div className='text-[21px] text-[#F26722] cutom-font-bold mt-5'>
                    <span className='h-[8px] w-[8px] bg-[#F26722] rounded inline-block mr-2 relative top-[-3px]'></span> Cloud Engineering
                  </div>
                  <p className='text-[16px] text-[#667085] leading-7 mt-[11px] lg:min-h-[200px] lg:ml-6 relative'>
                    In this ever-evolving digital era, securing the future of your cloud engineering is paramount. Experience the unparalleled power of our cutting-edge SaaS product, designed to provide dynamic solutions that safeguard and elevate your cloud infrastructure.
                    <Link to='/services/cloud-engineering' className="font-bold text-[#F26722]"> Read&nbsp;more... </Link>
                  </p>
                </div>

                <div className='transition-all duration-300 partner-item lg:p-[15px]'>
                  <div className='text-[21px] text-[#F26722] cutom-font-bold mt-5'>
                    <span className='h-[8px] w-[8px] bg-[#F26722] rounded inline-block mr-2 relative top-[-3px]'></span> Technical Development
                  </div>
                  <p className='text-[16px] text-[#667085] leading-7 mt-[11px] lg:min-h-[200px] lg:ml-6 relative'>
                    Unlock the full potential of your technical vision and elevate your projects to new heights of success by embracing our intuitive SaaS solution. Experience the transformative power of advanced technology, seamless integration, and data-driven insights
                    <Link to='/services/technical-development' className="font-bold text-[#F26722]"> Read&nbsp;more... </Link>
                  </p>
                </div>
              </div>

              <div className='flex justify-center items-center'>
                <Link to='/services' className=' py-[20px] px-[30px] w-[180px] bg-[#101828] text-white  mt-[34px] rounded-[50px] text-[18px] font-bold flex justify-center items-center hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg float-left duration-300'>
                  Learn more <FaArrowRight className='text-[15px] ml-[13px]' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-office bg-[#FB9851] md:gap-5 py-[50px] lg:py-[60px] my-[50px] lg:my-[70px]">
        <div className="lg:container mx-auto px-4 text-white">
          <div className="grid lg:grid-cols-12 md:grid-cols-12 px-4 md:gap-8">
            <div className="lg:col-span-3 md:col-span-6 lg:pr-[70px] xs-t-center">
              <div className="mb-5 min-h-[245px]">
                <div className='text-[72px] font-semibold'>
                  <div id="counter">
                    <span className="counter-value" data-count="5">0 </span>x
                  </div>
                </div>
                <div className='text-[20px] cutom-font-bold'>Our Growth </div>
                <div className='text-[16px] mt-[10px]'>Our growth rate is skyrocketing, a testament to the success ratio of our customers.</div>
              </div>
              <div className="mb-5 min-h-[245px]">
                <div className='text-[72px] font-semibold'> <div id="counter">
                  <span className="counter-value" data-count="60">0 </span>+
                </div> </div>
                <div className='text-[20px] cutom-font-bold'>Happy Clients</div>
                <div className='text-[16px] mt-[10px]'>We always provide people a complete solution upon focused of any business</div>
              </div>
            </div>
            <div className="lg:col-span-3 md:col-span-6 lg:pr-[70px] xs-t-center">
              <div className="mb-5 min-h-[245px]">
                <div className='text-[72px] font-semibold'> <div id="counter">
                  <div id="counter">
                    <span className="counter-value" data-count="100">0 </span>+
                  </div>
                </div> </div>
                <div className='text-[20px] cutom-font-bold'>Completed Projects </div>
                <div className='text-[16px] mt-[10px]'>A track record of completed projects showcasing our expertise.</div>
              </div>
              <div className="mb-5 min-h-[245px]">
                <div className='text-[72px] font-semibold'><div id="counter">
                  <span className="counter-value" data-count="50">0 </span>+
                </div> </div>
                <div className='text-[20px] cutom-font-bold'>Work Force</div>
                <div className='text-[16px] mt-[10px]'>We always provide people a complete solution upon focused of any business</div>

              </div>
            </div>
            <div className="lg:col-span-6 md:col-span-12">
              <h3 className='lg:text-[56px] text-[30px] font-semibold leading-tight'>
                Your business puzzle is our priority. Give us the details, and we will provide the solution
              </h3>
              <div className='relative mt-9 md:mx-[50px] lg:mx-[auto]'>
                <button onClick={() => newsLatter()} className='absolute px-4 lg:px-9 right-[10px] rounded-[7px] top-[11px] bg-[#101828] h-[50px] text-white font-bold hover:bg-[#f26722] hover:text-[#fff] hover:drop-shadow-lg duration-300 flex jusity-center items-center'>
                  <span className='hidden md:block'>Reach Us</span>  <FaArrowRight className='text-[15px] md:ml-[13px]' /></button>
                <input onChange={(e) => setEmailReachUs({ ...emailReachUs, email: e.target.value })} type="email"
                  className="block w-full lg:pl-9 px-4 py-2 mt-5 h-[72px] text-gray-700 bg-white border rounded-md focus:border-orange-400 focus:ring-orange-300 focus:outline-none focus:ring focus:ring-opacity-40" placeholder='Enter your email ...' value={emailReachUs.email} />
              </div>
              {emailReachUs.email && !emailReachUs.emailValid ? (<span className="text-[red] mt-[10px] font-bold">{errorMsg || 'Please Enter Valid Email'}</span>) : null}

            </div>
          </div>
        </div>
      </section>

      <section className='Our-blogs'>
        <div className='md:container p-4 mx-auto mt-[50px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Our <span className='text-[#F26722]'> Blogs</span></h2>

          <p className='text-[24px] text-[#667085] lg:w-[50%] text-center mx-auto mt-[5px] font-semibold'>
            Dive into our potentiality and experience the power that drives our accomplishments.
          </p>

          <div className='mt-[50px]'>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              autoPlaySpeed={3000}
              autoPlay={true}
              infinite={true}
            >
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/we-can-blend-colors-multiple-ways" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">We can blend colors multiple ways, the most common</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-We-can-blend-colors-multiple-ways.png'} alt="Digital Transformation" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/engineering-experiences-a-journey" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Engineering Experiences: A Journey of Innovation and Creativity</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/Blog-Engineering-Experiences.png'} alt="Engineering Experiences" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#BEE1E6]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/saas-products" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Customer Services</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">SaaS Products</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/blog-SaaS-Products.png'} alt="Saas Products" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/the-evolution-significance" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Company</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">The Evolution and Significance of Cloud Engineering in Modern Computing</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/the-evolution-and-significance.png'} alt="The evolution and significance" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='md:px-4 mb-[100px]'>
                <Link to="/blog/navigating-the-digital-migration" className="block group">
                  <div className="flex items-center mb-[11px]">
                    <div className="bg-gray-500 rounded-full w-[3px] h-[3px] mr-[6px]"></div><span className=" text-gray-500">Marketing Event</span>
                  </div>
                  <p className="text-[24px] text-[#101828] mb-7 mt-3 cutom-font-bold leading-tight min-h-[60px] ellipsee two-lines w-[100%]">Navigating the Digital Migration: A New Frontier in Business Transformation</p>
                  <div className="relative mb-16">
                    <div className="relative">
                      <div className="pr-[26px] aspect-[430/275]"><img className="h-full w-full object-cover rounded-2xl z-10 relative" src={env.baseUrl + '/images/navigating-the-digital-migration.png'} alt="Navigating the digital migration" />
                      </div>
                      <div className="absolute w-full h-full left-0 z-0 top-0 translate-y-[30px] pl-[33px]">
                        <div className="w-full h-full rounded-2xl bg-opacity-50 transition-all duration-300 bg-bg-9 group-hover:-translate-x-[10px] group-hover:-translate-y-[10px] bg-[#FAD2E1]"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

            </Carousel>
          </div>
        </div>
      </section>

      <section className='Customer-testimonila'>
        <div className='md:container p-4 mx-auto my-[50px] lg:mt-[50px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Customer <span className='text-[#F26722]'> Testimonials</span></h2>
          <p className='text-[24px] text-[#667085] lg:w-[50%] text-center mx-auto mt-[5px] font-semibold'>Our global corporate brand serves a prestigious clientele, spanning across continents and industries</p>

          <div className=' mt-9 gap-10 mb-10'>

            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              autoPlaySpeed={3000}
              autoPlay={true}
              infinite={true}
            >
              <div>
                <div className='hover:shadow-xl bg-[#ffffff] border-8 border-[#FAD2E1] min-h-[370px] p-5 md:mx-3 lg:mx-3 lg:mx-3 mb-16 lg:mb-16'>
                  <img className='hover:drop-shadow-2xl max-w-full' src={env.baseUrl + '/images/testimonial-img.png'} />
                  <h5 className='text-[20px] font-semibold mt-5'>Stephen Holt</h5>
                  <h5 className='text-[14px] font-semibold mt-2'>Cert Chain</h5>
                  <p className='text-[16px] mt-5 text-[#667085]'>
                    We've enjoyed collaborating with Versatile Commerce for over a year. They've consistently excelled throughout our partnership, playing a pivotal role in preparing our SaaS platform for market readiness.
                  </p>
                </div>
              </div>

              <div>
                <div className='hover:shadow-xl bg-[#ffffff] border-8 border-[#BEE1E6] min-h-[370px] p-5 md:mx-3 lg:mx-3 lg:mx-3 mb-16 lg:mb-16'>
                  <img className='hover:drop-shadow-2xl max-w-full' src={env.baseUrl + '/images/testimonial-img-cii.png'} />
                  <h5 className='text-[20px] font-semibold mt-5'>Nana Marshall </h5>
                  <h5 className='text-[14px] font-semibold mt-2'>CII</h5>
                  <p className='text-[16px] mt-5 text-[#667085]'>
                    They are experts in their field, readily available to communicate, address queries, and offer solutions. Their team consists of highly skilled professionals, eliminating the need for resource upskilling.
                  </p>
                </div>
              </div>

              <div>
                <div className='hover:shadow-xl bg-[#ffffff] border-8 border-[#DDD3FA] min-h-[370px] p-5 md:mx-3 lg:mx-3 mb-16 lg:mb-16'>
                  <img className='hover:drop-shadow-2xl max-w-full' src={env.baseUrl + '/images/testimonial-img-prop-check.png'} />
                  <h5 className='text-[20px] font-semibold mt-5'>Sourabh Tyagi </h5>
                  <h5 className='text-[14px] font-semibold mt-2'>Prop Right</h5>
                  <p className='text-[16px] mt-5 text-[#667085]'>
                    Your team exhibits several vital qualities that greatly benefit our collaboration. They are highly responsive, always providing a definitive timeline for project initiation, even when delays occur.
                  </p>
                </div>
              </div>

              <div>
                <div className='hover:shadow-xl bg-[#ffffff] border-8 border-[#f9d6d6] min-h-[370px] p-5 md:mx-3 lg:mx-3 mb-16 lg:mb-16'>
                  <img className='hover:drop-shadow-2xl max-w-full' src={env.baseUrl + '/images/testimonial-wowment.png'} />
                  <h5 className='text-[20px] font-semibold mt-5'>Fran Boorman</h5>
                  <h5 className='text-[14px] font-semibold mt-2'>Wowment </h5>
                  <p className='text-[16px] mt-5 text-[#667085]'>
                    We have access to talented individuals with diverse skill sets, allowing us to scale resources up or down as needed. This team comprises hardworking and personable individuals who are knowledgeable and exceptionally supportive.
                  </p>
                </div>
              </div>

            </Carousel>
          </div>
          <div className='bg-[#E4F5FF] p-4 lg:pt-[50px] lg:pb-[50px] lg:px-[110px] mt-50 lg:mt-[80px] lg:rounded-[58px] rounded-[25px]'>
            <h3 className='text-[25px] lg:text-[56px] md:text-[37px] text-center font-semibold'>Trusted by the world’s <span className='text-[#F26722]'>leading companies</span></h3>

            {/* new logos start */}
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-x-[30px] gap-y-[20px] lg:gap-x-[50px] lg:gap-y-[30px] mt-[20px] lg:mt-[40px]'>

              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-1.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-2.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-3.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-4.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-5.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-6.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-7.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-8.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-9.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-10.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-11.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-12.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-13.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-14.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-15.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-16.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-17.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-18.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-19.png'} alt='Trusted by the leading companies' />
              </div>
              <div className='flex items-start justify-center items-center hover:shadow-lg'>
                <img className='max-w-full lg:my-[.6rem]' src={env.baseUrl + '/images/trusted-compay-logo-20.png'} alt='Trusted by the leading companies' />
              </div>

            </div>
            {/* new logos end */}
          </div>
        </div>
      </section>

      <section className='Offered-Industry'>
        <div className='md:container p-4 mx-auto lg:mt-[50px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Offered <span className='text-[#F26722]'> Industry Solutions</span></h2>
          <p className='text-[24px] text-[#667085] lg:w-[50%] text-center mx-auto mt-[5px] font-semibold'>A proven track record of successful industry engagements, illustrating our depth of industry knowledge</p>

          <div className='mt-[40px] lg:mt-[70px] gap-5'>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              autoPlaySpeed={3000}
              autoPlay={true}
              infinite={true}
            >
              <div>
                <div className="relative lg:mx-5 md:mx-3 md:mx-3 hover:shadow-xl hover:shadow-xl bg-[#FFEFE7] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/offer-tech-top-icon.png'} alt='Technology revolutionizes the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Technology</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Technology revolutionizes the industry, driving efficiency, innovation, and competitive advantage for businesses worldwide.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block font-bold items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/offer-tech-down.png'} alt='Technology revolutionizes the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#E4F5FF] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/offer-tech-top.png'} alt='Communication is a cornerstone in the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Communication</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Communication is a cornerstone in the industry, fostering collaboration, efficiency, and informed decision-making to drive business success.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block font-bold items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/offer-comn-down.png'} alt='Communication is a cornerstone in the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#F2F4F7] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/offer-media-top.png'} alt='Media plays a pivotal role in the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Media</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Media plays a pivotal role in the industry, enabling businesses to connect with their audiences, build brand presence, and disseminate information in a dynamic and engaging manner.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block font-bold items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/offer-media-down.png'} alt='Media plays a pivotal role in the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#FAD2E1] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-4.png'} alt='Healthcare is essential in the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Healthcare</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Healthcare is essential in the industry, providing vital medical services and innovative solutions to improve people's well-being and quality of life.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-4-4.png'} alt='Healthcare is essential in the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#BEE1E6] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-5.png'} alt='Finance is a critical pillar in the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Finance</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Finance is a critical pillar in the industry, facilitating economic activities, managing resources, and driving financial stability and growth.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-5-5.png'} alt='Finance is a critical pillar in the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#E1D8FB] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-6.png'} alt='Insurance is integral to the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Insurance</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Insurance is integral to the industry, providing risk mitigation and financial protection for individuals and businesses alike.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-6-6.png'} alt='Insurance is integral to the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#DDF1FD] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-7.png'} alt='The travel industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Travel</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The travel industry encompasses a diverse range of businesses and services, offering travelers unforgettable experiences, seamless journeys, and opportunities to discover the world.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-7-7.png'} alt='The travel industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#FFEFE7] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-8.png'} alt='The education industry empowers' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Education</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The education industry empowers individuals with knowledge, skills, and opportunities, shaping a brighter future for generations to come.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-8-8.png'} alt='The education industry empowers' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#E4F5FF] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-9.png'} alt='The consulting industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Consulting</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The consulting industry provides invaluable expertise and guidance, assisting businesses in making informed decisions and achieving strategic objectives.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-9-9.png'} alt='The consulting industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#F2F4F7] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-10.png'} alt='The automotive industry drives innovation' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Automotive</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The automotive industry drives innovation, offering a wide array of vehicles and technologies that redefine mobility and transportation.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-10-10.png'} alt='The automotive industry drives innovation' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#FAD2E1] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-11.png'} />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Retail</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The retail industry caters to diverse consumer needs, offering a vast array of products and services in physical and digital spaces.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-11-11.png'} alt='The automotive industry drives innovation' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#BEE1E6] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-12.png'} alt='The manufacturing industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Manufacturing</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The manufacturing industry plays a pivotal role in producing goods and materials, driving economic growth and technological advancements.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-12-12.png'} alt='The manufacturing industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#E1D8FB] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-13.png'} alt='The infrastructure industry builds and sustains vital systems' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Infrastucture</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The infrastructure industry builds and sustains vital systems, shaping the foundation for modern societies and economic development.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-13-13.png'} alt='The infrastructure industry builds and sustains vital systems' />
                </div>
              </div>
            </Carousel>

          </div>
        </div>
      </section>

      {/* <section className='Offered-Industry'>
        <div className='md:container p-4 mx-auto lg:mt-[50px]'>
          <h2 className='text-[40px] md:text-[56px] text-center font-bold'>Offered <span className='text-[#F26722]'> Industry Solutions</span></h2>
          <p className='text-[24px] text-[#667085] lg:w-[50%] text-center mx-auto mt-[5px] font-semibold'>A proven track record of successful industry engagements, illustrating our depth of industry knowledge</p>

          <div className='mt-[40px] lg:mt-[70px] gap-5'>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              autoPlaySpeed={3000}
              autoPlay={true}
              infinite={true}
            >
              <div>
                <div className="relative lg:mx-5 md:mx-3 md:mx-3 hover:shadow-xl hover:shadow-xl bg-[#FFEFE7] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/offer-tech-top-icon.png'} alt='Technology revolutionizes the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Technology</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Technology revolutionizes the industry, driving efficiency, innovation, and competitive advantage for businesses worldwide.
                  </p>
                  <Link to="/technology" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block font-bold items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/offer-tech-down.png'} alt='Technology revolutionizes the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#E4F5FF] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/offer-tech-top.png'} alt='Communication is a cornerstone in the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Communication</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Communication is a cornerstone in the industry, fostering collaboration, efficiency, and informed decision-making to drive business success.
                  </p>
                  <Link to="/communication" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block font-bold items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/offer-comn-down.png'} alt='Communication is a cornerstone in the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#F2F4F7] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/offer-media-top.png'} alt='Media plays a pivotal role in the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Media</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Media plays a pivotal role in the industry, enabling businesses to connect with their audiences, build brand presence, and disseminate information in a dynamic and engaging manner.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block font-bold items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/offer-media-down.png'} alt='Media plays a pivotal role in the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#FAD2E1] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-4.png'} alt='Healthcare is essential in the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Healthcare</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Healthcare is essential in the industry, providing vital medical services and innovative solutions to improve people's well-being and quality of life.
                  </p>
                  <Link to="/health-care" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-4-4.png'} alt='Healthcare is essential in the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#BEE1E6] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-5.png'} alt='Finance is a critical pillar in the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Finance</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Finance is a critical pillar in the industry, facilitating economic activities, managing resources, and driving financial stability and growth.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-5-5.png'} alt='Finance is a critical pillar in the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#E1D8FB] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-6.png'} alt='Insurance is integral to the industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Insurance</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    Insurance is integral to the industry, providing risk mitigation and financial protection for individuals and businesses alike.
                  </p>
                  <Link to="/insurance" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-6-6.png'} alt='Insurance is integral to the industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#DDF1FD] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-7.png'} alt='The travel industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Travel</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The travel industry encompasses a diverse range of businesses and services, offering travelers unforgettable experiences, seamless journeys, and opportunities to discover the world.
                  </p>
                  <Link to="/travels" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-7-7.png'} alt='The travel industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#FFEFE7] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-8.png'} alt='The education industry empowers' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Education</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The education industry empowers individuals with knowledge, skills, and opportunities, shaping a brighter future for generations to come.
                  </p>
                  <Link to="/education" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-8-8.png'} alt='The education industry empowers' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#E4F5FF] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-9.png'} alt='The consulting industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Consulting</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The consulting industry provides invaluable expertise and guidance, assisting businesses in making informed decisions and achieving strategic objectives.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-9-9.png'} alt='The consulting industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#F2F4F7] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-10.png'} alt='The automotive industry drives innovation' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Automotive</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The automotive industry drives innovation, offering a wide array of vehicles and technologies that redefine mobility and transportation.
                  </p>
                  <Link to="/automotive" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-10-10.png'} alt='The automotive industry drives innovation' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#FAD2E1] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-11.png'} />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Retail</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The retail industry caters to diverse consumer needs, offering a vast array of products and services in physical and digital spaces.
                  </p>
                  <Link to="/retail" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-11-11.png'} alt='The automotive industry drives innovation' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#BEE1E6] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-12.png'} alt='The manufacturing industry' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Manufacturing</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The manufacturing industry plays a pivotal role in producing goods and materials, driving economic growth and technological advancements.
                  </p>
                  <Link to="/manufacturing" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-12-12.png'} alt='The manufacturing industry' />
                </div>
              </div>

              <div>
                <div className="relative lg:mx-5 md:mx-3 hover:shadow-xl bg-[#E1D8FB] min-h-[470px] p-[15px] lg:py-[45px]  lg:px-[30px] rounded-[16px] mb-16 lg:mb-16">
                  <img className='max-w-full' src={env.baseUrl + '/images/Offered-iocn-13.png'} alt='The infrastructure industry builds and sustains vital systems' />
                  <h4 className='text-[35px] text-[#101828] mt-5 font-black'>Infrastucture</h4>
                  <p className='text-[#475467] text-[18px] mt-4'>
                    The infrastructure industry builds and sustains vital systems, shaping the foundation for modern societies and economic development.
                  </p>
                  <Link to="/services" className='cutom-font-bold-200 w-[180px] h-[56px] bg-[#F2F4F7] block items-center justify-center flex rounded-[8px] absolute bottom-[45px] left-[30px] hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg'>
                    Learn more
                    <FaArrowRight className='text-[15px] ml-[13px]' />
                  </Link>
                  <img className='max-w-full absolute bottom-[0px] right-[10px]' src={env.baseUrl + '/images/Offered-iocn-13-13.png'} alt='The infrastructure industry builds and sustains vital systems' />
                </div>
              </div>
            </Carousel>

          </div>
        </div>
      </section> */}

      <Suspense>
        <Discovery
          title={'Discovery'}
          subTitle={'Call'}
          desc={'Our team of experts diligently identify and resolve a diverse array of inefficiencies within the organisations technologies & optimising the business operations.'}
          btnName={'Book Now'}
          placeholder={'Enter your mail'} />
      </Suspense>

      <Suspense>
        <Faqs
          heading1={'What is Digital Transformation?'}
          content1={"Digital Transformation refers to the profound and holistic integration of digital technologies, processes, and strategies into all aspects of an organisation's operations and business models. It involves leveraging technological advancements to enhance customer experiences, streamline operations, improve decision-making, and create new value propositions. "}
          heading2={"Why is Digital Transformation important?"}
          content2={"Digital Transformation is crucial because it allows organisations to stay competitive and relevant in a rapidly evolving digital landscape. It enables increased efficiency, agility, and innovation, fostering better customer relationships and opening new avenues for growth and revenue"}
          heading3={"What are the benefits and obstacles of Digital Transformation?"}
          content3={"Benefits of Digital Transformation include improved operational efficiency, enhanced customer experiences, data-driven insights, innovation opportunities, and competitive advantages. Obstacles can include resistance to change, resource constraints, cultural barriers, cybersecurity concerns, and the need for significant upfront investments. "}
          heading4={"How Can Digital Transformation Help My Business?"}
          content4={"Digital Transformation can help your business by optimising processes, enabling data-driven decision-making, reaching new markets, enhancing customer engagement, and fostering innovation. It can also lead to improved employee productivity and better adaptation to market trends. "}
          heading5={"How can I justify the cost of Digital Transformation?"}
          content5={"To justify the cost of Digital Transformation, focus on potential ROI, increased revenue streams, reduced operational costs, improved customer satisfaction, and long-term competitive advantages. Develop a clear business case that outlines the expected benefits and aligns with your organisations strategic goals. "}
          status={true}
        />
      </Suspense>

      {/* dialog start */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999] tex-[red] bg-[blue]" onClose={closeModal} >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full lg:max-w-[70%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="">
                    <section className="eqry-form">
                      <div className="lg:container mx-auto">
                        <div className="bg-gray-100 relative p-[40px] md:py-[20px] md:px-[40px] rounded-[16px]">
                          <button className='absolute  right-3 md:right-10' onClick={closeModal}><i class="las la-times text-[20px] text-[#ffa2a2] bg-white rounded-[50px] px-3 py-3 font-bold hover:text-[red] flex justify-center items-center"></i></button>
                          {/* <p className="base-p4 uppercase">Contact us</p> */}
                          <h4 className='text-[30px] lg:text-[35px] cutom-font-bold'>Have a project in mind?</h4>
                          <div className="base-p3 mb-6 font-bold">The right move at the right time saves your investment. Live the dream of expanding your business.</div>

                          {/* form start */}
                          <form className="flex-1" action="/">
                            <div className="flex flex-col gap-6 mb-6 lg:flex-row xl:gap-[30px]">
                              <input className={`outline-none flex-1 placeholder:text-gray-400 placeholder:text-md py-3 px-[30px] ${(data.isFormTouched && !data.name) ? 'inValidInput' : ''}`} type="text" placeholder="Enter your name" value={data.name}
                                onChange={e => setData({ ...data, isFormTouched: true, name: e.target.value })} />

                              <input className={`outline-none flex-1 placeholder:text-gray-400 placeholder:text-md py-3 px-[30px] ${(data.isFormTouched && !data.companyName) ? 'inValidInput' : ''}`} type="text" placeholder="Company" value={data.companyName}
                                onChange={e => setData({ ...data, isFormTouched: true, companyName: e.target.value })} />
                            </div>
                            <div className="flex flex-col gap-6 mb-6 lg:flex-row xl:gap-[30px]">
                              <input className={`outline-none lg:w-[49%] placeholder:text-gray-400 placeholder:text-md py-3 px-[30px] ${(data.isFormTouched && !data.emailValid) ? 'inValidInput' : ''}`} type="text" placeholder="Your email" value={data.email}
                                onChange={e => {
                                  let emailValid = false;
                                  if (validateEmail(e.target.value)) {
                                    emailValid = true;
                                  }
                                  setData({ ...data, isFormTouched: true, email: e.target.value, emailValid: emailValid });
                                }}
                              />

                              <select onChange={e => { setData({ ...data, isFormTouched: true, countryCode: e.target.value }); }} className={`outline-none  placeholder:text-gray-400 placeholder:text-md text-[#66708d] py-3 px-[10px] lg:w-[12%] ${(data.isFormTouched && !data.countryCode) ? 'inValidInput' : ''} `}>
                                <option value={""}>Select Country</option>
                                <Suspense>
                                  <CountryList />
                                </Suspense>
                              </select>
                              <input className={`outline-none placeholder:text-gray-400 placeholder:text-md py-3 px-[30px]  lg:w-[34%] ${(data.isFormTouched && !data.phoneNoValid) ? 'inValidInput' : ''}`} type="text" maxLength={12} minLength={10} placeholder="Phone number" value={data.phoneNo}
                                onChange={e => {
                                  let phoneNoValid = false;
                                  if (e.target.value.length > 9 && e.target.value.length < 13) {
                                    phoneNoValid = true;
                                  }
                                  setData({ ...data, isFormTouched: true, phoneNo: e.target.value, phoneNoValid: phoneNoValid })
                                }}
                              />
                            </div>
                            <textarea className={`w-full py-5 resize-none outline-0 px-[30px] max-h-[90px] mb-[35px] ${(data.isFormTouched && !data.message) ? 'inValidInput' : ''}`} name="" cols="100" rows="10" placeholder="Tell us about yourself" value={data.message}
                              onChange={e => setData({ ...data, isFormTouched: true, message: e.target.value })}></textarea>

                            {data.isFormTouched && (data.emailValid == false || data.phoneNoValid == false || data.name == '' || data.phoneNo == '' || data.message == '') ?
                              (<div className='lg:flex justify-left items-center text-[#f44336] font-[600]'>Please Fill All Required Fields.
                              </div>) : null
                            }

                            {data.errorMsg != "" ?
                              (<div className='lg:flex justify-left items-center text-[#f44336] font-[600]'>
                                {errorMsg}
                              </div>) : null
                            }

                            <div className='leading-3 mr-4 text-[#667085] text-[14px]'>
                              <p className='md:mt-[-40px] md:text-right text-center py-3'>By clicking contact us button, you agree our <a href='/' className='hover:text-gray-900'> terms and policy,</a>
                              </p>
                            </div>
                            <div className='lg:flex justify-end items-end'>
                              <div className='Captcha mr-10'>
                                <div className='captcha-inlines'>
                                  <div className='Captcha-charectr font-bold tracking-widest'>
                                  </div>
                                  <div className='Captcha-reload font-bold relative '><LoadCanvasTemplate reloadColor="#f36969" reloadText={'&#x21bb;'} /></div>
                                </div>
                                <div className='clear-both'></div>

                                {data.isInvalidCapcha == true && (<div className='text-[red] text-[14px] mt-3'>Invalid Captcha</div>)}
                                <input value={data.capcha} className={`outline-none flex-1 placeholder:text-gray-400 placeholder:text-md py-3 px-[20px] border-2 tracking-widest ${(data.isFormTouched && !data.capcha) ? 'inValidInput' : ''}`} type="text" placeholder="Enter Captcha Here"
                                  onChange={e => setData({ ...data, isFormTouched: true, capcha: e.target.value })}
                                />
                                <div className='clear-both'></div>
                              </div>
                              <div>
                                <button type='button' onClick={contactUsFun} class=" py-[20px] px-[30px] min-w-[180px] bg-[#101828] text-white  mt-[34px] rounded-[50px] text-[18px] font-bold flex justify-center items-center hover:bg-[#f26722] hover:text-[#fff] hover:shadow-lg float-left duration-300 inline-block">Submit <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="text-[15px] ml-[13px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></button>
                              </div>
                            </div>
                            <div className='clear-both'></div>
                          </form>
                          {/* form end */}
                        </div>
                      </div>
                    </section>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* dialog end */}

      {/* Success dialog start */}
      <Transition appear show={isOpen3} as={Fragment}>
        <Dialog as="div" className="relative z-[9999] tex-[red] bg-[blue]" onClose={isOpenSuccess} >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full lg:max-w-[50%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">

                  <div className="">
                    <section className="eqry-form">
                      <div className="lg:container mx-auto">
                        <div className="bg-gray-100 relative p-[40px] md:py-[20px] md:px-[40px] rounded-[16px]">
                          <button className='absolute  right-3 md:right-10' onClick={isCloseSuccess}><i class="las la-times text-[20px] text-[#ffa2a2] bg-white rounded-[50px] px-3 py-3 font-bold hover:text-[red] flex justify-center items-center"></i></button>
                          {/* <p className="base-p4 uppercase">Contact us</p> */}

                          {/* form start */}
                          <div className='text-center my-5'>
                            <i class="las la-check-circle text-[green] text-[80px] md:text-[150px]"></i>

                            <h4 className='text-[30px] lg:text-[35px] cutom-font-bold mb-5 mt-10'>Thank You</h4>

                            <p>Your Submission has been sent</p>
                          </div>
                          {/* form end */}
                        </div>
                      </div>
                    </section>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/*Success dialog end */}
    </>
  )
}

export default Home